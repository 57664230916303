import {FunctionComponent} from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: string;
  color?: string;
}

export const DownloadIcon: FunctionComponent<Props> = ({
  size = '21',
  color = '#2048D7',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14.45 7.41602C17.45 7.67435 18.675 9.21602 18.675 12.591V12.6993C18.675 16.4243 17.1833 17.916 13.4583 17.916H8.03332C4.30832 17.916 2.81665 16.4243 2.81665 12.6993V12.591C2.81665 9.24102 4.02498 7.69935 6.97498 7.42435"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.75 1.66602V12.3993"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5417 10.541L10.75 13.3327L7.95837 10.541"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
