import {FunctionComponent, useContext} from 'react';
import styled from 'styled-components';
import RedactAI from '../molecules/images/RedactAI.png';
import {MenuButton} from '../atoms/MenuButton';
import {SubscriptionInformationCard} from '../molecules/SubscriptionInformationCard';
import {useNavigate} from 'react-router-dom';
import {isSmallScreen} from '../../utils/responsive';
import {UserContext} from '../../common/UserContext/user.context';
import {creditsToPost} from '../../utils/creditsToPost';
import {Subscription} from '../../hooks/subscription/useSubscription';
import {Route} from '../templates/Page';
import {LoadingSubscriptionInformationCard} from '../molecules/LoadingSubscriptionInformationCard';

export const SIDEBAR_WIDTH = 14;

interface Props {
  subscription: Subscription | undefined;
  renewalDate: Date | null;
  routes: Route[];
}

export const NavigationBar: FunctionComponent<Props> = ({
  subscription,
  renewalDate,
  routes,
}) => {
  const navigate = useNavigate();
  const {me, loading} = useContext(UserContext);
  return (
    <NavBar>
      <div>
        <Image
          src={RedactAI}
          alt="redactai"
          onClick={() => navigate('/redactPost')}
        />
        <Routes>
          {routes.map((route, index) => (
            <Relative key={index}>
              <MenuButton
                onClick={() => navigate(route.path)}
                label={route.name}
                startIcon={route.icon}
                isActive={
                  location.pathname === route.path ||
                  location.pathname.startsWith(`${route.path}/`)
                }
              />
              {route.isNew && <New>Beta</New>}
            </Relative>
          ))}
        </Routes>
      </div>
      {subscription && !loading ? (
        <StyledSubscriptionInformationCard
          name={`${me?.firstName} ${me?.lastName}`}
          numberOfPostsLeft={creditsToPost(subscription?.remainingCredits || 0)}
          numberOfPosts={creditsToPost(subscription?.product.credits || 0)}
          renewalDate={renewalDate!}
          canceled={!subscription?.isActive}
          cancelsAtPeriodEnd={subscription.cancelsAtPeriodEnd}
          validUntil={subscription.validUntil}
        />
      ) : (
        <LoadingSubscriptionInformationCard />
      )}
    </NavBar>
  );
};

const NavBar = styled.div`
  width: ${SIDEBAR_WIDTH}em;
  height: 100vh;
  background-color: ${({theme}) => theme.colors.neutral.shade14};
  margin: 0;
  padding: 0 1.5em;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  @media (max-width: ${isSmallScreen}) {
    border-right: 1px solid ${({theme}) => theme.colors.neutral.shade6};
    display: none;
  }
`;
const Image = styled.img`
  width: 100%;
  cursor: pointer;
  margin-top: 2em;
`;
const Routes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-bottom: 3em;
  margin-top: 3em;
`;
const Relative = styled.div`
  position: relative;
`;
const New = styled.span`
  padding: 0.3em 0.5em;
  border-radius: 0.7em;
  text-align: center;
  color: ${({theme}) => theme.colors.primary3.shade2};
  font-weight: 600;
  line-height: 1.25em;
  font-size: 0.75em;
  letter-spacing: 0.0125em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  width: fit-content;
  position: absolute;
  top: 50%;
  right: 0.2em;
  transform: translateY(-50%);
  cursor: pointer;
`;
const StyledSubscriptionInformationCard = styled(SubscriptionInformationCard)`
  margin-bottom: 2em;
`;
