import {useMutation, useQueryClient} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {fetchApi} from '../../utils/fetchApi';

export const useSavePost = () => {
  const queryClient = useQueryClient();
  const savePost = useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['savedPosts']});
    },
    mutationFn: async (data: {id: string; text: string}) =>
      fetchApi(
        `/savedPosts/update/${data.id}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            text: data.text,
          }),
        },
        t`Unable to save post`,
      ),
  });

  return savePost;
};
