import styled from 'styled-components';

export const ThreeDotsLoader = styled.div`
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${({theme}) => theme.colors.neutral.shade11};
  color: ${({theme}) => theme.colors.primary1.shade2};
  box-shadow: 9999px 0 0 0 ${({theme}) => theme.colors.primary1.shade2};
  animation: dot-falling 1s infinite linear;
  animation-delay: 0.1s;
  ::before,
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  ::before {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({theme}) => theme.colors.primary1.shade2};
    color: ${({theme}) => theme.colors.primary1.shade2};
    animation: dot-falling-before 1s infinite linear;
    animation-delay: 0s;
  }
  ::after {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({theme}) => theme.colors.primary1.shade2};
    color: ${({theme}) => theme.colors.primary1.shade2};
    animation: dot-falling-after 1s infinite linear;
    animation-delay: 0.2s;
  }

  @keyframes dot-falling {
    0% {
      box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9999px 0 0 0 ${({theme}) => theme.colors.primary1.shade2};
    }
    100% {
      box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }
  @keyframes dot-falling-before {
    0% {
      box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9984px 0 0 0 ${({theme}) => theme.colors.primary1.shade2};
    }
    100% {
      box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }
  @keyframes dot-falling-after {
    0% {
      box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 10014px 0 0 0 ${({theme}) => theme.colors.primary1.shade2};
    }
    100% {
      box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }
`;
