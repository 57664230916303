import {FunctionComponent} from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {}

export const ErrorIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 70 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="35" cy="34.5" r="34.5" fill="#EC6A5E" />
      <path
        d="M25 45L45 25"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M45 45L25 25"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};
