import {useMutation} from '@tanstack/react-query';

export const useGenerateProfileFeedback = () => {
  return useMutation({
    mutationFn: async (username: string) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/free-tools/profile-feedback`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({username}),
        },
      );

      if (response.ok) {
        const feedback = await response.text();
        return feedback;
      }

      throw new Error('Failed to fetch feedback for this LinkedIn profile.');
    },
  });
};
