import {FunctionComponent} from 'react';
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import {theme} from './theme';

interface Props {
  children: React.ReactNode;
}

export const ThemeProvider: FunctionComponent<Props> = ({children}) => {
  const muiTheme = {};
  return (
    <MuiThemeProvider theme={muiTheme}>
      <StyledEngineProvider injectFirst>
        <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      </StyledEngineProvider>
    </MuiThemeProvider>
  );
};
