import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {Chip} from '../atoms/Chip';
import {Button} from '../atoms/Button';
import TrashIcon from '../atoms/Icons/TrashIcon';
import {t} from '@lingui/macro';
import {SavedIdeaInterface} from '../../pages/findIdea/components/MyIdeasTab';
import {format} from 'date-fns';
import {useMarkSavedIdeaAsUsed} from '../../hooks/savedPostsAndIdeas/useMarkSavedIdeaAsUsed';
import {DeleteIdeaPopup} from './popups/DeleteIdeaPopup';
import {useNavigate} from 'react-router-dom';

interface Props {
  idea: SavedIdeaInterface;
  fullWidth?: boolean;
}

export const SavedIdea: FunctionComponent<Props> = ({idea, fullWidth}) => {
  const markSavedIdeaAsUsed = useMarkSavedIdeaAsUsed();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const navigate = useNavigate();
  return (
    <Card $fullWidth={fullWidth}>
      <Header>
        <Chip
          label={t`Created at ${format(idea.createdAt, 'MM/dd/yyyy, hh:mm:ss')}`}
          variant="date"
        />
        <DeleteButton onClick={() => setShowDeletePopup(true)}>
          <TrashIcon />
        </DeleteButton>
      </Header>
      <Title>{idea.subject}</Title>
      <KeyIdeasSection>
        <KeyIdeasTitle>{t`Key ideas`}</KeyIdeasTitle>
        <KeyIdeasList>
          {idea.ideas.map((ideaText, index) => (
            <KeyIdea key={index}>{ideaText}</KeyIdea>
          ))}
        </KeyIdeasList>
      </KeyIdeasSection>
      <Footer>
        <Button
          onClick={() => markSavedIdeaAsUsed.mutate(idea.id)}
          label={idea.isUsed ? t`Mark as not used` : t`Mark as used`}
          fullWidth={false}
          variant="light"
          size="medium"
        />
        <Button
          label={t`Generate Post`}
          fullWidth={false}
          variant="main"
          size="medium"
          onClick={() => {
            navigate(
              `/redactPost?subject=${
                idea.subject
              }&ideas=${idea.ideas.join('::')} `,
            );
          }}
        />
      </Footer>
      {showDeletePopup && <DeleteIdeaPopup {...{idea, setShowDeletePopup}} />}
    </Card>
  );
};

const Card = styled.div<{$fullWidth: Props['fullWidth']}>`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  padding: 1em;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
  width: ${({$fullWidth}) => ($fullWidth ? '100%' : '25em')};
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DeleteButton = styled.span`
  cursor: pointer;
`;
const Title = styled.h2`
  font-size: 1.3em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
  margin: 0;
`;
const KeyIdeasSection = styled.div`
  flex: 1;
`;
const KeyIdeasTitle = styled.h3`
  font-size: 1.2em;
  font-weight: 500;
  color: ${({theme}) => theme.colors.neutral.shade9};
  margin-bottom: 1.2em;
  margin-top: 0;
`;
const KeyIdeasList = styled.ul`
  list-style-type: disc;
  padding-left: 1.2em;
`;
const KeyIdea = styled.li`
  font-size: 1.2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  margin-bottom: 1.2em;
  font-weight: 500;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  justify-content: center;
  margin-top: -1.2em;
`;
