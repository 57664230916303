import {useRef, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import ChevronBottom from '../../../components/atoms/Icons/ChevronBottom';
import {isTablet} from '../../../utils/responsive';
import {useClickOutside} from '../../../hooks/useClickOutside';

export interface FilterSelectOption<T> {
  label: string;
  value: T;
}

interface Props<T> {
  selectedOption: FilterSelectOption<T>;
  setSelectedOption: (option: FilterSelectOption<T>) => void;
  options: FilterSelectOption<T>[];
}

export const FilterSelect = <T,>({
  selectedOption,
  setSelectedOption,
  options,
}: Props<T>) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => {
    setIsActive(false);
  });

  return (
    <Container
      ref={containerRef}
      $active={isActive}
      onClick={() => {
        setIsActive(!isActive);
      }}>
      <SelectedOption>
        <OptionLabel>{selectedOption.label}</OptionLabel>
      </SelectedOption>
      <StyledChevronBottom $active={isActive} />
      {isActive && (
        <SubContainer>
          {options.map((option, index) => (
            <StyledButton
              key={index}
              $selected={selectedOption.value === option.value}
              onClick={() => {
                setSelectedOption(option);
                setIsActive(false);
              }}>
              {option.label}
            </StyledButton>
          ))}
        </SubContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{$active: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.625em;
  justify-content: space-between;
  padding: 1em;
  border-radius: 1em;
  border: 1px solid
    ${({theme, $active}) =>
      $active ? theme.colors.primary1.shade2 : theme.colors.neutral.shade5};
  box-shadow: ${({$active, theme}) =>
    $active ? `0px 0px 0px 4px ${theme.colors.primary1.shade1}` : 'none'};
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  flex: 1;
  cursor: pointer;
  transition:
    border 0.3s ease,
    box-shadow 0.3s ease;
`;

const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const OptionLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-weight: 600;
  font-size: 0.875em;
  letter-spacing: 0.03125em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SubContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 4em;
  padding: 0.8em;
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 0.625em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
  z-index: 20;
  animation: ${fadeIn} 0.3s ease forwards;
  @media (max-width: ${isTablet}) {
    top: 3.8em;
  }
`;

const StyledButton = styled.button<{$selected: boolean}>`
  display: flex;
  align-items: center;
  gap: 0.625em;
  border-radius: 0.75em;
  padding: 0.5em;
  font-size: 0.875em;
  letter-spacing: 0.0125em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
  background-color: ${({$selected, theme}) =>
    $selected ? theme.colors.primary1.shade5 : 'transparent'};
  border: none;
  text-align: start;
  cursor: pointer;
  transition: background-color 0.2s ease;

  ${({theme, $selected}) =>
    $selected &&
    `
      border: 2px solid ${theme.colors.primary1.shade2};
    `}
`;

const StyledChevronBottom = styled(ChevronBottom)<{$active: boolean}>`
  transition: transform 0.3s ease;
  transform: ${({$active}) => ($active ? 'rotate(180deg)' : 'rotate(0deg)')};
  color: ${({theme}) => theme.colors.neutral.shade11};
  @media (max-width: ${isTablet}) {
    height: 1.25em;
    width: 1.25em;
  }
`;
