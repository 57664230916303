import {FunctionComponent} from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {}

export const BackgroundStripe: FunctionComponent<Props> = (props) => {
  return (
    <svg
      width="1440"
      height="784"
      viewBox="0 0 1440 784"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        opacity="0.3"
        d="M-461.33 -215.05C-359.06 274.74 305.903 -671.239 565.712 168.943C773.559 841.089 1280.34 785.499 1507.75 673.685"
        stroke="url(#paint0_linear_4916_23333)"
        strokeWidth="4"
      />
      <path
        opacity="0.3"
        d="M-847.045 -810.95C-718.262 -194.18 119.096 -1385.41 446.261 -327.405C707.994 518.998 1346.16 448.995 1632.53 308.194"
        stroke="url(#paint1_linear_4916_23333)"
        strokeWidth="4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4916_23333"
          x1="1086.1"
          y1="800.007"
          x2="317"
          y2="-69.0018"
          gradientUnits="userSpaceOnUse">
          <stop
            stopColor="#FFAC3B"
            style={{stopColor: '#FFAC3B', stopOpacity: 1}}
          />
          <stop
            offset="1"
            stopColor="#FFAC3B"
            stopOpacity="0"
            style={{stopColor: 'none', stopOpacity: 0}}
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4916_23333"
          x1="1101.56"
          y1="467.266"
          x2="133.07"
          y2="-627.038"
          gradientUnits="userSpaceOnUse">
          <stop
            stopColor="#FFAC3B"
            style={{stopColor: '#FFAC3B', stopOpacity: 1}}
          />
          <stop
            offset="1"
            stopColor="#FFAC3B"
            stopOpacity="0"
            style={{stopColor: 'none', stopOpacity: 0}}
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
