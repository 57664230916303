import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Button} from '../../atoms/Button';
import {t} from '@lingui/macro';
import {TextInput} from '../../atoms/TextInput';
import {AuthMode} from '../../../pages/login/LoginPage';
import {useResetPassword} from '../../../hooks/resetPassword/useResetPassword';

interface Props {
  onAuthModeChange: (mode: AuthMode) => void;
}

export const ResetPasswordForm: FunctionComponent<Props> = ({
  onAuthModeChange,
}) => {
  const {email, setEmail, message, error, clearError, handleResetPassword} =
    useResetPassword();

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    handleResetPassword();
  };

  return (
    <VerificationContainer>
      <Title>{t`Reset your password`}</Title>
      <Message>{t`We will send you an email to reset your password`}</Message>
      <StyledTextInput
        label="Email"
        placeholderText={t`Enter email`}
        value={email}
        onChange={(event) => {
          setEmail(event.target.value);
          clearError();
        }}
        error={!!error}
        helperText={error}
      />
      {message && <SuccessMessage>{message}</SuccessMessage>}
      <Button
        label={t`Send email`}
        variant="main"
        size="medium"
        onClick={handleSubmit}
      />
      <Button
        label={t`Back`}
        variant="light"
        size="medium"
        onClick={() => {
          onAuthModeChange(AuthMode.LOGIN);
        }}
      />
      <Stylediv />
    </VerificationContainer>
  );
};

const VerificationContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2em;
  background: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  box-shadow: 0 4px 12px ${({theme}) => theme.colors.neutral.shade5};
  width: 27em;
  gap: 1em;
`;

const Title = styled.h2`
  font-size: 2.3em;
  font-weight: 700;
  margin-bottom: 0.5em;
  align-self: center;
`;

const Message = styled.p`
  font-size: 1.2em;
  color: ${({theme}) => theme.colors.neutral.shade7};
  text-align: center;
  margin-bottom: 1.5em;
  font-weight: 500;
`;

const Stylediv = styled.div`
  margin: 0.5em;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  margin-bottom: 1em;
`;

const SuccessMessage = styled.p`
  color: ${({theme}) => theme.colors.primary3.shade2};
  text-align: center;
  margin-bottom: 1em;
`;
