import {FunctionComponent} from 'react';

interface Props {
  size?: number;
  color?: string;
  className?: string;
}

const HistoryIcon: FunctionComponent<Props> = ({
  size = 16,
  color = '#FFAC3B',
  className,
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M5.20732 1.33203H10.7873C13.2207 1.33203 14.6673 2.7787 14.6673 5.20536V10.7854C14.6673 13.212 13.2207 14.6587 10.794 14.6587H5.20732C2.78065 14.6654 1.33398 13.2187 1.33398 10.792V5.20536C1.33398 2.7787 2.78065 1.33203 5.20732 1.33203Z"
        fill={color}
      />
      <path
        d="M7.99935 12.3882C5.88602 12.3882 4.16602 10.6682 4.16602 8.55488C4.16602 6.48155 5.81935 4.79488 7.87268 4.72155L7.62602 4.43488C7.44602 4.22821 7.46602 3.90821 7.67268 3.72821C7.87935 3.54821 8.19935 3.56821 8.37935 3.77488L9.48601 5.04155C9.49935 5.05488 9.50602 5.06821 9.51935 5.08155C9.54602 5.12155 9.57268 5.16821 9.58602 5.21488C9.59268 5.24155 9.59935 5.26821 9.60602 5.29488C9.61935 5.35488 9.61268 5.42155 9.59935 5.48155C9.58602 5.53488 9.56602 5.58821 9.53935 5.64155C9.53268 5.65488 9.51935 5.66821 9.51268 5.68155C9.48602 5.72155 9.44601 5.75488 9.40601 5.78155C9.40601 5.78155 9.39935 5.78155 9.39935 5.78821L8.10602 6.73488C7.87935 6.89488 7.57268 6.84821 7.40602 6.62821C7.24602 6.40821 7.29268 6.09488 7.51268 5.92821L7.78602 5.72821C6.31935 5.83488 5.15935 7.06154 5.15935 8.55488C5.15935 10.1149 6.43268 11.3882 7.99268 11.3882C9.55268 11.3882 10.826 10.1149 10.826 8.55488C10.826 7.99488 10.6593 7.44821 10.3527 6.98155C10.1993 6.75488 10.2593 6.44155 10.4927 6.28821C10.726 6.13488 11.0327 6.19488 11.186 6.42821C11.606 7.06155 11.8327 7.79488 11.8327 8.55488C11.8327 10.6682 10.1127 12.3882 7.99935 12.3882Z"
        fill={color}
      />
    </svg>
  );
};

export default HistoryIcon;
