import {t} from '@lingui/macro';
import {FunctionComponent} from 'react';
import BlogIcon from '../../atoms/Icons/BlogIcon';
import LinkedInIcon from '../../atoms/Icons/LinkedInIcon';
import UrlIcon from '../../atoms/Icons/UrlIcon';
import WebsiteIcon from '../../atoms/Icons/WebsiteIcon';
import YoutubeIcon from '../../atoms/Icons/YoutubeIcon';
import styled from 'styled-components';
import {isTablet} from '../../../utils/responsive';

interface Props {
  onClick: () => void;
}

export const FromUrl: FunctionComponent<Props> = ({onClick}) => {
  return (
    <Container onClick={onClick}>
      <Header>
        <StyledUrlIcon /> {t`From an URL`}
      </Header>
      <Grids>
        <Grid>
          <WebsiteIcon />
          <Text>{t`Your website`}</Text>
        </Grid>
        <Grid>
          <YoutubeIcon />
          <Text>{t`A Youtube video`}</Text>
        </Grid>
        <Grid>
          <LinkedInIcon />
          <Text>{t`A Linkedin post`}</Text>
        </Grid>
        <Grid>
          <BlogIcon />
          <Text>{t`A blog article`}</Text>
        </Grid>
      </Grids>
    </Container>
  );
};
const Container = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1em;
  padding-top: 2em;
  width: 19.875em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${isTablet}) {
    padding: 1.5em;
    align-items: start;
    width: 100%;
    box-sizing: border-box;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.25em;
  letter-spacing: 0.0125em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Grids = styled.div`
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  height: 100%;
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
const Grid = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade13};
  border-radius: 0.875em;
  padding: 1.25em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25em;
  text-align: center;
  flex: 1;
  white-space: nowrap;
`;
const Text = styled.span`
  font-size: 0.875em;
  line-height: 1.25;
`;
const StyledUrlIcon = styled(UrlIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
