import {Dispatch, FunctionComponent, SetStateAction, useState} from 'react';
import styled from 'styled-components';
import {TextField} from '@mui/material';
import {SearchIcon} from './Icons/SearchIcon';

interface Props {
  searchFilter: string;
  setSearchFilter: Dispatch<SetStateAction<string>>;
}

const SearchBar: FunctionComponent<Props> = ({
  searchFilter,
  setSearchFilter,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <SearchInput
      {...props}
      variant="outlined"
      placeholder="Search..."
      value={searchFilter}
      onChange={(event) => setSearchFilter(event.target.value)}
      InputProps={{
        startAdornment: !isFocused ? <SearchIcon /> : null,
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      $isFocused={isFocused}
    />
  );
};

const SearchInput = styled(TextField)<{$isFocused: boolean}>`
  position: relative;

  & .MuiOutlinedInput-root,
  & .MuiInputBase-input {
    font-family: ${({theme}) => theme.fontFamily};
  }

  .MuiOutlinedInput-root {
    font-size: 1em;

    &:not(.Mui-disabled) {
      fieldset {
        border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
      }
      color: ${({theme}) => theme.colors.neutral.shade7};
      background-color: ${({theme}) => theme.colors.neutral.shade1};

      &:hover fieldset {
        border: 1px solid ${({theme}) => theme.colors.neutral.shade7};
      }

      &.Mui-focused fieldset {
        border: 1px solid ${({theme}) => theme.colors.primary1.shade2};
        box-shadow: 0px 0px 0px 4px ${({theme}) => theme.colors.primary1.shade1};
      }
    }

    border-radius: 1em;
  }

  .MuiInputBase-input {
    color: ${({theme}) => theme.colors.neutral.shade11};
    padding: 1.25em;
    padding-right: ${(props) => (props.$isFocused ? '3em' : '0em')};
  }

  legend {
    width: 0;
  }
`;

export default SearchBar;
