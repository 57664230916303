import {FunctionComponent} from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: string;
  color?: string;
}

export const ResetIcon: FunctionComponent<Props> = ({
  size = '25',
  color = '#1C1B1A',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M15.3861 5.08039C14.5161 4.82039 13.5561 4.65039 12.4961 4.65039C7.7061 4.65039 3.8261 8.53039 3.8261 13.3204C3.8261 18.1204 7.7061 22.0004 12.4961 22.0004C17.2861 22.0004 21.1661 18.1204 21.1661 13.3304C21.1661 11.5504 20.6261 9.89039 19.7061 8.51039"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6261 5.32L13.7361 2"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6261 5.32031L13.2561 7.78031"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
