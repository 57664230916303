import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import styled from 'styled-components';
import ProfileHeaderBackground from '../molecules/images/ProfileHeaderBackground.png';
import EditIcon from '../atoms/Icons/EditIcon';
import EmailIcon from '../atoms/Icons/EmailIcon';
import {t} from '@lingui/macro';
import {getInitials} from '../../utils/getInitials';
import {isTablet} from '../../utils/responsive';
import {LogoutIcon} from '../atoms/Icons/LogoutIcon';
import TrashIcon from '../atoms/Icons/TrashIcon';
import {useTheme} from '../theme/theme';

interface Props {
  name: string;
  email: string;
  onSignout: () => void;
  handleResetPassword: () => void;
  setShowDeleteAccountPopup: Dispatch<SetStateAction<boolean>>;
}

export const ProfileHeaderMobile: FunctionComponent<Props> = ({
  name,
  email,
  onSignout,
  handleResetPassword,
  setShowDeleteAccountPopup,
}) => {
  const theme = useTheme();
  return (
    <Wrapper>
      <Header>
        <Image src={ProfileHeaderBackground} alt="background" />
      </Header>
      <FlexBetween>
        <Container>
          <AvatarWrapper>
            <Avatar>{getInitials(name)}</Avatar>
          </AvatarWrapper>
          <Div>
            <Name>{name}</Name>
            <Email>
              <EmailIcon /> {email}
            </Email>
          </Div>
        </Container>
      </FlexBetween>
      <FlexRow>
        <Edit onClick={handleResetPassword}>
          <EditIcon />
          {t` Change password`}
        </Edit>
        <Divider />
        <Delete onClick={onSignout}>
          <LogoutIcon color={theme.colors.primary2.shade3} /> {t`Sign out`}
        </Delete>
        <Divider />
        <Delete onClick={() => setShowDeleteAccountPopup(true)}>
          <TrashIcon
            height={19}
            width={19}
            color={theme.colors.primary2.shade3}
          />{' '}
          {t`Delete account`}
        </Delete>
      </FlexRow>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  @media (min-width: ${isTablet}) {
    display: none;
  }
  min-width: 25em;
`;
const Header = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade6};
  border-radius: 1.5em;
  height: 9.5em;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3em;
`;
const AvatarWrapper = styled.div`
  border-radius: 50%;
  height: 8.875em;
  width: 8.875em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.neutral.shade13};
`;
const Avatar = styled.p`
  font-size: 2em;
  font-weight: 700;
  margin: 0;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const FlexBetween = styled.div`
  margin-top: -4.44em;
  margin-bottom: 0.5em;
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
`;
const Name = styled.p`
  margin: 0;
  font-size: 1.125em;
  font-weight: 600;
  line-height: 1.75em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Email = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.neutral.shade8};
  font-size: 0.875em;
  line-height: 1.25em;
  letter-spacing: 0.0125em;
`;
const Edit = styled.p`
  margin: 0;
  color: ${({theme}) => theme.colors.primary4.shade1};
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.25em;
  letter-spacing: 0.0125em;
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
`;
const Delete = styled.p`
  margin: 0;
  color: ${({theme}) => theme.colors.primary2.shade3};
  font-size: 0.875em;
  font-weight: 600;
  letter-spacing: 0.0125em;
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
`;
const Divider = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade5};
  width: 1px;
  height: 9px;
`;
const Div = styled.div`
  margin-bottom: 0.8em;
  text-align: center;
`;
