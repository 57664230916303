import {useMutation, useQueryClient} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {deleteApi} from '../../../utils/fetchApi';

export const useDeleteProfile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['subscription']});
      await queryClient.invalidateQueries({queryKey: ['profiles']});
    },
    mutationFn: async (id: string) =>
      deleteApi(
        '/profile/delete',
        {
          body: JSON.stringify({
            id: id,
          }),
        },
        t`Unable to delete the profile`,
      ),
  });
};
