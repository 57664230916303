import React, {useEffect, useRef} from 'react';
import ThirdPartyEmailPassword, {
  signOut,
} from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import {SuperTokensError} from '../../../pages/login/LoginPage';
import {CircularLoader} from '../../atoms/CircularLoader';
import {useSnackbar} from 'notistack';
import {t} from '@lingui/macro';
import {useNavigate} from 'react-router-dom';

export const GoogleCallbackHandler: React.FunctionComponent = () => {
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const hasRun = useRef(false);

  useEffect(() => {
    async function handleGoogleCallback() {
      if (hasRun.current) {
        return;
      }
      hasRun.current = true;

      try {
        const response = await ThirdPartyEmailPassword.thirdPartySignInAndUp();

        if (response.status !== 'OK') {
          navigate('/auth');
          return;
        }

        const message = response.createdNewUser
          ? t`Signed up with Google successfully`
          : t`Signed in with Google successfully`;

        enqueueSnackbar(message, {variant: 'success'});
        navigate('/');
      } catch (err) {
        const superTokensError = err as SuperTokensError;

        if (!superTokensError.isSuperTokensGeneralError) {
          enqueueSnackbar(t`Oops! Something went wrong with Google sign-in.`, {
            variant: 'error',
          });
          navigate('/auth');
          return;
        }

        if (superTokensError.message === 'email already exists') {
          enqueueSnackbar(
            t`A user with this email already exists. Please sign-in using Email/Password instead.`,
            {
              variant: 'error',
            },
          );
          await signOut();
          setTimeout(() => {
            navigate('/auth');
          }, 3000);
          return;
        }

        enqueueSnackbar(superTokensError.message, {variant: 'error'});
      }
    }

    handleGoogleCallback();
  }, [enqueueSnackbar, navigate]);

  return <CircularLoader />;
};
