import {ForwardRefRenderFunction, forwardRef} from 'react';
import styled from 'styled-components';
import InfoIcon from './Icons/InfoIcon';

interface Props {
  message: string;
  variant: 'error' | 'success' | 'info';
  className?: string;
}

const ToastComponent: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {message, variant, className},
  ref,
) => {
  const getTitle = () => {
    switch (variant) {
      case 'success':
        return 'Success';
      case 'error':
        return 'Error';
      case 'info':
        return 'Information';
      default:
        return '';
    }
  };

  return (
    <StyledToast className={className} $variant={variant} ref={ref}>
      <ContentWrapper>
        <Title $variant={variant}>
          {variant === 'info' && <InfoIcon size={15} />}
          {getTitle()}
        </Title>
        <Message>{message}</Message>
      </ContentWrapper>
    </StyledToast>
  );
};

export const Toast = forwardRef(ToastComponent);
const StyledToast = styled.div<{$variant: Props['variant']}>`
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0.15em 0.5em 0.15em 0.15em;
  border-radius: 2em;
  font-size: 1em;
  width: fit-content;
  min-width: 5em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};

  ${({theme, $variant}) => {
    switch ($variant) {
      case 'success':
        return `
            color: ${theme.colors.neutral.shade11};
            border: 2px solid ${theme.colors.primary3.shade2};
          `;
      case 'error':
        return `
            color: ${theme.colors.neutral.shade11};
            border: 2px solid ${theme.colors.primary2.shade3};
          `;
      case 'info':
        return `
            color: ${theme.colors.neutral.shade11};
            background-color: ${theme.colors.primary2.shade1};
          `;
    }
  }};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const Title = styled.div<{$variant: Props['variant']}>`
  font-size: 1em;
  font-weight: 600;
  padding: 0.4em 1em;
  border-radius: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;

  ${({theme, $variant}) => {
    switch ($variant) {
      case 'success':
        return `
            color: ${theme.colors.neutral.shade1};
            background-color: ${theme.colors.primary3.shade2};
        `;
      case 'error':
        return `
            color: ${theme.colors.neutral.shade1};
            background-color: ${theme.colors.primary2.shade3};
        `;
      case 'info':
        return `
            color: ${theme.colors.primary2.shade3};
            background-color: ${theme.colors.primary2.shade1};
        `;
    }
  }}
`;

const Message = styled.div`
  font-weight: normal;
  flex-grow: 1;
`;
