import {FunctionComponent} from 'react';
import {PricingTable} from './PricingTable';
import styled from 'styled-components';
import {CloseButton} from '../atoms/CloseButton';

interface Props {
  onClose: () => void;
}

export const PricingPopup: FunctionComponent<Props> = ({onClose}) => {
  return (
    <ParentContainer>
      <Container>
        <CloseButtonWrapper onClick={onClose}>
          <CloseButton />
        </CloseButtonWrapper>
        <PricingTable />
      </Container>
    </ParentContainer>
  );
};

const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000099;
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 60;
`;
const Container = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  padding: 2em;
  border-radius: 2em;
  position: relative;
  scale: 0.9;
  overflow: scroll;
  height: calc(100vh - 1em);
  &::-webkit-scrollbar {
    display: none;
  }
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
`;
