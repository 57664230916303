import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {Button} from '../../../components/atoms/Button';
import {isTablet} from '../../../utils/responsive';

interface Props {
  onCreateProfile: () => void;
}

export const PostGeneratorIntro: FunctionComponent<Props> = ({
  onCreateProfile,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const features = [
    {
      image: 'https://i.ibb.co/F6Vtn54/Rectangle-66-1.png',
      title: t`1. RedactAI now loads all the data of your LinkedIn profile to give context to your posts.`,
      content: (
        <>
          <FeatureText>{t`Everything that you posted on LinkedIn will be used to enhance your content.`}</FeatureText>
          <FeatureHighlight>{t`This means that the AI knows you and will make references to your business, stories that you shared, product that you sell, ...`}</FeatureHighlight>
          <FeatureText>{t`This also means that the more that you post (or have posted), the better the AI will be.`}</FeatureText>
          <FeatureText>
            {t`This is called the`} <span>{t`context.`}</span>
          </FeatureText>
        </>
      ),
    },
    {
      image: 'https://i.ibb.co/0yRN1G1/Frame-1171277214.png',
      title: t`2. The post style now matches 99% of your writing style`,
      content: (
        <>
          <FeatureText>{t`We have trained the AI to better match your writing style from your previous posts.`}</FeatureText>
          <FeatureHighlight>{t`The AI now better reproduces your tone and post structure.`}</FeatureHighlight>
        </>
      ),
    },
    {
      image: 'https://i.ibb.co/kymKTJC/Rectangle-66-3.png',
      title: t`3. We removed the default post tone`,
      content: (
        <>
          <FeatureText>{t`Some of you may still use the default post tone.`}</FeatureText>
          <FeatureHighlight>{t`We encourage you to use the profile url of another creator to imitate his writing style.`}</FeatureHighlight>
        </>
      ),
    },
  ];

  const nextSlide = () => {
    if (currentSlide < features.length - 1) {
      setCurrentSlide(currentSlide + 1);
      return;
    }
    onCreateProfile();
  };

  return (
    <Container>
      <Title>
        {t`Introducing the new`} <Highlight>{t`Post Generator`}</Highlight>
      </Title>
      <Subtitle>{t`We worked hard to create a new post generator:`}</Subtitle>

      <Carousel>
        <FeatureSection>
          <StyleImage src={features[currentSlide].image} />
          <div>
            <FeatureTitle>{features[currentSlide].title}</FeatureTitle>
            {features[currentSlide].content}
          </div>
        </FeatureSection>
        <CarouselIndicators>
          {features.map((_, index) => (
            <Indicator
              key={index}
              $active={index === currentSlide}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </CarouselIndicators>
      </Carousel>

      <StyledButton
        label={
          currentSlide === features.length - 1
            ? t`Let's create my context`
            : t`Next`
        }
        size="medium"
        variant="main"
        onClick={nextSlide}
        fullWidth
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  padding: 0 10em;
  @media (max-width: ${isTablet}) {
    padding: 0 2em;
  }
`;

const Title = styled.p`
  font-size: 3em;
  font-weight: 700;
  text-align: center;
  margin: 0;
`;

const Highlight = styled.span`
  color: ${({theme}) => theme.colors.primary1.shade2};
`;

const Subtitle = styled.p`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 1.2em;
  font-weight: 700;
  text-align: center;
  margin: 0;
`;

const Carousel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeatureSection = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade1};
  padding: 2em;
  border-radius: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  width: 100%;
  line-height: 1.5em;

  @media (max-width: ${isTablet}) {
    flex-direction: column;
  }
`;

const FeatureTitle = styled.h2`
  font-size: 1.2em;
  font-weight: 700;
`;

const FeatureText = styled.p`
  font-size: 1em;
  font-weight: 500;

  span {
    font-weight: 700;
  }
`;

const FeatureHighlight = styled.p`
  font-size: 1em;
  font-weight: 700;
`;

const StyleImage = styled.img`
  width: 100%;
  max-width: 20em;
  height: auto;
`;

const StyledButton = styled(Button)`
  width: 25em;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;

const CarouselIndicators = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5em;
  margin-top: 1em;
`;

const Indicator = styled.div<{$active: boolean}>`
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  background-color: ${({$active, theme}) =>
    $active ? theme.colors.primary1.shade2 : theme.colors.neutral.shade4};
  cursor: pointer;
`;
