import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  className?: string;
}

const GeneratingPostIcon: FunctionComponent<Props> = ({
  width = 201,
  height = 193,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 201 193"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.564 32.2972C154.927 32.1159 164.914 22.1281 165.095 9.76562H165.101C165.281 22.1285 175.269 32.1166 187.632 32.2972V32.3025C175.27 32.4832 165.282 42.4709 165.101 54.8335H165.095C164.914 42.4713 154.927 32.4838 142.564 32.3025V32.2972Z"
        fill="#FFAC3B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.632 24.7873H187.635C187.735 17.9879 193.228 12.4947 200.028 12.3953V12.3926C193.228 12.2933 187.734 6.79972 187.635 0H187.632C187.533 6.79949 182.04 12.2929 175.24 12.3926V12.3953C182.039 12.4951 187.532 17.9881 187.632 24.7873Z"
        fill="#FFAC3B"
      />
      <rect
        x="0.97168"
        y="63.9805"
        width="194"
        height="129"
        rx="12.185"
        fill="url(#paint0_linear_2398_63048)"
      />
      <rect
        x="7.97168"
        y="87.9805"
        width="180"
        height="101"
        rx="9.13876"
        fill="url(#paint1_linear_2398_63048)"
      />
      <rect
        opacity="0.4"
        x="31.3809"
        y="113.473"
        width="133.274"
        height="10.6619"
        rx="5.33094"
        fill="url(#paint2_linear_2398_63048)"
      />
      <rect
        opacity="0.4"
        x="31.3809"
        y="138.605"
        width="78.441"
        height="10.6619"
        rx="5.33094"
        fill="url(#paint3_linear_2398_63048)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2398_63048"
          x1="97.9717"
          y1="63.9805"
          x2="97.9717"
          y2="192.98"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2398_63048"
          x1="97.9717"
          y1="87.9805"
          x2="97.9717"
          y2="188.98"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDEDD6" />
          <stop offset="1" stopColor="#FDEDD6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2398_63048"
          x1="164.654"
          y1="118.804"
          x2="31.3809"
          y2="118.804"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAB95E" stopOpacity="0" />
          <stop offset="1" stopColor="#FAB95E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2398_63048"
          x1="109.822"
          y1="143.936"
          x2="31.3809"
          y2="143.936"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAB95E" stopOpacity="0" />
          <stop offset="1" stopColor="#FAB95E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GeneratingPostIcon;
