import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Page} from '../../components/templates/Page';
import {t} from '@lingui/macro';
import {AffiliateConditions} from '../../components/molecules/affiliateConditions';
import {AffiliateLinkCopy} from '../../components/molecules/affiliateLinkCopy';
import {AffiliateEarnings} from '../../components/molecules/affiliateEarnings';
import {
  AffiliateHistory,
  AffiliateItem,
} from '../../components/molecules/affiliateHistory';

const affiliates: AffiliateItem[] = [
  {
    date: new Date('2023-01-01'),
    name: 'Nicolas Pamart',
    email: 'n.pamart@redactai.io',
    status: 'Active',
  },
  {
    date: new Date('2023-02-01'),
    name: 'Nicolas Pamart',
    email: 'n.pamart@redactai.io',
    status: 'Pending',
  },
  {
    date: new Date('2023-03-01'),
    name: 'Nicolas Pamart',
    email: 'n.pamart@redactai.io',
    status: 'Inactive',
  },
];

export const Affiliate: FunctionComponent = () => {
  return (
    <Page>
      <Title>{t`Affiliate`}</Title>
      <OfferConditions>{t`*Offer Conditions`}</OfferConditions>
      <Container>
        <AffiliateConditions />
        <AffiliateLinkCopy link="https://app.redactai.io/auth/?affiliate=ILB0711" />
        <AffiliateEarnings monthlyPosts={9} totalPosts={26} />
        <AffiliateHistory affiliates={affiliates} />
      </Container>
    </Page>
  );
};

const Title = styled.h1`
  font-size: 1.5em;
  font-weight: 700;
  margin: 0;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  gap: 2em;
`;

const OfferConditions = styled.a`
  display: block;
  text-align: right;
  color: ${({theme}) => theme.colors.primary4.shade1};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  font-size: 1em;
  font-weight: 600;
`;
