import {FunctionComponent} from 'react';

/* global SVGSVGElement */

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: string;
  color?: string;
}

export const GiftIcon: FunctionComponent<Props> = ({
  size = '18',
  color = 'white',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14.9775 7.5H2.97754V13.5C2.97754 15.75 3.72754 16.5 5.97754 16.5H11.9775C14.2275 16.5 14.9775 15.75 14.9775 13.5V7.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.125 5.25V6C16.125 6.825 15.7275 7.5 14.625 7.5H3.375C2.2275 7.5 1.875 6.825 1.875 6V5.25C1.875 4.425 2.2275 3.75 3.375 3.75H14.625C15.7275 3.75 16.125 4.425 16.125 5.25Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.73008 3.75008H4.59008C4.33508 3.47258 4.34258 3.04508 4.61258 2.77508L5.67758 1.71008C5.95508 1.43258 6.41258 1.43258 6.69008 1.71008L8.73008 3.75008Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4025 3.75008H9.26245L11.3025 1.71008C11.58 1.43258 12.0375 1.43258 12.315 1.71008L13.38 2.77508C13.65 3.04508 13.6575 3.47258 13.4025 3.75008Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.70508 7.5V11.355C6.70508 11.955 7.36508 12.3075 7.86758 11.985L8.57258 11.52C8.82758 11.355 9.15008 11.355 9.39758 11.52L10.0651 11.97C10.5601 12.3 11.2276 11.9475 11.2276 11.3475V7.5H6.70508Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
