import {FunctionComponent} from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color1?: string;
  color2?: string;
  className?: string;
  onClick?: () => void;
}

export const RedactaiIcon: FunctionComponent<Props> = ({
  width = 162,
  height = 23,
  color1 = '#1C1B1A',
  color2 = '#FFAC3B',
  className,
  onClick,
}) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 162 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.0274 3.49726H30.9932V0.0117188H40.9908C45.0842 0.0117188 47.9371 2.52974 47.9371 6.74712C47.9371 10.7784 45.171 12.4406 44.2283 12.6266V12.6887C44.2283 12.6887 44.9229 12.9988 45.3323 13.8174L47.503 18.1216C47.8503 18.7791 48.3837 18.8411 49.0783 18.8411H49.4876V22.3266H47.3169C45.6548 22.3266 44.9229 22.0785 44.2035 20.6645L41.3382 15.033C40.8668 14.1523 40.3954 13.9911 39.2295 13.9911H37.158V22.3266H33.0398V3.49726H33.0274ZM40.4202 10.4807C42.4917 10.4807 43.7197 9.19072 43.7197 6.95799C43.7197 4.76247 42.4917 3.49726 40.4823 3.49726H37.1456V10.4807H40.4202Z"
        fill={color1}
      />
      <path
        d="M58.7284 5.91797C63.3179 5.91797 65.8359 9.27947 65.8359 13.5341C65.8359 14.0054 65.7367 15.0101 65.7367 15.0101H54.6723C54.9824 17.8383 57.0663 19.3144 59.4851 19.3144C62.0651 19.3144 63.9877 17.5158 63.9877 17.5158L65.6499 20.2819C65.6499 20.2819 63.1939 22.7007 59.1998 22.7007C53.8908 22.7007 50.5789 18.8678 50.5789 14.3031C50.5789 9.3787 53.9156 5.91797 58.7284 5.91797ZM61.7426 12.244C61.6806 10.3214 60.3533 9.00658 58.7284 9.00658C56.6817 9.00658 55.2429 10.2346 54.7963 12.244H61.7426Z"
        fill={color1}
      />
      <path
        d="M75.3871 5.91674C78.8726 5.91674 80.0138 7.86417 80.0138 7.86417H80.0758C80.0758 7.86417 80.0386 7.45484 80.0386 6.92147V4.09334C80.0386 3.62199 79.7905 3.39871 79.344 3.39871H78.0912V0H81.8992C83.3753 0 84.0327 0.657417 84.0327 2.1335V18.2712C84.0327 18.7053 84.2808 18.9658 84.7274 18.9658H85.9554V22.3273H82.2714C80.8201 22.3273 80.2867 21.6327 80.2867 20.69C80.2867 20.3427 80.2867 20.0946 80.2867 20.0946H80.2371C80.2371 20.0946 78.9223 22.6994 75.2382 22.6994C70.8968 22.6994 68.0935 19.2759 68.0935 14.3019C68.0935 9.22862 71.1449 5.91674 75.3871 5.91674ZM80.1379 14.2895C80.1379 11.8087 78.8478 9.35266 76.181 9.35266C73.9854 9.35266 72.1496 11.1513 72.1496 14.3267C72.1496 17.3781 73.7498 19.3627 76.1065 19.3627C78.1904 19.3503 80.1379 17.837 80.1379 14.2895Z"
        fill={color1}
      />
      <path
        d="M97.2182 12.2812H97.7268V12.1572C97.7268 9.86246 96.8089 9.00658 94.7746 9.00658C94.0552 9.00658 92.6411 9.19264 92.6411 10.0733V10.9168H88.9323V9.21745C88.9323 6.22807 93.1497 5.91797 94.8119 5.91797C100.158 5.91797 101.733 8.72129 101.733 12.3061V18.2848C101.733 18.719 101.981 18.9794 102.428 18.9794H103.681V22.3409H100.096C98.6199 22.3409 98.0493 21.5223 98.0493 20.5796C98.0493 20.1702 98.0865 19.8849 98.0865 19.8849H98.0245C98.0245 19.8849 96.7965 22.7131 93.1869 22.7131C90.3216 22.7131 87.7167 20.9145 87.7167 17.7142C87.6919 12.6782 94.601 12.2812 97.2182 12.2812ZM94.1296 19.5996C96.2631 19.5996 97.7764 17.3297 97.7764 15.3823V15.0101H97.0818C95.0103 15.0101 91.6984 15.2954 91.6984 17.5282C91.7108 18.5949 92.4923 19.5996 94.1296 19.5996Z"
        fill={color1}
      />
      <path
        d="M114.038 5.91797C116.11 5.91797 119.856 6.76144 119.856 9.53995V11.4254H116.333V10.5447C116.333 9.66399 115.018 9.29187 114.038 9.29187C111.272 9.29187 109.287 11.4006 109.287 14.2907C109.287 17.5654 111.706 19.2275 114.286 19.2275C117.028 19.2275 119.037 17.0568 119.037 17.0568L120.699 19.8229C120.699 19.8229 118.305 22.7131 113.939 22.7131C108.655 22.7131 105.256 18.967 105.256 14.3155C105.268 9.78803 108.531 5.91797 114.038 5.91797Z"
        fill={color1}
      />
      <path
        d="M123.85 9.47526H121.778V6.29982H123.949V1.93359H127.844V6.29982H131.491V9.47526H127.844V15.9502C127.844 18.5922 129.915 18.9644 131.019 18.9644C131.453 18.9644 131.739 18.9023 131.739 18.9023V22.4003C131.739 22.4003 131.23 22.4995 130.511 22.4995C128.278 22.4995 123.85 21.8421 123.85 16.4587V9.47526Z"
        fill={color1}
      />
      <path
        d="M133.19 20.391H133.599C134.17 20.391 134.517 20.2917 134.728 19.6963L141.96 0.234375H144.316L151.548 19.6963C151.771 20.2917 152.081 20.391 152.677 20.391H153.049V22.3384H152.044C150.469 22.3384 149.997 22.0283 149.439 20.515L147.517 15.2929H138.747L136.8 20.515C136.229 22.0283 135.795 22.3384 134.22 22.3384H133.178V20.391H133.19ZM146.934 13.3703L144.006 5.48129C143.634 4.43935 143.163 2.61596 143.163 2.61596H143.101C143.101 2.61596 142.629 4.43935 142.257 5.48129L139.367 13.3703H146.934Z"
        fill={color2}
      />
      <path
        d="M154.922 20.391H156.993V2.18181H154.922V0.234375H161.248V2.18181H159.139V20.391H161.248V22.3384H154.922V20.391Z"
        fill={color2}
      />
      <path
        d="M22.3101 19.7109C24.2625 19.7109 25.8452 18.1282 25.8452 16.1758C25.8452 14.2234 24.2625 12.6406 22.3101 12.6406C20.3577 12.6406 18.7749 14.2234 18.7749 16.1758C18.7749 18.1282 20.3577 19.7109 22.3101 19.7109Z"
        fill={color2}
      />
      <path
        d="M13.2923 22.3398C15.2447 22.3398 16.8275 20.7571 16.8275 18.8047C16.8275 16.8523 15.2447 15.2695 13.2923 15.2695C11.3399 15.2695 9.75714 16.8523 9.75714 18.8047C9.75714 20.7571 11.3399 22.3398 13.2923 22.3398Z"
        fill={color1}
      />
      <path
        d="M4.28708 22.3398C6.23949 22.3398 7.82224 20.7571 7.82224 18.8047C7.82224 16.8523 6.23949 15.2695 4.28708 15.2695C2.33467 15.2695 0.751923 16.8523 0.751923 18.8047C0.751923 20.7571 2.33467 22.3398 4.28708 22.3398Z"
        fill={color1}
      />
    </svg>
  );
};
