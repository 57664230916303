import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {Button} from '../atoms/Button';
import ArrowRight from '../atoms/Icons/ArrowRight';
import DoneIcon from '../atoms/Icons/DoneIcon';
import {t} from '@lingui/macro';
import {useTheme} from '../theme/theme';
import {PricingSlider} from '../atoms/PricingSlider';
import {
  ProductPeriod,
  useSubscription,
} from '../../hooks/subscription/useSubscription';
import {Product} from '../../hooks/pricing/useProducts';
import {Status} from '../atoms/Status.tsx';
import {isTablet} from '../../utils/responsive.ts';

interface UpgradePricingCardProps {
  productsToDisplay: Product[];
  chosenSliderProduct: Product;
  redirectToCheckout: (productId: string, productName: string) => void;
  chosenPostsPerMonth: number;
  setChosenPostsPerMonth: (value: number) => void;
  chosenPostsPerMonthMarks: {value: number; label: string}[];
  productToPricePerPost: (product: Product) => string;
  loading: boolean;
  isAnnual: boolean;
  getCurrencySymbol: (useName?: boolean) => string;
  isFreeTrial: boolean;
}
interface BenefitsProps {
  creator?: boolean;
  enterprise?: boolean;
}

const Benefits: FunctionComponent<BenefitsProps> = ({
  creator = false,
  enterprise = false,
}) => {
  const theme = useTheme();
  return (
    <Col>
      {creator ? (
        <>
          <Row>
            <DoneIcon color={theme.colors.primary1.shade2} />
            <YellowText>{t`Unlimited post generation`}</YellowText>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`Unlimited idea generator`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.primary1.shade2} />
            <YellowText>{t`Access to ChatWithRedactAI`}</YellowText>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`Unlimited recycle post`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`24/5 customer support`}</Text>
          </Row>
        </>
      ) : enterprise ? (
        <>
          <Row>
            <Text>{t`Everything in Creator plus :`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`Multiple profiles`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`24/7 customer support`}</Text>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`8 posts per month`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`Unlimited idea generator`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`Access to Recycle post`}</Text>
          </Row>
          <Row>
            <DoneIcon color={theme.colors.neutral.shade11} />
            <Text>{t`24/5 customer support`}</Text>
          </Row>
        </>
      )}
    </Col>
  );
};

const priceToString = (price: number, period?: ProductPeriod): string =>
  price === 0
    ? '0'
    : (
        (price / 100 - 0.9) /
        (period === ProductPeriod.YEARLY ? 10 : 1)
      ).toFixed(2);

const priceToStringBF = (price: number, period?: ProductPeriod): string =>
  price === 0
    ? '0'
    : (price / 100 / (period === ProductPeriod.YEARLY ? 12 : 1)).toFixed(2);

export const UpgradePricingCardBF: FunctionComponent<
  UpgradePricingCardProps
> = ({
  productsToDisplay,
  chosenSliderProduct,
  redirectToCheckout,
  chosenPostsPerMonth,
  setChosenPostsPerMonth,
  chosenPostsPerMonthMarks,
  productToPricePerPost,
  loading,
  isAnnual,
  getCurrencySymbol,
}) => {
  const [isSlider, setIsSlider] = useState<boolean>(false);
  const {subscription} = useSubscription();
  return (
    <Container>
      {productsToDisplay.map((product, index) => (
        <UpgradeCard key={index} $isCreator={product.isCreator}>
          {product.isCreator && (
            <MostPopularContainer>
              <MostPopularText>{t`78% pick this plan`}</MostPopularText>
            </MostPopularContainer>
          )}
          <Div>
            <Header>{product.isCreator ? t`Creator` : t`Essential`}</Header>
            <Text>
              {product.isCreator
                ? t`Produce high quality LinkedIn content in no time.`
                : t`For casual posting on LinkedIn without hassle.`}
            </Text>
          </Div>
          <TrialPrice>
            {priceToString(product.price, product.period)}
            {getCurrencySymbol()} <span>/ mo*</span>
          </TrialPrice>
          <BFPrice>
            {priceToStringBF(product.price / 2, product.period)}
            {getCurrencySymbol()} <span>/ mo*</span>
            {isAnnual && (
              <StyledStatus
                label={'-' + t`50% + 2 months free`}
                variant={'completed'}
              />
            )}
          </BFPrice>
          {product.isCreator ? (
            <BuyButton
              onClick={() => redirectToCheckout(product.id, product.name)}
              label={
                product.id === subscription?.product?.id
                  ? t`You already have this plan`
                  : t`Get my -50% deal`
              }
              size="medium"
              variant={product.isCreator ? 'main' : 'inverted'}
              fullWidth
              endIcon={<ArrowRight height={18} width={18} />}
              disabled={loading || product.id === subscription?.product?.id}
            />
          ) : (
            <Button
              onClick={() => redirectToCheckout(product.id, product.name)}
              label={t`Get my -50% deal`}
              size="medium"
              variant={product.isCreator ? 'main' : 'inverted'}
              fullWidth
              endIcon={<ArrowRight height={18} width={18} />}
              disabled={loading || product.id === subscription?.product?.id}
            />
          )}
          <Benefits creator={product.isCreator} />
          <Divider />
          <Div>
            <Text>
              <StrongUnderlineText>
                {'*' +
                  t`${priceToString(product.price)} ${getCurrencySymbol(true)} ` +
                  ` ` +
                  t`billed` +
                  ` ` +
                  (product.period === ProductPeriod.YEARLY
                    ? t`annually`
                    : t`monthly`)}
              </StrongUnderlineText>
              <StrongText>
                {'*' +
                  t`${priceToString(product.price / 2)} ${getCurrencySymbol(true)} ` +
                  ` ` +
                  t`billed` +
                  ` ` +
                  (product.period === ProductPeriod.YEARLY
                    ? t`annually`
                    : t`monthly`)}
              </StrongText>
              {t`excluding taxes | Cancel anytime`}
            </Text>
          </Div>
        </UpgradeCard>
      ))}
      {false && (
        <UpgradeCard $isCreator={false}>
          <Div>
            <Header>{t`Enterprise`}</Header>
            <Text>{t`For copywriters or companies writing posts for others.`}</Text>
          </Div>
          <Benefits enterprise />
          <Divider />
          <Div>
            <TrialPrice>
              {t`Starting at`}{' '}
              {priceToString(
                chosenSliderProduct.price,
                chosenSliderProduct.period,
              )}{' '}
              {getCurrencySymbol()} <span>/ mo</span>
            </TrialPrice>
            <Text>
              <StrongText>
                {t`${priceToString(chosenSliderProduct.price)} ${getCurrencySymbol(true)} ` +
                  ` ` +
                  t`billed` +
                  ` ` +
                  (chosenSliderProduct.period === ProductPeriod.YEARLY
                    ? t`annually`
                    : t`monthly`)}
              </StrongText>
              <br />
              {t`excluding taxes | Cancel anytime`}
            </Text>
          </Div>
          {isSlider && (
            <PricingSlider
              chosenPostsPerMonth={chosenPostsPerMonth}
              setChosenPostsPerMonth={setChosenPostsPerMonth}
              chosenPostsPerMonthMarks={chosenPostsPerMonthMarks}
              productToPricePerPost={productToPricePerPost}
              chosenSliderProduct={chosenSliderProduct}
              getCurrencySymbol={getCurrencySymbol}
            />
          )}
          <Button
            onClick={() => {
              {
                !isSlider
                  ? setIsSlider(true)
                  : redirectToCheckout(
                      chosenSliderProduct.id,
                      chosenSliderProduct.name,
                    );
              }
            }}
            label={t`Upgrade`}
            size="medium"
            variant="main"
            fullWidth
            endIcon={<ArrowRight height={18} width={18} />}
            disabled={loading}
          />
        </UpgradeCard>
      )}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  gap: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};

  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 0;
  }
`;

const UpgradeCard = styled.div<{$isCreator: boolean}>`
  width: ${({$isCreator}) => ($isCreator ? '24em' : '20.7em')};
  padding: 1.5em;
  border-radius: 1.5em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  position: relative;
  margin-top: ${({$isCreator}) => ($isCreator ? '1em' : '2.5em')};
  ${({theme, $isCreator}) =>
    $isCreator
      ? `
      border: 6px solid ${theme.colors.primary1.shade2};
    `
      : ''}
`;

const Header = styled.span`
  font-size: 2em;
  letter-spacing: -0.03125em;
  font-weight: 700;
  margin: 0;
`;

const TrialPrice = styled.div`
  font-size: 1.6em;
  font-weight: 700;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5em;
  width: 100%;
  & span {
    font-size: 0.7em;
    font-weight: 400;
  }
  text-decoration: line-through;
`;

const BFPrice = styled.div`
  font-size: 1.6em;
  font-weight: 700;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5em;
  width: 100%;
  & span {
    font-size: 0.7em;
    font-weight: 400;
  }
`;

const Text = styled.p`
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0.03125em;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const StrongText = styled.span`
  font-weight: 700;
`;

const StrongUnderlineText = styled.span`
  font-weight: 700;
  text-decoration: line-through;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75em;
`;
const Divider = styled.div`
  height: 1px;
  background-color: ${({theme}) => theme.colors.neutral.shade18};
  width: 75%;
`;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const YellowText = styled(Text)`
  color: ${({theme}) => theme.colors.primary1.shade2};
  font-weight: 700;
  font-size: 1.1em;
`;

const MostPopularContainer = styled.div`
  position: absolute;
  top: -1em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const MostPopularText = styled.p`
  font-size: 1.1em;
  font-style: italic;
  background-color: ${({theme}) => theme.colors.primary1.shade2};
  color: ${({theme}) => theme.colors.neutral.shade1};
  text-align: center;
  width: fit-content;
  padding: 0.2em 0.5em;
  font-weight: 500;
  margin: 0 0 3em 0;
`;

const StyledStatus = styled(Status)`
  font-size: 1em;
  padding: 0.3em 0.5em;
  span {
    font-weight: 700;
  }
`;

const BuyButton = styled(Button)`
  font-weight: 700;
  font-size: 1.3em;

  animation-duration: 3s;
  /* animation-iteration-count: 3; */
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: grow;

  @keyframes grow {
    0% {
      transform: scale(0.95);
    }

    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(0.95);
    }
  }
`;
