import {FunctionComponent, useEffect, useState} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {useSnackbar} from 'notistack';
import {isTablet} from '../../../utils/responsive';
import {Button} from '../../../components/atoms/Button';
import {RedactStyleSource} from '../../redactpost/components/RedactPostStyle';
import {RecyclePostCard} from '../../../components/molecules/RecyclePostCard';
import {RedactContentSource} from '../../redactpost/components/RedactPostContent';
import {RedactPostGeneration} from '../../redactpost/components/RedactPostGeneration';
import {Post} from '../RecyclePost';
import ArrowRight from '../../../components/atoms/Icons/ArrowRightIcon';
import {PricingPopup} from '../../../components/organisms/PricingPopup';
import {RedactPostProfile} from '../../redactpost-v2/components/RedactPostProfile';
import {useSavedProfiles} from '../../../hooks/redactPostNew/useSavedProfiles';
import {EditCreateProfile} from '../../redactpost-v2/components/EditCreateProfile';
import {CreateProfilePopup} from '../../redactpost-v2/components/CreateProfilePopup';
import {useRedactPost} from '../../../hooks/redactPostNew/useRedactPost';
import {Profile} from '../../../hooks/profile/profile.ts';

interface RecyclePostProps {
  postToBeRecycled: Post;
  setIsRecycling: (value: boolean) => void;
}

export const RecyclePostForm: FunctionComponent<RecyclePostProps> = ({
  postToBeRecycled,
  setIsRecycling,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [postSubject, setPostSubject] = useState<string>('');
  const [styleSource, setStyleSource] = useState<RedactStyleSource>(
    RedactStyleSource.PROFILE_URL,
  );
  const [readyToRedact, setReadyToRedact] = useState<boolean>(false);
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [createProfile, setCreateProfile] = useState<boolean>(false);
  const [profileIdToEdit, setProfileIdToEdit] = useState<string | undefined>(
    undefined,
  );
  const [postProfile, setPostProfile] = useState<Profile | undefined>(
    undefined,
  );
  const {savedProfiles} = useSavedProfiles();

  useEffect(() => {
    if (postToBeRecycled) {
      setPostSubject(postToBeRecycled.content.split(' ').slice(0, 5).join(' '));
    }
  }, [postToBeRecycled]);

  const {
    onGeneratePostSubmit,
    redactedPosts: posts,
    isLoading,
    showPricingPopup,
    setShowPricingPopup,
    error,
  } = useRedactPost();

  const onWritePost = () => {
    if (!postToBeRecycled) {
      enqueueSnackbar(t`Please provide a post to recycle.`, {
        variant: 'error',
      });
      return;
    }
    onGeneratePostSubmit(
      postSubject,
      '',
      postProfile!.id,
      postToBeRecycled.content,
    );
    setReadyToRedact(true);
  };

  const onReset = () => {
    setPostSubject('');
    setStyleSource(RedactStyleSource.PROFILE_URL);
    setReadyToRedact(false);
  };

  useEffect(() => {
    if (showPricingPopup || error) {
      setReadyToRedact(false);
    }
  }, [showPricingPopup, error]);

  return (
    <>
      {readyToRedact ? (
        <Wrapper>
          <RedactPostGeneration
            posts={posts}
            contentSource={RedactContentSource.RECYCLE_POST}
            styleSource={styleSource}
            onChangeInputs={() => {
              setReadyToRedact(false);
            }}
            onNewPost={() => setIsRecycling(false)}
            isLoading={isLoading}
            profile={postProfile}
          />
        </Wrapper>
      ) : editProfile ? (
        <EditCreateProfile
          isEdit={true}
          profileIdToEdit={profileIdToEdit}
          savedProfiles={savedProfiles}
          setEditProfile={setEditProfile}
        />
      ) : createProfile ? (
        <EditCreateProfile isEdit={false} setCreateProfile={setCreateProfile} />
      ) : (
        <>
          <Title>{t`Recycle This Post`}</Title>
          <Container>
            <RecyclePostCard
              post={postToBeRecycled}
              isRecycleForm
              setIsRecycling={setIsRecycling}
            />
            <RedactPostProfile
              setPostProfile={setPostProfile}
              postProfile={postProfile}
              savedProfiles={savedProfiles}
              setEditProfile={setEditProfile}
              setCreateProfile={setCreateProfile}
              setProfileIdToEdit={setProfileIdToEdit}
            />
            <End>
              <Button
                label={t`Reset`}
                size="medium"
                variant="light"
                onClick={onReset}
              />
              <Button
                onClick={onWritePost}
                label={t`Write my post`}
                size="medium"
                variant="main"
                endIcon={<StyledArrowRight width={22} height={23} />}
              />
            </End>
            {showPricingPopup && (
              <PricingPopup onClose={() => setShowPricingPopup(false)} />
            )}
            <CreateProfilePopup />
          </Container>
        </>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 80%;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 1.5em;
  margin-bottom: 1.2em;
  margin-top: 0em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const End = styled.div`
  width: 100%;
  display: flex;
  gap: 1.25em;
  justify-content: end;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;

const StyledArrowRight = styled(ArrowRight)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
