msgid ""
msgstr ""
"POT-Creation-Date: 2023-08-20 18:39+0200\n"
"Mime-Version: 1.0\n"
"Content-Type: text/plain; charset=utf-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: en\n"
"Project-Id-Version: \n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: \n"
"Last-Translator: \n"
"Language-Team: \n"
"Plural-Forms: \n"

#: src/pages/onboarding-v2/components/FillInContent.tsx:142
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:112
msgid "- ..."
msgstr "- ..."

#: src/pages/onboarding/components/NoIdeasToolTip.tsx:28
msgid "- \"Hiring\""
msgstr "- \"Hiring\""

#: src/pages/onboarding/components/ContentURLToolTipFinalStep.tsx:22
msgid "- \"How Elon Musk sold his first startup\""
msgstr "- \"How Elon Musk sold his first startup\""

#: src/pages/onboarding/components/NoIdeasToolTip.tsx:29
msgid "- \"How to post on LinkedIn ?\""
msgstr "- \"How to post on LinkedIn ?\""

#: src/pages/onboarding/components/NoIdeasToolTip.tsx:27
msgid "- \"SEO\""
msgstr "- \"SEO\""

#: src/pages/onboarding/components/ContentURLToolTipFinalStep.tsx:21
msgid "- \"The story of Elon Musk\""
msgstr "- \"The story of Elon Musk\""

#: src/pages/onboarding/components/ContentURLToolTipFinalStep.tsx:23
msgid "- \"What Elon Musk said when he sold his first startup\""
msgstr "- \"What Elon Musk said when he sold his first startup\""

#: src/pages/onboarding/components/ContentURLToolTipFirstStep.tsx:23
msgid "- A blog post url"
msgstr "- A blog post url"

#: src/pages/onboarding/components/ContentURLToolTipFirstStep.tsx:24
msgid "- A LinkedIn post url"
msgstr "- A LinkedIn post url"

#: src/hooks/tutorial/useTutorialSteps.tsx:54
#~ msgid "- A list of ideas, separated by a newline and/or characters or semicolons"
#~ msgstr "- A list of ideas, separated by a newline and/or characters or semicolons"

#: src/hooks/tutorial/useTutorialSteps.tsx:84
#~ msgid "- A template text (advanced)"
#~ msgstr "- A template text (advanced)"

#: src/pages/onboarding/components/ContentURLToolTipFirstStep.tsx:22
msgid "- A Youtube video url"
msgstr "- A Youtube video url"

#: src/hooks/tutorial/useTutorialSteps.tsx:69
#~ msgid "- Any draft of text"
#~ msgstr "- Any draft of text"

#: src/pages/onboarding-v2/components/FillInContent.tsx:140
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:110
msgid "- Cheap"
msgstr "- Cheap"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:45
#~ msgid "- Create an audience"
#~ msgstr "- Create an audience"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:47
#~ msgid "- Find new clients"
#~ msgstr "- Find new clients"

#: src/pages/onboarding/components/ContentURLToolTipFirstStep.tsx:26
msgid "- Or any website URL."
msgstr "- Or any website URL."

#: src/hooks/tutorial/useTutorialSteps.tsx:104
#~ msgid "- Professionals"
#~ msgstr "- Professionals"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:46
#~ msgid "- Prove your skills"
#~ msgstr "- Prove your skills"

#: src/pages/onboarding-v2/components/FillInContent.tsx:138
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:108
msgid "- Proximity with clients"
msgstr "- Proximity with clients"

#: src/hooks/tutorial/useTutorialSteps.tsx:101
#~ msgid "- Students"
#~ msgstr "...see less"

#: src/pages/onboarding/components/ContentURLToolTipFirstStep.tsx:25
msgid "- Your website"
msgstr "- Your website"

#: src/components/molecules/SubscriptionInformationCard.tsx:232
#: src/components/molecules/SubscriptionInformationCard.tsx:257
msgid "-50% BlackFriday"
msgstr "-50% BlackFriday"

#: src/components/molecules/RecyclePostCard.tsx:71
#~ msgid "...see less"
#~ msgstr "...see less"

#: src/components/molecules/popups/PreviewPopup.tsx:132
msgid "...see more"
msgstr "...see more"

#: src/pages/onboarding/components/ChooseStyleSource.tsx:77
msgid "'s style"
msgstr "'s style"

#: src/hooks/tutorial/useTutorialSteps.tsx:77
#~ msgid "\"I think squats and deadlifts are bad for you."
#~ msgstr "\"I think squats and deadlifts are bad for you."

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:37
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:37
msgid "“I think squats and deadlift are bad for you. Best way to damage your back, shoulder, hips, knees. What I would do.”"
msgstr "“I think squats and deadlift are bad for you. Best way to damage your back, shoulder, hips, knees. What I would do.”"

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:57
msgid "(Don't worry, he won't get notified)"
msgstr "(Don't worry, he won't get notified)"

#: src/components/molecules/UpgradePricingCard.tsx:191
#: src/components/molecules/UpgradePricingCard.tsx:224
#: src/components/molecules/UpgradePricingCardBF.tsx:194
#: src/components/molecules/UpgradePricingCardBF.tsx:204
#: src/components/molecules/UpgradePricingCardBF.tsx:236
msgid "{0} {1}"
msgstr "{0} {1}"

#: src/components/pages/Pricing/components/PricingTable.tsx:154
#~ msgid "{0} credits"
#~ msgstr "{0} credits"

#: src/components/pages/Pricing/components/PricingTable.tsx:183
#: src/components/pages/Pricing/components/PricingTable.tsx:218
#~ msgid "{0} euro per post"
#~ msgstr "{0} euro per post"

#: src/components/molecules/UpgradePricingCard.tsx:139
#: src/components/molecules/UpgradePricingCard.tsx:179
#~ msgid "{0} euros billed"
#~ msgstr "{0} euros billed"

#: src/components/organisms/SideBar/SideBar.tsx:119
#~ msgid "{0} subscription"
#~ msgstr "{0} subscription"

#: src/components/molecules/SavedPost.tsx:46
#~ msgid "{0} Words"
#~ msgstr "{0} Words"

#: src/components/molecules/ChooseStyleSourceCards/MyStyle.tsx:20
#: src/pages/onboarding-v2/components/ChooseStyleSource.tsx:74
msgid "{0}'s style"
msgstr "{0}'s style"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:131
#~ msgid "* An email with a confirmation code may be sent by LinkedIn."
#~ msgstr "* An email with a confirmation code may be sent by LinkedIn."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:130
#~ msgid "* We will not store your LinkedIn credentials"
#~ msgstr "* We will not store your LinkedIn credentials"

#: src/components/pages/Pricing/components/PricingTable.tsx:190
#~ msgid "*7 days free trial | Cancelable anytime"
#~ msgstr "*7 days free trial | Cancelable anytime"

#: src/components/atoms/PricingSlider.tsx:51
msgid "*Each profile has its own context, post history, and settings."
msgstr "*Each profile has its own context, post history, and settings."

#: src/pages/affiliate/Affiliate.tsx:38
msgid "*Offer Conditions"
msgstr "*Offer Conditions"

#: src/pages/onboarding-v2/components/ChooseUserProfile.tsx:74
msgid "*RedactAI will retrieve your public profile information to know you better."
msgstr "*RedactAI will retrieve your public profile information to know you better."

#: src/components/molecules/UpgradePricingCard.tsx:175
msgid "*Then"
msgstr "*Then"

#: src/components/pages/Settings/Settings.tsx:75
#~ msgid "/month"
#~ msgstr "/month"

#: src/components/pages/Settings/Settings.tsx:76
#~ msgid "/year"
#~ msgstr "/year"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:48
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:48
msgid "<directions>"
msgstr "<directions>"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:56
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:56
msgid "<Hook with a statistics>"
msgstr "<Hook with a statistics>"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:58
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:58
msgid "<Optimistic conclusion about using LinkedIn>"
msgstr "<Optimistic conclusion about using LinkedIn>"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:59
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:59
msgid "<Signature>"
msgstr "<Signature>"

#: src/components/atoms/PricingSlider.tsx:58
#~ msgid "€ per post"
#~ msgstr "€ per post"

#: src/components/atoms/PricingSlider.tsx:44
#~ msgid "€ per profile"
#~ msgstr "€ per profile"

#: src/components/atoms/PricingSlider.tsx:44
#~ msgid "€ per profile*"
#~ msgstr "€ per profile*"

#: src/components/molecules/SubscriptionInformationCard.tsx:108
msgid "0 Credit left"
msgstr "0 Credit left"

#: src/components/molecules/SubscriptionInformationCard.tsx:244
msgid "0 posts left"
msgstr "0 posts left"

#: src/components/pages/Onboarding/components/ChooseStyleSource.tsx:24
#~ msgid "1 minute"
#~ msgstr "1 minute"

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:19
msgid "1. RedactAI now loads all the data of your LinkedIn profile to give context to your posts."
msgstr "1. RedactAI now loads all the data of your LinkedIn profile to give context to your posts."

#: src/components/molecules/GeneratedPost.tsx:129
msgid "1d"
msgstr "1d"

#: src/components/molecules/GeneratedPost.tsx:38
#~ msgid "1d •"
#~ msgstr "1d •"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:30
#~ msgid "2 minutes"
#~ msgstr "2 minutes"

#: src/components/molecules/UpgradePricingCard.tsx:149
#: src/components/molecules/UpgradePricingCard.tsx:180
#: src/components/organisms/PricingTable.tsx:182
msgid "2 months FREE"
msgstr "2 months FREE"

#: src/components/pages/Pricing/components/StripePricingTable.tsx:16
#~ msgid "2 months free on annual subscriptions"
#~ msgstr "2 months free on annual subscriptions"

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:33
msgid "2. The post style now matches 99% of your writing style"
msgstr "2. The post style now matches 99% of your writing style"

#: src/components/molecules/UpgradePricingCard.tsx:63
#: src/components/molecules/UpgradePricingCard.tsx:96
#: src/components/molecules/UpgradePricingCardBF.tsx:62
#: src/components/molecules/UpgradePricingCardBF.tsx:95
msgid "24/5 customer support"
msgstr "24/5 customer support"

#: src/components/molecules/UpgradePricingCard.tsx:77
#: src/components/molecules/UpgradePricingCardBF.tsx:76
msgid "24/7 customer support"
msgstr "24/7 customer support"

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:43
msgid "3. We removed the default post tone"
msgstr "3. We removed the default post tone"

#: src/components/molecules/UpgradePricingCard.tsx:79
#~ msgid "349.90 euros billed annually"
#~ msgstr "349.90 euros billed annually"

#: src/components/molecules/UpgradePricingCardBF.tsx:158
msgid "50% + 2 months free"
msgstr "50% + 2 months free"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:120
#~ msgid "6 digits code you received by email"
#~ msgstr "6 digits code you received by email"

#: src/components/molecules/SubscriptionInformationCard.tsx:84
#~ msgid "7 days"
#~ msgstr "7 days"

#: src/components/molecules/SubscriptionInformationCard.tsx:85
#~ msgid "7 days at Free"
#~ msgstr "7 days at Free"

#: src/components/organisms/PricingTable.tsx:108
#~ msgid "7 days free trial"
#~ msgstr "7 days free trial"

#: src/components/molecules/SubscriptionInformationCard.tsx:232
msgid "7 days Free Trial"
msgstr "7 days Free Trial"

#: src/components/molecules/UpgradePricingCard.tsx:126
#: src/components/molecules/UpgradePricingCardBF.tsx:138
msgid "78% pick this plan"
msgstr "78% pick this plan"

#: src/components/molecules/UpgradePricingCard.tsx:84
#: src/components/molecules/UpgradePricingCardBF.tsx:83
msgid "8 posts per month"
msgstr "8 posts per month"

#: src/components/molecules/ChooseContentSourceCards/FromUrl.tsx:36
msgid "A blog article"
msgstr "A blog article"

#: src/pages/onboarding/components/ContentURLToolTipFirstStep.tsx:21
msgid "A content can be :"
msgstr "A content can be :"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:31
#~ msgid "A content url"
#~ msgstr "A content url"

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:46
msgid "A content URL"
msgstr "A content URL"

#: src/pages/onboarding/components/NoIdeasToolTip.tsx:18
msgid "A global theme or a specific element you want to talk about."
msgstr "A global theme or a specific element you want to talk about."

#: src/components/molecules/ChooseContentSourceCards/FromUrl.tsx:32
msgid "A Linkedin post"
msgstr "A Linkedin post"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:63
#~ msgid "A LinkedIn post"
#~ msgstr "A LinkedIn post"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:21
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:21
msgid "A list of ideas, separated by a newline and/or charaters or semicolons"
msgstr "A list of ideas, separated by a newline and/or charaters or semicolons"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:44
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:44
msgid "A template text"
msgstr "A template text"

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:50
msgid "A user with this email already exists. Please sign-in using Email/Password instead."
msgstr "A user with this email already exists. Please sign-in using Email/Password instead."

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:39
#~ msgid "A user with this email already exists. Please sign-in using Email/Password."
#~ msgstr "A user with this email already exists. Please sign-in using Email/Password."

#: src/components/organisms/LoginForms/SignUpForm.tsx:152
msgid "A user with this email already exists. Please sign-in with Google instead."
msgstr "A user with this email already exists. Please sign-in with Google instead."

#: src/components/organisms/LoginForms/EmailVerification.tsx:79
msgid "A verification email has been sent to your email, please check your inbox."
msgstr "A verification email has been sent to your email, please check your inbox."

#: src/components/molecules/ChooseContentSourceCards/FromUrl.tsx:28
msgid "A Youtube video"
msgstr "A Youtube video"

#: src/components/molecules/UpgradePricingCard.tsx:55
#: src/components/molecules/UpgradePricingCardBF.tsx:54
msgid "Access to ChatWithRedactAI"
msgstr "Access to ChatWithRedactAI"

#: src/components/pages/Pricing/components/PricingTable.tsx:72
#~ msgid "Access to new features"
#~ msgstr "Access to new features"

#: src/components/molecules/UpgradePricingCard.tsx:92
#: src/components/molecules/UpgradePricingCardBF.tsx:91
msgid "Access to Recycle post"
msgstr "Access to Recycle post"

#: src/components/molecules/popups/DeleteAccountPopupConfirmed.tsx:22
msgid "account deleted"
msgstr "account deleted"

#: src/components/molecules/InvoiceTable.tsx:72
msgid "Actions"
msgstr "Actions"

#: src/components/molecules/affiliateHistory.tsx:26
msgid "Active"
msgstr "Active"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:119
#: src/pages/onboarding-v2/components/ChatWithRedactAIChatArea.tsx:120
msgid "Add \"X\""
msgstr "Add \"X\""

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:106
#~ msgid "Add emojis"
#~ msgstr "Add emojis"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:45
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:45
msgid "Advanced"
msgstr "Advanced"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFirstStep.tsx:21
#: src/pages/onboarding/components/MyOwnIdeasToolTipFirstStep.tsx:21
msgid "Advantage of street marketing"
msgstr "Advantage of street marketing"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:14
#: src/hooks/tutorial/useTutorialSteps.tsx:44
#~ msgid "Advantages of street marketing"
#~ msgstr "Advantages of street marketing"

#: src/pages/affiliate/Affiliate.tsx:37
msgid "Affiliate"
msgstr "Affiliate"

#: src/components/molecules/FilterSelectUsedNotUsed.tsx:24
#: src/pages/findIdea/components/MyIdeasTab.tsx:40
#: src/pages/myPosts/MyPosts.tsx:49
msgid "All"
msgstr "All"

#: src/pages/myPosts/MyPosts.tsx:97
msgid "All posts"
msgstr "All posts"

#: src/pages/myPosts/components/ProfileFilter.tsx:58
#: src/pages/myPosts/components/ProfileFilter.tsx:81
msgid "All profiles"
msgstr "All profiles"

#: src/pages/recyclepost/RecyclePost.tsx:74
msgid "All time"
msgstr "All time"

#: src/components/molecules/popups/DeleteAccountPopupConfirmation.tsx:33
msgid "All your data will be deleted, including your saved posts and ideas."
msgstr "All your data will be deleted, including your saved posts and ideas."

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:57
msgid "Almost there! One more step to go"
msgstr "Almost there! One more step to go"

#: src/components/organisms/LoginForms/SignUpForm.tsx:252
msgid "Already have an account?"
msgstr "Already have an account?"

#: src/pages/findIdea/FindIdea.tsx:96
msgid "Already have an idea ?"
msgstr "Already have an idea ?"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:111
#~ msgid "Already have an idea ? Generate a post"
#~ msgstr "Already have an idea ? Generate a post"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:76
msgid "Alright, I've changed my mind :)"
msgstr "Alright, I've changed my mind :)"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:37
#~ msgid "Always late for the meetings."
#~ msgstr "Always late for the meetings."

#: src/components/molecules/InvoiceTable.tsx:71
msgid "Amount"
msgstr "Amount"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:58
#~ msgid "An email with a new code has been sent to {email}"
#~ msgstr "An email with a new code has been sent to {email}"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:16
msgid "An error occurred during email verification."
msgstr "An error occurred during email verification."

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:17
msgid "An error occurred during password reset."
msgstr "An error occurred during password reset."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:53
#~ msgid "An error occurred, please make sure that you have entered the right email."
#~ msgstr "An error occurred, please make sure that you have entered the right email."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:67
#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:73
#~ msgid "An error occurred, please make sure that you have entered the right password."
#~ msgstr "An error occurred, please make sure that you have entered the right password."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:91
#~ msgid "An error occurred, please make sure you entered the last pin you received by email"
#~ msgstr "An error occurred, please make sure you entered the last pin you received by email"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:104
#: src/hooks/resetPassword/useResetPassword.ts:47
msgid "An error occurred. Please try again later."
msgstr "An error occurred. Please try again later."

#: src/pages/freeTools/TextFormatter.tsx:136
#~ msgid "an hour"
#~ msgstr "an hour"

#: src/hooks/usePostToneOptions.ts:9
msgid "Analytical"
msgstr "Analytical"

#: src/hooks/usePostToneOptions.ts:21
#~ msgid "Analytical / Pedagogical"
#~ msgstr "Analytical / Pedagogical"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:20
#: src/pages/onboarding/components/PostStyleSelect.tsx:18
#~ msgid "Analytical/Pedagogical"
#~ msgstr "Analytical/Pedagogical"

#: src/pages/redactpost-v2/components/LoadingLayout.tsx:34
#: src/pages/redactpost-v2/components/LoadingLayout.tsx:56
#: src/pages/redactpost/components/LoadingLayout.tsx:34
#: src/pages/redactpost/components/LoadingLayout.tsx:56
msgid "Analyzing the content"
msgstr "Analyzing the content"

#: src/components/pages/RedactPost/components/Loader.tsx:25
#~ msgid "Analyzing the style"
#~ msgstr "Analyzing the style"

#: src/pages/redactpost/components/RedactPostContent.tsx:142
#~ msgid "Angle of the post"
#~ msgstr "Angle of the post"

#: src/components/molecules/CurrentSubscription.tsx:62
msgid "Annual"
msgstr "Annual"

#: src/components/molecules/PricingCard.tsx:71
#: src/components/molecules/UpgradePricingCard.tsx:196
#: src/components/molecules/UpgradePricingCard.tsx:229
#: src/components/molecules/UpgradePricingCardBF.tsx:199
#: src/components/molecules/UpgradePricingCardBF.tsx:209
#: src/components/molecules/UpgradePricingCardBF.tsx:241
msgid "annually"
msgstr "annually"

#: src/components/atoms/ButtonTab.tsx:17
msgid "Annually"
msgstr "Annually"

#: src/components/molecules/ChooseStyleSourceCards/CreatorStyle.tsx:14
#: src/pages/onboarding-v2/components/ChooseStyleSource.tsx:88
#: src/pages/onboarding/components/ChooseStyleSource.tsx:90
msgid "Another creator's style"
msgstr "Another creator's style"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:32
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:32
msgid "Any draft of text"
msgstr "Any draft of text"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:323
#~ msgid "Any ideas you would like to share ?"
#~ msgstr "Any ideas you would like to share ?"

#: src/components/molecules/ProfileInformationCard.tsx:27
msgid "Are you looking for a way to monetize your website, blog, or social media following?"
msgstr "Are you looking for a way to monetize your website, blog, or social media following?"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:25
#~ msgid "Are you sure you want to cancel the subscription?"
#~ msgstr "Are you sure you want to cancel the subscription?"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:39
msgid "Are you sure you want to cancel your subscription?"
msgstr "Are you sure you want to cancel your subscription?"

#: src/components/organisms/LinkedInNewPostPopup/LinkedInNewPostPopup.tsx:38
#~ msgid "Are you sure you want to close the popup ? The text will be lost."
#~ msgstr "Are you sure you want to close the popup ? The text will be lost."

#: src/components/molecules/popups/DeleteIdeaPopup.tsx:36
msgid "Are you sure you want to delete this idea?"
msgstr "Are you sure you want to delete this idea?"

#: src/components/organisms/SavedIdea/SavedIdeaCard.tsx:30
#~ msgid "Are you sure you want to delete this idea? This action cannot be undone."
#~ msgstr "Are you sure you want to delete this idea? This action cannot be undone."

#: src/components/organisms/LinkedInPostPopup/LinkedInPostPopup.tsx:45
#~ msgid "Are you sure you want to delete this post ? The text will be lost."
#~ msgstr "Are you sure you want to delete this post ? The text will be lost."

#: src/components/organisms/SavedPost/SavedPostCard.tsx:57
#~ msgid "Are you sure you want to delete this post? This action cannot be undone."
#~ msgstr "Are you sure you want to delete this post? This action cannot be undone."

#: src/components/molecules/popups/DeleteAccountPopupConfirmation.tsx:30
msgid "Are you sure you want to delete your account?"
msgstr "Are you sure you want to delete your account?"

#: src/components/molecules/ProfileInformationCard.tsx:47
msgid "Are you the agency, copywriters or companies?"
msgstr "Are you the agency, copywriters or companies?"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:30
#~ msgid "Arrogant"
#~ msgstr "Arrogant"

#: src/components/molecules/LinkedInSchedulePostPopover/LinkedInSchedulePostPopover.tsx:64
#: src/components/organisms/LinkedInPostPopup/LinkedInPostPopup.tsx:73
#~ msgid "at"
#~ msgstr "at"

#: src/components/molecules/GeneratedPost.tsx:164
#: src/components/molecules/popups/PreviewPopup.tsx:84
msgid "Automatically saved"
msgstr "Automatically saved"

#: src/components/organisms/LoginForms/ResetPasswordForm.tsx:49
msgid "Back"
msgstr "Back"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:118
#: src/pages/onboarding-v2/components/ChatWithRedactAIChatArea.tsx:119
msgid "Be more formal"
msgstr "Be more formal"

#: src/components/pages/Pricing/components/PricingTable.tsx:194
#~ msgid "Best value"
#~ msgstr "Best value"

#: src/hooks/tutorial/useTutorialSteps.tsx:80
#~ msgid "Best way to damage your back, shoulders, hips, knees. What I would do:\""
#~ msgstr "Best way to damage your back, shoulders, hips, knees. What I would do:\""

#: src/components/molecules/UpgradePricingCard.tsx:193
#: src/components/molecules/UpgradePricingCard.tsx:226
#: src/components/molecules/UpgradePricingCardBF.tsx:196
#: src/components/molecules/UpgradePricingCardBF.tsx:206
#: src/components/molecules/UpgradePricingCardBF.tsx:238
msgid "billed"
msgstr "billed"

#: src/components/templates/Page.tsx:62
#~ msgid "Black Friday 🔥 50% off on all annual subscriptions with the code BLACKFRIDAY50"
#~ msgstr "Black Friday 🔥 50% off on all annual subscriptions with the code BLACKFRIDAY50"

#: src/components/templates/Page.tsx:203
msgid "BLACKFRIDAY SALE"
msgstr "BLACKFRIDAY SALE"

#: src/components/pages/Pricing/components/PricingTable.tsx:172
#~ msgid "Book a demo with Yohan"
#~ msgstr "Book a demo with Yohan"

#: src/components/molecules/ProfileInformationCard.tsx:24
msgid "Boost your earnings—join our affiliate program today!"
msgstr "Boost your earnings—join our affiliate program today!"

#: src/components/pages/Pricing/components/PricingTable.tsx:219
#~ msgid "Business"
#~ msgstr "Business"

#: src/components/organisms/SideBar/SideBar.tsx:113
#~ msgid "Buy credits"
#~ msgstr "Buy credits"

#: src/components/molecules/SubscriptionInformationCard.tsx:157
#~ msgid "Buy more credits"
#~ msgstr "Buy more credits"

#: src/pages/onboarding/components/NoIdeasToolTip.tsx:21
msgid "Can be a word or a sentence."
msgstr "Can be a word or a sentence."

#: src/pages/onboarding/components/NoIdeasToolTip.tsx:22
msgid "Can be broad or specific."
msgstr "Can be broad or specific."

#: src/components/molecules/popups/DeleteAccountPopupConfirmation.tsx:37
#: src/components/molecules/popups/DeleteIdeaPopup.tsx:41
#: src/components/molecules/popups/DescribeReason.tsx:35
msgid "Cancel"
msgstr "Cancel"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:90
msgid "Cancel Plan"
msgstr "Cancel Plan"

#: src/components/molecules/CurrentSubscription.tsx:73
#: src/components/molecules/CurrentSubscription.tsx:118
msgid "Cancel subscription"
msgstr "Cancel subscription"

#: src/components/pages/Settings/Settings.tsx:109
#~ msgid "Canceled subscription"
#~ msgstr "Canceled subscription"

#: src/components/molecules/RecyclePostCard.tsx:77
#: src/pages/onboarding/components/GenerateFromContentURL.tsx:50
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:50
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:147
#: src/pages/onboarding/components/PostStyle.tsx:52
#: src/pages/onboarding/components/ProfileUrlStyle.tsx:104
#: src/pages/redactpost-v2/components/PostStyle.tsx:52
#: src/pages/redactpost-v2/components/ProfileUrlStyle.tsx:104
msgid "Change"
msgstr "Change"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:121
#: src/pages/onboarding-v2/components/ChatWithRedactAIChatArea.tsx:122
msgid "Change \"X\" by \"Y\""
msgstr "Change \"X\" by \"Y\""

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:37
msgid "Change my inputs"
msgstr "Change my inputs"

#: src/components/molecules/ProfileHeader.tsx:49
#: src/components/molecules/ProfileHeaderMobile.tsx:50
msgid "Change password"
msgstr "Change password"

#: src/pages/pricing/Pricing.tsx:11
#~ msgid "Change plan"
#~ msgstr "Change plan"

#: src/components/molecules/SubscriptionInformationCard.tsx:168
msgid "Change subscription"
msgstr "Change subscription"

#: src/pages/redactpost-v2/components/RedactPostProfile.tsx:41
msgid "Change writing style"
msgstr "Change writing style"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:27
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:27
msgid "Cheap"
msgstr "Cheap"

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:53
msgid "Choose a LinkedIn creator that you like."
msgstr "Choose a LinkedIn creator that you like."

#: src/components/pages/Pricing/components/StripePricingTable.tsx:14
#~ msgid "Choose a subscription"
#~ msgstr "Choose a subscription"

#: src/pages/onboarding/components/ChooseContentSource.tsx:29
msgid "Choose the content of your post"
msgstr "Choose the content of your post"

#: src/components/pages/Pricing/components/PricingTable.tsx:64
#~ msgid "Choose the plan that works best for you. you can always upgrade later."
#~ msgstr "Choose the plan that works best for you. you can always upgrade later."

#: src/components/organisms/PricingTable.tsx:106
#~ msgid "Choose the plan that works best for you. You can always upgrade later."
#~ msgstr "Choose the plan that works best for you. You can always upgrade later."

#: src/pages/onboarding-v2/components/ChooseStyleSource.tsx:46
#: src/pages/onboarding/components/ChooseStyleSource.tsx:60
msgid "Choose the style of your post"
msgstr "Choose the style of your post"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:86
#: src/components/pages/RedactPost/components/RedactPostForm.tsx:283
#~ msgid "Close"
#~ msgstr "Close"

#: src/components/molecules/RecyclePostCard.tsx:52
msgid "Comments: {0}"
msgstr "Comments: {0}"

#: src/components/molecules/PricingCard.tsx:97
msgid "companies"
msgstr "companies"

#: src/components/pages/Settings/Settings.tsx:112
#~ msgid "Company name"
#~ msgstr "Company name"

#: src/components/pages/Settings/Settings.tsx:118
#~ msgid "Company position"
#~ msgstr "Company position"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:160
msgid "Confirm new password"
msgstr "Confirm new password"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:159
msgid "Confirm New Password"
msgstr "Confirm New Password"

#: src/components/molecules/Popup/ConfirmationPopup.tsx:24
#~ msgid "Confirmation"
#~ msgstr "Confirmation"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:128
#~ msgid "Connect my LinkedIn account"
#~ msgstr "Connect my LinkedIn account"

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:42
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:42
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:139
#: src/pages/redactpost/components/RedactPostContent.tsx:51
msgid "content"
msgstr "content"

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:22
#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:35
msgid "Content"
msgstr "Content"

#: src/components/organisms/SavedPost/SavedPostCard.tsx:72
#~ msgid "Content url:"
#~ msgstr "Content url:"

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:27
#: src/pages/redactpost-v2/components/RedactPostContext.tsx:38
#~ msgid "Context"
#~ msgstr "Context"

#: src/pages/redactpost-v2/components/EditCreateContext.tsx:120
#~ msgid "Context name"
#~ msgstr "Context name"

#: src/pages/redactpost-v2/components/EditCreateContext.tsx:121
#~ msgid "Context Name (example: Elon Musk)"
#~ msgstr "Context Name (example: Elon Musk)"

#: src/pages/redactpost-v2/components/EditCreateContext.tsx:59
#~ msgid "Context name is required"
#~ msgstr "Context name is required"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:233
msgid "Context profile URL"
msgstr "Context profile URL"

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:26
msgid "context."
msgstr "context."

#: src/components/molecules/OnboardingReferralSource/OnboardingReferralSource.tsx:86
#~ msgid "Continue"
#~ msgstr "Continue"

#: src/components/molecules/affiliateLinkCopy.tsx:19
#: src/components/molecules/GeneratedPost.tsx:80
#: src/components/molecules/SavedPost.tsx:79
#: src/hooks/redactPost/usePostPreview.ts:22
msgid "Copied to clipboard"
msgstr "Copied to clipboard"

#: src/components/molecules/affiliateLinkCopy.tsx:31
msgid "Copied!"
msgstr "Copied!"

#: src/components/molecules/affiliateLinkCopy.tsx:31
#: src/components/molecules/popups/PreviewPopup.tsx:152
msgid "Copy"
msgstr "Copy"

#: src/components/molecules/PricingCard.tsx:28
msgid "Copy writing style from any content creator"
msgstr "Copy writing style from any content creator"

#: src/components/molecules/PricingCard.tsx:96
msgid "copywriters, agencies or"
msgstr "copywriters, agencies or"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:114
#~ msgid "Cost: 1 credit"
#~ msgstr "Cost: 1 credit"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:326
#~ msgid "Cost: 3 credits"
#~ msgstr "Cost: 3 credits"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:301
msgid "Create"
msgstr "Create"

#: src/components/atoms/PricingSlider.tsx:28
#~ msgid "Create {0} different profile"
#~ msgstr "Create {0} different profile"

#: src/components/atoms/PricingSlider.tsx:30
msgid "Create {0} different profile*"
msgstr "Create {0} different profile*"

#: src/components/atoms/PricingSlider.tsx:28
#~ msgid "Create {0} posts per month"
#~ msgstr "Create {0} posts per month"

#: src/components/pages/Pricing/components/PricingTable.tsx:148
#~ msgid "Create {0} posts per week"
#~ msgstr "Create {0} posts per week"

#: src/components/atoms/PricingSlider.tsx:24
#~ msgid "Create {value} posts per month"
#~ msgstr "Create {value} posts per month"

#: src/components/pages/Pricing/components/PricingTable.tsx:116
#~ msgid "Create ≈ {0}+ posts or {1}+ posts ideas each month."
#~ msgstr "Create ≈ {0}+ posts or {1}+ posts ideas each month."

#: src/components/atoms/PricingSlider.tsx:57
msgid "Create 8 posts per month"
msgstr "Create 8 posts per month"

#: src/components/templates/Page.tsx:126
#: src/components/templates/Page.tsx:134
#: src/pages/redactpost-v2/RedactPost.tsx:202
#: src/pages/redactpost/RedactPost.tsx:217
msgid "Create a post"
msgstr "Create a post"

#: src/components/templates/Page.tsx:103
#~ msgid "Create a post (old)"
#~ msgstr "Create a post (old)"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:27
msgid "Create an audience"
msgstr "Create an audience"

#: src/pages/redactpost-v2/components/CreateContextPopup.tsx:22
#~ msgid "Create your first context"
#~ msgstr "Create your first context"

#: src/pages/redactpost-v2/components/CreateProfilePopup.tsx:88
msgid "Create your first profile"
msgstr "Create your first profile"

#: src/components/pages/RedactPost/RedactPost.tsx:44
#~ msgid "Create your LinkedIn post in 30 seconds"
#~ msgstr "Create your LinkedIn post in 30 seconds"

#: src/components/pages/RedactPost/RedactPost.tsx:13
#~ msgid "Create your LinkedIn post in 30 seconds 😎"
#~ msgstr "Create your LinkedIn post in 30 seconds 😎"

#: src/components/organisms/PricingTable.tsx:111
#~ msgid "Create your LinkedIn posts in 30 seconds."
#~ msgstr "Create your LinkedIn posts in 30 seconds."

#: src/components/pages/Pricing/components/PricingTable.tsx:128
#~ msgid "Create your LinkedIn posts in 30 seconds. You can cancel anytime."
#~ msgstr "Create your LinkedIn posts in 30 seconds. You can cancel anytime."

#: src/components/organisms/SavedIdea/SavedIdeaCard.tsx:23
#: src/components/organisms/SavedPost/SavedPostCard.tsx:45
#~ msgid "Created at"
#~ msgstr "Created at"

#: src/components/molecules/SavedIdea.tsx:26
#: src/components/molecules/SavedPost.tsx:43
msgid "Created at {0}"
msgstr "Created at {0}"

#: src/components/molecules/UpgradePricingCard.tsx:130
#: src/components/molecules/UpgradePricingCardBF.tsx:142
msgid "Creator"
msgstr "Creator"

#: src/components/organisms/SideBar/SideBar.tsx:111
#~ msgid "Credits left"
#~ msgstr "Credits left"

#: src/components/pages/Settings/Settings.tsx:142
#~ msgid "credits per month"
#~ msgstr "credits per month"

#: src/components/pages/Settings/Settings.tsx:145
#~ msgid "Credits renewal on"
#~ msgstr "Credits renewal on"

#: src/components/molecules/CurrentSubscription.tsx:60
#: src/components/molecules/CurrentSubscriptionFree.tsx:14
msgid "Current plan"
msgstr "Current plan"

#: src/components/molecules/affiliateEarnings.tsx:17
msgid "Currently, you earn"
msgstr "Currently, you earn"

#: src/components/pages/Pricing/components/PricingTable.tsx:225
#~ msgid "Custom"
#~ msgstr "Custom"

#: src/components/pages/Pricing/components/PricingTable.tsx:227
#~ msgid "Custom IA solution"
#~ msgstr "Custom IA solution"

#: src/components/pages/Pricing/components/PricingTable.tsx:165
#~ msgid "Custom pricing"
#~ msgstr "Custom pricing"

#: src/components/pages/Pricing/components/PricingTable.tsx:234
#~ msgid "Customize your own IA"
#~ msgstr "Customize your own IA"

#: src/components/templates/Page.tsx:19
#~ msgid "Dashboard"
#~ msgstr "Dashboard"

#: src/components/molecules/affiliateHistory.tsx:38
#: src/components/molecules/InvoiceTable.tsx:70
msgid "Date"
msgstr "Date"

#: src/components/molecules/PricingCard.tsx:48
msgid "Dedicated customer support"
msgstr "Dedicated customer support"

#: src/components/molecules/Popup/ConfirmationPopup.tsx:35
#: src/components/organisms/SavedIdea/SavedIdeaCard.tsx:44
#: src/components/organisms/SavedPost/SavedPostCard.tsx:106
#~ msgid "Delete"
#~ msgstr "Delete"

#: src/components/molecules/ProfileHeader.tsx:62
#: src/components/molecules/ProfileHeaderMobile.tsx:63
msgid "Delete account"
msgstr "Delete account"

#: src/pages/redactpost-v2/components/EditCreateContext.tsx:114
#~ msgid "Delete context"
#~ msgstr "Delete context"

#: src/components/molecules/popups/DeleteAccountPopupConfirmation.tsx:45
#: src/components/molecules/popups/DeleteIdeaPopup.tsx:51
msgid "Delete Now"
msgstr "Delete Now"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:214
msgid "Delete profile"
msgstr "Delete profile"

#: src/components/pages/Pricing/components/PricingTable.tsx:170
#~ msgid "Demo"
#~ msgstr "Demo"

#: src/components/molecules/popups/DescribeReason.tsx:25
msgid "Describe the reason"
msgstr "Describe the reason"

#: src/components/organisms/LinkedInPostPopup/LinkedInPostPopup.tsx:56
#~ msgid "Desktop"
#~ msgstr "Desktop"

#: src/components/organisms/LinkedInNewPostPopup/LinkedInNewPostPopup.tsx:38
#~ msgid "Discard post"
#~ msgstr "Discard post"

#: src/components/organisms/LoginForms/EmailVerification.tsx:88
msgid "Disconnect"
msgstr "Disconnect"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmed.tsx:25
#: src/components/molecules/popups/DeleteAccountPopupConfirmed.tsx:28
msgid "Dismiss"
msgstr "Dismiss"

#: src/pages/onboarding-v2/components/FillInContent.tsx:108
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:84
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:172
#: src/pages/onboarding/components/ProfileUrlStyle.tsx:141
#: src/pages/redactpost-v2/components/ProfileUrlStyle.tsx:141
msgid "Do you want suggestions on ideas?"
msgstr "Do you want suggestions on ideas?"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:54
#~ msgid "Doesn’t know what he does but still do it."
#~ msgstr "Doesn’t know what he does but still do it."

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:61
msgid "Doing all the work but don’t tell anyone."
msgstr "Doing all the work but don’t tell anyone."

#: src/components/organisms/LoginForms/LoginForm.tsx:162
msgid "Don't have an account?"
msgstr "Don't have an account?"

#: src/pages/onboarding-v2/components/ProfileUrlStyleToolTip.tsx:21
#: src/pages/onboarding/components/ProfileUrlStyleToolTip.tsx:21
msgid "Don’t worry, nobody will know that you used their profile url to generate posts on RedactAI."
msgstr "Don’t worry, nobody will know that you used their profile url to generate posts on RedactAI."

#: src/components/molecules/InvoiceTable.tsx:92
msgid "Download Invoice"
msgstr "Download Invoice"

#: src/pages/redactpost-v2/components/LoadingLayout.tsx:32
#: src/pages/redactpost-v2/components/LoadingLayout.tsx:52
#: src/pages/redactpost/components/LoadingLayout.tsx:32
#: src/pages/redactpost/components/LoadingLayout.tsx:52
msgid "Downloading the content"
msgstr "Downloading the content"

#: src/components/pages/RedactPost/components/Loader.tsx:25
#~ msgid "Downloading the style"
#~ msgstr "Downloading the style"

#: src/components/molecules/ProfileInformationCard.tsx:52
msgid "Each brings a unique perspective and set of skills to the table, ensuring the success of marketing campaigns and content strategies."
msgstr "Each brings a unique perspective and set of skills to the table, ensuring the success of marketing campaigns and content strategies."

#: src/components/atoms/PricingSlider.tsx:47
#~ msgid "Each profile has its own context, post history, and settings"
#~ msgstr "Each profile has its own context, post history, and settings"

#: src/pages/redactpost-v2/components/RedactPostProfileSelect.tsx:108
msgid "Edit"
msgstr "Edit"

#: src/pages/redactpost-v2/components/EditCreateContext.tsx:106
#~ msgid "Edit my context"
#~ msgstr "Edit my context"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:201
msgid "Edit my profile"
msgstr "Edit my profile"

#: src/components/molecules/CurrentSubscription.tsx:68
#: src/components/molecules/CurrentSubscription.tsx:113
msgid "Edit plan"
msgstr "Edit plan"

#: src/components/molecules/affiliateHistory.tsx:40
#: src/components/organisms/ReferralSourceForm.tsx:52
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:58
msgid "Email"
msgstr "Email"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:110
msgid "Email Verification Failed"
msgstr "Email Verification Failed"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:97
msgid "Email Verification Successful"
msgstr "Email Verification Successful"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:56
#~ msgid "Email verified!"
#~ msgstr "Email verified!"

#: src/components/organisms/LoginForms/LoginForm.tsx:120
#: src/components/organisms/LoginForms/ResetPasswordForm.tsx:32
#: src/components/organisms/LoginForms/SignUpForm.tsx:171
msgid "Enter email"
msgstr "Enter email"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:149
msgid "Enter new password"
msgstr "Enter new password"

#: src/components/organisms/LoginForms/LoginForm.tsx:132
#: src/components/organisms/LoginForms/SignUpForm.tsx:183
msgid "Enter password"
msgstr "Enter password"

#: src/components/organisms/LoginForms/LoginForm.tsx:12
#~ msgid "Enter your email"
#~ msgstr "Enter your email"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:15
msgid "Enter your new password"
msgstr "Enter your new password"

#: src/components/organisms/LoginForms/LoginForm.tsx:16
#~ msgid "Enter your password"
#~ msgstr "Enter your password"

#: src/components/molecules/UpgradePricingCard.tsx:208
#: src/components/molecules/UpgradePricingCardBF.tsx:220
msgid "Enterprise"
msgstr "Enterprise"

#: src/components/organisms/LinkedInPostsCalendar/LinkedInPostsCalendar.tsx:63
#~ msgid "Error while deleting post"
#~ msgstr "Error while deleting post"

#: src/components/organisms/LinkedInPostsCalendar/LinkedInPostsCalendar.tsx:83
#~ msgid "Error while scheduling post"
#~ msgstr "Error while scheduling post"

#: src/components/organisms/ReferralSourceForm.tsx:125
#~ msgid "Error!"
#~ msgstr "Error!"

#: src/components/molecules/UpgradePricingCard.tsx:130
#: src/components/molecules/UpgradePricingCardBF.tsx:142
msgid "Essential"
msgstr "Essential"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:28
#: src/components/pages/Onboarding/components/ChooseStyleSource.tsx:22
#~ msgid "Estimated time:"
#~ msgstr "Estimated time:"

#: src/hooks/tutorial/useTutorialSteps.tsx:98
#~ msgid "Everybody is using LinkedIn nowadays"
#~ msgstr "Everybody is using LinkedIn nowadays"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:57
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:57
msgid "Everybody is using LinkedIn nowadays-Students-Professional"
msgstr "Everybody is using LinkedIn nowadays-Students-Professional"

#: src/components/molecules/UpgradePricingCard.tsx:69
#: src/components/molecules/UpgradePricingCardBF.tsx:68
msgid "Everything in Creator plus :"
msgstr "Everything in Creator plus :"

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:22
msgid "Everything that you posted on LinkedIn will be used to enhance your content."
msgstr "Everything that you posted on LinkedIn will be used to enhance your content."

#: src/hooks/tutorial/useTutorialSteps.tsx:40
#: src/hooks/tutorial/useTutorialSteps.tsx:58
#: src/hooks/tutorial/useTutorialSteps.tsx:73
#: src/hooks/tutorial/useTutorialSteps.tsx:91
#: src/hooks/tutorial/useTutorialSteps.tsx:131
#~ msgid "Example :"
#~ msgstr "Example :"

#: src/pages/onboarding-v2/components/FillInContent.tsx:136
#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:25
#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:36
#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:55
#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFirstStep.tsx:20
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:106
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:25
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:36
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:55
#: src/pages/onboarding/components/MyOwnIdeasToolTipFirstStep.tsx:20
msgid "Example:"
msgstr "Example:"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:114
#~ msgid "Example: \"Digital marketing\" or \"How to write a newsletter\""
#~ msgstr "Example: \"Digital marketing\" or \"How to write a newsletter\""

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:286
#~ msgid "Example: 10 reasons why you should use street marketing"
#~ msgstr "Example: 10 reasons why you should use street marketing"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:47
#~ msgid "Example: advantages of street marketing"
#~ msgstr "Example: advantages of street marketing"

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:72
#~ msgid "Example: The story of elon musk"
#~ msgstr "Example: The story of elon musk"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:38
#~ msgid "Example: The story of Elon Musk"
#~ msgstr "Example: The story of Elon Musk"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:39
#~ msgid "Example: Why do you need to post on LinkedIn ?"
#~ msgstr "Example: Why do you need to post on LinkedIn ?"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:21
msgid "Example: Why do you need to post on Linkedin?"
msgstr "Example: Why do you need to post on Linkedin?"

#: src/hooks/tutorial/useTutorialSteps.tsx:15
#~ msgid "Examples :"
#~ msgstr "Examples :"

#: src/pages/onboarding-v2/components/ProfileUrlStyleToolTip.tsx:23
#: src/pages/onboarding/components/ContentURLToolTipFinalStep.tsx:19
#: src/pages/onboarding/components/ContentURLToolTipFirstStep.tsx:29
#: src/pages/onboarding/components/NoIdeasToolTip.tsx:25
#: src/pages/onboarding/components/ProfileUrlStyleToolTip.tsx:23
msgid "Examples:"
msgstr "Examples:"

#: src/components/molecules/UpgradePricingCard.tsx:129
#~ msgid "excluding taxes"
#~ msgstr "excluding taxes"

#: src/components/molecules/PricingCard.tsx:75
#: src/components/molecules/UpgradePricingCard.tsx:200
#: src/components/molecules/UpgradePricingCard.tsx:233
#: src/components/molecules/UpgradePricingCardBF.tsx:212
#: src/components/molecules/UpgradePricingCardBF.tsx:245
msgid "excluding taxes | Cancel anytime"
msgstr "excluding taxes | Cancel anytime"

#: src/components/organisms/LoginForms/EmailVerification.tsx:36
msgid "Failed to check email verification status. Please try again later."
msgstr "Failed to check email verification status. Please try again later."

#: src/hooks/profile/profilePostsCheck/useProfilePostsCheck.ts:17
msgid "Failed to check profile status"
msgstr "Failed to check profile status"

#: src/hooks/profile/createProfile/useCreateProfile.ts:70
#: src/hooks/profile/updateProfile/useUpdateProfile.ts:49
msgid "Failed to create your profile. Please check that you entered a valid LinkedIn profile url and try again."
msgstr "Failed to create your profile. Please check that you entered a valid LinkedIn profile url and try again."

#: src/hooks/redactPostNew/usePostSubjectSuggestions.ts:36
msgid "Failed to fetch suggestions. Please try again."
msgstr "Failed to fetch suggestions. Please try again."

#: src/hooks/redactPostNew/useFetchContext.ts:34
#: src/pages/onboarding-v2/components/ChooseUserContext.tsx:40
#~ msgid "Failed to fetch user context. Please try again."
#~ msgstr "Failed to fetch user context. Please try again."

#: src/hooks/redactPostNew/useFetchProfile.ts:34
#: src/pages/onboarding-v2/components/ChooseUserProfile.tsx:42
#~ msgid "Failed to fetch user profile. Please try again."
#~ msgstr "Failed to fetch user profile. Please try again."

#: src/components/organisms/LoginForms/EmailVerification.tsx:56
msgid "Failed to resend verification email. Please try again later."
msgstr "Failed to resend verification email. Please try again later."

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:89
msgid "Failed to reset password. Please try again."
msgstr "Failed to reset password. Please try again."

#: src/hooks/resetPassword/useResetPassword.ts:36
msgid "Failed to send reset email. Please try again."
msgstr "Failed to send reset email. Please try again."

#: src/components/organisms/LoginForms/EmailVerification.tsx:66
msgid "Failed to sign out. Please try again later."
msgstr "Failed to sign out. Please try again later."

#: src/pages/myPosts/MyPosts.tsx:103
msgid "Favourites"
msgstr "Favourites"

#: src/components/molecules/FilterSelectUsedNotUsed.tsx:39
msgid "Filter By"
msgstr "Filter By"

#: src/pages/redactpost-v2/components/LoadingLayout.tsx:35
#: src/pages/redactpost-v2/components/LoadingLayout.tsx:60
#: src/pages/redactpost/components/LoadingLayout.tsx:35
#: src/pages/redactpost/components/LoadingLayout.tsx:60
msgid "Final steps"
msgstr "Final steps"

#: src/components/templates/Page.tsx:124
#: src/pages/findIdea/components/MyIdeasTab.tsx:75
#: src/pages/findIdea/FindIdea.tsx:94
#: src/pages/findIdea/FindIdea.tsx:115
msgid "Find an idea"
msgstr "Find an idea"

#: src/pages/redactpost-v2/RedactPost.tsx:206
#: src/pages/redactpost/RedactPost.tsx:221
msgid "Find an idea here →"
msgstr "Find an idea here →"

#: src/pages/findIdea/FindIdea.tsx:167
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:197
msgid "Find my ideas"
msgstr "Find my ideas"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:35
msgid "Find new clients"
msgstr "Find new clients"

#: src/components/molecules/PricingCard.tsx:40
msgid "Find original and up-to-date post ideas"
msgstr "Find original and up-to-date post ideas"

#: src/components/pages/FindIdea/FindIdea.tsx:13
#~ msgid "Find your LinkedIn post idea in a few words"
#~ msgstr "Find your LinkedIn post idea in a few words"

#: src/components/organisms/ChatWithRedactAI.tsx:49
#: src/pages/onboarding-v2/components/ChatWithRedactAI.tsx:56
msgid "Finish tutorial"
msgstr "Finish tutorial"

#: src/components/organisms/LoginForms/SignUpForm.tsx:194
#: src/components/organisms/LoginForms/SignUpForm.tsx:195
msgid "First name"
msgstr "First name"

#: src/components/molecules/SubscriptionInformationCard.tsx:83
#~ msgid "for"
#~ msgstr "for"

#: src/components/molecules/PricingCard.tsx:94
msgid "For"
msgstr "For"

#: src/components/molecules/UpgradePricingCard.tsx:141
msgid "for 7 days*"
msgstr "for 7 days*"

#: src/components/molecules/UpgradePricingCard.tsx:127
#~ msgid "For beginners starting on LinkedIn."
#~ msgstr "For beginners starting on LinkedIn."

#: src/components/molecules/UpgradePricingCard.tsx:134
#: src/components/molecules/UpgradePricingCardBF.tsx:146
msgid "For casual posting on LinkedIn without hassle."
msgstr "For casual posting on LinkedIn without hassle."

#: src/components/molecules/UpgradePricingCard.tsx:209
#: src/components/molecules/UpgradePricingCardBF.tsx:221
msgid "For copywriters or companies writing posts for others."
msgstr "For copywriters or companies writing posts for others."

#: src/components/pages/Pricing/components/PricingTable.tsx:232
#~ msgid "For copywriters, agencies or companies"
#~ msgstr "For copywriters, agencies or companies"

#: src/components/molecules/SubscriptionInformationCard.tsx:86
#~ msgid "for free"
#~ msgstr "for free"

#: src/components/molecules/UpgradePricingCard.tsx:126
#~ msgid "For individuals wanting to produce high quality LinkedIn content in no time."
#~ msgstr "For individuals wanting to produce high quality LinkedIn content in no time."

#: src/components/molecules/affiliateConditions.tsx:10
msgid "For me"
msgstr "For me"

#: src/components/molecules/affiliateConditions.tsx:15
msgid "For my referral"
msgstr "For my referral"

#: src/components/organisms/LoginForms/LoginForm.tsx:144
msgid "Forgot password?"
msgstr "Forgot password?"

#: src/hooks/usePostToneOptions.ts:29
#~ msgid "Formal / Serious"
#~ msgstr "Formal / Serious"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:22
#: src/pages/onboarding/components/PostStyleSelect.tsx:20
#~ msgid "Formal/Serious"
#~ msgstr "Formal/Serious"

#: src/pages/freeTools/TextFormatter.tsx:105
#~ msgid "Format your LinkedIn post with bold, strikethrough, italic text styles and more!"
#~ msgstr "Format your LinkedIn post with bold, strikethrough, italic text styles and more!"

#: src/pages/freeTools/TextFormatter.tsx:102
#~ msgid "Formatter"
#~ msgstr "Formatter"

#: src/components/molecules/CurrentSubscriptionFree.tsx:15
msgid "Free"
msgstr "Free"

#: src/components/molecules/PricingCard.tsx:64
msgid "Free Trial for 7 days"
msgstr "Free Trial for 7 days"

#: src/pages/freeTools/components/FAQ.tsx:14
#~ msgid "Frequently Asked Questions"
#~ msgstr "Frequently Asked Questions"

#: src/hooks/usePostToneOptions.ts:13
msgid "Friendly"
msgstr "Friendly"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:51
#~ msgid "From an url"
#~ msgstr "From an url"

#: src/components/molecules/ChooseContentSourceCards/FromUrl.tsx:19
#: src/pages/redactpost/components/RedactPostContent.tsx:60
msgid "From an URL"
msgstr "From an URL"

#: src/pages/onboarding/components/ProfileUrlStyle.tsx:100
#: src/pages/redactpost-v2/components/ProfileUrlStyle.tsx:100
msgid "From LinkedIn profile url"
msgstr "From LinkedIn profile url"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:247
#: src/pages/redactpost-v2/components/RedactPostStyle.tsx:76
#: src/pages/redactpost/components/RedactPostStyle.tsx:76
msgid "From LinkedIn profile URL"
msgstr "From LinkedIn profile URL"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:28
#: src/pages/onboarding-v2/components/ChooseStyleSource.tsx:105
#: src/pages/onboarding/components/ChooseStyleSource.tsx:107
msgid "From our list of style"
msgstr "From our list of style"

#: src/pages/onboarding/components/PostStyle.tsx:48
#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:253
#: src/pages/redactpost-v2/components/PostStyle.tsx:48
#: src/pages/redactpost-v2/components/RedactPostStyle.tsx:82
#: src/pages/redactpost/components/RedactPostStyle.tsx:82
msgid "From post type"
msgstr "From post type"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:300
#~ msgid "From tone"
#~ msgstr "From tone"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:360
#~ msgid "From tone, pronoun and length"
#~ msgstr "From tone, pronoun and length"

#: src/pages/findIdea/FindIdea.tsx:98
msgid "Generate a post"
msgstr "Generate a post"

#: src/components/pages/RedactPost/RedactPost.tsx:50
#~ msgid "Generate a post V2"
#~ msgstr "Generate a post V2"

#: src/components/molecules/PricingCard.tsx:32
msgid "Generate all your posts in minutes"
msgstr "Generate all your posts in minutes"

#: src/pages/onboarding-v2/components/ChooseStyleSource.tsx:131
#: src/pages/onboarding-v2/components/ChooseStyleUrl.tsx:115
#: src/pages/onboarding-v2/components/FillInContent.tsx:171
msgid "Generate my post"
msgstr "Generate my post"

#: src/components/molecules/GeneratedIdea.tsx:54
#: src/components/molecules/SavedIdea.tsx:51
msgid "Generate Post"
msgstr "Generate Post"

#: src/components/organisms/SideBar/SideBar.tsx:134
#~ msgid "Generated ideas"
#~ msgstr "Generated ideas"

#: src/components/organisms/SideBar/SideBar.tsx:127
#~ msgid "Generated posts"
#~ msgstr "Generated posts"

#: src/pages/onboarding-v2/components/PostGeneration.tsx:42
#: src/pages/onboarding/components/PostGeneration.tsx:46
#: src/pages/redactpost/components/RedactPostGeneration.tsx:38
msgid "Generating posts..."
msgstr "Generating posts..."

#: src/components/pages/RedactPost/components/Loader.tsx:27
#~ msgid "Generating the post"
#~ msgstr "Generating the post"

#: src/components/pages/Pricing/components/PricingTable.tsx:139
#~ msgid "Get 2 free months"
#~ msgstr "Get 2 free months"

#: src/components/pages/Pricing/components/PricingTable.tsx:174
#~ msgid "Get a 15 minutes tour of our product"
#~ msgstr "Get a 15 minutes tour of our product"

#: src/components/molecules/SubscriptionInformationCard.tsx:211
#: src/components/molecules/SubscriptionInformationCard.tsx:257
msgid "Get more posts"
msgstr "Get more posts"

#: src/components/molecules/UpgradePricingCardBF.tsx:169
#: src/components/molecules/UpgradePricingCardBF.tsx:180
msgid "Get my -50% deal"
msgstr "Get my -50% deal"

#: src/components/pages/Pricing/components/PricingTable.tsx:124
#~ msgid "Get started"
#~ msgstr "Get started"

#: src/components/pages/Pricing/components/PricingTable.tsx:126
#~ msgid "Get started for Free"
#~ msgstr "Get started for Free"

#: src/components/pages/Pricing/components/PricingTable.tsx:186
#~ msgid "Get started for Free*"
#~ msgstr "Get started for Free*"

#: src/components/molecules/CurrentSubscriptionFree.tsx:23
msgid "Get unlimited posts"
msgstr "Get unlimited posts"

#: src/pages/onboarding-v2/components/ChooseUserContext.tsx:69
#~ msgid "Getting your context..."
#~ msgstr "Getting your context..."

#: src/pages/onboarding-v2/components/ChooseUserProfile.tsx:61
msgid "Getting your profile..."
msgstr "Getting your profile..."

#: src/pages/onboarding-v2/components/ChooseStyleSource.tsx:116
#: src/pages/onboarding-v2/components/ChooseStyleUrl.tsx:102
#: src/pages/onboarding/components/ChooseStyleSource.tsx:115
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:190
#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:69
#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:88
#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:290
msgid "Go back"
msgstr "Go back"

#: src/pages/onboarding-v2/Onboarding.tsx:264
#: src/pages/onboarding/Onboarding.tsx:204
msgid "Good to have you,"
msgstr "Good to have you,"

#: src/components/organisms/ReferralSourceForm.tsx:54
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:60
msgid "Google search"
msgstr "Google search"

#: src/components/organisms/PricingTable.tsx:37
#: src/components/organisms/PricingTable.tsx:55
#~ msgid "Great!"
#~ msgstr "Great!"

#: src/ui-v2/organisms/PricingTable.tsx:37
#~ msgid "Great! you are getting 2 months FREE with annual subscription"
#~ msgstr "Great! you are getting 2 months FREE with annual subscription"

#: src/ui-v2/organisms/PricingTable.tsx:25
#~ msgid "Great! you are getting 2months FREE with annual subscription"
#~ msgstr "Great! you are getting 2months FREE with annual subscription"

#: src/components/pages/Dashboard/Dashboard.tsx:10
#~ msgid "Hello {0} 👋"
#~ msgstr "Hello {0} 👋"

#: src/components/pages/Dashboard/Dashboard.tsx:8
#~ msgid "Hello {Yohan} 👋"
#~ msgstr "Hello {Yohan} 👋"

#: src/components/organisms/NavigationBar.tsx:41
#: src/components/organisms/NavigationBarMobile.tsx:56
#~ msgid "Help & support"
#~ msgstr "Help & support"

#: src/components/organisms/PostEditor/PostEditor.tsx:90
#~ msgid "Here will appear your generated post"
#~ msgstr "Here will appear your generated post"

#: src/components/molecules/popups/PreviewPopup.tsx:89
#~ msgid "Here will appear your generated post.."
#~ msgstr "Votre publication générée apparaîtra ici.."

#: src/components/organisms/SavedPost/SavedPostCard.tsx:51
#~ msgid "Hide"
#~ msgstr "Hide"

#: src/components/molecules/PricingCard.tsx:24
msgid "High quality post generation"
msgstr "High quality post generation"

#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:93
msgid "Hiring"
msgstr "Hiring"

#: src/pages/findIdea/components/MyIdeasTab.tsx:81
#: src/pages/findIdea/FindIdea.tsx:121
msgid "History"
msgstr "History"

#: src/components/pages/FindIdea/FindIdea.tsx:20
#~ msgid "History ({0} ideas)"
#~ msgstr "History ({0} ideas)"

#: src/components/pages/RedactPost/RedactPost.tsx:47
#~ msgid "History ({0} posts)"
#~ msgstr "History ({0} posts)"

#: src/components/templates/Page.tsx:211
msgid "hours left"
msgstr "hours left"

#: src/components/molecules/OnboardingReferralSource/OnboardingReferralSource.tsx:72
#~ msgid "How did you know RedactAI ?"
#~ msgstr "How did you know RedactAI ?"

#: src/components/molecules/OnboardingReferralSource/OnboardingReferralSource.tsx:72
#~ msgid "How did you know us ?"
#~ msgstr "How did you know us ?"

#: src/components/organisms/ReferralSourceForm.tsx:94
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:111
msgid "How did you know us?"
msgstr "How did you know us?"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:16
#~ msgid "How to create an audience ?"
#~ msgstr "How to create an audience ?"

#: src/pages/freeTools/TextFormatter.tsx:175
#~ msgid "How to use the LinkedIn Text Formatter"
#~ msgstr "How to use the LinkedIn Text Formatter"

#: src/hooks/tutorial/useTutorialSteps.tsx:135
#~ msgid "https://www.linkedin.com/in/benoitdubos/"
#~ msgstr "https://www.linkedin.com/in/benoitdubos/"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:26
#~ msgid "Humble"
#~ msgstr "Humble"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:16
#~ msgid "Humorous"
#~ msgstr "Humorous"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:54
#~ msgid "I"
#~ msgstr "I"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:50
msgid "I did not receive adequate assistance when I had a problem."
msgstr "I did not receive adequate assistance when I had a problem."

#: src/components/molecules/ChooseContentSourceCards/NoIdeas.tsx:15
msgid "I don't have ideas"
msgstr "I don't have ideas"

#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:143
msgid "I Don't have ideas"
msgstr "I Don't have ideas"

#: src/components/organisms/LoginForms/SignUpForm.tsx:229
msgid "I have read and accept the"
msgstr "I have read and accept the"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:58
msgid "I have stopped posting on LinkedIn"
msgstr "I have stopped posting on LinkedIn"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:69
msgid "I have to cancel it"
msgstr "I have to cancel it"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:54
msgid "I only needed the product for a short-term project"
msgstr "I only needed the product for a short-term project"

#: src/components/molecules/GeneratedPost.tsx:126
#: src/components/molecules/popups/PreviewPopup.tsx:119
#: src/components/molecules/SavedPost.tsx:57
msgid "I use RedactAI because I'm smart"
msgstr "I use RedactAI because I'm smart"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:42
msgid "I'm not using the product as much as I thought I would."
msgstr "I'm not using the product as much as I thought I would."

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:32
msgid "IA model"
msgstr "IA model"

#: src/components/pages/Settings/Settings.tsx:91
#~ msgid "idea generation per month"
#~ msgstr "idea generation per month"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:24
msgid "Ideas"
msgstr "Ideas"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:42
#~ msgid "Ideas:"
#~ msgstr "Ideas:"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:40
msgid "If you are doing it, you are messing one of us and that's....unacceptable!"
msgstr "If you are doing it, you are messing one of us and that's....unacceptable!"

#: src/pages/redactpost-v2/components/CreateContextPopup.tsx:24
#~ msgid "If you create posts for multiple clients don't worry, you will be able to create more contexts after."
#~ msgstr "If you create posts for multiple clients don't worry, you will be able to create more contexts after."

#: src/pages/redactpost-v2/components/CreateProfilePopup.tsx:90
msgid "If you create posts for multiple clients don't worry, you will be able to create more profiles after."
msgstr "If you create posts for multiple clients don't worry, you will be able to create more profiles after."

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:67
#: src/pages/redactpost-v2/components/CreateProfilePopup.tsx:89
msgid "In order to personalise your experience we need your LinkedIn profile url."
msgstr "In order to personalise your experience we need your LinkedIn profile url."

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:330
#~ msgid "In which style ?"
#~ msgstr "In which style ?"

#: src/components/molecules/affiliateHistory.tsx:28
msgid "Inactive"
msgstr "Inactive"

#: src/hooks/usePostToneOptions.ts:9
#~ msgid "Informal / Cool"
#~ msgstr "Informal / Cool"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:17
#: src/pages/onboarding/components/PostStyleSelect.tsx:15
#~ msgid "Informal/Cool"
#~ msgstr "Informal/Cool"

#: src/pages/redactpost-v2/components/LoadingLayout.tsx:48
#: src/pages/redactpost/components/LoadingLayout.tsx:48
msgid "Initializing the IA model"
msgstr "Initializing the IA model"

#: src/hooks/usePostToneOptions.ts:17
msgid "Inspirational"
msgstr "Inspirational"

#: src/pages/redactpost-v2/components/CreateProfilePopup.tsx:72
msgid "Instead please choose the profile of a LinkedIn creator that you like."
msgstr "Instead please choose the profile of a LinkedIn creator that you like."

#: src/pages/login/LoginPage.tsx:59
msgid "Introducing"
msgstr "Introducing"

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:64
msgid "Introducing the new"
msgstr "Introducing the new"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:18
msgid "Invalid password reset link."
msgstr "Invalid password reset link."

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:17
msgid "Invalid verification link."
msgstr "Invalid verification link."

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:20
#~ msgid "Ironic"
#~ msgstr "Ironic"

#: src/pages/onboarding/components/ContentURLToolTipFinalStep.tsx:17
msgid "It can be a specific element from the content you want to talk about or the global idea."
msgstr "It can be a specific element from the content you want to talk about or the global idea."

#: src/pages/onboarding-v2/components/ProfileUrlStyleToolTip.tsx:19
#: src/pages/onboarding/components/ProfileUrlStyleToolTip.tsx:19
msgid "It can be your profile URL or your favourite content creator on LinkedIn."
msgstr "It can be your profile URL or your favourite content creator on LinkedIn."

#: src/pages/onboarding/components/ChooseContentSource.tsx:32
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:134
msgid "It will only take 2 mins"
msgstr "It will only take 2 mins"

#: src/components/molecules/ProfileInformationCard.tsx:49
msgid "It's essential to understand the roles and responsibilities of the key players"
msgstr "It's essential to understand the roles and responsibilities of the key players"

#: src/components/molecules/ProfileInformationCard.tsx:35
msgid "Join affiliate program"
msgstr "Join affiliate program"

#: src/components/molecules/GeneratedIdea.tsx:31
#: src/components/molecules/SavedIdea.tsx:35
msgid "Key ideas"
msgstr "Key ideas"

#: src/pages/onboarding-v2/components/FillInContent.tsx:132
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:102
msgid "Key Ideas"
msgstr "Key Ideas"

#: src/pages/onboarding-v2/components/FillInContent.tsx:134
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:104
msgid "Key ideas."
msgstr "Key ideas."

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:87
#: src/components/pages/RedactPost/components/RedactPostForm.tsx:284
#~ msgid "Last"
#~ msgstr "Last"

#: src/pages/recyclepost/RecyclePost.tsx:58
msgid "Last 14 days"
msgstr "Last 14 days"

#: src/pages/recyclepost/RecyclePost.tsx:62
msgid "Last 28 days"
msgstr "Last 28 days"

#: src/pages/recyclepost/RecyclePost.tsx:70
msgid "Last 365 days"
msgstr "Last 365 days"

#: src/pages/recyclepost/RecyclePost.tsx:54
msgid "Last 7 days"
msgstr "Last 7 days"

#: src/pages/recyclepost/RecyclePost.tsx:66
msgid "Last 90 days"
msgstr "Last 90 days"

#: src/components/organisms/LoginForms/SignUpForm.tsx:205
#: src/components/organisms/LoginForms/SignUpForm.tsx:206
msgid "Last name"
msgstr "Last name"

#: src/components/molecules/PricingCard.tsx:92
msgid "Learn about RedactAI"
msgstr "Learn about RedactAI"

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:63
#~ msgid "Learn how to generate the content"
#~ msgstr "Learn how to generate the content"

#: src/components/organisms/SideBar/SideBar.tsx:36
#~ msgid "Left"
#~ msgstr "Left"

#: src/components/pages/Pricing/components/PricingTable.tsx:60
#: src/components/pages/Pricing/components/PricingTable.tsx:78
#~ msgid "Legend"
#~ msgstr "Legend"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:373
#~ msgid "Length"
#~ msgstr "Length"

#: src/components/organisms/SavedPost/SavedPostCard.tsx:87
#~ msgid "Length:"
#~ msgstr "Length:"

#: src/components/organisms/PricingTable.tsx:172
msgid "Less time redacting. More time on your business."
msgstr "Less time redacting. More time on your business."

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:95
msgid "Let's choose a writing style"
msgstr "Let's choose a writing style"

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:90
msgid "Let's create my context"
msgstr "Let's create my context"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:23
#~ msgid "Let's create your first post"
#~ msgstr "Let's create your first post"

#: src/pages/onboarding/components/ChooseContentSource.tsx:31
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:133
msgid "Let's do this,"
msgstr "Let's do this,"

#: src/components/pages/Onboarding/Onboarding.tsx:133
#~ msgid "Let's find an idea"
#~ msgstr "Let's find an idea"

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:66
msgid "Let's get to know each other"
msgstr "Let's get to know each other"

#: src/components/molecules/OnboardingReferralSource/OnboardingReferralSource.tsx:86
#~ msgid "Let's go"
#~ msgstr "Let's go"

#: src/components/organisms/OnboardingPopup/OnboardingPopup.tsx:55
#~ msgid "Let's go !"
#~ msgstr "Let's go !"

#: src/pages/onboarding-v2/components/ChooseUserProfile.tsx:64
msgid "Let's go!"
msgstr "Let's go!"

#: src/components/pages/Pricing/components/PricingTable.tsx:227
#~ msgid "Let's talk"
#~ msgstr "Let's talk"

#: src/components/molecules/PricingCard.tsx:91
msgid "Let's Talk"
msgstr "Let's Talk"

#: src/components/molecules/ChooseContentSourceCards/NoIdeas.tsx:16
msgid "Let's use our idea generator"
msgstr "Let's use our idea generator"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:74
#~ msgid "Let's use our idea generator 😉"
#~ msgstr "Let's use our idea generator 😉"

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:62
msgid "Let’s create your first post"
msgstr "Let’s create your first post"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:234
msgid "LinkedIn context profile url (example: https://www.linkedin.com/in/elonmusk/)"
msgstr "LinkedIn context profile url (example: https://www.linkedin.com/in/elonmusk/)"

#: src/components/organisms/ReferralSourceForm.tsx:42
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:48
msgid "LinkedIn message"
msgstr "LinkedIn message"

#: src/components/organisms/ReferralSourceForm.tsx:44
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:50
msgid "LinkedIn post"
msgstr "LinkedIn post"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:103
#~ msgid "LinkedIn post scheduling is temporarily disabled"
#~ msgstr "LinkedIn post scheduling is temporarily disabled"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:269
msgid "LinkedIn profile style url (example: https://www.linkedin.com/in/elonmusk/)"
msgstr "LinkedIn profile style url (example: https://www.linkedin.com/in/elonmusk/)"

#: src/pages/recyclepost/RecyclePost.tsx:242
#~ msgid "LinkedIn profile url"
#~ msgstr "LinkedIn profile url"

#: src/pages/onboarding-v2/components/ChooseStyleUrl.tsx:61
#: src/pages/onboarding-v2/components/ChooseUserProfile.tsx:39
msgid "LinkedIn Profile URL"
msgstr "LinkedIn Profile URL"

#: src/components/organisms/ReferralSourceForm.tsx:46
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:52
msgid "LinkedIn training"
msgstr "LinkedIn training"

#: src/pages/onboarding-v2/components/ChooseStyleUrl.tsx:112
msgid "Loading ..."
msgstr "Loading ..."

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:122
#: src/components/molecules/popups/DescribeReason.tsx:63
#~ msgid "Loading..."
#~ msgstr "Loading..."

#: src/components/organisms/LoginForms/LoginForm.tsx:19
#~ msgid "Log in"
#~ msgstr "Log in"

#: src/components/organisms/LoginForms/LoginForm.tsx:11
#~ msgid "Log in to explore"
#~ msgstr "Log in to explore"

#: src/components/organisms/LoginForms/LoginForm.tsx:21
#~ msgid "Log in via Google"
#~ msgstr "Log in via Google"

#: src/components/organisms/LoginForms/LoginForm.tsx:117
#: src/components/organisms/LoginForms/LoginForm.tsx:146
msgid "Login"
msgstr "Login"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:112
#~ msgid "Login code required"
#~ msgstr "Login code required"

#: src/components/organisms/LoginForms/LoginForm.tsx:97
msgid "Login successful!"
msgstr "Login successful!"

#: src/components/organisms/LoginForms/LoginForm.tsx:153
msgid "Login via Google"
msgstr "Login via Google"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:8
#~ msgid "Long"
#~ msgstr "Long"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:116
#: src/pages/onboarding-v2/components/ChatWithRedactAIChatArea.tsx:117
msgid "Make it longer"
msgstr "Make it longer"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:117
#: src/pages/onboarding-v2/components/ChatWithRedactAIChatArea.tsx:118
msgid "Make it shorter"
msgstr "Make it shorter"

#: src/components/pages/RedactPost/RedactPost.tsx:230
#~ msgid "Make your post unique !"
#~ msgstr "Make your post unique !"

#: src/components/pages/Settings/Settings.tsx:153
#~ msgid "Manage subscription"
#~ msgstr "Manage subscription"

#: src/components/molecules/SavedIdea.tsx:45
#: src/components/molecules/SavedPost.tsx:100
msgid "Mark as not used"
msgstr "Mark as not used"

#: src/components/molecules/SavedIdea.tsx:45
#: src/components/molecules/SavedPost.tsx:94
msgid "Mark as used"
msgstr "Mark as used"

#: src/hooks/tutorial/useTutorialSteps.tsx:19
#~ msgid "Marketing"
#~ msgstr "Marketing"

#: src/hooks/tutorial/useTutorialSteps.tsx:22
#~ msgid "Marketing Digital"
#~ msgstr "Marketing Digital"

#: src/components/pages/Pricing/components/PricingTable.tsx:156
#: src/components/pages/Pricing/components/PricingTable.tsx:198
#~ msgid "mo"
#~ msgstr "mo"

#: src/components/molecules/popups/PreviewPopup.tsx:102
msgid "Mobile"
msgstr "Mobile"

#: src/components/molecules/PricingCard.tsx:72
#: src/components/molecules/UpgradePricingCard.tsx:197
#: src/components/molecules/UpgradePricingCard.tsx:230
#: src/components/molecules/UpgradePricingCardBF.tsx:200
#: src/components/molecules/UpgradePricingCardBF.tsx:210
#: src/components/molecules/UpgradePricingCardBF.tsx:242
msgid "monthly"
msgstr "monthly"

#: src/components/atoms/ButtonTab.tsx:14
#: src/components/molecules/CurrentSubscription.tsx:62
msgid "Monthly"
msgstr "Monthly"

#: src/pages/recyclepost/RecyclePost.tsx:87
msgid "More comments"
msgstr "More comments"

#: src/components/organisms/SideBar/SideBar.tsx:115
#~ msgid "More credits"
#~ msgstr "More credits"

#: src/components/organisms/SideBar/SideBar.tsx:145
#~ msgid "More posts"
#~ msgstr "More posts"

#: src/pages/recyclepost/RecyclePost.tsx:83
msgid "More reactions"
msgstr "More reactions"

#: src/components/pages/Pricing/components/PricingTable.tsx:145
#~ msgid "Most Popular"
#~ msgstr "Most Popular"

#: src/components/pages/FindIdea/components/MyIdeasTab.tsx:51
#: src/components/pages/RedactPost/components/HistoryTab.tsx:64
#~ msgid "Most recent"
#~ msgstr "Most recent"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:28
#~ msgid "Motivating"
#~ msgstr "Motivating"

#: src/components/molecules/PricingCard.tsx:44
msgid "Multilingual support"
msgstr "Multilingual support"

#: src/components/molecules/UpgradePricingCard.tsx:73
#: src/components/molecules/UpgradePricingCardBF.tsx:72
msgid "Multiple profiles"
msgstr "Multiple profiles"

#: src/components/pages/MyPostsAndIdeas/MyPostsAndIdeas.tsx:31
#~ msgid "My ideas"
#~ msgstr "My ideas"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:16
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:46
#: src/pages/redactpost/components/RedactPostContent.tsx:54
msgid "My own ideas"
msgstr "My own ideas"

#: src/components/templates/Page.tsx:145
msgid "My posts"
msgstr "My posts"

#: src/components/templates/Page.tsx:35
#~ msgid "My posts and ideas"
#~ msgstr "My posts and ideas"

#: src/components/molecules/affiliateHistory.tsx:39
msgid "Name"
msgstr "Name"

#: src/hooks/usePostToneOptions.ts:21
msgid "Narrative"
msgstr "Narrative"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:98
#: src/components/pages/RedactPost/components/RedactPostForm.tsx:301
#~ msgid "Need help ? See an explanatory video"
#~ msgstr "Need help ? See an explanatory video"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:82
#~ msgid "Neuromarketing"
#~ msgstr "Neuromarketing"

#: src/components/atoms/NewLabel.tsx:6
#~ msgid "New"
#~ msgstr "New"

#: src/pages/redactpost-v2/components/EditCreateContext.tsx:106
#~ msgid "New context"
#~ msgstr "New context"

#: src/pages/redactpost-v2/components/RedactPostContextSelect.tsx:81
#~ msgid "New context +"
#~ msgstr "New context +"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:148
msgid "New Password"
msgstr "New Password"

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:44
msgid "New post"
msgstr "New post"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:201
msgid "New profile"
msgstr "New profile"

#: src/pages/redactpost-v2/components/RedactPostProfileSelect.tsx:113
msgid "New profile +"
msgstr "New profile +"

#: src/components/templates/Page.tsx:165
#~ msgid "NEW: Unlimited posts plan at only 34,90 euros per month !!! Click here to know more"
#~ msgstr "NEW: Unlimited posts plan at only 34,90 euros per month !!! Click here to know more"

#: src/pages/redactpost/RedactPost.tsx:228
msgid "New: We released a new post generator. Click here to test it."
msgstr "New: We released a new post generator. Click here to test it."

#: src/components/organisms/ReferralSourceForm.tsx:50
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:56
msgid "Newsletter"
msgstr "Newsletter"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFirstStep.tsx:32
#: src/pages/onboarding/components/ChooseStyleSource.tsx:130
#: src/pages/onboarding/components/ContentURLToolTipFirstStep.tsx:46
#: src/pages/onboarding/components/MyOwnIdeasToolTipFirstStep.tsx:32
#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:91
msgid "Next"
msgstr "Next"

#: src/components/molecules/InvoiceTable.tsx:69
msgid "No"
msgstr "No"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:132
#~ msgid "No Email ? Click here to resend the email"
#~ msgstr "No Email ? Click here to resend the email"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:116
#: src/components/pages/RedactPost/components/RedactPostContent.tsx:49
#~ msgid "No idea ? Choose from these ones:"
#~ msgstr "No idea ? Choose from these ones:"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:44
#~ msgid "No idea ? Find an idea here"
#~ msgstr "No idea ? Find an idea here"

#: src/components/pages/RedactPost/components/RedactPostStyle.tsx:64
#~ msgid "No idea ? Here are some suggestions:"
#~ msgstr "No idea ? Here are some suggestions:"

#: src/pages/redactpost-v2/RedactPost.tsx:204
#: src/pages/redactpost/RedactPost.tsx:219
msgid "No idea?"
msgstr "No idea?"

#: src/hooks/findIdeas/useFindIdeas.ts:36
#~ msgid "Not enough credits"
#~ msgstr "Not enough credits"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:236
#: src/pages/onboarding-v2/components/ChatWithRedactAIChatArea.tsx:239
msgid "Not satisfied with the result ? Tell RedactAI what to change you can leave empty to regenerate new variations"
msgstr "Not satisfied with the result ? Tell RedactAI what to change you can leave empty to regenerate new variations"

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:204
#~ msgid "Not satisfied with the result? Tell RedactAI what to change. You can leave it empty to regenerate new variations."
#~ msgstr "Not satisfied with the result? Tell RedactAI what to change. You can leave it empty to regenerate new variations."

#: src/components/organisms/SavedIdea/SavedIdeaCard.tsx:26
#: src/components/organisms/SavedPost/SavedPostCard.tsx:48
#: src/components/pages/FindIdea/components/MyIdeasTab.tsx:47
#: src/components/pages/RedactPost/components/HistoryTab.tsx:60
#~ msgid "Not used"
#~ msgstr "Not used"

#: src/components/molecules/FilterSelectUsedNotUsed.tsx:24
#: src/pages/findIdea/components/MyIdeasTab.tsx:45
#: src/pages/myPosts/MyPosts.tsx:63
msgid "Not Used"
msgstr "Not Used"

#: src/components/molecules/GeneratedPost.tsx:230
#: src/utils/formatCreatedAt.ts:8
msgid "Now"
msgstr "Now"

#: src/components/molecules/SubscriptionInformationCard.tsx:87
#~ msgid "of cost"
#~ msgstr "of cost"

#: src/components/templates/Page.tsx:206
msgid "off on annual subscriptions"
msgstr "off on annual subscriptions"

#: src/common/snackbar/SnackbarProvider.tsx:23
#~ msgid "Ok, I understand"
#~ msgstr "Ok, I understand"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:72
#: src/pages/onboarding-v2/components/ProfileUrlStyleToolTip.tsx:40
#: src/pages/onboarding/components/ContentURLToolTipFinalStep.tsx:34
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:72
#: src/pages/onboarding/components/NoIdeasToolTip.tsx:37
#: src/pages/onboarding/components/ProfileUrlStyleToolTip.tsx:40
msgid "Okay, I got it"
msgstr "Okay, I got it"

#: src/components/pages/FindIdea/components/MyIdeasTab.tsx:52
#: src/components/pages/RedactPost/components/HistoryTab.tsx:65
#~ msgid "Oldest"
#~ msgstr "Oldest"

#: src/hooks/tutorial/useTutorialSteps.tsx:10
#~ msgid "One or multiple words representing a domain or a work area. RedactAI will give you 3 different ideas to choose from."
#~ msgstr "One or multiple words representing a domain or a work area. RedactAI will give you 3 different ideas to choose from."

#: src/components/templates/Page.tsx:210
msgid "Only"
msgstr "Only"

#: src/pages/onboarding-v2/Onboarding.tsx:267
#: src/pages/onboarding/Onboarding.tsx:206
msgid "Only one question before crafting your first post!"
msgstr "Only one question before crafting your first post!"

#: src/components/pages/Onboarding/Onboarding.tsx:109
#~ msgid "Only one question before creating your first post."
#~ msgstr "Only one question before creating your first post."

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:41
#: src/utils/handleGoogleSignInUp.ts:27
msgid "Oops! Something went wrong with Google sign-in."
msgstr "Oops! Something went wrong with Google sign-in."

#: src/components/organisms/LoginForms/LoginForm.tsx:107
#: src/components/organisms/LoginForms/SignUpForm.tsx:146
#: src/hooks/redactPost/useRedactPostMutation.ts:117
#: src/hooks/redactPostNew/useRedactPostMutation.ts:122
msgid "Oops! Something went wrong."
msgstr "Oops! Something went wrong."

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:89
#: src/components/pages/RedactPost/components/RedactPostForm.tsx:286
#~ msgid "Open the dialog"
#~ msgstr "Open the dialog"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:32
#~ msgid "Optimistic"
#~ msgstr "Optimistic"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:62
#: src/components/organisms/ReferralSourceForm.tsx:56
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:62
msgid "Other"
msgstr "Other"

#: src/components/molecules/ProfileInformationCard.tsx:30
msgid "Our Affiliate Program offers a fantastic opportunity to earn generous commissions while promoting products you love"
msgstr "Our Affiliate Program offers a fantastic opportunity to earn generous commissions while promoting products you love"

#: src/components/pages/RedactPost/components/RedactPostFormV2.tsx:137
#~ msgid "Our AI will imitate the style of the given LinkedIn profile (from the 100 last posts of the person)."
#~ msgstr "Our AI will imitate the style of the given LinkedIn profile (from the 100 last posts of the person)."

#: src/components/organisms/LoginForms/LoginForm.tsx:131
#: src/components/organisms/LoginForms/SignUpForm.tsx:182
msgid "Password"
msgstr "Password"

#: src/hooks/resetPassword/useResetPassword.ts:42
msgid "Password reset email sent. Please check your inbox."
msgstr "Password reset email sent. Please check your inbox."

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:118
msgid "Password Reset Failed"
msgstr "Password Reset Failed"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:132
msgid "Password Reset Successful"
msgstr "Password Reset Successful"

#: src/components/organisms/LoginForms/SignUpForm.tsx:74
msgid "Password: Min 6 chars, 1 number, 1 letter."
msgstr "Password: Min 6 chars, 1 number, 1 letter."

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:69
msgid "Passwords do not match."
msgstr "Passwords do not match."

#: src/components/pages/Settings/Settings.tsx:155
#~ msgid "Payment renewal on"
#~ msgstr "Payment renewal on"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:24
#~ msgid "Pedagogical"
#~ msgstr "Pedagogical"

#: src/components/molecules/affiliateHistory.tsx:24
msgid "Pending"
msgstr "Pending"

#: src/components/molecules/CurrentSubscription.tsx:87
msgid "per month."
msgstr "per month."

#: src/components/atoms/PricingSlider.tsx:60
msgid "per post"
msgstr "per post"

#: src/components/atoms/PricingSlider.tsx:46
msgid "per profile*"
msgstr "per profile*"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:18
#~ msgid "Persuasive"
#~ msgstr "Persuasive"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:34
#~ msgid "Pessimistic"
#~ msgstr "Pessimistic"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:41
msgid "Pick who will take a pay cut."
msgstr "Pick who will take a pay cut."

#: src/components/organisms/LoginForms/SignUpForm.tsx:89
msgid "Please accept the Terms and Conditions."
msgstr "Please accept the Terms and Conditions."

#: src/pages/redactpost-v2/components/LoadingLayout.tsx:65
#: src/pages/redactpost/components/LoadingLayout.tsx:65
msgid "Please be patient, your post will be generated, please don't open a new window"
msgstr "Please be patient, your post will be generated, please don't open a new window"

#: src/hooks/findIdeas/useFindIdeas.ts:46
#: src/hooks/pricing/createCheckoutSession.ts:40
#: src/hooks/profile/createProfile/useCreateProfile.ts:54
#: src/hooks/profile/updateProfile/useUpdateProfile.ts:33
#: src/hooks/redactPost/useRedactPostMutation.ts:109
#: src/hooks/redactPostNew/useRedactPostMutation.ts:114
#: src/hooks/regeneratePost/useRegeneratePostMutation.ts:64
msgid "Please check if all the fields are filled correctly"
msgstr "Please check if all the fields are filled correctly"

#: src/hooks/redactPost/useRedactPostMutation.ts:99
#: src/hooks/redactPostNew/useRedactPostMutation.ts:104
msgid "Please check the video link. Sometimes, the video is not compatible because captions are disabled."
msgstr "Please check the video link. Sometimes, the video is not compatible because captions are disabled."

#: src/pages/onboarding-v2/components/ChooseStyleSource.tsx:124
#: src/pages/onboarding/components/ChooseStyleSource.tsx:123
msgid "Please choose a style"
msgstr "Please choose a style"

#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:97
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:109
msgid "Please choose one of the three ideas"
msgstr "Please choose one of the three ideas"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:120
msgid "Please contact support for assistance."
msgstr "Please contact support for assistance."

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:113
msgid "Please contact the support by chat on the bottom right corner for help."
msgstr "Please contact the support by chat on the bottom right corner for help."

#: src/pages/recyclepost/components/RecyclePostForm.tsx:87
#~ msgid "Please ensure the LinkedIn profile URL is correct and accessible."
#~ msgstr "Please ensure the LinkedIn profile URL is correct and accessible."

#: src/pages/onboarding/Onboarding.tsx:228
#: src/pages/onboarding/Onboarding.tsx:333
#~ msgid "Please enter a content URL"
#~ msgstr "Please enter a content URL"

#: src/pages/findIdea/FindIdea.tsx:56
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:67
#: src/utils/validateForm.ts:36
#: src/utils/validateForm.ts:47
msgid "Please enter a post subject"
msgstr "Please enter a post subject"

#: src/utils/validateForm.ts:30
msgid "Please enter a valid content URL"
msgstr "Please enter a valid content URL"

#: src/components/organisms/LoginForms/LoginForm.tsx:45
#: src/components/organisms/LoginForms/SignUpForm.tsx:64
#: src/hooks/resetPassword/useResetPassword.ts:25
msgid "Please enter a valid email address."
msgstr "Please enter a valid email address."

#: src/pages/onboarding-v2/components/ChooseStyleUrl.tsx:37
#: src/pages/onboarding-v2/components/ChooseUserProfile.tsx:28
#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:103
#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:106
msgid "Please enter a valid LinkedIn profile URL"
msgstr "Please enter a valid LinkedIn profile URL"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:116
#~ msgid "Please enter the LinkedIn code you received by email ({email})"
#~ msgstr "Please enter the LinkedIn code you received by email ({email})"

#: src/hooks/resetPassword/useResetPassword.ts:19
msgid "Please enter your email address."
msgstr "Please enter your email address."

#: src/pages/redactpost/RedactPost.tsx:83
#~ msgid "Please fill in all required fields."
#~ msgstr "Please fill in all required fields."

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:60
msgid "Please fill in both password fields."
msgstr "Please fill in both password fields."

#: src/components/organisms/ReferralSourceForm.tsx:78
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:84
msgid "Please fill in the referral source details"
msgstr "Please fill in the referral source details"

#: src/components/organisms/LoginForms/LoginForm.tsx:40
#: src/components/organisms/LoginForms/SignUpForm.tsx:59
msgid "Please fill in your email."
msgstr "Please fill in your email."

#: src/components/organisms/LoginForms/SignUpForm.tsx:79
msgid "Please fill in your first name."
msgstr "Please fill in your first name."

#: src/components/organisms/LoginForms/SignUpForm.tsx:84
msgid "Please fill in your last name."
msgstr "Please fill in your last name."

#: src/components/organisms/LoginForms/LoginForm.tsx:54
#: src/components/organisms/LoginForms/SignUpForm.tsx:69
msgid "Please fill in your password."
msgstr "Please fill in your password."

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:75
msgid "Please let us know why exactly you want to leave?"
msgstr "Please let us know why exactly you want to leave?"

#: src/components/pages/RedactPost/components/RedactPostGeneration.tsx:33
#~ msgid "Please like or dislike the posts below to help us improve our IA model"
#~ msgstr "Please like or dislike the posts below to help us improve our IA model"

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:31
msgid "Please like or dislike the posts to improve our"
msgstr "Please like or dislike the posts to improve our"

#: src/pages/onboarding-v2/components/ProfileUrlStyleToolTip.tsx:20
#: src/pages/onboarding/components/ProfileUrlStyleToolTip.tsx:20
msgid "Please note that it is best to use a profile with a lot of posts, so if you're a beginner on LinkedIn we advise you to use other's profile URL."
msgstr "Please note that it is best to use a profile with a lot of posts, so if you're a beginner on LinkedIn we advise you to use other's profile URL."

#: src/pages/recyclepost/components/RecyclePostForm.tsx:63
msgid "Please provide a post to recycle."
msgstr "Please provide a post to recycle."

#: src/utils/validateForm.ts:61
msgid "Please provide a URL that begins with 'https://www.linkedin.com/in/' or 'https://www.linkedin.com/company/'"
msgstr "Please provide a URL that begins with 'https://www.linkedin.com/in/' or 'https://www.linkedin.com/company/'"

#: src/utils/validateForm.ts:61
#~ msgid "Please provide a url that begins with ‘https://www.linkedin.com/in/’"
#~ msgstr "Please provide a url that begins with ‘https://www.linkedin.com/in/’"

#: src/pages/settings/components/CancelSubscription.tsx:108
msgid "Please select a reason"
msgstr "Please select a reason"

#: src/components/organisms/ReferralSourceForm.tsx:72
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:77
msgid "Please select a referral source"
msgstr "Please select a referral source"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:27
msgid "Please select who will take a pay cut"
msgstr "Please select who will take a pay cut"

#: src/components/molecules/OnboardingReferralSource/OnboardingReferralSource.tsx:82
#~ msgid "Please specify"
#~ msgstr "Please specify"

#: src/pages/settings/components/CancelSubscription.tsx:117
msgid "Please type a reason"
msgstr "Please type a reason"

#: src/components/pages/RedactPost/components/HelpPopup.tsx:8
#~ msgid "Please watch this video entirely to get advanced AI techniques"
#~ msgstr "Please watch this video entirely to get advanced AI techniques"

#: src/components/molecules/CurrentSubscription.tsx:85
msgid "post"
msgstr "post"

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:31
msgid "Post"
msgstr "Post"

#: src/components/organisms/LinkedInPostsCalendar/LinkedInPostsCalendar.tsx:69
#~ msgid "Post deleted successfully"
#~ msgstr "Post deleted successfully"

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:64
msgid "Post Generator"
msgstr "Post Generator"

#: src/components/molecules/popups/PreviewPopup.tsx:73
msgid "Post preview"
msgstr "Post preview"

#: src/components/organisms/LinkedInPostsCalendar/LinkedInPostsCalendar.tsx:89
#~ msgid "Post scheduled successfully"
#~ msgstr "Post scheduled successfully"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:309
#~ msgid "Post tone"
#~ msgstr "Post tone"

#: src/pages/onboarding/components/PostStyleSelect.tsx:48
#: src/pages/redactpost/components/RedactPostStyleSelect.tsx:40
msgid "Post type"
msgstr "Post type"

#: src/components/organisms/LinkedInPostPopup/LinkedInPostPopup.tsx:63
#~ msgid "Posted on"
#~ msgstr "Posted on"

#: src/components/molecules/CurrentSubscription.tsx:85
msgid "posts"
msgstr "posts"

#: src/components/templates/Page.tsx:49
#~ msgid "Posts calendar"
#~ msgstr "Posts calendar"

#: src/components/pages/Settings/Settings.tsx:87
#~ msgid "posts generations per month"
#~ msgstr "posts generations per month"

#: src/components/molecules/SubscriptionInformationCard.tsx:102
#: src/components/molecules/SubscriptionInformationCard.tsx:125
msgid "posts left"
msgstr "posts left"

#: src/components/organisms/SideBar/SideBar.tsx:136
#~ msgid "Posts left"
#~ msgstr "Posts left"

#: src/components/pages/Settings/Settings.tsx:143
#~ msgid "posts per month"
#~ msgstr "posts per month"

#: src/components/molecules/affiliateEarnings.tsx:19
msgid "posts per month."
msgstr "posts per month."

#: src/components/pages/Settings/Settings.tsx:146
#~ msgid "Posts renewal on"
#~ msgstr "Posts renewal on"

#: src/components/molecules/affiliateConditions.tsx:12
#: src/components/molecules/affiliateConditions.tsx:17
msgid "Posts/month*"
msgstr "Posts/month*"

#: src/components/pages/Pricing/components/PricingTable.tsx:51
#: src/components/pages/Pricing/components/PricingTable.tsx:69
#~ msgid "Premium"
#~ msgstr "Premium"

#: src/components/organisms/SideBar/SideBar.tsx:114
#~ msgid "Premium subscription"
#~ msgstr "Premium subscription"

#: src/components/pages/Settings/Settings.tsx:139
#~ msgid "Price:"
#~ msgstr "Price:"

#: src/components/molecules/UpgradePricingCard.tsx:133
#: src/components/molecules/UpgradePricingCardBF.tsx:145
msgid "Produce high quality LinkedIn content in no time."
msgstr "Produce high quality LinkedIn content in no time."

#: src/hooks/usePostToneOptions.ts:25
msgid "Professional"
msgstr "Professional"

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:31
#: src/pages/redactpost-v2/components/RedactPostProfile.tsx:30
#: src/pages/settings/Settings.tsx:60
msgid "Profile"
msgstr "Profile"

#: src/components/pages/Settings/Settings.tsx:19
#~ msgid "Profile Details"
#~ msgstr "Profile Details"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:221
msgid "Profile name"
msgstr "Profile name"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:222
msgid "Profile Name (example: Elon Musk)"
msgstr "Profile Name (example: Elon Musk)"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:100
msgid "Profile name is required"
msgstr "Profile name is required"

#: src/components/organisms/SavedPost/SavedPostCard.tsx:93
#~ msgid "Profile url:"
#~ msgstr "Profile url:"

#: src/hooks/usePostToneOptions.ts:29
msgid "Promotional"
msgstr "Promotional"

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:203
#~ msgid "Prompt"
#~ msgstr "Prompt"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:372
#~ msgid "Pronoun"
#~ msgstr "Pronoun"

#: src/components/organisms/SavedPost/SavedPostCard.tsx:81
#~ msgid "Pronoun:"
#~ msgstr "Pronoun:"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:31
msgid "Prove your skills"
msgstr "Prove your skills"

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:26
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:26
msgid "Proximity with clients"
msgstr "Proximity with clients"

#: src/components/molecules/LinkedInSchedulePostPopover/LinkedInSchedulePostPopover.tsx:54
#~ msgid "Publish on"
#~ msgstr "Publish on"

#: src/components/molecules/PricingCard.tsx:107
#: src/components/molecules/ProfileInformationCard.tsx:57
msgid "Quick demo with Yohan"
msgstr "Quick demo with Yohan"

#: src/components/molecules/ChatInputBar.tsx:41
#~ msgid "Re-generate posts"
#~ msgstr "Re-generate posts"

#: src/components/molecules/ChatInputBar.tsx:44
msgid "Re-generate posts (free)"
msgstr "Re-generate posts (free)"

#: src/components/molecules/RecyclePostCard.tsx:51
msgid "Reactions: {0}"
msgstr "Reactions: {0}"

#: src/pages/settings/components/SettingsSubscription.tsx:29
#: src/pages/settings/components/SettingsSubscription.tsx:50
msgid "Recent transactions"
msgstr "Recent transactions"

#: src/components/templates/Page.tsx:141
#: src/pages/recyclepost/RecyclePost.tsx:237
msgid "Recycle a post"
msgstr "Recycle a post"

#: src/components/molecules/RecyclePostCard.tsx:60
msgid "Recycle Post"
msgstr "Recycle Post"

#: src/components/pages/RecyclePost/components/RecyclePostContent.tsx:7
#~ msgid "Recycle this post"
#~ msgstr "Recycle this post"

#: src/pages/recyclepost/components/RecyclePostForm.tsx:116
msgid "Recycle This Post"
msgstr "Recycle This Post"

#: src/components/molecules/ChooseStyleSourceCards/CreatorStyle.tsx:15
#: src/components/molecules/ChooseStyleSourceCards/MyStyle.tsx:22
#~ msgid "Redact AI will analyze your last posts to copy your writing style"
#~ msgstr "Redact AI will analyze your last posts to copy your writing style"

#: src/components/molecules/ChooseStyleSourceCards/CreatorStyle.tsx:24
msgid "RedactAI will analyze the lasts posts of another creator of your choice."
msgstr "RedactAI will analyze the lasts posts of another creator of your choice."

#: src/components/pages/Onboarding/components/ChooseStyleSource.tsx:49
#~ msgid "RedactAI will analyze their last posts to copy their writing style."
#~ msgstr "RedactAI will analyze their last posts to copy their writing style."

#: src/components/pages/Onboarding/components/ChooseStyleSource.tsx:36
#~ msgid "RedactAI will analyze your last posts to copy your writing style."
#~ msgstr "RedactAI will analyze your last posts to copy your writing style."

#: src/components/molecules/ChooseStyleSourceCards/MyStyle.tsx:26
msgid "RedactAI will analyze your last posts to imitate your writing style"
msgstr "RedactAI will analyze your last posts to imitate your writing style"

#: src/hooks/tutorial/useTutorialSteps.tsx:124
#~ msgid "RedactAI will copy his writing style based on his most recent posts. Writing style include any things like: emojis, tone, language level, expressions, signature, ..."
#~ msgstr "RedactAI will copy his writing style based on his most recent posts. Writing style include any things like: emojis, tone, language level, expressions, signature, ..."

#: src/pages/onboarding/components/NoIdeasToolTip.tsx:19
msgid "RedactAI will give you detailed and structured ideas on this subject."
msgstr "RedactAI will give you detailed and structured ideas on this subject."

#: src/components/pages/RedactPost/components/RedactPostFormV2.tsx:148
#~ msgid "RedactAI will imitate the style of the given LinkedIn profile."
#~ msgstr "RedactAI will imitate the style of the given LinkedIn profile."

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:55
msgid "RedactAI will retrieve his lasts posts and imitate the writing style of the given creator."
msgstr "RedactAI will retrieve his lasts posts and imitate the writing style of the given creator."

#: src/pages/onboarding-v2/components/ProfileUrlStyleToolTip.tsx:18
#: src/pages/onboarding/components/ProfileUrlStyleToolTip.tsx:18
msgid "RedactAI will retrieve the latests posts of the given profile and generate a post with the same writing style."
msgstr "RedactAI will retrieve the latests posts of the given profile and generate a post with the same writing style."

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:141
#: src/pages/onboarding-v2/components/ChatWithRedactAIChatArea.tsx:142
msgid "Regenerate my posts"
msgstr "Regenerate my posts"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:120
#: src/pages/onboarding-v2/components/ChatWithRedactAIChatArea.tsx:121
msgid "Remove \"Y\""
msgstr "Remove \"Y\""

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:115
#: src/pages/onboarding-v2/components/ChatWithRedactAIChatArea.tsx:116
msgid "Remove emojis"
msgstr "Remove emojis"

#: src/components/molecules/SubscriptionInformationCard.tsx:167
#: src/components/molecules/SubscriptionInformationCard.tsx:211
#: src/components/molecules/SubscriptionInformationCard.tsx:278
msgid "Renew subscription"
msgstr "Renew subscription"

#: src/components/molecules/SubscriptionInformationCard.tsx:201
#: src/components/molecules/SubscriptionInformationCard.tsx:248
msgid "Renewal date:"
msgstr "Renewal date:"

#: src/components/pages/Settings/Settings.tsx:108
#~ msgid "Renewal on"
#~ msgstr "Renewal on"

#: src/components/organisms/SideBar/SideBar.tsx:113
#~ msgid "Renewal:"
#~ msgstr "Renewal:"

#: src/components/organisms/SideBar/SideBar.tsx:140
#~ msgid "Renews on {formattedRenewalDate} (in {remainingDays} days)"
#~ msgstr "Renews on {formattedRenewalDate} (in {remainingDays} days)"

#: src/components/molecules/PricingCard.tsx:36
msgid "Repurpose any piece of content to a LinkedIn post"
msgstr "Repurpose any piece of content to a LinkedIn post"

#: src/components/organisms/LinkedInPostPopup/LinkedInPostPopup.tsx:97
#~ msgid "Reschedule"
#~ msgstr "Reschedule"

#: src/components/organisms/LoginForms/EmailVerification.tsx:82
msgid "Resend"
msgstr "Resend"

#: src/pages/findIdea/FindIdea.tsx:160
#: src/pages/recyclepost/components/RecyclePostForm.tsx:133
#: src/pages/redactpost-v2/RedactPost.tsx:235
#: src/pages/redactpost/RedactPost.tsx:258
msgid "Reset"
msgstr "Reset"

#: src/components/pages/Settings/Settings.tsx:124
#~ msgid "Reset password"
#~ msgstr "Reset password"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:170
msgid "Reset Password"
msgstr "Reset Password"

#: src/components/organisms/LoginForms/ResetPasswordForm.tsx:28
msgid "Reset your password"
msgstr "Reset your password"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:144
msgid "Reset Your Password"
msgstr "Reset Your Password"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:54
msgid "Responsible for all the bugs."
msgstr "Responsible for all the bugs."

#: src/pages/onboarding-v2/components/PostGeneration.tsx:33
#: src/pages/onboarding/components/PostGeneration.tsx:36
msgid "Review & publish"
msgstr "Review & publish"

#: src/pages/login/LoginPage.tsx:57
msgid "Revolutionizing Content Creation:"
msgstr "Revolutionizing Content Creation:"

#: src/hooks/usePostToneOptions.ts:17
#~ msgid "Sales / Persuasive"
#~ msgstr "Sales / Persuasive"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:19
#: src/pages/onboarding/components/PostStyleSelect.tsx:17
#~ msgid "Sales/Persuasive"
#~ msgstr "Sales/Persuasive"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:300
msgid "Save"
msgstr "Save"

#: src/components/pages/Settings/Settings.tsx:131
#~ msgid "Save changes"
#~ msgstr "Save changes"

#: src/components/organisms/PostEditor/PostEditor.tsx:111
#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:149
#~ msgid "Saved automatically"
#~ msgstr "Saved automatically"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:298
msgid "Saving ..."
msgstr "Saving ..."

#: src/components/pages/Pricing/components/PricingTable.tsx:170
#~ msgid "Scale your agency business"
#~ msgstr "Scale your agency business"

#: src/components/pages/Pricing/components/PricingTable.tsx:172
#~ msgid "Scale your content creation"
#~ msgstr "Scale your content creation"

#: src/components/molecules/LinkedInSchedulePostPopover/LinkedInSchedulePostPopover.tsx:66
#: src/components/organisms/LinkedInNewPost/LinkedInNewPost.tsx:50
#: src/components/organisms/LinkedInNewPostPopup/LinkedInNewPostPopup.tsx:53
#~ msgid "Schedule"
#~ msgstr "Schedule"

#: src/components/pages/Pricing/components/PricingTable.tsx:238
#~ msgid "Schedule a demo"
#~ msgstr "Schedule a demo"

#: src/components/pages/Calendar/Calendar.tsx:10
#~ msgid "Schedule all your posts in 30 seconds"
#~ msgstr "Schedule all your posts in 30 seconds"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:112
#~ msgid "Schedule my post"
#~ msgstr "Schedule my post"

#: src/components/organisms/PostEditor/PostEditor.tsx:132
#~ msgid "Schedule on LinkedIn"
#~ msgstr "Schedule on LinkedIn"

#: src/components/pages/RedactPost/RedactPost.tsx:45
#~ msgid "Schedule posts"
#~ msgstr "Schedule posts"

#: src/components/organisms/LinkedInPostPopup/LinkedInPostPopup.tsx:63
#~ msgid "Scheduled for"
#~ msgstr "Scheduled for"

#: src/components/pages/FindIdea/components/MyIdeasTab.tsx:44
#: src/components/pages/RedactPost/components/HistoryTab.tsx:57
#~ msgid "Search for a post or idea"
#~ msgstr "Search for a post or idea"

#: src/components/molecules/popups/PreviewPopup.tsx:139
msgid "See less"
msgstr "See less"

#: src/components/pages/RecyclePost/components/RecyclePostBox.tsx:70
#~ msgid "See less..."
#~ msgstr "See less..."

#: src/components/pages/RecyclePost/components/RecyclePostBox.tsx:70
#~ msgid "See more..."
#~ msgstr "See more..."

#: src/components/organisms/PostEditor/PostEditor.tsx:97
#~ msgid "See my posts"
#~ msgstr "See my posts"

#: src/components/molecules/GeneratedPost.tsx:213
#: src/components/molecules/SavedPost.tsx:105
msgid "See preview"
msgstr "See preview"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:79
msgid "Select a reason"
msgstr "Select a reason"

#: src/components/organisms/LoginForms/ResetPasswordForm.tsx:43
msgid "Send email"
msgstr "Send email"

#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:93
msgid "SEO"
msgstr "SEO"

#: src/components/templates/Page.tsx:146
#: src/pages/settings/Settings.tsx:48
msgid "Settings"
msgstr "Settings"

#: src/components/molecules/affiliateLinkCopy.tsx:27
msgid "Share this link with your friends and win free posts every month:"
msgstr "Share this link with your friends and win free posts every month:"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:6
#~ msgid "Short"
#~ msgstr "Short"

#: src/components/organisms/LoginForms/SignUpForm.tsx:256
msgid "Sign in"
msgstr "Sign in"

#: src/components/molecules/ProfileHeader.tsx:53
#: src/components/molecules/ProfileHeaderMobile.tsx:54
msgid "Sign out"
msgstr "Sign out"

#: src/components/organisms/LoginForms/LoginForm.tsx:166
#: src/components/organisms/LoginForms/SignUpForm.tsx:236
msgid "Sign up"
msgstr "Sign up"

#: src/components/organisms/LoginForms/SignUpForm.tsx:168
msgid "Sign Up"
msgstr "Sign Up"

#: src/components/organisms/LoginForms/SignUpForm.tsx:243
msgid "Sign up via Google"
msgstr "Sign up via Google"

#: src/components/organisms/LoginForms/EmailVerification.tsx:29
msgid "Sign-up successful! Please verify your email."
msgstr "Sign-up successful! Please verify your email."

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:33
msgid "Signed in with Google successfully"
msgstr "Signed in with Google successfully"

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:20
#~ msgid "Signed in with Google successfuly"
#~ msgstr "Signed in with Google successfuly"

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:32
msgid "Signed up with Google successfully"
msgstr "Signed up with Google successfully"

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:16
#~ msgid "Signed up with Google successfuly"
#~ msgstr "Signed up with Google successfuly"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:90
#: src/components/pages/RedactPost/components/RedactPostForm.tsx:287
#~ msgid "Skip"
#~ msgstr "Skip"

#: src/components/organisms/OnboardingPopup/OnboardingPopup.tsx:52
#~ msgid "Some advices for a good start:"
#~ msgstr "Some advices for a good start:"

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:46
msgid "Some of you may still use the default post tone."
msgstr "Some of you may still use the default post tone."

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:49
#~ msgid "Sorry but your profile is missing enough posts to define your style"
#~ msgstr "Sorry but your profile is missing enough posts to define your style"

#: src/pages/redactpost-v2/components/CreateProfilePopup.tsx:71
msgid "Sorry, but your profile is missing enough posts to define your style"
msgstr "Sorry, but your profile is missing enough posts to define your style"

#: src/pages/onboarding-v2/components/ChooseStyleUrl.tsx:44
#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:127
msgid "Sorry, but your profile needs at least 15 posts to define your style."
msgstr "Sorry, but your profile needs at least 15 posts to define your style."

#: src/components/molecules/UpgradePricingCard.tsx:162
msgid "Start 7 days Free Trial"
msgstr "Start 7 days Free Trial"

#: src/components/organisms/ReferralSourceForm.tsx:120
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:136
msgid "Start exploring"
msgstr "Start exploring"

#: src/components/organisms/PricingTable.tsx:108
#~ msgid "Start your"
#~ msgstr "Start your"

#: src/components/pages/Pricing/components/PricingTable.tsx:122
#~ msgid "Start your 7 days free trial"
#~ msgstr "Start your 7 days free trial"

#: src/components/organisms/PricingTable.tsx:171
msgid "Start your 7 days Free Trial"
msgstr "Start your 7 days Free Trial"

#: src/components/pages/Pricing/components/PricingTable.tsx:62
#~ msgid "Start your free trial"
#~ msgstr "Start your free trial"

#: src/components/molecules/UpgradePricingCard.tsx:215
#: src/components/molecules/UpgradePricingCardBF.tsx:227
msgid "Starting at"
msgstr "Starting at"

#: src/components/molecules/affiliateHistory.tsx:41
msgid "Status"
msgstr "Status"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:97
msgid "Stay with us and support our families"
msgstr "Stay with us and support our families"

#: src/components/pages/RedactPost/components/Loader.tsx:44
#~ msgid "Step {0} of {1}:"
#~ msgstr "Step {0} of {1}:"

#: src/pages/onboarding/components/PostGeneration.tsx:79
#~ msgid "Step 1"
#~ msgstr "Step 1"

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:31
#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:35
msgid "Step 1:"
msgstr "Step 1:"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:18
#: src/components/pages/Onboarding/Onboarding.tsx:130
#: src/components/pages/Onboarding/Onboarding.tsx:145
#~ msgid "Step 1: Content"
#~ msgstr "Step 1: Content"

#: src/pages/redactpost-v2/components/LoadingLayout.tsx:27
#: src/pages/redactpost/components/LoadingLayout.tsx:27
msgid "Step 2"
msgstr "Step 2"

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:22
#: src/pages/onboarding/components/ChooseStyleSource.tsx:51
msgid "Step 2:"
msgstr "Step 2:"

#: src/components/pages/Onboarding/components/ChooseStyleSource.tsx:17
#~ msgid "Step 2: Style"
#~ msgstr "Step 2: Style"

#: src/pages/redactpost-v2/components/LoadingLayout.tsx:27
#: src/pages/redactpost/components/LoadingLayout.tsx:27
msgid "Step 3"
msgstr "Step 3"

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:27
#: src/pages/onboarding-v2/components/PostGeneration.tsx:33
#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:31
msgid "Step 3:"
msgstr "Step 3:"

#: src/components/pages/Onboarding/Onboarding.tsx:176
#~ msgid "Step 3: Post"
#~ msgstr "Step 3: Post"

#: src/pages/redactpost-v2/components/LoadingLayout.tsx:27
#: src/pages/redactpost/components/LoadingLayout.tsx:27
msgid "Step 4"
msgstr "Step 4"

#: src/pages/onboarding/components/PostGeneration.tsx:36
msgid "Step 4:"
msgstr "Step 4:"

#: src/components/pages/RedactPost/components/RedactPostGeneration.tsx:48
#~ msgid "Step 4: Review & publish"
#~ msgstr "Step 4: Review & publish"

#: src/hooks/usePostToneOptions.ts:13
#~ msgid "Storytelling / Inspirational"
#~ msgstr "Storytelling / Inspirational"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:18
#: src/pages/onboarding/components/PostStyleSelect.tsx:16
#~ msgid "Storytelling/Inspirational"
#~ msgstr "Storytelling/Inspirational"

#: src/pages/onboarding-v2/components/OnboardingContainer.tsx:27
#: src/pages/onboarding/components/ChooseStyleSource.tsx:51
msgid "Style"
msgstr "Style"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:20
#: src/pages/onboarding-v2/components/FillInContent.tsx:85
#: src/pages/onboarding/components/GenerateFromContentURL.tsx:84
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:61
msgid "Subject"
msgstr "Subject"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:316
#~ msgid "Subject ?"
#~ msgstr "Subject ?"

#: src/pages/onboarding-v2/components/FillInContent.tsx:86
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:62
msgid "Subject of your post. Example: \"How to increase your sales by 30% in 3 months\""
msgstr "Subject of your post. Example: \"How to increase your sales by 30% in 3 months\""

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:83
msgid "Subject of your post. Example: The story of elon musk"
msgstr "Subject of your post. Example: The story of elon musk"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:36
#~ msgid "Subject:"
#~ msgstr "Subject:"

#: src/components/molecules/popups/DescribeReason.tsx:42
msgid "Submit"
msgstr "Submit"

#: src/pages/settings/Settings.tsx:65
msgid "Subscription"
msgstr "Subscription"

#: src/components/molecules/CurrentSubscription.tsx:105
msgid "Subscription canceled"
msgstr "Subscription canceled"

#: src/components/molecules/SubscriptionInformationCard.tsx:112
#: src/components/molecules/SubscriptionInformationCard.tsx:157
#: src/components/molecules/SubscriptionInformationCard.tsx:195
#: src/components/molecules/SubscriptionInformationCard.tsx:272
msgid "Subscription Canceled"
msgstr "Subscription Canceled"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmed.tsx:20
msgid "subscription cancelled"
msgstr "subscription cancelled"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:235
#: src/pages/onboarding-v2/components/ChatWithRedactAIChatArea.tsx:238
msgid "Tell RedactAI what to change"
msgstr "Tell RedactAI what to change"

#: src/components/organisms/LoginForms/SignUpForm.tsx:233
msgid "Terms and Conditions"
msgstr "Terms and Conditions"

#: src/pages/freeTools/TextFormatter.tsx:101
#~ msgid "Text"
#~ msgstr "Text"

#: src/components/molecules/GeneratedPost.tsx:69
msgid "Thanks for your feedback!"
msgstr "Thanks for your feedback!"

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:37
msgid "The AI now better reproduces your tone and post structure."
msgstr "The AI now better reproduces your tone and post structure."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:127
#~ msgid "The connection make take up to 1 minute, please be patient"
#~ msgstr "The connection make take up to 1 minute, please be patient"

#: src/hooks/redactPost/useRedactPostMutation.ts:94
#: src/hooks/redactPostNew/useRedactPostMutation.ts:99
msgid "The content link is not a valid LinkedIn post link. Please check that the url is the url of a LinkedIn post. Please note that LinkedIn articles are not supported for now. You can copy/paste the content of the article directly into the “Key ideas” form field."
msgstr "The content link is not a valid LinkedIn post link. Please check that the url is the url of a LinkedIn post. Please note that LinkedIn articles are not supported for now. You can copy/paste the content of the article directly into the “Key ideas” form field."

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:18
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:18
msgid "The Key ideas of your post, This field is recommended but  not mandatory. Keys ideas can be:"
msgstr "The Key ideas of your post, This field is recommended but  not mandatory. Keys ideas can be:"

#: src/hooks/tutorial/useTutorialSteps.tsx:51
#~ msgid "The keys ideas of your post. This field is recommended but not mandatory. Keys ideas can be :"
#~ msgstr "The keys ideas of your post. This field is recommended but not mandatory. Keys ideas can be :"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:47
msgid "The most paid at RedactAI."
msgstr "The most paid at RedactAI."

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:46
msgid "The product did not meet my expectations."
msgstr "The product did not meet my expectations."

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFirstStep.tsx:18
#: src/pages/onboarding/components/MyOwnIdeasToolTipFirstStep.tsx:18
msgid "The subject of your post. It is recommended to write one sentence. This field is mandatory"
msgstr "The subject of your post. It is recommended to write one sentence. This field is mandatory"

#: src/hooks/tutorial/useTutorialSteps.tsx:35
#~ msgid "The subject of your post. It is recommended to write one sentence. This field is mandatory."
#~ msgstr "The subject of your post. It is recommended to write one sentence. This field is mandatory."

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:38
msgid "The subscription cost is too high for my budget."
msgstr "The subscription cost is too high for my budget."

#: src/pages/onboarding/components/ContentURLToolTipFinalStep.tsx:16
msgid "The topic you want to talk about."
msgstr "The topic you want to talk about."

#: src/hooks/tutorial/useTutorialSteps.tsx:120
#~ msgid "The url of the profile of the person you want to imitate."
#~ msgstr "The url of the profile of the person you want to imitate."

#: src/components/molecules/PricingCard.tsx:68
msgid "Then {0} euros billed"
msgstr "Then {0} euros billed"

#: src/components/molecules/PricingCard.tsx:54
#: src/components/molecules/UpgradePricingCard.tsx:57
#~ msgid "Then 189.90 euros billed annually"
#~ msgstr "Then 189.90 euros billed annually"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:172
#~ msgid "There is an error in the form, please check the form fields."
#~ msgstr "There is an error in the form, please check the form fields."

#: src/pages/onboarding/components/PostGeneration.tsx:48
#~ msgid "There you go!"
#~ msgstr "There you go!"

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:26
#~ msgid "There you go! You got the lightened fast results"
#~ msgstr "There you go! You got the lightened fast results"

#: src/components/molecules/popups/DeleteAccountPopupConfirmation.tsx:32
#: src/components/molecules/popups/DeleteIdeaPopup.tsx:37
msgid "This action cannot be undone."
msgstr "This action cannot be undone."

#: src/components/atoms/PricingSlider.tsx:52
msgid "This allows you to create one personalized profile for each one of your client."
msgstr "This allows you to create one personalized profile for each one of your client."

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:24
msgid "This also means that the more that you post (or have posted), the better the AI will be."
msgstr "This also means that the more that you post (or have posted), the better the AI will be."

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:26
msgid "This is called the"
msgstr "This is called the"

#: src/pages/recyclepost/RecyclePost.tsx:148
#~ msgid "This LinkedIn profile url is not a valid url. Please provide a url that begins with ‘https://www.linkedin.com/in/’"
#~ msgstr "This LinkedIn profile url is not a valid url. Please provide a url that begins with ‘https://www.linkedin.com/in/’"

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:23
msgid "This means that the AI knows you and will make references to your business, stories that you shared, product that you sell, ..."
msgstr "This means that the AI knows you and will make references to your business, stories that you shared, product that you sell, ..."

#: src/pages/redactpost-v2/RedactPost.tsx:210
msgid "This new generator is in test phase. You have access to multiple profiles for free during the test."
msgstr "This new generator is in test phase. You have access to multiple profiles for free during the test."

#: src/hooks/redactPost/useRedactPostMutation.ts:104
#: src/hooks/redactPostNew/useRedactPostMutation.ts:109
msgid "This profile link is not valid, please check that this is a real LinkedIn profile url."
msgstr "This profile link is not valid, please check that this is a real LinkedIn profile url."

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:49
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:49
msgid "to help drive the creative work of our IA"
msgstr "to help drive the creative work of our IA"

#: src/components/organisms/SavedPost/SavedPostCard.tsx:77
#~ msgid "Tone:"
#~ msgstr "Tone:"

#: src/pages/findIdea/FindIdea.tsx:131
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:155
msgid "Topic or theme"
msgstr "Topic or theme"

#: src/pages/findIdea/FindIdea.tsx:132
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:156
msgid "Topic or theme you want to talk about. Example: \"Digital Marketing\" or \"SEO\"."
msgstr "Topic or theme you want to talk about. Example: \"Digital Marketing\" or \"SEO\"."

#: src/components/organisms/PostEditor/PostEditor.tsx:75
#~ msgid "Total words:"
#~ msgstr "Total words:"

#: src/components/organisms/PricingTable.tsx:179
msgid "Trusted by +10,000 experts, founders, marketers and professionals"
msgstr "Trusted by +10,000 experts, founders, marketers and professionals"

#: src/components/organisms/PricingTableBF.tsx:176
msgid "Trusted by +12,000 experts, founders, marketers and professionals"
msgstr "Trusted by +12,000 experts, founders, marketers and professionals"

#: src/hooks/usePostToneOptions.ts:25
#~ msgid "Truth / Arrogant"
#~ msgstr "Truth / Arrogant"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:21
#: src/pages/onboarding/components/PostStyleSelect.tsx:19
#~ msgid "Truth/Arrogant"
#~ msgstr "Truth/Arrogant"

#: src/components/molecules/CurrentSubscriptionFree.tsx:23
#~ msgid "Try for free"
#~ msgstr "Try for free"

#: src/components/molecules/SubscriptionInformationCard.tsx:45
#: src/components/molecules/SubscriptionInformationCard.tsx:93
#~ msgid "Try for FREE"
#~ msgstr "Try for FREE"

#: src/components/molecules/SubscriptionInformationCard.tsx:81
#~ msgid "Try redact AI"
#~ msgstr "Try redact AI"

#: src/components/molecules/SubscriptionInformationCard.tsx:81
#~ msgid "Try RedactAI"
#~ msgstr "Try RedactAI"

#: src/components/molecules/PricingCard.tsx:80
msgid "Try RedactAI for Free"
msgstr "Try RedactAI for Free"

#: src/components/molecules/ChatInputBar.tsx:51
msgid "Try these"
msgstr "Try these"

#: src/components/molecules/popups/DescribeReason.tsx:27
msgid "Type here.."
msgstr "Type here.."

#: src/hooks/subscription/useCancelSubscription.ts:33
msgid "Unable to cancel the subscription"
msgstr "Unable to cancel the subscription"

#: src/hooks/savedPostsAndIdeas/useDeleteSavedIdea.ts:16
msgid "Unable to delete idea"
msgstr "Unable to delete idea"

#: src/hooks/savedPostsAndIdeas/useLikeSavedPost.ts:40
#~ msgid "Unable to delete post"
#~ msgstr "Unable to delete post"

#: src/hooks/deleteAccount/useDeleteAccount.ts:12
msgid "Unable to delete the account"
msgstr "Unable to delete the account"

#: src/hooks/profile/deleteProfile/useDeleteProfile.ts:20
msgid "Unable to delete the profile"
msgstr "Unable to delete the profile"

#: src/hooks/savedPostsAndIdeas/useLikeSavedPost.ts:47
#~ msgid "Unable to like post"
#~ msgstr "Unable to like post"

#: src/hooks/savedPostsAndIdeas/useLikeSavedPost.ts:40
#: src/hooks/savedPostsAndIdeas/useLikeSavedPost.ts:47
msgid "Unable to like the post"
msgstr "Unable to like the post"

#: src/hooks/savedPostsAndIdeas/useMarkSavedIdeaAsUsed.ts:18
msgid "Unable to mark idea as used"
msgstr "Unable to mark idea as used"

#: src/hooks/savedPostsAndIdeas/useMarkSavedPostAsUsed.ts:18
msgid "Unable to mark post as used"
msgstr "Unable to mark post as used"

#: src/hooks/savedPostsAndIdeas/useMarkPostAsFavourite.ts:15
msgid "Unable to mark the post as favourite"
msgstr "Unable to mark the post as favourite"

#: src/hooks/linkedin/useLinkedInLogin.ts:44
#: src/hooks/linkedin/useLinkedInLogin.ts:44
#~ msgid "Unable to resend the pin email, please retry or contact us"
#~ msgstr "Unable to resend the pin email, please retry or contact us"

#: src/hooks/savedPostsAndIdeas/useSavePost.ts:24
msgid "Unable to save post"
msgstr "Unable to save post"

#: src/components/molecules/UpgradePricingCard.tsx:51
#: src/components/molecules/UpgradePricingCard.tsx:88
#: src/components/molecules/UpgradePricingCardBF.tsx:50
#: src/components/molecules/UpgradePricingCardBF.tsx:87
msgid "Unlimited idea generator"
msgstr "Unlimited idea generator"

#: src/components/molecules/UpgradePricingCard.tsx:47
#: src/components/molecules/UpgradePricingCardBF.tsx:46
msgid "Unlimited post generation"
msgstr "Unlimited post generation"

#: src/components/molecules/CurrentSubscription.tsx:81
#: src/components/molecules/SubscriptionInformationCard.tsx:95
#: src/components/molecules/SubscriptionInformationCard.tsx:139
msgid "Unlimited posts"
msgstr "Unlimited posts"

#: src/components/molecules/UpgradePricingCard.tsx:59
#: src/components/molecules/UpgradePricingCardBF.tsx:58
msgid "Unlimited recycle post"
msgstr "Unlimited recycle post"

#: src/components/molecules/UpgradePricingCard.tsx:163
#: src/components/molecules/UpgradePricingCard.tsx:257
#: src/components/molecules/UpgradePricingCardBF.tsx:269
msgid "Upgrade"
msgstr "Upgrade"

#: src/components/molecules/SubscriptionInformationCard.tsx:127
#~ msgid "Upgrade now"
#~ msgstr "Upgrade now"

#: src/components/pages/Settings/Settings.tsx:154
#~ msgid "Upgrade subscription"
#~ msgstr "Upgrade subscription"

#: src/components/organisms/PricingTable.tsx:104
#~ msgid "Upgrade your account"
#~ msgstr "Upgrade your account"

#: src/components/pages/Pricing/components/PricingTable.tsx:122
#~ msgid "Upgrade your acount"
#~ msgstr "Upgrade your acount"

#: src/pages/onboarding/components/ProfileUrlStyle.tsx:117
#: src/pages/redactpost-v2/components/ProfileUrlStyle.tsx:117
msgid "Url of LinkedIn profile"
msgstr "Url of LinkedIn profile"

#: src/pages/onboarding/components/ProfileUrlStyle.tsx:118
#: src/pages/redactpost-v2/components/ProfileUrlStyle.tsx:118
msgid "Url of LinkedIn profile (you or your favourite content creator). Example: https://www.linkedin.com/in/pamartn/"
msgstr "Url of LinkedIn profile (you or your favourite content creator). Example: https://www.linkedin.com/in/pamartn/"

#: src/pages/onboarding/components/ProfileUrlStyle.tsx:116
#~ msgid "Url of LinkedIn profile (your favourite content creator)"
#~ msgstr "Url of LinkedIn profile (your favourite content creator)"

#: src/components/pages/RedactPost/components/RedactPostStyle.tsx:61
#~ msgid "Url of LinkedIn profile (yours or your favorite content creator)"
#~ msgstr "Url of LinkedIn profile (yours or your favorite content creator)"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:344
#~ msgid "Url of LinkedIn profile (yours or your favorite influencer):"
#~ msgstr "Url of LinkedIn profile (yours or your favorite influencer):"

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:59
msgid "URL of the content"
msgstr "URL of the content"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:35
#~ msgid "Url of the content (Website, Youtube, News article, LinkedIn post, ...)"
#~ msgstr "Url of the content (Website, Youtube, News article, LinkedIn post, ...)"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:298
#~ msgid "Url of the content (Youtube, News article, LinkedIn post)"
#~ msgstr "Url of the content (Youtube, News article, LinkedIn post)"

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:56
#~ msgid "URL of the content https://www.forbes.com/profile/elon-musk/?sh=33e5b9517999"
#~ msgstr "URL of the content https://www.forbes.com/profile/elon-musk/?sh=33e5b9517999"

#: src/pages/onboarding/components/ContentURLToolTipFirstStep.tsx:19
msgid "Url of the content you want to talk about."
msgstr "Url of the content you want to talk about."

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:60
msgid "URL of the content. Example: https://www.forbes.com/profile/elon-musk/?sh=33e5b9517999"
msgstr "URL of the content. Example: https://www.forbes.com/profile/elon-musk/?sh=33e5b9517999"

#: src/pages/onboarding-v2/components/ProfileUrlStyleToolTip.tsx:16
#: src/pages/onboarding/components/ProfileUrlStyleToolTip.tsx:16
msgid "URL of the LinkedIn profile you want to imitate."
msgstr "URL of the LinkedIn profile you want to imitate."

#: src/pages/onboarding-v2/components/ChooseUserProfile.tsx:63
msgid "Use the post generator"
msgstr "Use the post generator"

#: src/components/molecules/FilterSelectUsedNotUsed.tsx:24
#: src/pages/findIdea/components/MyIdeasTab.tsx:48
#: src/pages/myPosts/MyPosts.tsx:66
msgid "Used"
msgstr "Used"

#: src/components/molecules/SubscriptionInformationCard.tsx:152
#: src/components/molecules/SubscriptionInformationCard.tsx:190
msgid "Valid until:"
msgstr "Valid until:"

#: src/components/organisms/LoginForms/EmailVerification.tsx:50
msgid "Verification email resent successfully!"
msgstr "Verification email resent successfully!"

#: src/components/organisms/LoginForms/EmailVerification.tsx:77
msgid "Verification email sent!"
msgstr "Verification email sent!"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:84
msgid "Verifying Email"
msgstr "Verifying Email"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:18
msgid "Verifying your email..."
msgstr "Verifying your email..."

#: src/components/organisms/SavedPost/SavedPostCard.tsx:51
#~ msgid "View"
#~ msgstr "View"

#: src/pages/redactpost/RedactPost.tsx:228
#~ msgid "Warning: This generator is deprecated. Please use the new generator instead."
#~ msgstr "Warning: This generator is deprecated. Please use the new generator instead."

#: src/hooks/tutorial/useTutorialSteps.tsx:127
#~ msgid "Warning: We suggest using the writing style of well known content creators if you are a beginner on LinkedIn."
#~ msgstr "Warning: We suggest using the writing style of well known content creators if you are a beginner on LinkedIn."

#: src/components/molecules/ChooseStyleSourceCards/MyStyle.tsx:23
msgid "WARNING: You must already have a good style to use it."
msgstr "WARNING: You must already have a good style to use it."

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:58
#~ msgid "We"
#~ msgstr "We"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:105
#~ msgid "We are currently working on a new version of the LinkedIn connection, it will be available soon."
#~ msgstr "We are currently working on a new version of the LinkedIn connection, it will be available soon."

#: src/hooks/redactPost/useRedactPostMutation.ts:89
#: src/hooks/redactPostNew/useRedactPostMutation.ts:94
msgid "We can't retrieve the content of this website (it is blocking our AI). Please copy paste the content of the website or article directly into the “Key ideas” form field to redact your post."
msgstr "We can't retrieve the content of this website (it is blocking our AI). Please copy paste the content of the website or article directly into the “Key ideas” form field to redact your post."

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:47
msgid "We encourage you to use the profile url of another creator to imitate his writing style."
msgstr "We encourage you to use the profile url of another creator to imitate his writing style."

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:36
msgid "We have trained the AI to better match your writing style from your previous posts."
msgstr "We have trained the AI to better match your writing style from your previous posts."

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmed.tsx:21
msgid "We hope to see you again!"
msgstr "We hope to see you again!"

#: src/components/organisms/LoginForms/ResetPasswordForm.tsx:29
msgid "We will send you an email to reset your password"
msgstr "We will send you an email to reset your password"

#: src/pages/redactpost-v2/components/PostGeneratorIntro.tsx:66
msgid "We worked hard to create a new post generator:"
msgstr "We worked hard to create a new post generator:"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:73
msgid "We'd be sad to see you go"
msgstr "We'd be sad to see you go"

#: src/components/molecules/popups/DeleteAccountPopupConfirmed.tsx:24
msgid "We'll miss you"
msgstr "We'll miss you"

#: src/ui-v2/molecules/popups/DeleteAccountPopupConfirmed.tsx:13
#~ msgid "We'll miss you, {name}!"
#~ msgstr "We'll miss you, {name}!"

#: src/components/molecules/popups/PreviewPopup.tsx:96
msgid "Web"
msgstr "Web"

#: src/components/organisms/OnboardingPopup/OnboardingPopup.tsx:32
#~ msgid "Welcome on ..."
#~ msgstr "Welcome on ..."

#: src/components/organisms/OnboardingPopup/OnboardingPopup.tsx:58
#~ msgid "Welcome to"
#~ msgstr "Welcome to"

#: src/components/pages/Onboarding/Onboarding.tsx:102
#~ msgid "Welcome,"
#~ msgstr "Welcome,"

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:61
msgid "What do you want to talk about ?"
msgstr "What do you want to talk about ?"

#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:153
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:154
#~ msgid "What topic/theme you want to talk about"
#~ msgstr "What topic/theme you want to talk about"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:108
#~ msgid "What topic/theme you want to talk about ?"
#~ msgstr "What topic/theme you want to talk about ?"

#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:36
msgid "What types of content are most successful on LinkedIn?"
msgstr "What types of content are most successful on LinkedIn?"

#: src/pages/findIdea/FindIdea.tsx:130
#: src/pages/findIdea/FindIdea.tsx:131
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:61
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:62
#~ msgid "What's your thought?"
#~ msgstr "What's your thought?"

#: src/components/organisms/ReferralSourceForm.tsx:109
#: src/components/organisms/ReferralSourceForm.tsx:110
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:124
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:125
msgid "Where did you know us?"
msgstr "Where did you know us?"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:15
#~ msgid "Why do you need to post on LinkedIn ?"
#~ msgstr "Why do you need to post on LinkedIn ?"

#: src/components/molecules/GeneratedPost.tsx:155
#: src/components/molecules/popups/PreviewPopup.tsx:76
msgid "Word"
msgstr "Word"

#: src/components/organisms/ReferralSourceForm.tsx:48
#: src/pages/onboarding-v2/components/ReferralSourceForm.tsx:54
msgid "Word of mouth"
msgstr "Word of mouth"

#: src/components/organisms/PostEditor/PostEditor.tsx:109
#~ msgid "words"
#~ msgstr "words"

#: src/components/molecules/GeneratedPost.tsx:155
#: src/components/molecules/popups/PreviewPopup.tsx:76
msgid "Words"
msgstr "Words"

#: src/components/pages/RedactPost/RedactPost.tsx:51
#~ msgid "Write a post"
#~ msgstr "Write a post"

#: src/pages/freeTools/TextFormatter.tsx:72
#~ msgid "Write here.."
#~ msgstr "Write here.."

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:77
#: src/pages/recyclepost/components/RecyclePostForm.tsx:140
#: src/pages/redactpost-v2/RedactPost.tsx:242
#: src/pages/redactpost/RedactPost.tsx:265
msgid "Write my post"
msgstr "Write my post"

#: src/components/organisms/PostEditorV2/PostEditorV2.tsx:36
#~ msgid "Write your awesome post here ..."
#~ msgstr "Write your awesome post here ..."

#: src/pages/onboarding/components/PostStyle.tsx:40
#: src/pages/onboarding/components/ProfileUrlStyle.tsx:96
#: src/pages/redactpost-v2/components/PostStyle.tsx:40
#: src/pages/redactpost-v2/components/ProfileUrlStyle.tsx:96
#: src/pages/redactpost-v2/components/RedactPostStyle.tsx:73
#: src/pages/redactpost/components/RedactPostStyle.tsx:73
msgid "writing style"
msgstr "writing style"

#: src/components/pages/RedactPost/components/RedactPostStyle.tsx:48
#~ msgid "Writing style"
#~ msgstr "Writing style"

#: src/pages/redactpost-v2/components/EditCreateProfile.tsx:268
msgid "Writing style profile URL"
msgstr "Writing style profile URL"

#: src/components/organisms/LoginForms/LoginForm.tsx:89
msgid "Wrong email or password. Please try again."
msgstr "Wrong email or password. Please try again."

#: src/components/pages/Pricing/components/PricingTable.tsx:135
#~ msgid "Yearly"
#~ msgstr "Yearly"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:56
#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:60
#~ msgid "You"
#~ msgstr "You"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:135
#~ msgid "You (plural)"
#~ msgstr "You (plural)"

#: src/components/molecules/UpgradePricingCard.tsx:160
#: src/components/molecules/UpgradePricingCardBF.tsx:168
msgid "You already have this plan"
msgstr "You already have this plan"

#: src/utils/handleGoogleSignInUp.ts:19
msgid "You are being redirected to Google for authentication..."
msgstr "You are being redirected to Google for authentication..."

#: src/components/pages/Pricing/components/PricingTable.tsx:70
#~ msgid "You are close to generate a LinkedIn post in your writing style in 30 seconds. You can cancel anytime."
#~ msgstr "You are close to generate a LinkedIn post in your writing style in 30 seconds. You can cancel anytime."

#: src/components/organisms/PricingTable.tsx:154
#~ msgid "you are getting 2 months FREE with annual subscription"
#~ msgstr "you are getting 2 months FREE with annual subscription"

#: src/components/organisms/PricingTable.tsx:118
#~ msgid "You are getting 2 months FREE with annual subscription"
#~ msgstr "You are getting 2 months FREE with annual subscription"

#: src/pages/login/LoginPage.tsx:55
#~ msgid "you are getting 2months FREE with annual subscription"
#~ msgstr "you are getting 2months FREE with annual subscription"

#: src/components/organisms/PricingTable.tsx:112
#~ msgid "You can cancel anytime."
#~ msgstr "You can cancel anytime."

#: src/components/molecules/ChooseStyleSourceCards/CreatorStyle.tsx:21
msgid "You can choose any creator you want."
msgstr "You can choose any creator you want."

#: src/pages/onboarding-v2/components/MyOwnIdeasToolTipFinalStep.tsx:48
#: src/pages/onboarding/components/MyOwnIdeasToolTipFinalStep.tsx:48
msgid "You can use"
msgstr "You can use"

#: src/hooks/tutorial/useTutorialSteps.tsx:87
#~ msgid "You can use <directions> to help drive the creative work of our IA."
#~ msgstr "You can use <directions> to help drive the creative work of our IA."

#: src/components/pages/Settings/Settings.tsx:149
#~ msgid "You do not have an active subscription yet."
#~ msgstr "You do not have an active subscription yet."

#: src/components/molecules/CurrentSubscriptionFree.tsx:17
msgid "You do not have an active subscription."
msgstr "You do not have an active subscription."

#: src/components/pages/Pricing/components/PricingTable.tsx:124
#~ msgid "You do not have enough credits. Choose the plan that works best for you."
#~ msgstr "You do not have enough credits. Choose the plan that works best for you."

#: src/components/pages/Pricing/components/StripePricingTable.tsx:11
#~ msgid "You don't have enough credits for this generation."
#~ msgstr "You don't have enough credits for this generation."

#: src/pages/onboarding/components/PostGeneration.tsx:49
#~ msgid "You got the lightened fast results"
#~ msgstr "You got the lightened fast results"

#: src/pages/settings/components/DeleteAccount.tsx:35
msgid "You have an active subscription. Please cancel your subscription in the subscription tab before deleting your account."
msgstr "You have an active subscription. Please cancel your subscription in the subscription tab before deleting your account."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:117
#~ msgid "You must connect your LinkedIn account in order to schedule your posts*"
#~ msgstr "You must connect your LinkedIn account in order to schedule your posts*"

#: src/components/molecules/ChooseStyleSourceCards/MyStyle.tsx:30
msgid "You need to have at least 15 published posts to use your own style."
msgstr "You need to have at least 15 published posts to use your own style."

#: src/components/pages/Settings/Settings.tsx:149
#~ msgid "You subscription has been canceled."
#~ msgstr "You subscription has been canceled."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:46
#~ msgid "You successfully connected your LinkedIn account !"
#~ msgstr "You successfully connected your LinkedIn account !"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:134
msgid "You will be redirected to the login page shortly."
msgstr "You will be redirected to the login page shortly."

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:99
msgid "You will be redirected to the onboarding page shortly."
msgstr "You will be redirected to the onboarding page shortly."

#: src/components/molecules/affiliateEarnings.tsx:22
msgid "You’ve earned a total of {totalPosts} posts."
msgstr "You’ve earned a total of {totalPosts} posts."

#: src/pages/login/LoginPage.tsx:61
msgid "Your AI Writing Assistant!"
msgstr "Your AI Writing Assistant!"

#: src/components/pages/Settings/Settings.tsx:112
#~ msgid "Your company name"
#~ msgstr "Your company name"

#: src/components/pages/Settings/Settings.tsx:118
#~ msgid "Your company position"
#~ msgstr "Your company position"

#: src/components/pages/Settings/Settings.tsx:111
#~ msgid "Your email"
#~ msgstr "Your email"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:15
msgid "Your email has been successfully verified!"
msgstr "Your email has been successfully verified!"

#: src/components/pages/Settings/Settings.tsx:110
#~ msgid "Your first name"
#~ msgstr "Your first name"

#: src/components/pages/Settings/Settings.tsx:115
#~ msgid "Your last name"
#~ msgstr "Your last name"

#: src/components/pages/Settings/Settings.tsx:117
#~ msgid "Your LinkedIn profile url"
#~ msgstr "Your LinkedIn profile url"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:16
msgid "Your password has been successfully reset!"
msgstr "Your password has been successfully reset!"

#: src/components/molecules/CurrentSubscription.tsx:93
msgid "Your plan will be renewed on"
msgstr "Your plan will be renewed on"

#: src/hooks/tutorial/useTutorialSteps.tsx:141
#~ msgid "Your post has been created ! Please rework it a little to your convenience, and when ready to publish on LinkedIn just click copy and paste it on your LinkedIn post."
#~ msgstr "Your post has been created ! Please rework it a little to your convenience, and when ready to publish on LinkedIn just click copy and paste it on your LinkedIn post."

#: src/components/molecules/CurrentSubscription.tsx:100
msgid "Your subscription will be valid until"
msgstr "Your subscription will be valid until"

#: src/components/molecules/ChooseContentSourceCards/FromUrl.tsx:24
msgid "Your website"
msgstr "Your website"
