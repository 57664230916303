import mixpanel from 'mixpanel-browser';

export const initMixpanel = () => {
  const token = import.meta.env.VITE_MIXPANEL_TOKEN || '';
  mixpanel.init(token, {
    debug: import.meta.env.VITE_NODE_ENV === 'development',
    track_pageview: true,
    persistence: 'localStorage',
  });
};
