import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {Button} from '../../atoms/Button';
import {TextInput} from '../../atoms/TextInput';
import {Checkbox} from '../../atoms/Checkbox';
import Google from '../images/Google.png';
import {t} from '@lingui/macro';
import {AuthMode, SuperTokensError} from '../../../pages/login/LoginPage';
import ThirdPartyEmailPassword from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import EmailVerification from 'supertokens-web-js/recipe/emailverification';
import {useSnackbar} from 'notistack';
import {isValidEmail} from '../../../utils/isValidEmail';
import {handleGoogleSignInUp} from '../../../utils/handleGoogleSignInUp';
import {useNavigate} from 'react-router-dom';

interface Props {
  onAuthModeChange: (mode: AuthMode) => void;
}

export const SignUpForm: FunctionComponent<Props> = ({onAuthModeChange}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const [termsError, setTermsError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const validatePassword = (password: string): boolean => {
    return (
      password.length >= 6 && /\d/.test(password) && /[a-zA-Z]/.test(password)
    );
  };

  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    const resetErrors = () => {
      setEmailError(null);
      setPasswordError(null);
      setFirstNameError(null);
      setLastNameError(null);
      setTermsError(null);
    };

    const validateForm = () => {
      let isValid = true;
      const validations = [
        {
          condition: !email,
          errorSetter: setEmailError,
          message: t`Please fill in your email.`,
        },
        {
          condition: email && !isValidEmail(email),
          errorSetter: setEmailError,
          message: t`Please enter a valid email address.`,
        },
        {
          condition: !password,
          errorSetter: setPasswordError,
          message: t`Please fill in your password.`,
        },
        {
          condition: password && !validatePassword(password),
          errorSetter: setPasswordError,
          message: t`Password: Min 6 chars, 1 number, 1 letter.`,
        },
        {
          condition: !firstName,
          errorSetter: setFirstNameError,
          message: t`Please fill in your first name.`,
        },
        {
          condition: !lastName,
          errorSetter: setLastNameError,
          message: t`Please fill in your last name.`,
        },
        {
          condition: !termsAccepted,
          errorSetter: setTermsError,
          message: t`Please accept the Terms and Conditions.`,
        },
      ];

      validations.forEach(({condition, errorSetter, message}) => {
        if (condition) {
          errorSetter(message);
          isValid = false;
        }
      });

      return isValid;
    };

    resetErrors();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      const response = await ThirdPartyEmailPassword.emailPasswordSignUp({
        formFields: [
          {id: 'email', value: email},
          {id: 'password', value: password},
          {id: 'firstName', value: firstName},
          {id: 'lastName', value: lastName},
        ],
      });

      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach(({id, error}) => {
          switch (id) {
            case 'email':
              setEmailError(error);
              break;
            case 'password':
              setPasswordError(error);
              break;
            case 'firstName':
              setFirstNameError(error);
              break;
            case 'lastName':
              setLastNameError(error);
              break;
          }
        });
        return;
      }

      await EmailVerification.sendVerificationEmail();
      navigate('/auth/send-email-verification');
    } catch (err) {
      const superTokensError = err as SuperTokensError;

      if (!superTokensError.isSuperTokensGeneralError) {
        enqueueSnackbar(t`Oops! Something went wrong.`, {variant: 'error'});
        return;
      }

      if (superTokensError.message === 'email already exists') {
        enqueueSnackbar(
          t`A user with this email already exists. Please sign-in with Google instead.`,
          {
            variant: 'error',
          },
        );
        return;
      }

      enqueueSnackbar(superTokensError.message, {variant: 'error'});
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContainer>
      <FormTitle>{t`Sign Up`}</FormTitle>
      <StyledTextInput
        label="Email"
        placeholderText={t`Enter email`}
        onChange={(event) => {
          setEmail(event.target.value);
          setEmailError(null);
        }}
        error={!!emailError}
        helperText={emailError}
        required
      />
      <StyledTextInput
        type="password"
        label={t`Password`}
        placeholderText={t`Enter password`}
        onChange={(event) => {
          setPassword(event.target.value);
          setPasswordError(null);
        }}
        error={!!passwordError}
        helperText={passwordError}
        required
      />
      <NameContainer>
        <StyledTextInput
          label={t`First name`}
          placeholderText={t`First name`}
          onChange={(event) => {
            setFirstName(event.target.value);
            setFirstNameError(null);
          }}
          error={!!firstNameError}
          helperText={firstNameError}
          required
        />
        <StyledTextInput
          label={t`Last name`}
          placeholderText={t`Last name`}
          onChange={(event) => {
            setLastName(event.target.value);
            setLastNameError(null);
          }}
          error={!!lastNameError}
          helperText={lastNameError}
          required
        />
      </NameContainer>
      <TermsContainer>
        <Checkbox
          size="small"
          isChecked={termsAccepted}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTermsAccepted(event.target.checked);
            setTermsError(null);
          }}
          error={!!termsError}
        />
        <TermsText
          onClick={() =>
            setTermsAccepted(!termsAccepted)
          }>{t`I have read and accept the`}</TermsText>
        <TermsLink
          href="https://www.redactai.io/terms"
          target="_blank"
          rel="noopener noreferrer">{t`Terms and Conditions`}</TermsLink>
      </TermsContainer>
      <Button
        label={t`Sign up`}
        variant="main"
        size="medium"
        onClick={handleSubmit}
        disabled={loading}
      />
      <Button
        label={t`Sign up via Google`}
        variant="light"
        size="medium"
        startIcon={<img src={Google} alt="Google" />}
        onClick={() => handleGoogleSignInUp(enqueueSnackbar, setLoading)}
        type="button"
        disabled={loading}
      />
      <SignIn>
        {t`Already have an account?`}{' '}
        <SignInLink
          onClick={() =>
            onAuthModeChange(AuthMode.LOGIN)
          }>{t`Sign in`}</SignInLink>
      </SignIn>
    </FormContainer>
  );
};

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2em;
  background: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  box-shadow: 0 4px 12px ${({theme}) => theme.colors.neutral.shade5};
  width: 27em;
  gap: 1em;
`;

const FormTitle = styled.h2`
  font-size: 2.3em;
  align-self: center;
  font-weight: 700;
  margin-bottom: 1em;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  margin-bottom: ${(props) => (props.error ? '1em' : '0.5em')};
`;

const NameContainer = styled.div`
  display: flex;
  gap: 1em;
  margin-bottom: 0.5em;
`;

const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
`;

const TermsText = styled.span`
  font-size: 1em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
  cursor: pointer;
`;

const TermsLink = styled.a`
  color: ${({theme}) => theme.colors.primary};
  text-decoration: none;
  font-weight: bold;
  margin-left: 0.2em;

  &:hover {
    text-decoration: underline;
  }
`;

const SignIn = styled.div`
  font-size: 1em;
  font-weight: 600;
  align-self: center;
  margin: 1em 0 2em;
`;

const SignInLink = styled.a`
  color: ${({theme}) => theme.colors.primary4.shade1};
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
