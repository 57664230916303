import {createContext, useContext, useState, ReactNode, useRef} from 'react';
import mixpanel from 'mixpanel-browser';
import {StyleSource} from '../pages/onboarding/components/ChooseStyleSource';
import {ContentSource} from '../pages/onboarding/components/ChooseContentSource';
import {UserReferralSource} from '../components/organisms/ReferralSourceForm';
import {Reason} from '../components/molecules/popups/CancelSubscriptionPopupReasonAsk';
import {SavedPostLanguage} from './savedPost/savedPost.types';

export interface EventData {
  contextUrl?: string;
  styleUrl?: string;
  userReferralSource?: UserReferralSource;
  profileUrl?: string;
  subject?: string;
  keyIdeas?: string;
  language?: SavedPostLanguage;
  period?: string;
  filterBy?: string;
  keyword?: string;
  daysFromNow?: number;
  postCount?: number;
  reason?: string;
  otherText?: string;
  chosenPerson?: string;
  text?: string;
  isDefaultText?: boolean;
  productId?: string;
  productName?: string;
}

export type OnboardingEvent =
  | 'Onboarding - Start'
  | 'Onboarding - Click - Choose referral source'
  | 'Onboarding - Choose context url'
  | 'Onboarding - Choose style url'
  | 'Onboarding - Click - Generate post'
  | 'Onboarding - Post has been generated'
  | 'Onboarding - Click - Copy post'
  | 'Onboarding - Click - Finish tutorial';

export type RecyclePostEvent =
  | 'RecyclePost - Click - Open page'
  | 'RecyclePost - Fill - LinkedIn profile url'
  | 'RecyclePost - Click - Filter by date'
  | 'RecyclePost - Click - Filter by success'
  | 'RecyclePost - Fill - Search'
  | 'RecyclePost - List posts'
  | 'RecyclePost - Click - Recycle post'
  | 'RecyclePost - Click - Generate post'
  | 'RecyclePost - Post has been generated'
  | 'RecyclePost - Click - Copy post';

export type CancelSubscriptionEvent =
  | 'Cancel Subscription - Click - Cancel'
  | 'Cancel Subscription - Click - Choose someone'
  | 'Cancel Subscription - Click - Abort cancel'
  | 'Cancel Subscription - Click - Confirm cancel'
  | 'Cancel Subscription - Click - Confirm reason';

export type ChatWithRedactAIEvent =
  'ChatWithRedactAI - Click - Regenerate posts';

export type PricingEvent =
  | 'Pricing - Open page'
  | 'Pricing - Click - Choose product'
  | 'Pricing - Click - Switch period'
  | 'Pricing - Open popup';

export type TrackEventFunction = (
  eventType: string,
  eventData?: EventData,
) => void;

interface TrackingContextProps {
  selectedContentSource?: ContentSource;
  setSelectedContentSource: React.Dispatch<
    React.SetStateAction<ContentSource | undefined>
  >;
  selectedStyleSource?: StyleSource;
  setSelectedStyleSource: React.Dispatch<
    React.SetStateAction<StyleSource | undefined>
  >;
  selectedReferralSource?: UserReferralSource;
  setSelectedReferralSource: React.Dispatch<
    React.SetStateAction<UserReferralSource | undefined>
  >;
  trackEvent: TrackEventFunction;
}

const TrackingContext = createContext<TrackingContextProps | undefined>(
  undefined,
);

export const TrackingProvider = ({children}: {children: ReactNode}) => {
  const [selectedContentSource, setSelectedContentSource] = useState<
    ContentSource | undefined
  >();
  const [selectedStyleSource, setSelectedStyleSource] = useState<
    StyleSource | undefined
  >();
  const [selectedReferralSource, setSelectedReferralSource] = useState<
    UserReferralSource | undefined
  >(undefined);

  const contextUrlRef = useRef<string | undefined>(undefined);
  const styleUrlRef = useRef<string | undefined>(undefined);
  const subjectRef = useRef<string | undefined>(undefined);
  const keyIdeasRef = useRef<string | undefined>(undefined);
  const languageRef = useRef<SavedPostLanguage | undefined>(undefined);

  const trackEvent: TrackEventFunction = (eventType, eventData = {}) => {
    if (eventType.startsWith('Onboarding')) {
      trackOnboardingEvent(eventType as OnboardingEvent, eventData);
    }
    if (eventType.startsWith('RecyclePost')) {
      trackRecyclePostEvent(eventType as RecyclePostEvent, eventData);
    }
    if (eventType.startsWith('Cancel Subscription')) {
      trackCancelSubscriptionEvent(
        eventType as CancelSubscriptionEvent,
        eventData,
      );
    }
    if (eventType.startsWith('ChatWithRedactAI')) {
      trackChatWithRedactAIEvent(eventType as ChatWithRedactAIEvent, eventData);
    }
    if (eventType.startsWith('Pricing')) {
      trackPricingEvent(eventType as PricingEvent, eventData);
    }
  };

  const trackOnboardingEvent = (
    eventType: OnboardingEvent,
    eventData: EventData = {},
  ) => {
    switch (eventType) {
      case 'Onboarding - Start':
        mixpanel.track(eventType, {});
        break;
      case 'Onboarding - Click - Choose referral source':
        mixpanel.track(eventType, {
          'Referral Source': eventData?.userReferralSource?.source,
          'Referral Source Details': eventData?.userReferralSource?.details,
        });
        break;
      case 'Onboarding - Choose context url':
        mixpanel.track(eventType, {
          'Context Url': eventData?.contextUrl,
        });
        contextUrlRef.current = eventData.contextUrl;
        break;
      case 'Onboarding - Choose style url':
        mixpanel.track(eventType, {
          'Style Url': eventData.styleUrl,
        });
        styleUrlRef.current = eventData.styleUrl;
        break;
      case 'Onboarding - Click - Generate post':
        mixpanel.track(eventType, {
          'Subject ': eventData.subject,
          'KeyIdeas ': eventData.keyIdeas,
          'Language ': eventData.language,
        });
        subjectRef.current = eventData.subject;
        keyIdeasRef.current = eventData.keyIdeas;
        languageRef.current = eventData.language;
        break;
      case 'Onboarding - Post has been generated':
      case 'Onboarding - Click - Copy post':
      case 'Onboarding - Click - Finish tutorial':
        mixpanel.track(eventType, {
          'Subject ': subjectRef.current,
          'KeyIdeas ': keyIdeasRef.current,
          'Language ': languageRef.current,
          'Context Url': contextUrlRef.current,
          'Style Url': styleUrlRef.current,
        });
        break;
      default:
        console.warn(`Unknown onboarding event type: ${eventType}`);
    }
  };

  const trackRecyclePostEvent = (
    eventType: RecyclePostEvent,
    eventData: EventData = {},
  ) => {
    switch (eventType) {
      case 'RecyclePost - Click - Open page':
        mixpanel.track(eventType, {});
        break;
      case 'RecyclePost - Click - Filter by date':
        mixpanel.track(eventType, {
          Period: eventData?.period,
        });
        break;
      case 'RecyclePost - Click - Filter by success':
        mixpanel.track(eventType, {
          'Filter By': eventData?.filterBy,
        });
        break;
      case 'RecyclePost - Fill - LinkedIn profile url':
        mixpanel.track(eventType, {
          'LinkedIn Profile URL': eventData?.profileUrl,
        });
        break;
      case 'RecyclePost - Fill - Search':
        mixpanel.track(eventType, {
          Keyword: eventData?.keyword,
        });
        break;
      case 'RecyclePost - Click - Recycle post':
        mixpanel.track(eventType, {
          'Difference in days from now': eventData?.daysFromNow,
        });
        break;
      case 'RecyclePost - Click - Generate post':
      case 'RecyclePost - Post has been generated':
      case 'RecyclePost - Click - Copy post':
        mixpanel.track(eventType, {
          'Style Source': selectedStyleSource,
        });
        break;
      case 'RecyclePost - List posts':
        mixpanel.track(eventType, {
          'LinkedIn Profile URL': eventData?.profileUrl,
          Period: eventData?.period,
          'Post Count': eventData?.postCount,
        });
        break;
      default:
        console.warn(`Unknown recyclePost event type: ${eventType}`);
    }
  };

  const trackCancelSubscriptionEvent = (
    eventType: CancelSubscriptionEvent,
    eventData: EventData,
  ) => {
    switch (eventType) {
      case 'Cancel Subscription - Click - Cancel':
      case 'Cancel Subscription - Click - Abort cancel':
      case 'Cancel Subscription - Click - Confirm cancel':
        mixpanel.track(eventType, {});
        break;
      case 'Cancel Subscription - Click - Choose someone':
        mixpanel.track(eventType, {'Chosen Person': eventData.chosenPerson});
        break;
      case 'Cancel Subscription - Click - Confirm reason':
        mixpanel.track(eventType, {
          'Reason ': eventData?.reason,
          'Other Text':
            eventData.reason === Reason.OTHER ? eventData.otherText : undefined,
        });
        break;
      default:
        console.warn(`Unknown cancel subscription event type: ${eventType}`);
    }
  };

  const trackChatWithRedactAIEvent = (
    eventType: ChatWithRedactAIEvent,
    eventData: EventData,
  ) => {
    switch (eventType) {
      case 'ChatWithRedactAI - Click - Regenerate posts':
        mixpanel.track(eventType, {
          text: eventData.isDefaultText ? undefined : eventData.text,
          isDefaultText: eventData.isDefaultText,
        });
        break;
      default:
        console.warn(`Unknown chatWithRedactAI event type: ${eventType}`);
    }
  };

  const trackPricingEvent = (eventType: PricingEvent, eventData: EventData) => {
    switch (eventType) {
      case 'Pricing - Open page':
      case 'Pricing - Open popup':
      case 'Pricing - Click - Switch period':
        mixpanel.track(eventType, {});
        break;
      case 'Pricing - Click - Choose product':
        mixpanel.track(eventType, {
          productId: eventData.productId,
          productName: eventData.productName,
        });
        break;
      default:
        console.warn(`Unknown pricing event type: ${eventType}`);
    }
  };

  return (
    <TrackingContext.Provider
      value={{
        selectedContentSource,
        setSelectedContentSource,
        selectedStyleSource,
        setSelectedStyleSource,
        selectedReferralSource,
        setSelectedReferralSource,
        trackEvent,
      }}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => {
  const context = useContext(TrackingContext);
  if (context === undefined) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }
  return context;
};
