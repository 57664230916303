import {FunctionComponent, useEffect} from 'react';
import {Page} from '../../components/templates/Page';
import {PricingTable} from '../../components/organisms/PricingTable';
import {useTracking} from '../../hooks/useTracking';
import {useIsBlackFriday} from '../../hooks/useIsBlackFriday.ts';
import {PricingTableBF} from '../../components/organisms/PricingTableBF.tsx';
import styled from 'styled-components';

export const Pricing: FunctionComponent = () => {
  const {trackEvent} = useTracking();
  useEffect(() => {
    trackEvent('Pricing - Open page');
  }, [trackEvent]);

  const isBlackFriday = useIsBlackFriday();

  if (isBlackFriday) {
    return (
      <StyledPage>
        <PricingTableBF />
      </StyledPage>
    );
  }

  return (
    <Page>
      <PricingTable />
    </Page>
  );
};

const StyledPage = styled(Page)`
  background-color: ${({theme}) => theme.colors.neutral.shade14};
`;
