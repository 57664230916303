import {useState} from 'react';
import {i18n} from '@lingui/core';
import {SavedPostLanguage} from '../savedPost/savedPost.types';

export interface Language {
  name: string;
  countryCode: string;
  value: SavedPostLanguage;
}

export const postLanguages = [
  {
    name: 'Français',
    countryCode: 'fr',
    value: SavedPostLanguage.FRENCH,
  },
  {
    name: 'English',
    countryCode: 'us',
    value: SavedPostLanguage.ENGLISH,
  },
  {
    name: 'Español',
    countryCode: 'es',
    value: SavedPostLanguage.SPANISH,
  },
  {
    name: 'Deutsch',
    countryCode: 'de',
    value: SavedPostLanguage.GERMAN,
  },
  {
    name: 'Italiano',
    countryCode: 'it',
    value: SavedPostLanguage.ITALIAN,
  },
  {
    name: 'Português',
    countryCode: 'pt',
    value: SavedPostLanguage.PORTUGUESE,
  },
];

export const settingLanguages: Language[] = [
  {
    name: 'Français',
    countryCode: 'fr',
    value: SavedPostLanguage.FRENCH,
  },
  {
    name: 'English',
    countryCode: 'us',
    value: SavedPostLanguage.ENGLISH,
  },
];

const useDefaultLanguage = (): [
  SavedPostLanguage,
  React.Dispatch<React.SetStateAction<SavedPostLanguage>>,
] => {
  const countryCode = i18n.locale;
  const defaultLanguage = postLanguages.find(
    (lang) => lang.countryCode === (countryCode === 'en' ? 'us' : countryCode),
  );

  const [postLang, setPostLang] = useState<SavedPostLanguage>(
    defaultLanguage ? defaultLanguage.value : postLanguages[0].value,
  );

  return [postLang, setPostLang];
};

export default useDefaultLanguage;
