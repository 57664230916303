import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const GenerateIcon: FunctionComponent<Props> = ({
  width = 25,
  height = 24,
  color = '#1C1B1A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3288 5.65437C20.6642 5.20796 19.3822 3.87884 18.9961 2.19922C18.61 3.87884 17.328 5.20796 15.6633 5.65437L15.4961 5.69922L15.6633 5.74407C17.328 6.19048 18.61 7.5196 18.9961 9.19922C19.3822 7.5196 20.6642 6.19048 22.3288 5.74407L22.4961 5.69922L22.3288 5.65437ZM17.9878 13.613C14.4778 12.5045 11.7462 9.72715 10.6961 6.19922C9.64599 9.72715 6.91438 12.5045 3.40438 13.613L2.69609 13.8367L3.10668 13.9664C6.79501 15.1312 9.64822 18.076 10.6961 21.7992C11.744 18.076 14.5972 15.1312 18.2855 13.9664L18.6961 13.8367L17.9878 13.613Z"
        fill={color}
        style={{fill: color, fillOpacity: 1}}
      />
    </svg>
  );
};

export default GenerateIcon;
