import {t} from '@lingui/macro';
import {Dispatch, FunctionComponent, SetStateAction, useState} from 'react';
import styled from 'styled-components';
import {Button} from '../../../components/atoms/Button';
import ArrowLeftIcon from '../../../components/atoms/Icons/ArrowLeftIcon';
import {isTablet} from '../../../utils/responsive';
import LinkIcon from '../../../components/atoms/Icons/LinkIcon';
import {RadioButton} from '../../../components/atoms/RadioButton';
import LogoDotIcon from '../../../components/atoms/Icons/LogoDotIcon';
import {useTheme} from '../../../components/theme/theme';
import {useSnackbar} from 'notistack';
import {OnboardingStyleChoiceCards} from '../../../components/molecules/ChooseStyleSourceCards/OnboardingStyleChoiceCards';
import {Profile} from '../../../hooks/profile/profile.ts';
import {SavedPostTone} from '../../../hooks/savedPost/savedPost.types.ts';
import {RedactaiStyle} from '../../../components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx';

export enum StyleSource {
  PROFILE_URL = 'PROFILE_URL',
  OTHERS_PROFILE_URL = 'OTHERS_PROFILE_URL',
  POST_TYPE = 'POST_TYPE',
}

interface Props {
  hasEnoughPosts: boolean;
  profileToDisplay?: Profile;
  chooseStyleSource: (styleSource: StyleSource) => void;
  onGoBack?: () => void;
  setPostTone: Dispatch<SetStateAction<SavedPostTone>>;
}

export const ChooseStyleSource: FunctionComponent<Props> = ({
  hasEnoughPosts,
  profileToDisplay,
  chooseStyleSource,
  onGoBack,
  setPostTone,
}) => {
  const theme = useTheme();
  const {enqueueSnackbar} = useSnackbar();
  const [chosenStyle, setChosenStyle] = useState<StyleSource | undefined>(
    undefined,
  );
  return (
    <Col>
      <Top>
        <Header>{t`Choose the style of your post`}</Header>
      </Top>
      <Bottom>
        <OnboardingStyleChoiceCards
          hasEnoughPosts={hasEnoughPosts}
          profile={profileToDisplay}
          chooseStyleSource={(styleSource) => {
            setChosenStyle(styleSource);
            if (styleSource === StyleSource.POST_TYPE) {
              return;
            }
            chooseStyleSource(styleSource);
          }}
          setPostTone={setPostTone}
        />
        <CardsMobile>
          {profileToDisplay && (
            <MyStyleMobile
              $disabled={!hasEnoughPosts}
              onClick={() => {
                if (!hasEnoughPosts) {
                  return;
                }

                setChosenStyle(StyleSource.PROFILE_URL);
              }}>
              <HeaderMobile>
                <Avatar src={profileToDisplay.profilePhotoUrl} />
                {t`${profileToDisplay?.name}'s style`}
              </HeaderMobile>
              <RadioButton
                isSelected={chosenStyle === StyleSource.PROFILE_URL}
              />
            </MyStyleMobile>
          )}
          <Line />
          <CreatorStyleMobile
            onClick={() => {
              setChosenStyle(StyleSource.OTHERS_PROFILE_URL);
            }}>
            <HeaderMobile>
              <LinkIcon height={15} width={15} />
              {t`Another creator's style`}
            </HeaderMobile>
            <RadioButton
              isSelected={chosenStyle === StyleSource.OTHERS_PROFILE_URL}
            />
          </CreatorStyleMobile>
          <Line />
          <RedactaiStyleMobile
            onClick={() => {
              setChosenStyle(StyleSource.POST_TYPE);
            }}>
            <HeaderMobile>
              <RedactaiPoints>
                <LogoDotIcon />
                <LogoDotIcon />
                <StyledLogoDotIcon color={theme.colors.primary1.shade2} />
              </RedactaiPoints>
              {t`From our list of style`}
            </HeaderMobile>
            <RadioButton isSelected={chosenStyle === StyleSource.POST_TYPE} />
          </RedactaiStyleMobile>
          {chosenStyle === StyleSource.POST_TYPE && (
            <RedactaiStyle setPostTone={setPostTone} isMobile={true} />
          )}
        </CardsMobile>
        <Row>
          <Button
            onClick={onGoBack}
            label={t`Go back`}
            size="medium"
            variant="light"
            startIcon={<StyledArrowLeftIcon />}
          />
          <StyledButton
            onClick={() => {
              if (chosenStyle === undefined) {
                enqueueSnackbar(t`Please choose a style`, {
                  variant: 'error',
                });
                return;
              }
              chooseStyleSource(chosenStyle as StyleSource);
            }}
            label={t`Generate my post`}
            size="medium"
            variant="main"
            fullWidth
          />
        </Row>
      </Bottom>
    </Col>
  );
};
const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4em;
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1.5em;
`;
const Header = styled.span`
  font-size: 2.75em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;
const StyledButton = styled(Button)`
  width: 15.125em;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;
const CardsMobile = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  @media (min-width: ${isTablet}) {
    display: none;
  }
`;
const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
const MyStyleMobile = styled.div<{$disabled: boolean}>`
  cursor: ${({$disabled}) => ($disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({$disabled}) => ($disabled ? 0.5 : 1)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CreatorStyleMobile = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const RedactaiStyleMobile = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Line = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade4};
  height: 1px;
  width: 100%;
`;
const HeaderMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.25em;
  letter-spacing: 0.0125em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const RedactaiPoints = styled.div`
  height: 1.25em;
  width: 1.25em;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  border: 1px solid ${({theme}) => theme.colors.primary1.shade8};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8px;
`;
const StyledLogoDotIcon = styled(LogoDotIcon)`
  margin-bottom: 1px;
`;

const Avatar = styled.img`
  width: 1.7em;
  height: 1.7em;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid ${({theme}) => theme.colors.neutral.shade13};
`;
