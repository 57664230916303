import {FunctionComponent} from 'react';
import {useTheme} from '../../theme/theme';

interface Props {
  width?: number;
  height?: number;
}

const SettingsIcon: FunctionComponent<Props> = ({width = 20, height = 21}) => {
  const theme = useTheme();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 9.82031V15.5903C3 17.7103 3 17.7103 5 19.0603L10.5 22.2403C11.33 22.7203 12.68 22.7203 13.5 22.2403L19 19.0603C21 17.7103 21 17.7103 21 15.6003V9.82031C21 7.71031 21 7.71031 19 6.36031L13.5 3.18031C12.68 2.70031 11.33 2.70031 10.5 3.18031L5 6.36031C3 7.71031 3 7.71031 3 9.82031Z"
        stroke={theme.colors.neutral.shade3}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.7109C13.6569 15.7109 15 14.3678 15 12.7109C15 11.0541 13.6569 9.71094 12 9.71094C10.3431 9.71094 9 11.0541 9 12.7109C9 14.3678 10.3431 15.7109 12 15.7109Z"
        stroke={theme.colors.primary1.shade2}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIcon;
