import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {Button} from '../../atoms/Button';
import {TextInput} from '../../atoms/TextInput';
import Google from '../images/Google.png';
import {t} from '@lingui/macro';
import {AuthMode, SuperTokensError} from '../../../pages/login/LoginPage';
import ThirdPartyEmailPassword from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router-dom';
import {isValidEmail} from '../../../utils/isValidEmail';
import {handleGoogleSignInUp} from '../../../utils/handleGoogleSignInUp';
import {emailVerificationCheck} from '../../../utils/emailVerificationCheck';

interface Props {
  onAuthModeChange: (mode: AuthMode) => void;
}

export const LoginForm: FunctionComponent<Props> = ({onAuthModeChange}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    if (loading) {
      return;
    }

    let hasError = false;

    if (!email) {
      const errorMessage = t`Please fill in your email.`;
      setEmailError(errorMessage);
      hasError = true;
    }
    if (email && !isValidEmail(email)) {
      const errorMessage = t`Please enter a valid email address.`;
      setEmailError(errorMessage);
      hasError = true;
    }
    if (email && isValidEmail(email)) {
      setEmailError(null);
    }

    if (!password) {
      const errorMessage = t`Please fill in your password.`;
      setPasswordError(errorMessage);
      hasError = true;
    }
    if (password) {
      setPasswordError(null);
    }

    if (hasError) {
      return;
    }

    setLoading(true);

    try {
      const response = await ThirdPartyEmailPassword.emailPasswordSignIn({
        formFields: [
          {id: 'email', value: email},
          {id: 'password', value: password},
        ],
      });

      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach((formField) => {
          if (formField.id === 'email') {
            setEmailError(formField.error);
          }
          if (formField.id === 'password') {
            setPasswordError(formField.error);
          }
        });
        return;
      }

      if (response.status === 'WRONG_CREDENTIALS_ERROR') {
        const errorMessage = t`Wrong email or password. Please try again.`;
        setEmailError(errorMessage);
        setPasswordError(errorMessage);
        return;
      }

      await emailVerificationCheck(navigate);

      enqueueSnackbar(t`Login successful!`, {
        variant: 'success',
      });
      navigate('/redactPost');
    } catch (err) {
      const superTokensError = err as SuperTokensError;
      if (superTokensError.isSuperTokensGeneralError === true) {
        setEmailError(superTokensError.message);
        return;
      }
      enqueueSnackbar(t`Oops! Something went wrong.`, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContainer>
      <FormTitle>{t`Login`}</FormTitle>
      <StyledTextInput
        label="Email"
        placeholderText={t`Enter email`}
        onChange={(event) => {
          setEmail(event.target.value);
          setEmailError(null);
        }}
        error={!!emailError}
        helperText={emailError}
        required
      />
      <StyledTextInput
        type="password"
        label={t`Password`}
        placeholderText={t`Enter password`}
        onChange={(event) => {
          setPassword(event.target.value);
          setPasswordError(null);
        }}
        error={!!passwordError}
        helperText={passwordError}
        required
      />
      <StyledForgotPasswordLink
        onClick={() =>
          onAuthModeChange(AuthMode.RESET_PASSWORD)
        }>{t`Forgot password?`}</StyledForgotPasswordLink>
      <Button
        label={t`Login`}
        variant="main"
        size="medium"
        onClick={handleSubmit}
        disabled={loading}
      />
      <Button
        label={t`Login via Google`}
        variant="light"
        size="medium"
        startIcon={<img src={Google} alt="Google" />}
        onClick={() => handleGoogleSignInUp(enqueueSnackbar, setLoading)}
        type="button"
        disabled={loading}
      />
      <SignIn>
        {t`Don't have an account?`}{' '}
        <SignInLink
          onClick={() =>
            onAuthModeChange(AuthMode.SIGNUP)
          }>{t`Sign up`}</SignInLink>
      </SignIn>
    </FormContainer>
  );
};

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2em;
  background: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  box-shadow: 0 4px 12px ${({theme}) => theme.colors.neutral.shade5};
  width: 27em;
  gap: 1em;
`;

const FormTitle = styled.h2`
  font-size: 2.3em;
  align-self: center;
  font-weight: 700;
  margin-bottom: 1em;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  margin-bottom: ${(props) => (props.error ? '1em' : '0.5em')};
`;

const SignIn = styled.div`
  font-size: 1em;
  font-weight: 600;
  align-self: center;
  margin: 1em 0 2em;
`;

const SignInLink = styled.a`
  color: ${({theme}) => theme.colors.primary4.shade1};
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledForgotPasswordLink = styled.a`
  color: ${({theme}) => theme.colors.primary4.shade1};
  text-decoration: underline;
  cursor: pointer;
  align-self: start;
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: 600;
`;
