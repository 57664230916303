import {useMutation, useQueryClient} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {postApi} from '../../utils/fetchApi';

export const useDeleteSavedIdea = () => {
  const queryClient = useQueryClient();

  const deleteSavedIdea = useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['savedIdeas']});
    },
    mutationFn: async (id: string) =>
      postApi(
        `/savedIdeas/delete/${id}`,
        {method: 'POST'},
        t`Unable to delete idea`,
      ),
  });

  return deleteSavedIdea;
};
