import {FunctionComponent, useCallback} from 'react';
import styled, {keyframes} from 'styled-components';
import {DownloadIcon} from './Icons/DownloadIcon';
import {t} from '@lingui/macro';
import {isTablet} from '../../utils/responsive';
import {Invoice} from '../../hooks/subscription/useInvoices';
import {format} from 'date-fns';

interface Props {
  invoices: Invoice[];
  loading: boolean;
}

export const InvoiceTable: FunctionComponent<Props> = ({invoices, loading}) => {
  const formatCurrency = useCallback(
    (amount: number, currency: string, locale: string) => {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
      }).format(amount / 100);
    },
    [],
  );

  if (loading) {
    return (
      <StyledTable>
        <thead>
          <tr>
            <th>
              <SkeletonLoader />
            </th>
            <th>
              <SkeletonLoader />
            </th>
            <th>
              <SkeletonLoader />
            </th>
            <th>
              <SkeletonLoader />
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from({length: 2}, (_, index) => (
            <tr key={index}>
              <td>
                <SkeletonLoader />
              </td>
              <td>
                <SkeletonLoader />
              </td>
              <td>
                <SkeletonLoader />
              </td>
              <td>
                <SkeletonLoader />
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    );
  }
  return (
    <StyledTable>
      <thead>
        <tr>
          <th>{t`No`}</th>
          <th>{t`Date`}</th>
          <th>{t`Amount`}</th>
          <th>{t`Actions`}</th>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice, index) => (
          <tr key={index}>
            <td>
              <InvoiceNo>{invoice.number}</InvoiceNo>
            </td>
            <td>{format(new Date(invoice.created * 1000), 'd MMM, yyyy')}</td>
            <td>
              {formatCurrency(
                invoice.total,
                invoice.currency.toUpperCase(),
                invoice.customer_address.country,
              )}
            </td>
            <td>
              <DownloadButton onClick={() => window.open(invoice.invoice_pdf)}>
                <DownloadIcon />
                <Span>{t`Download Invoice`}</Span>
              </DownloadButton>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 0.2em;
  background-color: ${({theme}) => theme.colors.neutral.shade3};
  border-radius: 2em;
  padding: 0.2em;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
  font-weight: 600;

  th {
    text-align: left;
    padding: 1.3em 0 1.3em 1em;
    color: ${({theme}) => theme.colors.primary1.shade3};
    @media (min-width: ${isTablet}) {
      width: 12.5em;
      padding: 1.3em 1em;
    }
  }

  td {
    background-color: ${({theme}) => theme.colors.neutral.shade1};
    padding: 1.3em 0 1.3em 1em;
    font-size: 1em;
    text-align: left;
    @media (min-width: ${isTablet}) {
      padding: 1.3em 1em;
    }
  }

  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 2em;
  }

  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 2em;
  }

  tr td {
    text-align: left;
  }
`;

const InvoiceNo = styled.span`
  background-color: ${({theme}) => theme.colors.primary1.shade1};
  color: ${({theme}) => theme.colors.primary1.shade3};
  padding: 0.3em 1em;
  border-radius: 0.5em;
  font-size: 0.8em;
`;

const DownloadButton = styled.span`
  color: ${({theme}) => theme.colors.primary4.shade1};
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1em;
  cursor: pointer;
`;
const Span = styled.span`
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;
const SkeletonLoader = styled.div`
  width: 70%;
  height: 1em;
  border-radius: 0.5em;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
`;
