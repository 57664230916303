import {t} from '@lingui/macro';
import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import styled from 'styled-components';
import {isTablet} from '../../../utils/responsive';

interface Props {
  setfinalStep: Dispatch<SetStateAction<boolean>>;
}

export const ContentURLToolTipFinalStep: FunctionComponent<Props> = ({
  setfinalStep,
}) => {
  return (
    <Relative>
      <HelpContainer>
        <Head>{t`The topic you want to talk about.`}</Head>
        <Head>{t`It can be a specific element from the content you want to talk about or the global idea.`}</Head>
        <ExampleContainer>
          <Example>{t`Examples:`}</Example>
          <div>
            <ExampleText>{t`- "The story of Elon Musk"`}</ExampleText>
            <ExampleText>{t`- "How Elon Musk sold his first startup"`}</ExampleText>
            <ExampleText>{t`- "What Elon Musk said when he sold his first startup"`}</ExampleText>
          </div>
        </ExampleContainer>
        <NextContainer>
          <Row>
            <NextNotActive />
            <NextActive />
          </Row>
          <NextText
            onClick={() => {
              setfinalStep(false);
            }}>{t`Okay, I got it`}</NextText>
        </NextContainer>
      </HelpContainer>
      <Triangle />
    </Relative>
  );
};
const Relative = styled.div`
  position: absolute;
  right: 0.3em;
  top: 3.1em;
  background-color: ${({theme}) => theme.colors.neutral.shade11};
  border-radius: 1em;
  z-index: 100;
  max-width: 25em;
`;
const HelpContainer = styled.div`
  padding: 1.25em;
  display: flex;
  flex-direction: column;
  gap: 1.25em;
  font-size: 0.9em;
`;
const Head = styled.span`
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.primary1.shade2};
`;
const ExampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  @media (max-width: ${isTablet}) {
    gap: 0.5em;
  }
`;
const Example = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade6};
  letter-spacing: 0.03125em;
`;
const ExampleText = styled.p`
  margin: 0;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.neutral.shade1};
  font-weight: 600;
  line-height: 1.6em;
`;
const NextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const NextActive = styled.div`
  height: 0.5em;
  width: 0.5em;
  background-color: ${({theme}) => theme.colors.primary1.shade2};
  border-radius: 50%;
`;
const NextNotActive = styled.div`
  height: 0.5em;
  width: 0.5em;
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  border-radius: 50%;
  opacity: 0.2;
`;
const NextText = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade1};
  letter-spacing: 0.03125em;
  cursor: pointer;
`;
const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 7px solid ${({theme}) => theme.colors.neutral.shade11};
  position: absolute;
  right: 9px;
  top: -6px;
`;
