export const isValidLinkedInProfileUrl = (url: string) => {
  const pattern = new RegExp(
    '^https?:\\/\\/([\\w\\-])+\\.linkedin\\.com\\/in\\/.+$',
  );
  return pattern.test(url);
};

export const isValidLinkedInProfileOrCompanyUrl = (url: string) => {
  const pattern = new RegExp(
    '^https?:\\/\\/([\\w\\-])+\\.linkedin\\.com\\/(in|company)\\/.+$',
  );
  return pattern.test(url);
};
