import {t} from '@lingui/macro';
import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import styled from 'styled-components';
import {CloseButton} from '../../atoms/CloseButton';
import TrashIcon from '../../atoms/Icons/TrashIcon';
import {Button} from '../../atoms/Button';
import {useTheme} from '../../theme/theme';
import {useDeleteSavedIdea} from '../../../hooks/savedPostsAndIdeas/useDeleteSavedIdea';
import {SavedIdeaInterface} from '../../../pages/findIdea/components/MyIdeasTab';

interface Props {
  setShowDeletePopup: Dispatch<SetStateAction<boolean>>;
  idea: SavedIdeaInterface;
}

export const DeleteIdeaPopup: FunctionComponent<Props> = ({
  setShowDeletePopup,
  idea,
}) => {
  const theme = useTheme();
  const deleteSavedIdea = useDeleteSavedIdea();
  return (
    <DeletePopupContainer>
      <PopupContainer>
        <CloseButtonWrapper onClick={() => setShowDeletePopup(false)}>
          <CloseButton />
        </CloseButtonWrapper>
        <Container>
          <TrashWrapper>
            <TrashIcon
              color={theme.colors.primary2.shade3}
              width={28}
              height={28}
            />
          </TrashWrapper>
          <StrongText>{t`Are you sure you want to delete this idea?`}</StrongText>
          <Text>{t`This action cannot be undone.`}</Text>
          <Row>
            <Button
              onClick={() => setShowDeletePopup(false)}
              label={t`Cancel`}
              size="big"
              variant="light"
              fullWidth
            />
            <Button
              onClick={() => {
                deleteSavedIdea.mutate(idea.id);
                setShowDeletePopup(false);
              }}
              label={t`Delete Now`}
              size="big"
              variant="main"
              fullWidth
            />
          </Row>
        </Container>
      </PopupContainer>
    </DeletePopupContainer>
  );
};
const DeletePopupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000099;
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 60;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`;
const TrashWrapper = styled.div`
  height: 6em;
  width: 6em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.primary2.shade4};
  border-radius: 50%;
`;
const StrongText = styled.span`
  font-weight: 700;
  font-size: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  text-align: center;
  width: 14em;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 2em;
`;
const Text = styled.span`
  font-size: 1.125em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade16};
`;
const PopupContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  padding: 2.5em;
  position: relative;
  min-width: 20em;
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
`;
