import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {PostGeneratorIntro} from './PostGeneratorIntro';
import {ChooseUserProfile} from '../../onboarding-v2/components/ChooseUserProfile';
import {t} from '@lingui/macro';
import {useCreateProfile} from '../../../hooks/profile/createProfile/useCreateProfile.ts';
import {ChooseStyleUrl} from '../../onboarding-v2/components/ChooseStyleUrl.tsx';
import {useUpdateProfile} from '../../../hooks/profile/updateProfile/useUpdateProfile.ts';
import {useLastPostLanguage} from '../../../hooks/redactPost/useLastPostLanguage.ts';
import {useSavedProfiles} from '../../../hooks/redactPostNew/useSavedProfiles.tsx';

export const CreateProfilePopup: React.FC = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isChooseProfile, setIsChooseProfile] = useState<boolean>(false);
  const [isChooseStyleUrl, setIsChooseStyleUrl] = useState<boolean>(false);
  const {
    createProfile,
    isLoading: isLoadingCreateProfile,
    createdProfile,
  } = useCreateProfile();

  const {
    updateProfile,
    isLoading: isLoadingUpdateProfile,
    isSuccess: isSuccessUpdateProfile,
  } = useUpdateProfile();

  const {savedProfiles, isSuccess: isSuccessLoadingProfiles} =
    useSavedProfiles();

  const language = useLastPostLanguage();

  useEffect(() => {
    if (savedProfiles.length === 0 && isSuccessLoadingProfiles) {
      setShowPopup(true);
      return;
    }
  }, [isSuccessLoadingProfiles, savedProfiles]);

  useEffect(() => {
    if (createdProfile?.status === 'OK') {
      setShowPopup(false);
      return;
    }
    if (createdProfile?.status === 'NOT_ENOUGH_POSTS') {
      setIsChooseStyleUrl(true);
      return;
    }
  }, [createdProfile, setShowPopup]);

  useEffect(() => {
    if (isSuccessUpdateProfile) {
      setIsChooseStyleUrl(false);
      setIsChooseProfile(false);
      setShowPopup(false);
    }
  }, [isSuccessUpdateProfile, setShowPopup]);

  if (!showPopup) {
    return null;
  }

  return (
    <ParentContainer>
      <Container>
        <ScrollableContent>
          <Wrapper>
            {isChooseStyleUrl ? (
              <>
                <TopWrapper>
                  <Header>{t`Sorry, but your profile is missing enough posts to define your style`}</Header>
                  <Title>{t`Instead please choose the profile of a LinkedIn creator that you like.`}</Title>
                </TopWrapper>
                <ChooseStyleUrl
                  isLoading={isLoadingUpdateProfile}
                  setStyleUrl={async (styleUrl) => {
                    await updateProfile({
                      ...createdProfile!.profile!,
                      linkedInStyleUrl: styleUrl,
                    });
                  }}
                  isRedactPost={true}
                />
              </>
            ) : isChooseProfile ? (
              <>
                <TopWrapper>
                  <Header>{t`Create your first profile`}</Header>
                  <Title>{t`In order to personalise your experience we need your LinkedIn profile url.`}</Title>
                  <Subtitle>{t`If you create posts for multiple clients don't worry, you will be able to create more profiles after.`}</Subtitle>
                </TopWrapper>
                <ChooseUserProfile
                  onCreateProfile={async (profileUrl: string) => {
                    await createProfile({
                      linkedInContextUrl: profileUrl,
                      language: language,
                    });
                  }}
                  isLoading={isLoadingCreateProfile}
                  isRedactPost={true}
                />
              </>
            ) : (
              <PostGeneratorIntro
                onCreateProfile={() => setIsChooseProfile(true)}
              />
            )}
          </Wrapper>
        </ScrollableContent>
      </Container>
    </ParentContainer>
  );
};

const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 60;
`;

const Container = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  position: relative;
  width: 100%;
  max-width: 80em;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  max-height: calc(90vh - 4em);
  padding: 2em;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  width: 100%;
`;

const Header = styled.h2`
  font-size: 2.5em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
  text-align: center;
  margin-bottom: 0.5em;
`;

const Title = styled.p`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
  margin: 0;
`;

const Subtitle = styled.p`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1em;
  font-weight: 300;
  text-align: center;
  margin: 0;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 100%;
`;
