import {i18n} from '@lingui/core';
import {messages as fr} from './locales/fr.po';
import {messages as en} from './locales/en.po';
import {Language} from './hooks/postLang/useDefaultLanguage.ts';

export const getBrowserLocale = () =>
  navigator.language.split('-')[0].toLowerCase() === 'fr' ? 'fr' : 'en';

export async function activateLocale() {
  const locale = getBrowserLocale();
  const langFromLocalStorage = localStorage.getItem('language');
  i18n.load('fr', fr);
  i18n.load('en', en);

  i18n.activate(langFromLocalStorage ? langFromLocalStorage : locale);
}

export const switchLocale = (language: Language) => {
  const locale = language.countryCode === 'fr' ? 'fr' : 'en';
  i18n.activate(locale);
  localStorage.setItem('language', locale);
};
