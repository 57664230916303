import {FunctionComponent, useCallback} from 'react';
import styled from 'styled-components';
import {Button} from '../atoms/Button';
import {t} from '@lingui/macro';
import {RedactedPost} from '../../hooks/redactPost/useRedactPostMutation';
import {useTracking} from '../../hooks/useTracking';
import {postApi} from '../../utils/fetchApi';
import {OnboardingSteps} from '../../pages/onboarding/Onboarding';
import {useQueryClient} from '@tanstack/react-query';
import {ChatWithRedactAIChatArea} from './ChatWithRedactAIChatArea';

interface Props {
  posts: RedactedPost[];
  stepState: OnboardingSteps;
}

export const ChatWithRedactAI: FunctionComponent<Props> = ({
  posts,
  stepState,
}) => {
  const {trackEvent} = useTracking();
  const queryClient = useQueryClient();

  const onFinishOnboarding = useCallback(async () => {
    if (!stepState?.userReferralSource) {
      return;
    }
    await postApi('/user/hasFinishedOnboarding', {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        source: stepState?.userReferralSource.source,
        details: stepState?.userReferralSource.details,
      }),
    });
    await queryClient.invalidateQueries({queryKey: ['me']});
  }, [queryClient, stepState?.userReferralSource]);
  return (
    <Parent>
      <Container>
        <ChatWithRedactAIChatArea isOnboarding={true} posts={posts} />
      </Container>
      <StyledButton
        onClick={async () => {
          trackEvent('Onboarding - Click - Finish tutorial');
          await onFinishOnboarding();
        }}
        label={t`Finish tutorial`}
        size="medium"
        variant="main"
      />
    </Parent>
  );
};

const Parent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`;
const Container = styled.div`
  border-radius: 2em;
  border: 1px solid ${({theme}) => theme.colors.neutral.shade6};
  overflow: hidden;
  max-width: min(90%, 1400px);
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const StyledButton = styled(Button)`
  width: 15.125em;
`;
