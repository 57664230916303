import {DefaultTheme} from 'styled-components';

export const theme: DefaultTheme = {
  fontFamily: 'Urbanist, sans-serif;',
  colors: {
    primary1: {
      shade1: '#FFF0DD',
      shade2: '#FFAC3B',
      shade3: '#B7761E',
      shade4: '#FFF7EC',
      shade5: '#FBF2E6',
      shade6: '#FFE4C0',
      shade7: '#F4E5CF',
      shade8: '#FFE6C3',
    },
    primary2: {
      shade1: '#FFECE9',
      shade2: '#DD7373',
      shade3: '#D92020',
      shade4: '#FFE4E4',
    },
    primary3: {
      shade1: '#EFFFF8',
      shade2: '#11B248',
    },
    primary4: {
      shade1: '#2048D7',
      shade2: '#0766C2',
    },
    neutral: {
      shade1: '#FFFFFF',
      shade2: '#FFFCF5',
      shade3: '#FBF7EF',
      shade4: '#F3F1E6',
      shade5: '#E7E3DA',
      shade6: '#D6D2C9',
      shade7: '#BEBBB2',
      shade8: '#9F9C95',
      shade9: '#797670',
      shade10: '#4B4A46',
      shade11: '#1C1B1A',
      shade12: '#252525',
      shade13: '#F7F6F2',
      shade14: '#313131',
      shade15: '#454545',
      shade16: '#9B9B9B',
      shade17: '#333333',
      shade18: '#D9D9D9',
    },
  },
};

type ThemeRecord<T> = Record<string, T>;

export const useTheme = () => {
  return theme as ThemeRecord<ThemeRecord<ThemeRecord<string>>>;
};
