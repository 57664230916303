import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Radio} from '@mui/material';

interface Props {
  isSelected?: boolean;
  size?: 'medium' | 'big';
  label?: string;
  withLabel?: boolean;
}

export const RadioButton: FunctionComponent<Props> = ({
  isSelected,
  size,
  label,
  withLabel,
}) => {
  return (
    <RadioContainer>
      <StyledRadioWrapper size={size}>
        <StyledRadio checked={isSelected} />
      </StyledRadioWrapper>
      {withLabel && <RadioLabel size={size}>{label}</RadioLabel>}
    </RadioContainer>
  );
};

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4em;
`;

const RadioLabel = styled.span<{size?: 'medium' | 'big'}>`
  font-size: 1em;
  font-weight: ${(props) => (props.size === 'medium' ? '500' : '600')};
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const StyledRadioWrapper = styled.div<{size?: 'medium' | 'big'}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.size === 'medium' ? '1em' : '1.7em')};
  height: ${(props) => (props.size === 'medium' ? '1em' : '1.7em')};
`;

const StyledRadio = styled(Radio)`
  padding: 0;

  &.MuiRadio-root {
    color: ${({theme}) => theme.colors.neutral.shade5};
  }

  &.Mui-checked {
    color: ${({theme}) => theme.colors.primary1.shade2};
    background-color: ${({theme}) => theme.colors.primary1.shade1};
  }

  .MuiSvgIcon-root {
    width: 100%;
    height: 100%;
  }
`;
