import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  className?: string;
}

const UrlIcon: FunctionComponent<Props> = ({
  width = 24,
  height = 24,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.07 14.2416C18.78 14.5316 18.32 14.5316 18.04 14.2416C17.75 13.9516 17.75 13.4916 18.04 13.2116C20.04 11.2116 20.04 7.96156 18.04 5.97156C16.04 3.98156 12.79 3.97156 10.8 5.97156C8.81003 7.97156 8.80003 11.2216 10.8 13.2116C11.09 13.5016 11.09 13.9616 10.8 14.2416C10.51 14.5316 10.05 14.5316 9.77003 14.2416C7.20003 11.6716 7.20003 7.49156 9.77003 4.93156C12.34 2.37156 16.52 2.36156 19.08 4.93156C21.64 7.50156 21.64 11.6716 19.07 14.2416Z"
        fill="#FFAC3B"
      />
      <path
        opacity="0.4"
        d="M19.07 4.93141C21.64 7.50141 21.64 11.6714 19.07 14.2414C18.19 15.1214 17.11 15.7014 15.98 15.9814C16.51 13.8214 15.93 11.4514 14.24 9.76141C12.55 8.07141 10.18 7.49141 8.02002 8.02141C8.30002 6.89141 8.87002 5.81141 9.76002 4.93141C12.33 2.36141 16.5 2.36141 19.07 4.93141Z"
        fill="#FFAC3B"
      />
      <path
        d="M4.92997 9.76047C5.21997 9.47047 5.67997 9.47047 5.95997 9.76047C6.24997 10.0505 6.24997 10.5105 5.95997 10.7905C3.95997 12.7905 3.95997 16.0405 5.95997 18.0305C7.95997 20.0205 11.21 20.0305 13.2 18.0305C15.19 16.0305 15.2 12.7805 13.2 10.7905C12.91 10.5005 12.91 10.0405 13.2 9.76047C13.49 9.47047 13.95 9.47047 14.23 9.76047C16.8 12.3305 16.8 16.5105 14.23 19.0705C11.66 21.6305 7.47997 21.6405 4.91997 19.0705C2.35997 16.5005 2.35997 12.3305 4.92997 9.76047Z"
        fill="#FFAC3B"
      />
      <path
        opacity="0.5"
        d="M14.2399 9.76183C15.9299 11.4518 16.5099 13.8218 15.9799 15.9818C15.6999 17.1118 15.1199 18.1918 14.2399 19.0718C11.6699 21.6418 7.49994 21.6418 4.92994 19.0718C2.35994 16.5018 2.35994 12.3318 4.92994 9.76183C5.80994 8.87183 6.88994 8.30183 8.01994 8.02183C10.1799 7.49183 12.5499 8.07183 14.2399 9.76183Z"
        fill="#FFAC3B"
      />
    </svg>
  );
};

export default UrlIcon;
