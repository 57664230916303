import {FunctionComponent} from 'react';

interface Props {
  size?: number;
}

export const DesktopIcon: FunctionComponent<Props> = ({size = 16}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M14.6454 4.27203V8.60536H1.33203V4.27203C1.33203 2.65203 2.65203 1.33203 4.27203 1.33203H11.7054C13.3254 1.33203 14.6454 2.65203 14.6454 4.27203Z"
        fill="#FFAC3B"
      />
      <path
        d="M1.33203 8.61328V8.74661C1.33203 10.3733 2.65203 11.6866 4.27203 11.6866H6.83203C7.1987 11.6866 7.4987 11.9866 7.4987 12.3533V12.9999C7.4987 13.3666 7.1987 13.6666 6.83203 13.6666H5.2187C4.94536 13.6666 4.7187 13.8933 4.7187 14.1666C4.7187 14.4399 4.9387 14.6666 5.2187 14.6666H10.7854C11.0587 14.6666 11.2854 14.4399 11.2854 14.1666C11.2854 13.8933 11.0587 13.6666 10.7854 13.6666H9.17203C8.80537 13.6666 8.50536 13.3666 8.50536 12.9999V12.3533C8.50536 11.9866 8.80537 11.6866 9.17203 11.6866H11.712C13.3387 11.6866 14.652 10.3666 14.652 8.74661V8.61328H1.33203Z"
        fill="#FFAC3B"
      />
    </svg>
  );
};
