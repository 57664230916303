import {FunctionComponent} from 'react';
import {UserContext} from './user.context';
import {useUserContext} from './useUserContext';

interface Props {
  children?: React.ReactNode;
}

export const UserContextProvider: FunctionComponent<Props> = ({children}) => {
  const userContext = useUserContext();
  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};
