import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Modal} from './Modal';
import {t} from '@lingui/macro';
import {useTheme} from '../../theme/theme';
import TrashIcon from '../../atoms/Icons/TrashIcon';
import {Button} from '../../atoms/Button';
import {isTablet} from '../../../utils/responsive';

interface Props {
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteAccountPopupConfirmation: FunctionComponent<Props> = ({
  onClose,
  onDelete,
}) => {
  const theme = useTheme();
  return (
    <Modal onClose={onClose}>
      <Container>
        <TrashWrapper>
          <TrashIcon
            color={theme.colors.primary2.shade3}
            width={28}
            height={28}
          />
        </TrashWrapper>
        <StrongText>{t`Are you sure you want to delete your account?`}</StrongText>
        <Div>
          <GrayText>{t`This action cannot be undone.`}</GrayText>
          <GrayText>{t`All your data will be deleted, including your saved posts and ideas.`}</GrayText>
        </Div>
        <Row>
          <Button
            label={t`Cancel`}
            size="big"
            variant="light"
            fullWidth
            onClick={onClose}
          />
          <Button
            onClick={onDelete}
            label={t`Delete Now`}
            size="big"
            variant="main"
            fullWidth
          />
        </Row>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  width: 30em;
  @media (max-width: ${isTablet}) {
    width: 23em;
  }
`;
const TrashWrapper = styled.div`
  height: 6em;
  width: 6em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.primary2.shade4};
  border-radius: 50%;
`;
const StrongText = styled.span`
  font-weight: 700;
  font-size: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  text-align: center;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 2em;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1em;
  }
`;
const GrayText = styled.span`
  font-weight: 600;
  font-size: 1em;
  color: ${({theme}) => theme.colors.neutral.shade9};
  text-align: center;
  letter-spacing: 0.0125em;
`;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3em;
`;
