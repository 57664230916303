import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import GeneratingPostIcon from '../../../components/atoms/Icons/GeneratingPostIcon';
import {RedactedPost} from '../../../hooks/redactPost/useRedactPostMutation';
import {RedactContentSource} from './RedactPostContent';
import {RedactStyleSource} from './RedactPostStyle';
import {RedactPostChatWithAi} from './RedactPostChatWithAi';
import {LoadingLayout} from './LoadingLayout';
import {Profile} from '../../../hooks/profile/profile.ts';
import {useLoadingProgress} from '../../../hooks/redactPostNew/useLoadingProgress.ts';

interface Props {
  posts: RedactedPost[];
  contentSource: RedactContentSource;
  styleSource?: RedactStyleSource;
  onChangeInputs: () => void;
  onNewPost: () => void;
  isLoading: boolean;
  profile?: Profile;
}

export const RedactPostGeneration: FunctionComponent<Props> = ({
  posts,
  onChangeInputs,
  isLoading,
  onNewPost,
  profile,
}) => {
  const {loading, progress} = useLoadingProgress(isLoading, posts);

  return (
    <Container>
      <ContentWrapper>
        {loading && (
          <Col>
            <Top>
              <Header>{t`Generating posts...`}</Header>
            </Top>
            <GeneratingPostIcon />
          </Col>
        )}
        {loading ? (
          <LoadingContent>
            <LoadingLayout progress={progress} isLoading={isLoading} />
          </LoadingContent>
        ) : (
          <RedactPostChatWithAi
            posts={posts}
            onChangeInputs={onChangeInputs}
            onNewPost={onNewPost}
            profile={profile}
          />
        )}
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 1em;
  width: 100%;
`;

const Header = styled.span`
  font-size: 1.7em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
