import {SnackbarProvider as NotistackSnackbarProvider} from 'notistack';
import {FunctionComponent} from 'react';
import {Toast} from '../../components/atoms/Toast';

export const SnackbarProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({children}) => {
  return (
    <NotistackSnackbarProvider
      Components={{
        error: Toast,
        success: Toast,
        info: Toast,
      }}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}>
      {children}
    </NotistackSnackbarProvider>
  );
};
