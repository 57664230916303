import {useQuery} from '@tanstack/react-query';
import {fetchApi} from '../../utils/fetchApi';
import {useContext, useMemo} from 'react';
import {UserContext} from '../../common/UserContext/user.context';

export interface Invoice {
  number: string;
  created: number;
  total: number;
  currency: string;
  invoice_pdf: string;
  customer_address: {country: string};
}

export const useInvoices = () => {
  const {me} = useContext(UserContext);
  const {data, isLoading} = useQuery({
    queryKey: ['invoices'],
    queryFn: () => fetchApi('/subscription/invoices'),
    enabled: !!me,
  });
  const invoices = useMemo(() => data || [], [data]);

  return {
    invoices,
    loading: isLoading,
  };
};
