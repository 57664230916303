import {useMutation, useQueryClient} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {postApi} from '../../utils/fetchApi';

const likeSavedPostMock = async (data: {id: string; like: boolean}) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        ok: true,
        status: 200,
        json: async () => ({success: true, like: data.like}),
      });
    }, 500);
  });
};

export const useLikeSavedPost = () => {
  const queryClient = useQueryClient();
  const likeSavedPost = useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['savedPosts']});
    },
    mutationFn: async (data: {id: string; like: boolean}) => {
      if (
        import.meta.env.VITE_NODE_ENV === 'development' &&
        import.meta.env.VITE_WITH_AI !== 'true'
      ) {
        return likeSavedPostMock(data);
      }

      const response = await postApi(
        `/savedPosts/like/${data.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({like: data.like}),
        },
        t`Unable to like the post`,
      );

      if (response.ok) {
        return response;
      }

      throw new Error(t`Unable to like the post`);
    },
  });

  return likeSavedPost;
};
