import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import styled from 'styled-components';
import {isTablet} from '../../../utils/responsive';
import {MyStyle} from './MyStyle';
import {CreatorStyle} from './CreatorStyle';
import {RedactaiStyle} from './RedactaiStyle';
import {StyleSource} from '../../../pages/onboarding/components/ChooseStyleSource';
import {SavedPostTone} from '../../../hooks/savedPost/savedPost.types';
import {Profile} from '../../../hooks/profile/profile.ts';

interface Props {
  profile?: Profile;
  chooseStyleSource: (styleSource: StyleSource) => void;
  setPostTone: Dispatch<SetStateAction<SavedPostTone>>;
  hasEnoughPosts: boolean;
}

export const OnboardingStyleChoiceCards: FunctionComponent<Props> = ({
  profile,
  chooseStyleSource,
  setPostTone,
  hasEnoughPosts,
}) => {
  return (
    <CardsDesktop>
      {profile && (
        <MyStyle
          hasEnoughPosts={hasEnoughPosts}
          profile={profile}
          onClick={() => {
            chooseStyleSource(StyleSource.PROFILE_URL);
          }}
        />
      )}
      <CreatorStyle
        onClick={() => {
          chooseStyleSource(StyleSource.OTHERS_PROFILE_URL);
        }}
      />
      <RedactaiStyle
        setPostTone={(tone) => {
          setPostTone(tone);
          chooseStyleSource(StyleSource.POST_TYPE);
        }}
      />
    </CardsDesktop>
  );
};

const CardsDesktop = styled.div`
  display: flex;
  gap: 1em;
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
