import {FunctionComponent, ReactNode} from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';

interface Props {
  label: string;
  isActive: boolean;
  startIcon?: ReactNode;
  onClick?: () => void;
}

export const MenuButton: FunctionComponent<Props> = ({
  label,
  isActive,
  startIcon,
  onClick,
}) => {
  return (
    <StyledButton variant="contained" $isActive={isActive} onClick={onClick}>
      {startIcon && startIcon}
      {label}
    </StyledButton>
  );
};

const StyledButton = styled(Button)<{$isActive: boolean}>`
  && {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1em;
    font-size: 0.95em;
    font-weight: 700;
    padding: 0.75em 1.25em;
    border-radius: 1em;
    text-transform: none;
    font-family: ${({theme}) => theme.fontFamily};
    color: ${({theme, $isActive}) =>
      $isActive ? theme.colors.neutral.shade1 : theme.colors.neutral.shade16};
    background-color: ${({theme, $isActive}) =>
      $isActive ? theme.colors.neutral.shade15 : 'transparent'};
    box-shadow: none;
    width: 100%;
  }
  &&:hover {
    background-color: ${({theme}) => theme.colors.neutral.shade15};
  }
`;
