import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  onClick?: () => void;
}

const DislikeIcon: FunctionComponent<Props> = ({
  width = 22,
  height = 22,
  color = '#1C1B1A',
  onClick,
}) => {
  return (
    <svg
      onClick={onClick}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5197 5.65039L13.4197 3.25039C13.0197 2.85039 12.1197 2.65039 11.5197 2.65039H7.71973C6.51973 2.65039 5.21973 3.55039 4.91973 4.75039L2.51973 12.0504C2.01973 13.4504 2.91973 14.6504 4.41973 14.6504H8.41973C9.01973 14.6504 9.51973 15.1504 9.41973 15.8504L8.91973 19.0504C8.71973 19.9504 9.31973 20.9504 10.2197 21.2504C11.0197 21.5504 12.0197 21.1504 12.4197 20.5504L16.5197 14.4504"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M21.6201 5.65V15.45C21.6201 16.85 21.0201 17.35 19.6201 17.35H18.6201C17.2201 17.35 16.6201 16.85 16.6201 15.45V5.65C16.6201 4.25 17.2201 3.75 18.6201 3.75H19.6201C21.0201 3.75 21.6201 4.25 21.6201 5.65Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DislikeIcon;
