import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import HelpRoundedIcon from '../../../components/atoms/Icons/HelpRoundedIcon';
import {LanguageSelect} from '../../../components/atoms/LanguageSelect';
import {t} from '@lingui/macro';
import styled from 'styled-components';
import EditIcon from '../../../components/atoms/Icons/EditIcon';
import LinkIcon from '../../../components/atoms/Icons/LinkIcon';
import {TextInput} from '../../../components/atoms/TextInput';
import {isTablet} from '../../../utils/responsive';
import ArrowLeftIcon from '../../../components/atoms/Icons/ArrowLeftIcon';
import {Button} from '../../../components/atoms/Button';
import {useFindIdeas} from '../../../hooks/findIdeas/useFindIdeas';
import {GeneratedIdea} from '../../../components/molecules/GeneratedIdea';
import {SavedPostLanguage} from '../../../hooks/savedPost/savedPost.types';
import {useTracking} from '../../../hooks/useTracking';
import {UserContext} from '../../../common/UserContext/user.context';
import {useSnackbar} from 'notistack';
import {NoIdeasToolTip} from './NoIdeasToolTip';

interface Props {
  onGoBack: () => void;
  onChoosenIdea?: (subject: string, ideas: string) => void;
  postLang: SavedPostLanguage;
  setPostLang: React.Dispatch<React.SetStateAction<SavedPostLanguage>>;
}

export const GenerateFromNoIdeas: FunctionComponent<Props> = ({
  onGoBack,
  onChoosenIdea,
  postLang,
  setPostLang,
}) => {
  const findIdeas = useFindIdeas(() => {});
  const {me} = useContext(UserContext);
  const {trackEvent} = useTracking();
  const [postTopic, setPostTopic] = useState('');
  const [postTopicError, setPostTopicError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const {enqueueSnackbar} = useSnackbar();
  const [isToolTip, setIsToolTip] = useState<boolean>(false);
  const [generatedIdeas, setGeneratedIdeas] = useState<
    {
      subject: string;
      ideas: string[];
    }[]
  >([]);
  useEffect(() => {
    if (!findIdeas.isError) {
      return;
    }
    const error = findIdeas.error as Error;
    enqueueSnackbar(error.message, {
      variant: 'error',
    });
  }, [findIdeas.error, findIdeas.isError, enqueueSnackbar]);

  const onGenerateIdea = useCallback(() => {
    setIsSubmitted(true);
    if (!postTopic) {
      setPostTopicError(t`Please enter a post subject`);
      return;
    }
    setPostTopicError('');
    setIsSubmitted(false);
    if (me && !me.hasFinishedOnboarding) {
      trackEvent('Onboarding - Click - Find an idea');
    }
    setLoading(true);
    findIdeas.mutate({
      subject: postTopic,
      language: postLang,
    });
  }, [findIdeas, postLang, postTopic, me, trackEvent]);

  useEffect(() => {
    void (async () => {
      if (findIdeas.isError || !findIdeas.isSuccess) {
        setLoading(false);
        return;
      }
      const data = await findIdeas.data.json();
      setGeneratedIdeas(data);
      setLoading(false);
    })();
  }, [findIdeas.data, findIdeas.isError, findIdeas.isSuccess]);
  const suggestions: string[] = [t`SEO`, t`Hiring`];
  if (loading) {
    return (
      <Container>
        <SubHeader>{t`Please choose one of the three ideas`}</SubHeader>
        <IdeasContainer>
          <GeneratedIdea isLoading={true} />
          <GeneratedIdea isLoading={true} />
          <GeneratedIdea isLoading={true} />
        </IdeasContainer>
      </Container>
    );
  }
  if (generatedIdeas.length > 0) {
    return (
      <Container>
        <SubHeader>{t`Please choose one of the three ideas`}</SubHeader>
        <IdeasContainer>
          {generatedIdeas.map((generatedIdea, index) => (
            <GeneratedIdea
              key={index}
              idea={generatedIdea}
              onClick={() => {
                if (onChoosenIdea) {
                  onChoosenIdea(
                    generatedIdea.subject,
                    '- ' + generatedIdea.ideas.join('\n- '),
                  );
                  return;
                }
              }}
            />
          ))}
        </IdeasContainer>
      </Container>
    );
  }
  return (
    <Container>
      <SubHeader>
        {t`Let's do this,`} {me?.firstName}!{' '}
        <StrongText>{t`It will only take 2 mins`}</StrongText> 🚀
      </SubHeader>
      <Bottom>
        <ContentContainer>
          <Between>
            <P>{t`content`}</P>
            <Div>
              <Type>
                <StyledLinkIcon />
                {t`I Don't have ideas`}
              </Type>
              <Change onClick={onGoBack}>
                <StyledEditIcon width={20} height={20} />
                {t`Change`}
              </Change>
            </Div>
          </Between>
          <ChooseThemeContainer>
            <TopicAndLangContainer>
              <TextInputContainer>
                <StyledTextInput
                  label={t`Topic or theme`}
                  placeholderText={t`Topic or theme you want to talk about. Example: "Digital Marketing" or "SEO".`}
                  value={postTopic}
                  onChange={(event) => setPostTopic(event.target.value)}
                  error={!postTopic && isSubmitted}
                  helperText={!postTopic && postTopicError}
                />
                <StyledHelpRoundedIcon onClick={() => setIsToolTip(!isToolTip)}>
                  <StyHelpRoundedIcon />
                </StyledHelpRoundedIcon>
                {isToolTip && <NoIdeasToolTip setIsToolTip={setIsToolTip} />}
              </TextInputContainer>
              <StyledLanguageSelect {...{postLang, setPostLang}} />
            </TopicAndLangContainer>
            <SuggestionsContainer
              $postTopic={postTopic}
              $postTopicError={postTopicError}>
              <Quest>{t`Do you want suggestions on ideas?`}</Quest>
              <Suggestions>
                {suggestions.map((suggestion, index) => (
                  <Suggestion
                    key={index}
                    onClick={() => {
                      setPostTopic(suggestion);
                    }}>
                    {suggestion}
                  </Suggestion>
                ))}
              </Suggestions>
            </SuggestionsContainer>
          </ChooseThemeContainer>
        </ContentContainer>
        <Row>
          <Button
            onClick={onGoBack}
            label={t`Go back`}
            size="medium"
            variant="light"
            startIcon={<StyledArrowLeftIcon />}
          />
          <Button
            onClick={onGenerateIdea}
            label={t`Find my ideas`}
            size="medium"
            variant="main"
          />
        </Row>
      </Bottom>
    </Container>
  );
};

const ContentContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;
const Between = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    align-items: start;
    gap: 1em;
  }
`;
const P = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  @media (max-width: ${isTablet}) {
    width: 100%;
    justify-content: space-between;
  }
`;
const Type = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 1.125em;
  font-weight: 600;
`;
const Change = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.primary4.shade1};
  font-size: 0.875em;
  font-weight: 600;
  cursor: pointer;
`;
const ChooseThemeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const TopicAndLangContainer = styled.div`
  display: flex;
  gap: 1em;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    gap: 1.5em;
  }
`;
const TextInputContainer = styled.div`
  flex: 1;
  position: relative;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;
const StyledTextInput = styled(TextInput)`
  width: 100%;
`;
const SuggestionsContainer = styled.div<{
  $postTopicError: string;
  $postTopic: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  @media (min-width: ${isTablet}) {
    margin-top: ${({$postTopicError, $postTopic}) =>
      $postTopicError && !$postTopic && '0.7em'};
  }
`;
const Quest = styled.span`
  color: ${({theme}) => theme.colors.primary4.shade10};
  font-size: 0.75em;
  letter-spacing: 0.0125em;
`;
const Suggestions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const Suggestion = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade3};
  border-radius: 3.125em;
  padding: 0.5em 0.75em;
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 0.75em;
  letter-spacing: 0.0125em;
  cursor: pointer;
`;
const StyledHelpRoundedIcon = styled.div`
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  background-color: white;
  height: calc(100% - 4px);
  padding-left: 6px;
`;
const StyHelpRoundedIcon = styled(HelpRoundedIcon)`
  cursor: pointer;
  @media (max-width: ${isTablet}) {
    height: 1.25em;
    width: 1.25em;
  }
`;
const StyledLinkIcon = styled(LinkIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
const StyledEditIcon = styled(EditIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;
const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
const IdeasContainer = styled.div`
  display: flex;
  gap: 1.5em;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
  }
`;
const StyledLanguageSelect = styled(LanguageSelect)`
  @media (max-width: ${isTablet}) {
    width: 100%;
    box-sizing: border-box;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 4em;
  margin-top: -2.5em;
`;
const SubHeader = styled.span`
  margin-top: 3em;
  font-size: 1.75em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const StrongText = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 600;
`;
