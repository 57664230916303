import {useMutation} from '@tanstack/react-query';
import {t} from '@lingui/macro';

export const useProfilePostsCheck = () => {
  const postsCheckMutation = useMutation({
    mutationFn: async (linkedInStyleUrl: string): Promise<boolean> => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/profile/hasEnoughPostsForStyle?linkedInStyleUrl=${encodeURIComponent(linkedInStyleUrl)}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        },
      );
      if (!response.ok) {
        throw new Error(t`Failed to check profile status`);
      }
      const data = await response.json();
      return data.hasEnoughPosts;
    },
  });

  return {
    checkProfilePosts: postsCheckMutation,
    hasEnoughPosts: postsCheckMutation.data as boolean | undefined,
    isLoading: postsCheckMutation.isPending,
    isSuccess: postsCheckMutation.isSuccess,
  };
};
