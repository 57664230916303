import {t} from '@lingui/macro';
import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import styled from 'styled-components';
import {Status} from '../../../components/atoms/Status';
import {isTablet} from '../../../utils/responsive';

interface Props {
  setfinalStep: Dispatch<SetStateAction<boolean>>;
}

export const MyOwnIdeasToolTipFinalStep: FunctionComponent<Props> = ({
  setfinalStep,
}) => {
  return (
    <Relative>
      <Triangle />
      <HelpContainer>
        <Head>{t`The Key ideas of your post, This field is recommended but  not mandatory. Keys ideas can be:`}</Head>
        <Cards>
          <Card>
            <StrongText>{t`A list of ideas, separated by a newline and/or charaters or semicolons`}</StrongText>
            <ExampleContainer>
              <Line />
              <Flex>
                <Example>{t`Example:`}</Example>
                <ExampleText>{t`Proximity with clients`}</ExampleText>
                <ExampleText>{t`Cheap`}</ExampleText>
              </Flex>
            </ExampleContainer>
          </Card>
          <Card>
            <StrongText>{t`Any draft of text`}</StrongText>
            <ExampleContainer>
              <Line />
              <Flex>
                <Example>{t`Example:`}</Example>
                <ExampleText>{t`“I think squats and deadlift are bad for you. Best way to damage your back, shoulder, hips, knees. What I would do.”`}</ExampleText>
              </Flex>
            </ExampleContainer>
          </Card>
          <Card>
            <Col>
              <Row>
                <StrongText>{t`A template text`}</StrongText>
                <StyledStatus label={t`Advanced`} variant="alert" />
              </Row>
              <ExampleText>
                {t`You can use`} <StrongText>{t`<directions>`}</StrongText>{' '}
                {t`to help drive the creative work of our IA`}
              </ExampleText>
            </Col>
            <ExampleContainer>
              <Line />
              <Flex>
                <Example>{t`Example:`}</Example>
                <StrongText>{t`<Hook with a statistics>`}</StrongText>
                <ExampleText>{t`Everybody is using LinkedIn nowadays-Students-Professional`}</ExampleText>
                <StrongText>{t`<Optimistic conclusion about using LinkedIn>`}</StrongText>
                <StrongText>{t`<Signature>`}</StrongText>
              </Flex>
            </ExampleContainer>
          </Card>
        </Cards>
        <NextContainer>
          <Row>
            <NextNotActive />
            <NextActive />
          </Row>
          <NextText
            onClick={() => {
              setfinalStep(false);
            }}>{t`Okay, I got it`}</NextText>
        </NextContainer>
      </HelpContainer>
    </Relative>
  );
};

const Relative = styled.div`
  position: absolute;
  z-index: 100;
  background-color: ${({theme}) => theme.colors.neutral.shade11};
  border-radius: 1em;
  right: 0.3em;
  top: 3.2em;
  height: fit-content;
  @media (max-width: ${isTablet}) {
    width: 14em;
  }
`;
const HelpContainer = styled.div`
  padding: 1.25em;
  display: flex;
  flex-direction: column;
  gap: 1.25em;
  font-size: 0.9em;
`;
const Head = styled.span`
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.primary1.shade2};
`;
const Cards = styled.div`
  display: flex;
  gap: 0.625em;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    height: 22em;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
const Card = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade17};
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 0.75em;
  line-height: 1.6em;
  width: 15.6em;
  @media (max-width: ${isTablet}) {
    width: 100%;
    box-sizing: border-box;
  }
`;
const ExampleContainer = styled.div`
  display: flex;
  gap: 0.75em;
  align-items: center;
`;
const Example = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade6};
  letter-spacing: 0.03125em;
`;
const ExampleText = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade1};
  letter-spacing: 0.0125em;
`;
const StrongText = styled.span`
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.neutral.shade1};
  font-weight: 700;
`;
const NextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const NextActive = styled.div`
  height: 0.5em;
  width: 0.5em;
  background-color: ${({theme}) => theme.colors.primary1.shade2};
  border-radius: 50%;
`;
const NextNotActive = styled.div`
  height: 0.5em;
  width: 0.5em;
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  border-radius: 50%;
  opacity: 0.2;
`;
const NextText = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade1};
  letter-spacing: 0.03125em;
  cursor: pointer;
`;
const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 7px solid ${({theme}) => theme.colors.neutral.shade11};
  position: absolute;
  right: 12px;
  top: -5px;
  @media (max-width: ${isTablet}) {
    right: 8px;
  }
`;
const StyledStatus = styled(Status)`
  padding-bottom: 0;
  padding-top: 0;
`;
const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Line = styled.div`
  width: 0.5px;
  background-color: ${({theme}) => theme.colors.neutral.shade9};
  height: calc(100% - 4px);
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;
