import {useCallback, useContext, useEffect, useState} from 'react';
import {useTracking} from '../useTracking';
import {UserContext} from '../../common/UserContext/user.context';
import {useSnackbar} from 'notistack';
import {
  RedactedPost,
  RedactPostError,
  RedactPostResponse,
  useRedactPostMutation,
} from './useRedactPostMutation';

export const useRedactPost = () => {
  const [redactedPosts, setRedactedPosts] = useState<RedactedPost[]>([]);
  const [showPricingPopup, setShowPricingPopup] = useState(false);
  const redactPost = useRedactPostMutation(() => {
    setShowPricingPopup(true);
  });
  const {me} = useContext(UserContext);
  const {trackEvent} = useTracking();
  const {enqueueSnackbar} = useSnackbar();

  const onGeneratePostSubmit = useCallback(
    async (
      subject: string,
      keyIdeas: string,
      profileId: string,
      postToBeRecycled?: string,
      contentUrl?: string,
    ) => {
      const postToRedact = {
        subject,
        keyIdeas,
        profileId,
        postToBeRecycled,
        contentUrl,
      };
      localStorage.setItem(
        'redactPostFormParameters',
        JSON.stringify(postToRedact),
      );
      if (me && !me.hasFinishedOnboarding) {
        trackEvent('Onboarding - Click - Generate post', {
          subject,
          keyIdeas,
        });
      }
      redactPost.mutate(postToRedact);
    },
    [redactPost, me, trackEvent],
  );

  useEffect(() => {
    if (!redactPost.isError) {
      return;
    }
    const error = redactPost.error as RedactPostError;
    enqueueSnackbar(error.message, {
      variant: error.level,
      autoHideDuration: 15000,
    });
  }, [enqueueSnackbar, redactPost.error, redactPost.isError]);

  useEffect(() => {
    const fetchRedactedPosts = async () => {
      if (redactPost.isError || !redactPost.isSuccess || !redactPost.data) {
        return;
      }
      const response = redactPost.data as Response;
      if (!response.ok) {
        return;
      }
      const data = (await response.json()) as RedactPostResponse;
      setRedactedPosts(data.posts);
    };

    fetchRedactedPosts();
  }, [redactPost.data, redactPost.isError, redactPost.isSuccess]);

  return {
    onGeneratePostSubmit,
    redactedPosts,
    isLoading: redactPost.isPending,
    showPricingPopup,
    setShowPricingPopup,
    error: redactPost.error || redactPost.isError,
  };
};
