import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {isTablet} from '../../utils/responsive';
import {t} from '@lingui/macro';

export const AffiliateConditions: FunctionComponent = () => {
  return (
    <ConditionsContainer>
      <ConditionCard>
        <Title>{t`For me`}</Title>
        <Bonus>+3</Bonus>
        <Description>{t`Posts/month*`}</Description>
      </ConditionCard>
      <ConditionCard>
        <Title>{t`For my referral`}</Title>
        <Bonus>+2</Bonus>
        <Description>{t`Posts/month*`}</Description>
      </ConditionCard>
    </ConditionsContainer>
  );
};

const ConditionsContainer = styled.div`
  display: flex;
  gap: 2em;
  justify-content: center;
  align-items: center;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
  }
`;

const ConditionCard = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  padding: 1.5em;
  text-align: center;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  flex: 1;
  min-width: 30em;
  max-width: calc(50% - 1em);
  @media (max-width: ${isTablet}) {
    max-width: 100%;
  }
`;

const Title = styled.div`
  font-size: 3.2em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  border-radius: 0.5em;
  padding: 0.2em;
`;

const Bonus = styled.div`
  font-size: 4.4em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.primary1.shade2};
`;

const Description = styled.div`
  font-size: 1.7em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
