import {formatDistanceToNow} from 'date-fns';

export const formatRelativeTime = (createdAt: Date): string => {
  const distance = formatDistanceToNow(createdAt, {addSuffix: true});

  const [, num, unit] = distance.match(/(\d+)\s(\w+)/) || [];
  if (num && unit) {
    switch (unit) {
      case 'second':
      case 'seconds':
        return `${num}s`;
      case 'minute':
      case 'minutes':
        return `${num}m`;
      case 'hour':
      case 'hours':
        return `${num}h`;
      case 'day':
      case 'days':
        return `${num}d`;
      case 'month':
      case 'months':
        return `${num}mo`;
      case 'year':
      case 'years':
        return `${num}y`;
      default:
        return distance;
    }
  }
  return distance;
};
