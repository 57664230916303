import {t} from '@lingui/macro';
import {FunctionComponent} from 'react';
import DotIcon from '../../atoms/Icons/DotIcon';
import {IdeaIcon} from '../../atoms/Icons/IdeaIcon';
import styled from 'styled-components';
import {isTablet} from '../../../utils/responsive';

interface Props {
  onClick: () => void;
}

export const MyIdeas: FunctionComponent<Props> = ({onClick}) => {
  return (
    <Container onClick={onClick}>
      <Header>
        <StyledIdeaIcon size="26" /> {t`My own ideas`}
      </Header>
      <Col>
        <Subject>
          <StrongText>{t`Subject`}</StrongText>
          <SubjetContent>{t`Example: Why do you need to post on Linkedin?`}</SubjetContent>
        </Subject>
        <Ideas>
          <StrongText>{t`Ideas`}</StrongText>
          <SubjetContent>
            <DotIcon />
            {t`Create an audience`}
          </SubjetContent>
          <SubjetContent>
            <DotIcon />
            {t`Prove your skills`}
          </SubjetContent>
          <SubjetContent>
            <DotIcon />
            {t`Find new clients`}
          </SubjetContent>
        </Ideas>
      </Col>
    </Container>
  );
};
const Container = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1em;
  padding-top: 2em;
  width: 19.875em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${isTablet}) {
    padding: 1.5em;
    align-items: start;
    width: 100%;
    box-sizing: border-box;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.25em;
  letter-spacing: 0.0125em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
const Subject = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade13};
  color: ${({theme}) => theme.colors.neutral.shade11};
  padding: 1.5em;
  border-radius: 1.5em 1.5em 0 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const StrongText = styled.div`
  font-weight: 700;
  letter-spacing: 0.0125em;
`;
const Ideas = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade13};
  color: ${({theme}) => theme.colors.neutral.shade11};
  padding: 1.5em;
  border-radius: 0 0 1.5em 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const SubjetContent = styled.span`
  line-height: 1.5em;
  letter-spacing: 0.03125em;
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const StyledIdeaIcon = styled(IdeaIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
