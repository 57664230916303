import {useMutation, useQueryClient} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {postApi} from '../../utils/fetchApi';

export const useMarkSavedPostAsUsed = () => {
  const queryClient = useQueryClient();

  const markSavedPostAsUsed = useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['savedPosts']});
    },
    mutationFn: async (id: string) =>
      postApi(
        `/savedPosts/markAsUsed/${id}`,
        {
          method: 'POST',
        },
        t`Unable to mark post as used`,
      ),
  });

  return markSavedPostAsUsed;
};
