import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {isTablet} from '../../utils/responsive';
import {format} from 'date-fns';
import {t} from '@lingui/macro';
import {i18n} from '@lingui/core';
import {enUS, fr} from 'date-fns/locale';

export interface AffiliateItem {
  date: Date;
  name: string;
  email: string;
  status: 'Pending' | 'Active' | 'Inactive';
}

interface Props {
  affiliates: AffiliateItem[];
}

export const AffiliateHistory: FunctionComponent<Props> = ({affiliates}) => {
  const getTranslatedStatus = (status: AffiliateItem['status']) => {
    switch (status) {
      case 'Pending':
        return t`Pending`;
      case 'Active':
        return t`Active`;
      case 'Inactive':
        return t`Inactive`;
      default:
        return status;
    }
  };

  return (
    <StyledTable>
      <thead>
        <tr>
          <th>{t`Date`}</th>
          <th>{t`Name`}</th>
          <th>{t`Email`}</th>
          <th>{t`Status`}</th>
        </tr>
      </thead>
      <tbody>
        {affiliates.map((affiliate, index) => (
          <tr key={index}>
            <td>
              {format(affiliate.date, 'd MMMM, yyyy', {
                locale: i18n.locale === 'fr' ? fr : enUS,
              })}
            </td>
            <td>{affiliate.name}</td>
            <td>{affiliate.email}</td>
            <td>
              <StatusBadge status={affiliate.status}>
                {getTranslatedStatus(affiliate.status)}
              </StatusBadge>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 0.2em;
  background-color: ${({theme}) => theme.colors.neutral.shade3};
  border-radius: 2em;
  padding: 0.2em;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
  font-weight: 600;

  th {
    text-align: left;
    padding: 1.3em 0 1.3em 1em;
    color: ${({theme}) => theme.colors.primary1.shade3};
    @media (min-width: ${isTablet}) {
      width: 12.5em;
      padding: 1.3em 1em;
    }
  }

  td {
    background-color: ${({theme}) => theme.colors.neutral.shade1};
    padding: 1.3em 0 1.3em 1em;
    font-size: 1em;
    text-align: left;
    @media (min-width: ${isTablet}) {
      padding: 1.3em 1em;
    }
  }

  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 1.7em;
  }

  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 1.7em;
  }

  tr td {
    text-align: left;
  }
`;

const StatusBadge = styled.span<{status: string}>`
  padding: 0.5em 1em;
  border-radius: 0.5em;
  font-size: 0.875em;
  font-weight: bold;
  color: ${({theme, status}) =>
    status === 'Active'
      ? theme.colors.primary3.shade2
      : status === 'Pending'
        ? theme.colors.primary1.shade3
        : theme.colors.primary2.shade3};
  background-color: ${({theme, status}) =>
    status === 'Active'
      ? theme.colors.primary3.shade1
      : status === 'Pending'
        ? theme.colors.primary1.shade1
        : theme.colors.primary2.shade1};
`;
