import {useMutation, useQueryClient} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {useCallback, useEffect} from 'react';
import {enqueueSnackbar} from 'notistack';
import {Profile} from '../profile.ts';

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  const updateProfileMutation = useMutation({
    onSuccess: async () => {
      queryClient.removeQueries({queryKey: ['profiles']});
      await queryClient.invalidateQueries({queryKey: ['subscription']});
      await queryClient.invalidateQueries({queryKey: ['profiles']});
    },
    mutationFn: async (profile: Partial<Profile>) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/profile/update`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(profile),
        },
      );

      if (response.ok) {
        return response;
      }

      throw new Error(t`Please check if all the fields are filled correctly`);
    },
  });

  const updateProfile = useCallback(
    async (profile: Partial<Profile>) => {
      return updateProfileMutation.mutate(profile);
    },
    [updateProfileMutation],
  );

  useEffect(() => {
    if (!updateProfileMutation.isError) {
      return;
    }
    enqueueSnackbar(
      t`Failed to create your profile. Please check that you entered a valid LinkedIn profile url and try again.`,
      {
        variant: 'error',
      },
    );
  }, [updateProfileMutation.error, updateProfileMutation.isError]);

  return {
    updateProfile,
    isLoading: updateProfileMutation.isPending,
    isSuccess: updateProfileMutation.isSuccess,
  };
};
