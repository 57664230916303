import {FunctionComponent, useContext} from 'react';
import styled from 'styled-components';
import {Modal} from './Modal';
import Dog from '../images/Dog.png';
import {t} from '@lingui/macro';
import {Button} from '../../atoms/Button';
import {UserContext} from '../../../common/UserContext/user.context';

interface Props {
  onClose: () => void;
}

export const DeleteAccountPopupConfirmed: FunctionComponent<Props> = ({
  onClose,
}) => {
  const {me} = useContext(UserContext);
  return (
    <Modal onClose={onClose}>
      <Container>
        <Image src={Dog} alt="dog" />
        <FelxCol>
          <RedText>{t`account deleted`}</RedText>
          <StrongText>
            {t`We'll miss you`}, {me?.firstName}!
          </StrongText>
        </FelxCol>
        <Button
          label={t`Dismiss`}
          size="big"
          variant="main"
          onClick={onClose}
        />
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`;
const StrongText = styled.span`
  font-weight: 700;
  font-size: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  text-align: center;
  width: 14em;
`;
const FelxCol = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
`;
const RedText = styled.span`
  color: ${({theme}) => theme.colors.primary2.shade3};
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
`;
const Image = styled.img`
  width: 12em;
  height: 7.5em;
`;
