import {FunctionComponent} from 'react';

interface IconProps {
  size?: number;
  color?: string;
}

const InfoIcon: FunctionComponent<IconProps> = ({
  size = 18,
  color = '#D92020',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_92_10653)">
        <path
          d="M9 12V9M9 6H9.0075M1.5 6.39206V11.6079C1.5 11.7914 1.5 11.8831 1.52072 11.9694C1.5391 12.046 1.5694 12.1191 1.61052 12.1862C1.6569 12.2619 1.72176 12.3268 1.85147 12.4565L5.54353 16.1485C5.67324 16.2782 5.7381 16.3431 5.81379 16.3895C5.88089 16.4306 5.95405 16.4609 6.03058 16.4793C6.11689 16.5 6.20861 16.5 6.39206 16.5H11.6079C11.7914 16.5 11.8831 16.5 11.9694 16.4793C12.046 16.4609 12.1191 16.4306 12.1862 16.3895C12.2619 16.3431 12.3268 16.2782 12.4565 16.1485L16.1485 12.4565C16.2782 12.3268 16.3431 12.2619 16.3895 12.1862C16.4306 12.1191 16.4609 12.046 16.4793 11.9694C16.5 11.8831 16.5 11.7914 16.5 11.6079V6.39206C16.5 6.20861 16.5 6.11689 16.4793 6.03058C16.4609 5.95405 16.4306 5.88089 16.3895 5.81379C16.3431 5.7381 16.2782 5.67324 16.1485 5.54353L12.4565 1.85147C12.3268 1.72176 12.2619 1.6569 12.1862 1.61052C12.1191 1.5694 12.046 1.5391 11.9694 1.52072C11.8831 1.5 11.7914 1.5 11.6079 1.5H6.39206C6.20861 1.5 6.11689 1.5 6.03058 1.52072C5.95405 1.5391 5.88089 1.5694 5.81379 1.61052C5.7381 1.6569 5.67324 1.72176 5.54353 1.85147L1.85147 5.54353C1.72176 5.67324 1.6569 5.7381 1.61052 5.81379C1.5694 5.88089 1.5391 5.95405 1.52072 6.03058C1.5 6.11689 1.5 6.20861 1.5 6.39206Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_92_10653">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoIcon;
