import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from 'react';
import styled, {keyframes} from 'styled-components';
import {useTheme} from '../../../components/theme/theme';
import {useClickOutside} from '../../../hooks/useClickOutside';
import {isTablet} from '../../../utils/responsive';
import ChevronBottom from '../../../components/atoms/Icons/ChevronBottom';
import {t} from '@lingui/macro';
import {Button} from '../../../components/atoms/Button';
import {Profile} from '../../../hooks/profile/profile.ts';

interface Props {
  selectedProfile: Profile | undefined;
  setSelectedProfile: Dispatch<SetStateAction<Profile | undefined>>;
  profileOptions: Profile[];
}

export const ProfileFilter: FunctionComponent<Props> = ({
  selectedProfile,
  setSelectedProfile,
  profileOptions,
}) => {
  const theme = useTheme();
  const [isActive, setIsActive] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const setProfile = useCallback(
    (profile: Profile) => {
      setSelectedProfile(profile);
      setIsActive(false);
    },
    [setSelectedProfile],
  );

  useClickOutside(containerRef, () => setIsActive(false));

  return (
    <Container
      ref={containerRef}
      $active={isActive}
      onClick={() => setIsActive(!isActive)}>
      <AvatarNameContainer>
        {selectedProfile ? (
          <>
            <Avatar
              src={selectedProfile.profilePhotoUrl}
              alt={selectedProfile.name}
            />
            <ProfileName>{selectedProfile.name}</ProfileName>
          </>
        ) : (
          <ProfileName>{t`All profiles`}</ProfileName>
        )}
      </AvatarNameContainer>
      <StyledChevronBottom
        $active={isActive}
        color={theme.colors.neutral.shade11}
      />
      {isActive && (
        <Dropdown>
          {profileOptions.map((profile) => (
            <ProfileItemContainer
              key={profile.id}
              $selected={selectedProfile?.id === profile.id}
              onClick={() => {
                setProfile(profile);
              }}>
              <AvatarNameContainer>
                <Avatar src={profile.profilePhotoUrl} alt={profile.name} />
                <ProfileName>{profile.name}</ProfileName>
              </AvatarNameContainer>
            </ProfileItemContainer>
          ))}
          <StyledMainButton
            label={t`All profiles`}
            size="small"
            variant="main"
            onClick={() => {
              setSelectedProfile(undefined);
              setIsActive(false);
            }}
          />
        </Dropdown>
      )}
    </Container>
  );
};

const Container = styled.div<{$active: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.625em;
  justify-content: space-between;
  padding: 0.59em 1em;
  border-radius: 0.75em;
  flex: 1;
  text-wrap: nowrap;
  min-width: 20em;
  border: 1px solid
    ${({theme, $active}) =>
      $active ? theme.colors.primary1.shade2 : theme.colors.neutral.shade5};
  box-shadow: ${({$active, theme}) =>
    $active ? `0px 0px 0px 4px ${theme.colors.primary1.shade1}` : 'none'};
  cursor: pointer;
  @media (max-width: ${isTablet}) {
    padding: 0.3em 1em;
  }
`;

const AvatarNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  height: 2.5em;
`;

const Avatar = styled.img`
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid ${({theme}) => theme.colors.primary1.shade2};
`;

const ProfileName = styled.span`
  font-weight: 600;
  margin-left: 0.75em;
`;

const StyledChevronBottom = styled(ChevronBottom)<{$active: boolean}>`
  transition: transform 0.3s ease;
  transform: ${({$active}) => ($active ? 'rotate(180deg)' : 'rotate(0deg)')};
  @media (max-width: ${isTablet}) {
    height: 1.25em;
    width: 1.25em;
  }
`;

const fadeIn = keyframes`
      from {
        opacity: 0;
        transform: translateY(-10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    `;

const Dropdown = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 4em;
  padding: 0.8em;
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 0.625em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
  z-index: 20;
  animation: ${fadeIn} 0.3s ease forwards;
  @media (max-width: ${isTablet}) {
    top: 3.8em;
  }
`;

const ProfileItemContainer = styled.button<{$selected: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625em;
  border-radius: 0.75em;
  padding: 0.5em;
  font-size: 0.875em;
  letter-spacing: 0.0125em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
  background-color: ${({theme, $selected}) =>
    $selected ? theme.colors.primary1.shade5 : 'transparent'};
  border: none;
  text-align: start;
  cursor: pointer;

  ${({theme, $selected}) =>
    $selected &&
    `
          border: 2px solid ${theme.colors.primary1.shade2};
        `}
`;

const StyledMainButton = styled(Button)`
  align-self: flex-end;
`;
