import {t} from '@lingui/macro';
import {FunctionComponent, ReactNode} from 'react';
import {Button} from '../../../components/atoms/Button';
import ArrowLeftIcon from '../../../components/atoms/Icons/ArrowLeftIcon';
import styled from 'styled-components';
import {OnboardingSteps} from '../Onboarding';
import ArrowRight from '../../../components/atoms/Icons/ArrowRightIcon';
import {isTablet} from '../../../utils/responsive';
import {ContentSource} from './ChooseContentSource';

interface Props {
  onGoBack?: () => void;
  onWritePost?: () => void;
  onChooseStyle?: () => void;
  children: ReactNode;
  stepState: OnboardingSteps;
}
export const LearnHowToGenerateContentContainer: FunctionComponent<Props> = ({
  children,
  onGoBack,
  onChooseStyle,
  stepState,
  onWritePost,
}) => {
  return (
    <Col>
      <Top>
        <Steps>
          {stepState.hasFilledContent ? (
            <StepsCount>
              {t`Step 3:`} <StepsTitle>{t`Post`}</StepsTitle>
            </StepsCount>
          ) : (
            <StepsCount>
              {t`Step 1:`} <StepsTitle>{t`Content`}</StepsTitle>
            </StepsCount>
          )}
          <StepsBar>
            {stepState.hasFilledContent ? (
              <>
                <StepActive />
                <StepActive />
                <StepActive />
                <StepNotActive />
              </>
            ) : (
              <>
                <StepActive />
                <StepNotActive />
                <StepNotActive />
                <StepNotActive />
              </>
            )}
          </StepsBar>
        </Steps>
        {stepState.hasFilledContent && (
          <Header>{t`Almost there! One more step to go`}</Header>
        )}
        {!stepState.hasFilledContent &&
          stepState.contentSource !== ContentSource.I_DONT_HAVE_IDEAS && (
            <Header>{t`What do you want to talk about ?`}</Header>
          )}
      </Top>
      <Bottom>
        {children}
        {stepState.hasFilledContent ? (
          <End>
            <Button
              label={t`Go back`}
              size="medium"
              variant="light"
              onClick={onGoBack}
              startIcon={<StyledArrowLeftIcon />}
            />
            <Button
              onClick={onWritePost}
              label={t`Write my post`}
              size="medium"
              variant="main"
              endIcon={<StyledArrowRight width={22} height={23} />}
            />
          </End>
        ) : (
          stepState.contentSource !== ContentSource.I_DONT_HAVE_IDEAS && (
            <Row>
              <Button
                onClick={onGoBack}
                label={t`Go back`}
                size="medium"
                variant="light"
                startIcon={<StyledArrowLeftIcon />}
              />
              <Button
                onClick={onChooseStyle}
                label={t`Let's choose a writing style`}
                size="medium"
                variant="main"
              />
            </Row>
          )
        )}
      </Bottom>
    </Col>
  );
};

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1.5em;
`;
const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
`;
const StepsCount = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade10};
  letter-spacing: 0.03125em;
`;
const StepsTitle = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 600;
`;
const StepsBar = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const StepActive = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade2};
  height: 0.6875em;
  width: 3.6875em;
  border-radius: 2.5em;
  @media (max-width: ${isTablet}) {
    width: 2em;
  }
`;
const StepNotActive = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade7};
  height: 0.6875em;
  width: 3.6875em;
  border-radius: 2.5em;
  @media (max-width: ${isTablet}) {
    width: 2em;
  }
`;
const Header = styled.span`
  font-size: 1.75em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Bottom = styled.div`
  width: 54.125em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;
const End = styled.div`
  width: 100%;
  display: flex;
  gap: 1.25em;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;
const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
const StyledArrowRight = styled(ArrowRight)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
