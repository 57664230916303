import {useState} from 'react';
import {useSnackbar} from 'notistack';
import ThirdPartyEmailPassword from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import {t} from '@lingui/macro';
import {isValidEmail} from '../../utils/isValidEmail';

export const useResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const {enqueueSnackbar} = useSnackbar();

  const clearError = () => {
    setError('');
  };

  const handleResetPassword = async () => {
    if (!email) {
      const errorMessage = t`Please enter your email address.`;
      setError(errorMessage);
      return;
    }

    if (!isValidEmail(email)) {
      const errorMessage = t`Please enter a valid email address.`;
      setError(errorMessage);
      return;
    }

    try {
      const response = await ThirdPartyEmailPassword.sendPasswordResetEmail({
        formFields: [{id: 'email', value: email}],
      });

      if (response.status !== 'OK') {
        const errorMessage = t`Failed to send reset email. Please try again.`;
        setError(errorMessage);
        enqueueSnackbar(errorMessage, {variant: 'error'});
        return;
      }

      const successMessage = t`Password reset email sent. Please check your inbox.`;
      setMessage(successMessage);
      enqueueSnackbar(successMessage, {variant: 'success'});
      setError('');
    } catch (err) {
      const errorMessage = t`An error occurred. Please try again later.`;
      setError(errorMessage);
      enqueueSnackbar(errorMessage, {variant: 'error'});
    }
  };

  return {email, setEmail, message, error, clearError, handleResetPassword};
};
