import {FunctionComponent} from 'react';
import Copywriters from '../molecules/images/Copywriters.png';
import Boost from '../molecules/images/Boost.png';
import styled from 'styled-components';
import {Button} from '../atoms/Button';
import ArrowRight from '../atoms/Icons/ArrowRight';
import Yohan from '../molecules/images/Yohan.png';
import {t} from '@lingui/macro';
import {isTablet} from '../../utils/responsive';

const joinAffiliateProgramUrl =
  'https://redactai.getrewardful.com/signup?campaign=redactai-ambassadeur-20';
const demoWithYohanUrl =
  'https://calendly.com/yohan-fvs/presentation-redactai?month=2024-07';

export const ProfileInformationCard: FunctionComponent = () => {
  return (
    <Container>
      <Wrapper>
        <BoostImage src={Boost} alt="Boost" />
        <Card>
          <Col>
            <Header>
              {t`Boost your earnings—join our affiliate program today!`}
            </Header>
            <SubHeader>
              {t`Are you looking for a way to monetize your website, blog, or social media following?`}
            </SubHeader>
            <Text>
              {t`Our Affiliate Program offers a fantastic opportunity to earn generous commissions while promoting products you love`}
            </Text>
          </Col>
          <Button
            onClick={() => window.open(joinAffiliateProgramUrl)}
            label={t`Join affiliate program`}
            size="medium"
            variant="main"
            fullWidth
            endIcon={<ArrowRight height={18} width={18} />}
          />
        </Card>
      </Wrapper>
      <Wrapper>
        <Image src={Copywriters} alt="Copywriters" />
        <Card>
          <Col>
            <Header>{t`Are you the agency, copywriters or companies?`}</Header>
            <SubHeader>
              {t`It's essential to understand the roles and responsibilities of the key players`}
            </SubHeader>
            <Text>
              {t`Each brings a unique perspective and set of skills to the table, ensuring the success of marketing campaigns and content strategies.`}
            </Text>
          </Col>
          <Button
            onClick={() => window.open(demoWithYohanUrl)}
            label={t`Quick demo with Yohan`}
            size="medium"
            variant="main"
            fullWidth
            startIcon={<StyeldImage src={Yohan} alt="Yohan" />}
            endIcon={<ArrowRight height={18} width={18} />}
          />
        </Card>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    gap: 1.5;
  }
`;
const Card = styled.div`
  padding: 1.5em;
  background: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  flex: 1;
`;
const Header = styled.p`
  font-weight: 700;
  font-size: 1.5em;
  letter-spacing: 0;
  margin: 0;
`;
const SubHeader = styled.p`
  line-height: 1.5em;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 0.0125em;
  margin: 0;
`;
const Text = styled.p`
  line-height: 1.25em;
  font-size: 0.875em;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.neutral.shade8};
  margin: 0;
`;
const StyeldImage = styled.img`
  height: 1.5em;
  width: 1.5em;
`;
const Wrapper = styled.div`
  width: 24.28125em;
  background: linear-gradient(
    to bottom,
    #ffebcf 0%,
    ${({theme}) => theme.colors.neutral.shade1} 100%
  );
  border-radius: 1.5em;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
  display: flex;
  flex-direction: column;
`;
const Image = styled.img`
  width: 100%;
`;
const BoostImage = styled.img`
  margin-top: -3px;
  width: 100%;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1;
`;
