import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const TrashIcon: FunctionComponent<Props> = ({
  width = 21,
  height = 21,
  color = '#1C1B1A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.6666 5.98047C18.3366 5.65047 14.9866 5.48047 11.6466 5.48047C9.66663 5.48047 7.68663 5.58047 5.70663 5.78047L3.66663 5.98047"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16663 4.97L9.38663 3.66C9.54663 2.71 9.66663 2 11.3566 2H13.9766C15.6666 2 15.7966 2.75 15.9466 3.67L16.1666 4.97"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5167 9.14062L18.8667 19.2106C18.7567 20.7806 18.6666 22.0006 15.8766 22.0006H9.45665C6.66665 22.0006 6.57665 20.7806 6.46665 19.2106L5.81665 9.14062"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9966 16.5H14.3266"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1666 12.5H15.1666"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashIcon;
