import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {RegeneratedPost} from '../../hooks/regeneratePost/useRegeneratePostMutation';
import {isTablet} from '../../utils/responsive';

interface Props {
  message: string | RegeneratedPost[];
}

export const Message: FunctionComponent<Props> = ({message}) => {
  if (typeof message === 'string') return <Container>{message}</Container>;
};

const Container = styled.div`
  font-size: 0.875em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
  line-height: 1.35em;
  letter-spacing: 0.0125em;
  padding: 0.8em 1.25em;
  background-color: ${({theme}) => theme.colors.neutral.shade5};
  border-radius: 0.875em 0.875em 0 0.875em;
  width: fit-content;
  height: fit-content;
  word-wrap: break-word;
  @media (max-width: ${isTablet}) {
    max-width: 20em;
  }
`;
