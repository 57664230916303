import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

export const CircularLoader = () => (
  <CenteredFlex>
    <StyledCircularProgress size={'10em'} />
  </CenteredFlex>
);
const CenteredFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${({theme}) => theme.colors.primary1.shade2};
`;
