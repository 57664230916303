import {i18n} from '@lingui/core';

interface Suggestion {
  name: string;
  imageUrl: string;
  linkedInUrl: string;
}

const frCreators: Suggestion[] = [
  {
    name: 'Benoît Dubos',
    imageUrl: 'https://i.ibb.co/BrwhkbL/1695053204498-1.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/benoitdubos/',
  },
  {
    name: 'Juliette Cadot',
    imageUrl: 'https://i.ibb.co/d5PsQRM/1695654816337.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/juliettecadot/',
  },
  {
    name: 'Caroline Rousset',
    imageUrl: 'https://i.ibb.co/j3rN3n8/1662898929601-1.jpg',
    linkedInUrl:
      'https://www.linkedin.com/in/caroline-rousset-creatrice-de-contenus/',
  },
  {
    name: `Thibault Louis`,
    imageUrl: 'https://i.ibb.co/f09NRrf/1694438720863-1.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/thibault-louis/',
  },
];

const enCreators: Suggestion[] = [
  {
    name: `Justin welsh`,
    imageUrl: 'https://i.ibb.co/FqGN8LV/justin.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/justinwelsh/',
  },
  {
    name: `Mita Mallick`,
    imageUrl: 'https://i.ibb.co/bvKFvK0/mita.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/mita-mallick-2b165822/',
  },
  {
    name: `Alex Hormozi`,
    imageUrl: 'https://i.ibb.co/sQFDPD4/alex.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/alexhormozi/',
  },
  {
    name: `Shaun Robinson`,
    imageUrl: 'https://i.ibb.co/1qYCLFS/shaun.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/msshaunrobinson/',
  },
];

export const useCreatorsSuggestionsBasedOnLocale = () => {
  return i18n.locale === 'fr' ? frCreators : enCreators;
};
