import {FunctionComponent, useEffect} from 'react';
import {Page} from '../components/templates/Page';
import {useNavigate} from 'react-router-dom';

export const Home: FunctionComponent = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/redactPost');
  }, [navigate]);
  return <Page />;
};
