import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {Button} from '../../../components/atoms/Button';
import {isTablet} from '../../../utils/responsive';
import {isValidLinkedInProfileOrCompanyUrl} from '../../../utils/isValidLinkedInProfileUrl';
import {TextInput} from '../../../components/atoms/TextInput';
import {LanguageSelect} from '../../../components/atoms/LanguageSelect';
import useDefaultLanguage from '../../../hooks/postLang/useDefaultLanguage';
import TrashIcon from '../../../components/atoms/Icons/TrashIcon';
import {useTheme} from '../../../components/theme/theme';
import {Profile} from '../../../hooks/profile/profile.ts';
import {useCreateProfile} from '../../../hooks/profile/createProfile/useCreateProfile.ts';
import {useUpdateProfile} from '../../../hooks/profile/updateProfile/useUpdateProfile.ts';
import {useDeleteProfile} from '../../../hooks/profile/deleteProfile/useDeleteProfile.ts';
import {useProfilePostsCheck} from '../../../hooks/profile/profilePostsCheck/useProfilePostsCheck.ts';
import {LightTab} from '../../../components/atoms/LightTab.tsx';
import LinkIcon from '../../../components/atoms/Icons/LinkIcon.tsx';
import LinkedInIcon from '../../../components/atoms/Icons/LinkedInIcon.tsx';
import {SavedPostTone} from '../../../hooks/savedPost/savedPost.types.ts';
import {PostStyleSelect} from '../../onboarding/components/PostStyleSelect.tsx';
import {StyleSource} from '../../onboarding-v2/components/ChooseStyleSource.tsx';

interface Props {
  isEdit: boolean;
  profileIdToEdit?: string;
  savedProfiles?: Profile[];
  setEditProfile?: React.Dispatch<React.SetStateAction<boolean>>;
  setCreateProfile?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditCreateProfile: FunctionComponent<Props> = ({
  isEdit,
  profileIdToEdit,
  savedProfiles,
  setEditProfile,
  setCreateProfile,
}) => {
  const [profileName, setProfileName] = useState<string>('');
  const [contextProfileUrl, setContextProfileUrl] = useState<string>('');
  const [styleProfileUrl, setStyleProfileUrl] = useState<string>('');
  const [postTone, setPostTone] = useState<SavedPostTone>(
    SavedPostTone.FRIENDLY,
  );
  const [styleSource, setStyleSource] = useState<StyleSource>(
    StyleSource.PROFILE_URL,
  );
  const [profileLang, setProfileLang] = useDefaultLanguage();
  const theme = useTheme();

  const [errors, setErrors] = useState<{
    profileName?: string;
    contextProfileUrl?: string;
    styleProfileUrl?: string;
  }>({});

  const {
    createProfile,
    isLoading: isLoadingCreateProfile,
    isSuccess: isSuccessCreateProfile,
  } = useCreateProfile();

  const {
    updateProfile,
    isLoading: isLoadingUpdateProfile,
    isSuccess: isSuccessUpdateProfile,
  } = useUpdateProfile();

  const deleteProfileMutation = useDeleteProfile();

  useEffect(() => {
    if (isEdit && profileIdToEdit && savedProfiles) {
      const profileToEdit = savedProfiles.find(
        (profile) => profile.id === profileIdToEdit,
      );
      if (profileToEdit) {
        setProfileName(profileToEdit.name);
        setContextProfileUrl(profileToEdit.linkedInContextUrl);
        setStyleProfileUrl(profileToEdit.linkedInStyleUrl);
        setProfileLang(profileToEdit.language);
        setPostTone(profileToEdit.postTone || SavedPostTone.FRIENDLY);
        setStyleSource(
          profileToEdit?.isStyleUrl
            ? StyleSource.PROFILE_URL
            : StyleSource.POST_TYPE,
        );
      }
    }
  }, [isEdit, profileIdToEdit, savedProfiles, setProfileLang]);

  const validateForm = (): boolean => {
    const newErrors: typeof errors = {};

    if (!profileName.trim()) {
      newErrors.profileName = t`Profile name is required`;
    }
    if (!isValidLinkedInProfileOrCompanyUrl(contextProfileUrl)) {
      newErrors.contextProfileUrl = t`Please enter a valid LinkedIn profile URL`;
    }
    if (!isValidLinkedInProfileOrCompanyUrl(styleProfileUrl)) {
      newErrors.styleProfileUrl = t`Please enter a valid LinkedIn profile URL`;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClose = () => {
    if (isEdit) {
      setEditProfile && setEditProfile(false);
    }
    setCreateProfile && setCreateProfile(false);
  };

  const {checkProfilePosts, isLoading: isChecking} = useProfilePostsCheck();

  const validateProfilePosts = async () => {
    const hasEnoughPosts = await checkProfilePosts.mutateAsync(styleProfileUrl);
    if (!hasEnoughPosts) {
      setErrors((prev) => ({
        ...prev,
        styleProfileUrl: t`Sorry, but your profile needs at least 15 posts to define your style.`,
      }));
    }
    return hasEnoughPosts;
  };

  const handleCreateProfile = async () => {
    if (!validateForm() || !(await validateProfilePosts())) {
      return;
    }

    await createProfile({
      name: profileName,
      linkedInContextUrl: contextProfileUrl,
      linkedInStyleUrl: styleProfileUrl,
      language: profileLang,
      postTone: postTone,
      isStyleUrl: styleSource === StyleSource.PROFILE_URL,
    });
  };

  useEffect(() => {
    if (isSuccessCreateProfile) {
      setCreateProfile && setCreateProfile(false);
    }
  }, [isSuccessCreateProfile, setCreateProfile]);

  const handleEditProfile = useCallback(async () => {
    if (!validateForm()) {
      return;
    }

    if (
      styleSource === StyleSource.PROFILE_URL &&
      !(await validateProfilePosts())
    ) {
      return;
    }

    await updateProfile({
      id: profileIdToEdit!,
      name: profileName,
      linkedInStyleUrl: styleProfileUrl,
      language: profileLang,
      postTone: postTone,
      isStyleUrl: styleSource === StyleSource.PROFILE_URL,
    });
  }, [
    postTone,
    profileIdToEdit,
    profileLang,
    profileName,
    styleProfileUrl,
    styleSource,
    updateProfile,
    validateForm,
    validateProfilePosts,
  ]);

  useEffect(() => {
    if (isSuccessUpdateProfile) {
      setEditProfile && setEditProfile(false);
    }
  }, [isSuccessUpdateProfile, setEditProfile]);

  useEffect(() => {
    if (deleteProfileMutation.isSuccess) {
      handleClose();
    }
  }, [deleteProfileMutation.isSuccess, handleClose]);

  return (
    <Container>
      <Header>
        <Title>{isEdit ? t`Edit my profile` : t`New profile`}</Title>
        {isEdit && (
          <Delete
            onClick={() => {
              if (profileIdToEdit) {
                deleteProfileMutation.mutate(profileIdToEdit);
              }
            }}>
            <TrashIcon
              height={19}
              width={19}
              color={theme.colors.primary2.shade3}
            />{' '}
            {t`Delete profile`}
          </Delete>
        )}
      </Header>
      <FormContainer>
        <StyledTextInput
          alwaysDisplayLabel
          label={t`Profile name`}
          placeholderText={t`Profile Name (example: Elon Musk)`}
          value={profileName}
          onChange={(event) => {
            setProfileName(event.target.value);
            setErrors((prev) => ({...prev, profileName: undefined}));
          }}
          error={!!errors.profileName}
          helperText={errors.profileName}
        />
        <StyledTextInput
          alwaysDisplayLabel
          label={t`Context profile URL`}
          placeholderText={t`LinkedIn context profile url (example: https://www.linkedin.com/in/elonmusk/)`}
          value={contextProfileUrl}
          onChange={(event) => {
            setContextProfileUrl(event.target.value);
            setErrors((prev) => ({...prev, contextProfileUrl: undefined}));
          }}
          error={!!errors.contextProfileUrl}
          helperText={errors.contextProfileUrl}
          disabled={isEdit}
        />
        <StyleContainer>
          <Div>
            <LightTab
              label={t`From LinkedIn profile URL`}
              icon={<StyledLinkedInIcon size={18} />}
              isSelected={styleSource === StyleSource.PROFILE_URL}
              onClick={() => setStyleSource(StyleSource.PROFILE_URL)}
            />
            <LightTab
              label={t`From post type`}
              icon={<StyledLinkIcon />}
              isSelected={styleSource === StyleSource.POST_TYPE}
              onClick={() => setStyleSource(StyleSource.POST_TYPE)}
            />
          </Div>
          {styleSource === StyleSource.POST_TYPE ? (
            <StyledPostStyleSelect
              postTone={postTone}
              setPostTone={setPostTone}
              chooseStyleSource={(styleSource) => setStyleSource(styleSource)}
            />
          ) : (
            <StyledTextInput
              alwaysDisplayLabel
              label={t`Writing style profile URL`}
              placeholderText={t`LinkedIn profile style url (example: https://www.linkedin.com/in/elonmusk/)`}
              value={styleProfileUrl}
              onChange={(event) => {
                setStyleProfileUrl(event.target.value);
                setErrors((prev) => ({...prev, styleProfileUrl: undefined}));
              }}
              error={!!errors.styleProfileUrl}
              helperText={errors.styleProfileUrl}
            />
          )}
        </StyleContainer>
        <SelectContainer>
          <LanguageSelect
            postLang={profileLang}
            setPostLang={setProfileLang}
            onChange={(selectedLang) => setProfileLang(selectedLang.value)}
          />
        </SelectContainer>
      </FormContainer>
      <End>
        <Button
          label={t`Go back`}
          size="medium"
          variant="light"
          onClick={handleClose}
        />
        <StyledButton
          label={
            isLoadingCreateProfile || isLoadingUpdateProfile
              ? t`Saving ...`
              : isEdit
                ? t`Save`
                : t`Create`
          }
          size="medium"
          variant="main"
          onClick={isEdit ? handleEditProfile : handleCreateProfile}
          disabled={
            isLoadingCreateProfile || isLoadingUpdateProfile || isChecking
          }
        />
      </End>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: min(1100px, 80%);
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 1.5em;
  font-weight: 700;
  margin: 0;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const End = styled.div`
  width: 100%;
  display: flex;
  gap: 1.25em;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;

const StyledTextInput = styled(TextInput)<{error?: boolean}>`
  width: 100%;
  margin-bottom: ${(props) => (props.error ? '16px' : '0')};
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const SelectContainer = styled.div`
  width: 15em;
`;

const StyledButton = styled(Button)`
  @media (min-width: ${isTablet}) {
    width: 15em;
  }
`;

const Delete = styled.p`
  margin: 0;
  color: ${({theme}) => theme.colors.primary2.shade3};
  font-size: 1em;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
`;

const StyleContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1.5em 1em 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    align-items: start;
    gap: 1em;
  }
`;

const StyledLinkIcon = styled(LinkIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;

const StyledLinkedInIcon = styled(LinkedInIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;

const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  @media (max-width: ${isTablet}) {
    width: 100%;
    justify-content: space-between;
  }
`;

const StyledPostStyleSelect = styled(PostStyleSelect)`
  width: 15em;
`;
