import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  className?: string;
}

const LinkIcon: FunctionComponent<Props> = ({
  width = 24,
  height = 25,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.07 14.7416C18.78 15.0316 18.32 15.0316 18.04 14.7416C17.75 14.4516 17.75 13.9916 18.04 13.7116C20.04 11.7116 20.04 8.46156 18.04 6.47156C16.04 4.48156 12.79 4.47156 10.8 6.47156C8.81003 8.47156 8.80003 11.7216 10.8 13.7116C11.09 14.0016 11.09 14.4616 10.8 14.7416C10.51 15.0316 10.05 15.0316 9.77003 14.7416C7.20003 12.1716 7.20003 7.99156 9.77003 5.43156C12.34 2.87156 16.52 2.86156 19.08 5.43156C21.64 8.00156 21.64 12.1716 19.07 14.7416Z"
        fill="#FFAC3B"
      />
      <path
        opacity="0.4"
        d="M19.07 5.43141C21.64 8.00141 21.64 12.1714 19.07 14.7414C18.19 15.6214 17.11 16.2014 15.98 16.4814C16.51 14.3214 15.93 11.9514 14.24 10.2614C12.55 8.57141 10.18 7.99141 8.02002 8.52141C8.30002 7.39141 8.87002 6.31141 9.76002 5.43141C12.33 2.86141 16.5 2.86141 19.07 5.43141Z"
        fill="#FFAC3B"
      />
      <path
        d="M4.92997 10.2605C5.21997 9.97047 5.67997 9.97047 5.95997 10.2605C6.24997 10.5505 6.24997 11.0105 5.95997 11.2905C3.95997 13.2905 3.95997 16.5405 5.95997 18.5305C7.95997 20.5205 11.21 20.5305 13.2 18.5305C15.19 16.5305 15.2 13.2805 13.2 11.2905C12.91 11.0005 12.91 10.5405 13.2 10.2605C13.49 9.97047 13.95 9.97047 14.23 10.2605C16.8 12.8305 16.8 17.0105 14.23 19.5705C11.66 22.1305 7.47997 22.1405 4.91997 19.5705C2.35997 17.0005 2.35997 12.8305 4.92997 10.2605Z"
        fill="#FFAC3B"
      />
      <path
        opacity="0.5"
        d="M14.2399 10.2618C15.9299 11.9518 16.5099 14.3218 15.9799 16.4818C15.6999 17.6118 15.1199 18.6918 14.2399 19.5718C11.6699 22.1418 7.49994 22.1418 4.92994 19.5718C2.35994 17.0018 2.35994 12.8318 4.92994 10.2618C5.80994 9.37183 6.88994 8.80183 8.01994 8.52183C10.1799 7.99183 12.5499 8.57183 14.2399 10.2618Z"
        fill="#FFAC3B"
      />
    </svg>
  );
};

export default LinkIcon;
