import {
  FunctionComponent,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import BackgroundBlur from '../../components/molecules/images/BackgroundBlur.png';
import styled from 'styled-components';
import {RedactaiIcon} from '../../components/atoms/Icons/RedactaiIcon';
import {t} from '@lingui/macro';
import {isTablet} from '../../utils/responsive';
import {PostGeneration} from './components/PostGeneration';
import {useRedactPost} from '../../hooks/redactPostNew/useRedactPost';
import {useTracking} from '../../hooks/useTracking';
import {UserContext} from '../../common/UserContext/user.context';
import {useNavigate} from 'react-router-dom';
import {CircularLoader} from '../../components/atoms/CircularLoader';
import {emailVerificationCheck} from '../../utils/emailVerificationCheck';
import {
  ReferralSourceForm,
  UserReferralSource,
} from './components/ReferralSourceForm';
import {OnboardingContainer} from './components/OnboardingContainer';
import {FillInContent} from './components/FillInContent';
import {ChooseUserProfile} from './components/ChooseUserProfile';
import {
  SavedPostLanguage,
  SavedPostTone,
} from '../../hooks/savedPost/savedPost.types';
import {ChooseStyleUrl} from './components/ChooseStyleUrl';
import {
  ProfileResponse,
  useCreateProfile,
} from '../../hooks/profile/createProfile/useCreateProfile.ts';
import {useUpdateProfile} from '../../hooks/profile/updateProfile/useUpdateProfile.ts';
import {useSavedProfiles} from '../../hooks/redactPostNew/useSavedProfiles.tsx';
import {useProfilePostsCheck} from '../../hooks/profile/profilePostsCheck/useProfilePostsCheck.ts';
import {
  ChooseStyleSource,
  StyleSource,
} from './components/ChooseStyleSource.tsx';

export enum OnboardingSteps {
  SET_USER_REFERRAL_SOURCE = 'SET_USER_REFERRAL_SOURCE',
  CHOOSE_USER_CONTEXT = 'CHOOSE_USER_CONTEXT',
  FILL_IN_CONTENT = 'FILL_IN_CONTENT',
  SET_STYLE_URL = 'SET_STYLE_URL',
  REVIEW_AND_PUBLISH = 'REVIEW_AND_PUBLISH',
  CHOOSE_STYLE = 'CHOOSE_STYLE',
}

export interface OnboardingState {
  currentStep: OnboardingSteps;
  userReferralSource?: UserReferralSource;
  postSubject?: string;
  postIdeas?: string;
  language?: SavedPostLanguage;
  contextUrl?: string;
  profile?: ProfileResponse;
}

type OnboardingAction =
  | {type: 'SET_USER_REFERRAL_SOURCE'; payload: UserReferralSource}
  | {type: 'SET_USER_CONTEXT_URL'; payload: string}
  | {
      type: 'SET_CONTENT';
      payload: {
        postSubject: string;
        postIdeas: string;
        language: SavedPostLanguage;
        profile: ProfileResponse | undefined;
      };
    }
  | {type: 'CHOOSE_STYLE'}
  | {type: 'SET_STYLE_URL'}
  | {type: 'REVIEW_AND_PUBLISH'}
  | {type: 'GO_TO_CHOOSE_STYLE'}
  | {type: 'RESET'};

const onboardingReducer = (
  state: OnboardingState,
  action: OnboardingAction,
): OnboardingState => {
  switch (action.type) {
    case 'SET_USER_REFERRAL_SOURCE':
      return {
        ...state,
        userReferralSource: action.payload,
        currentStep: OnboardingSteps.CHOOSE_USER_CONTEXT,
      };
    case 'SET_USER_CONTEXT_URL':
      return {
        ...state,
        contextUrl: action.payload,
        currentStep: OnboardingSteps.FILL_IN_CONTENT,
      };
    case 'SET_CONTENT':
      return {
        ...state,
        postSubject: action.payload.postSubject,
        postIdeas: action.payload.postIdeas,
        language: action.payload.language,
        profile: action.payload.profile,
        currentStep: OnboardingSteps.CHOOSE_STYLE,
      };
    case 'CHOOSE_STYLE':
      return {
        ...state,
      };
    case 'SET_STYLE_URL':
      return {
        ...state,
        currentStep: OnboardingSteps.SET_STYLE_URL,
      };
    case 'REVIEW_AND_PUBLISH':
      return {
        ...state,
        currentStep: OnboardingSteps.REVIEW_AND_PUBLISH,
      };
    case 'GO_TO_CHOOSE_STYLE':
      return {
        ...state,
        currentStep: OnboardingSteps.CHOOSE_STYLE,
      };
    case 'RESET':
      return {currentStep: OnboardingSteps.SET_USER_REFERRAL_SOURCE};
    default:
      return state;
  }
};

export const OnboardingNew: FunctionComponent = () => {
  const {trackEvent} = useTracking();
  const navigate = useNavigate();
  const {me, loading, refetch} = useContext(UserContext);
  const {savedProfiles, loading: loadingProfiles} = useSavedProfiles();
  const [stepState, dispatchSteps] = useReducer(onboardingReducer, {
    currentStep: OnboardingSteps.SET_USER_REFERRAL_SOURCE,
  });
  const [postTone, setPostTone] = useState<SavedPostTone>(
    SavedPostTone.ANALYTICAL_PEDAGOGICAL,
  );

  const {
    createProfile,
    createdProfile,
    setCreatedProfile,
    isLoading: isLoadingCreateProfile,
  } = useCreateProfile();

  const {updateProfile, isLoading: isLoadingUpdateProfile} = useUpdateProfile();

  const {
    onGeneratePostSubmit,
    redactedPosts,
    isLoading: isLoadingRedactPost,
    error,
  } = useRedactPost();

  const {checkProfilePosts, hasEnoughPosts} = useProfilePostsCheck();

  useEffect(() => {
    if (
      stepState.currentStep !== 'CHOOSE_USER_CONTEXT' ||
      loadingProfiles ||
      savedProfiles.length === 0
    ) {
      return;
    }

    const existingProfile = savedProfiles[0];

    if (!existingProfile) {
      return;
    }

    if (hasEnoughPosts === undefined) {
      checkProfilePosts
        .mutateAsync(existingProfile.linkedInStyleUrl)
        .then((hasEnoughPosts) => {
          setCreatedProfile({
            profile: existingProfile,
            status: hasEnoughPosts ? 'OK' : 'NOT_ENOUGH_POSTS',
          });

          dispatchSteps({
            type: 'SET_USER_CONTEXT_URL',
            payload: existingProfile.linkedInContextUrl || '',
          });
        });
    }
  }, [
    savedProfiles,
    loadingProfiles,
    stepState.currentStep,
    checkProfilePosts,
    setCreatedProfile,
    hasEnoughPosts,
  ]);

  useEffect(() => {
    trackEvent('Onboarding - Start');
  }, []);

  useEffect(() => {
    if (error) {
      dispatchSteps({type: 'RESET'});
    }
  }, [error]);

  useEffect(() => {
    if (!me) {
      refetch();
    }
    if (me?.hasFinishedOnboarding) {
      navigate('/pricing');
      return;
    }
  }, [me, loading, navigate, refetch]);

  useEffect(() => {
    const verifyEmail = async () => {
      await emailVerificationCheck(navigate);
    };

    verifyEmail();
  }, [navigate]);

  useEffect(() => {
    if (!createdProfile) {
      return;
    }
    dispatchSteps({
      type: 'SET_USER_CONTEXT_URL',
      payload: createdProfile.profile?.linkedInContextUrl || '',
    });
    trackEvent('Onboarding - Choose context url', {
      contextUrl: createdProfile!.profile!.linkedInContextUrl,
    });
  }, [createdProfile, trackEvent]);

  useEffect(() => {
    if (
      isLoadingRedactPost &&
      stepState.currentStep !== OnboardingSteps.REVIEW_AND_PUBLISH
    ) {
      dispatchSteps({type: 'REVIEW_AND_PUBLISH'});
    }
  }, [isLoadingRedactPost, stepState.currentStep]);

  if (!me || loading) {
    return <CircularLoader />;
  }

  const renderStep = () => {
    switch (stepState.currentStep) {
      case OnboardingSteps.SET_USER_REFERRAL_SOURCE:
        return (
          <Content>
            <SubContent>
              <StyledRedactaiIcon />
              <Welcome>
                {t`Good to have you,`}{' '}
                <StrongText>{me?.firstName} 👋</StrongText>
              </Welcome>
              <Question>{t`Only one question before crafting your first post!`}</Question>
            </SubContent>
            <ReferralSourceForm
              setUserReferralSource={(source) => {
                dispatchSteps({
                  type: 'SET_USER_REFERRAL_SOURCE',
                  payload: source,
                });
                trackEvent('Onboarding - Click - Choose referral source', {
                  userReferralSource: source,
                });
              }}
            />
          </Content>
        );
      case OnboardingSteps.CHOOSE_USER_CONTEXT:
        return (
          <OnboardingContainer stepState={stepState}>
            <ChooseUserProfile
              onCreateProfile={async (profileUrl: string) => {
                await createProfile({
                  linkedInContextUrl: profileUrl,
                });
              }}
              isLoading={isLoadingCreateProfile}
            />
          </OnboardingContainer>
        );
      case OnboardingSteps.FILL_IN_CONTENT:
        return (
          <OnboardingContainer stepState={stepState}>
            <FillInContent
              loading={isLoadingUpdateProfile}
              onGeneratePost={async (content) => {
                await updateProfile({
                  id: createdProfile!.profile!.id,
                  language: content.language,
                });
                dispatchSteps({
                  type: 'SET_CONTENT',
                  payload: {
                    ...content,
                    profile: createdProfile,
                    language: content.language,
                  },
                });
              }}
              contextUrl={stepState.contextUrl!}
              createdProfile={createdProfile!.profile}
            />
          </OnboardingContainer>
        );
      case OnboardingSteps.CHOOSE_STYLE:
        return (
          <OnboardingContainer stepState={stepState}>
            <ChooseStyleSource
              hasEnoughPosts={
                createdProfile?.status === 'OK' || hasEnoughPosts === true
              }
              profileToDisplay={
                createdProfile?.profile
                  ? createdProfile.profile
                  : savedProfiles.length > 0
                    ? savedProfiles[0]
                    : undefined
              }
              chooseStyleSource={async (styleSource) => {
                if (styleSource === StyleSource.PROFILE_URL) {
                  if (createdProfile?.status === 'OK') {
                    await onGeneratePostSubmit(
                      stepState.postSubject || '',
                      stepState.postIdeas || '',
                      createdProfile!.profile!.id,
                      '',
                    );
                  }
                  return;
                }
                if (styleSource === StyleSource.OTHERS_PROFILE_URL) {
                  dispatchSteps({type: 'SET_STYLE_URL'});
                  return;
                }
                if (styleSource === StyleSource.POST_TYPE) {
                  await updateProfile({
                    id: createdProfile!.profile!.id,
                    postTone,
                    isStyleUrl: false,
                  });
                  await onGeneratePostSubmit(
                    stepState.postSubject || '',
                    stepState.postIdeas || '',
                    createdProfile!.profile!.id,
                    '',
                    '',
                  );
                }
              }}
              setPostTone={setPostTone}
              onGoBack={() => {
                dispatchSteps({type: 'RESET'});
              }}
            />
          </OnboardingContainer>
        );
      case OnboardingSteps.SET_STYLE_URL:
        return (
          <OnboardingContainer stepState={stepState}>
            <ChooseStyleUrl
              onGoBack={() => {
                dispatchSteps({type: 'GO_TO_CHOOSE_STYLE'});
              }}
              isLoading={isLoadingUpdateProfile}
              setStyleUrl={async (styleUrl) => {
                await updateProfile({
                  id: createdProfile!.profile!.id,
                  linkedInStyleUrl: styleUrl,
                });
                await onGeneratePostSubmit(
                  stepState.postSubject!,
                  stepState.postIdeas!,
                  createdProfile!.profile!.id,
                  '',
                );
                trackEvent('Onboarding - Choose style url', {
                  styleUrl: styleUrl,
                });
              }}
            />
          </OnboardingContainer>
        );
      case OnboardingSteps.REVIEW_AND_PUBLISH:
        return (
          <PostGeneration
            posts={redactedPosts}
            isLoading={isLoadingRedactPost}
            stepState={stepState}
            profile={createdProfile!.profile!}
          />
        );
      default:
        return null;
    }
  };

  return <Container>{renderStep()}</Container>;
};

const Container = styled.div`
  background-image: url(${BackgroundBlur});
  min-height: calc(100vh - 3em);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 1.5em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4em;
`;

const SubContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
`;

const Welcome = styled.span`
  font-size: 2.75em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  text-align: center;
`;

const StrongText = styled.span`
  font-weight: 700;
`;

const Question = styled.span`
  font-size: 1.25em;
  color: ${({theme}) => theme.colors.neutral.shade10};
  letter-spacing: 0.0125em;
  text-align: center;
`;

const StyledRedactaiIcon = styled(RedactaiIcon)`
  @media (max-width: ${isTablet}) {
    scale: 0.75;
  }
`;
