import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import styled, {keyframes} from 'styled-components';
import {isTablet} from '../../utils/responsive';
import {t} from '@lingui/macro';
import FilterIcon from '../atoms/Icons/FilterIcon';
import {useClickOutside} from '../../hooks/useClickOutside';

interface Props {
  usedFilter: string;
  setUsedFilter: Dispatch<SetStateAction<string>>;
}

export const FilterSelectUsedNotUsed: FunctionComponent<Props> = ({
  usedFilter,
  setUsedFilter,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const filterOptions: string[] = [t`All`, t`Used`, t`Not Used`];
  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => {
    setIsActive(false);
  });
  return (
    <Container
      ref={containerRef}
      $active={isActive}
      onClick={() => {
        setIsActive(!isActive);
      }}>
      <SemiBold>
        <FilterIcon />
        <Text>{t`Filter By`}</Text>
      </SemiBold>
      {isActive && (
        <SubContainer>
          {filterOptions.map((option, index) => (
            <Span
              key={index}
              $selected={usedFilter === option}
              onClick={() => setUsedFilter(option)}>
              {option}
            </Span>
          ))}
        </SubContainer>
      )}
    </Container>
  );
};
const Container = styled.div<{$active: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.625em;
  justify-content: space-between;
  padding: 0.9em 1.375em;
  border-radius: 0.875em;
  border: 1px solid
    ${({theme, $active}) =>
      $active ? theme.colors.primary1.shade2 : theme.colors.neutral.shade5};
  box-shadow: ${({$active, theme}) =>
    $active ? `0px 0px 0px 4px ${theme.colors.primary1.shade1}` : 'none'};
  cursor: pointer;
  @media (max-width: ${isTablet}) {
    padding: 0.3em 1em;
  }
`;
const SemiBold = styled.span`
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.03125em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;
const SubContainer = styled.div`
  position: absolute;
  right: 0;
  top: 4em;
  padding: 0.8em;
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 0.625em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
  z-index: 20;
  animation: ${fadeIn} 0.3s ease forwards;
  @media (max-width: ${isTablet}) {
    top: 3.8em;
    width: max-content;
  }
  @media (min-width: ${isTablet}) {
    left: 0;
  }
`;
const Span = styled.span<{$selected: boolean}>`
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0.03125em;
  font-weight: ${({$selected}) => ($selected ? '700' : '500')};
`;
const Text = styled.span`
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
