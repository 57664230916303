import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const HelpRoundedIcon: FunctionComponent<Props> = ({
  width = 20,
  height = 21,
  color = '#1C1B1A',
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.57508 8.0013C7.771 7.44436 8.15771 6.97473 8.66671 6.67558C9.17571 6.37644 9.77416 6.26708 10.3561 6.3669C10.938 6.46671 11.4658 6.76924 11.846 7.22091C12.2262 7.67258 12.4343 8.24424 12.4334 8.83464C12.4334 10.5013 9.93342 11.3346 9.93342 11.3346M10.0001 14.668H10.0084M18.3334 10.5013C18.3334 15.1037 14.6025 18.8346 10.0001 18.8346C5.39771 18.8346 1.66675 15.1037 1.66675 10.5013C1.66675 5.89893 5.39771 2.16797 10.0001 2.16797C14.6025 2.16797 18.3334 5.89893 18.3334 10.5013Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HelpRoundedIcon;
