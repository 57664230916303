import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';

interface Props {
  monthlyPosts: number;
  totalPosts: number;
}

export const AffiliateEarnings: FunctionComponent<Props> = ({
  monthlyPosts,
  totalPosts,
}) => {
  return (
    <EarningsContainer>
      <EarningsText>
        {t`Currently, you earn`}{' '}
        <EarningsHighlight>
          {monthlyPosts} {t`posts per month.`}
        </EarningsHighlight>
      </EarningsText>
      <TotalText>{t`You’ve earned a total of ${totalPosts} posts.`}</TotalText>
    </EarningsContainer>
  );
};

const EarningsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
  border-radius: 2em;
  padding: 1.5em;
  text-align: left;
  font-weight: 700;
  gap: 2em;
`;

const EarningsText = styled.div`
  font-size: 1.7em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const EarningsHighlight = styled.span`
  color: ${({theme}) => theme.colors.primary3.shade2};
`;

const TotalText = styled.div`
  font-size: 1.2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
