import {useMemo} from 'react';
import {t} from '@lingui/macro';
import {SavedPostTone} from './savedPost/savedPost.types';

export const usePostToneOptions = () => {
  const postToneOptions = useMemo(
    () => [
      {
        name: t`Analytical`,
        value: SavedPostTone.ANALYTICAL,
      },
      {
        name: t`Friendly`,
        value: SavedPostTone.FRIENDLY,
      },
      {
        name: t`Inspirational`,
        value: SavedPostTone.INSPIRATIONAL,
      },
      {
        name: t`Narrative`,
        value: SavedPostTone.NARRATIVE,
      },
      {
        name: t`Professional`,
        value: SavedPostTone.PROFESSIONAL,
      },
      {
        name: t`Promotional`,
        value: SavedPostTone.PROMOTIONAL,
      },
    ],
    [],
  );

  return postToneOptions;
};
