import {t} from '@lingui/macro';
import {isValidHttpUrl} from './isValidHttpUrl';
import {ContentSource} from '../pages/onboarding/components/ChooseContentSource';
import {StyleSource} from '../pages/onboarding/components/ChooseStyleSource';
import {isValidLinkedInProfileOrCompanyUrl} from './isValidLinkedInProfileUrl';
import {OnboardingSteps} from '../pages/onboarding/Onboarding';
import {Dispatch, SetStateAction} from 'react';
import {RedactContentSource} from '../pages/redactpost/components/RedactPostContent';
import {RedactStyleSource} from '../pages/redactpost/components/RedactPostStyle';

export const validateForm = (
  contentUrl: string,
  postSubject: string,
  profileUrl: string,
  setContentUrlError: Dispatch<SetStateAction<string>>,
  setPostSubjectError: Dispatch<SetStateAction<string>>,
  setPostSubjectUrlError: Dispatch<SetStateAction<string>>,
  setProfileUrlError?: Dispatch<SetStateAction<string>>,
  stepState?: OnboardingSteps,
  contentSource?: RedactContentSource,
  styleSource?: RedactStyleSource,
) => {
  let hasError = false;

  if (
    stepState?.contentSource === ContentSource.FROM_AN_URL ||
    contentSource === RedactContentSource.FROM_AN_URL
  ) {
    if (!isValidHttpUrl(contentUrl)) {
      setContentUrlError(t`Please enter a valid content URL`);
      hasError = true;
    } else {
      setContentUrlError('');
    }
    if (!postSubject) {
      setPostSubjectUrlError(t`Please enter a post subject`);
      hasError = true;
    } else {
      setPostSubjectUrlError('');
    }
  }

  if (
    (stepState?.contentSource === ContentSource.MY_OWN_IDEAS && !postSubject) ||
    (contentSource === RedactContentSource.MY_OWN_IDEAS && !postSubject)
  ) {
    setPostSubjectError(t`Please enter a post subject`);
    hasError = true;
  } else {
    setPostSubjectError('');
  }

  if (
    ((stepState?.writingStyle === StyleSource.OTHERS_PROFILE_URL ||
      stepState?.writingStyle === StyleSource.PROFILE_URL) &&
      !isValidLinkedInProfileOrCompanyUrl(profileUrl)) ||
    (styleSource === RedactStyleSource.PROFILE_URL &&
      !isValidLinkedInProfileOrCompanyUrl(profileUrl))
  ) {
    setProfileUrlError?.(
      t`Please provide a URL that begins with 'https://www.linkedin.com/in/' or 'https://www.linkedin.com/company/'`,
    );
    hasError = true;
  } else {
    setProfileUrlError?.('');
  }

  return hasError;
};

export const resetErrors = (
  setContentUrlError: Dispatch<SetStateAction<string>>,
  setPostSubjectError: Dispatch<SetStateAction<string>>,
  setPostSubjectUrlError: Dispatch<SetStateAction<string>>,
  setIsSubmitted: Dispatch<SetStateAction<boolean>>,
  setProfileUrlError?: Dispatch<SetStateAction<string>>,
) => {
  setIsSubmitted(false);
  setPostSubjectError('');
  setPostSubjectUrlError('');
  setContentUrlError('');
  setProfileUrlError?.('');
};
