import {FunctionComponent} from 'react';
import {Chip as MUIChip} from '@mui/material';
import styled from 'styled-components';
import {GiftIcon} from './Icons/GiftIcon';

interface Props {
  label: string;
  variant: 'default' | 'success' | 'date';
  className?: string;
  onclick?: () => void;
}

export const Chip: FunctionComponent<Props> = ({
  label,
  variant,
  className,
  onclick,
}) => {
  return (
    <StyledChip
      onClick={onclick}
      label={label}
      variant="outlined"
      $chipVariant={variant}
      size="small"
      icon={variant === 'success' ? <GiftIcon /> : undefined}
      className={className}
    />
  );
};

export const StyledChip = styled(MUIChip)<{$chipVariant: Props['variant']}>`
  & {
    border-radius: 2em;
    font-weight: 600;
    font-size: 0.75em;
    padding: 0em 1em;
    cursor: pointer;

    ${({theme, $chipVariant}) => {
      switch ($chipVariant) {
        case 'success':
          return `
            border-color: ${theme.colors.primary3.shade2};
            color: ${theme.colors.neutral.shade1};
            background-color: ${theme.colors.primary3.shade2};
            font-weight: 700;
          `;
        case 'date':
          return `
            border-color: ${theme.colors.primary1.shade2};
            color: ${theme.colors.neutral.shade11};
            background-color: ${theme.colors.primary1.shade1};
          `;
        default:
          return `
            border-color: ${theme.colors.neutral.shade7};
            color: ${theme.colors.neutral.shade11};
            background-color: transparent;
          `;
      }
    }}
  }
`;
