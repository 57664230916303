import React, {FunctionComponent} from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: string;
  color?: string;
}

export const LogoutIcon: FunctionComponent<Props> = ({
  size = 20,
  color = '#D92020',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7001 18.5583H12.5918C8.89176 18.5583 7.10843 17.1 6.80009 13.8333C6.76676 13.4917 7.01676 13.1833 7.36676 13.15C7.70843 13.1167 8.01676 13.375 8.05009 13.7167C8.29176 16.3333 9.52509 17.3083 12.6001 17.3083H12.7084C16.1001 17.3083 17.3001 16.1083 17.3001 12.7167V7.28333C17.3001 3.89167 16.1001 2.69167 12.7084 2.69167H12.6001C9.50843 2.69167 8.27509 3.68333 8.05009 6.35C8.00843 6.69167 7.72509 6.95 7.36676 6.91667C7.01676 6.89167 6.76676 6.58333 6.79176 6.24167C7.07509 2.925 8.86676 1.44167 12.5918 1.44167H12.7001C16.7918 1.44167 18.5418 3.19167 18.5418 7.28333V12.7167C18.5418 16.8083 16.7918 18.5583 12.7001 18.5583Z"
        fill={color}
      />
      <path
        d="M12.3998 10.625H1.6665C1.32484 10.625 1.0415 10.3417 1.0415 10C1.0415 9.65833 1.32484 9.375 1.6665 9.375H12.3998C12.7415 9.375 13.0248 9.65833 13.0248 10C13.0248 10.3417 12.7498 10.625 12.3998 10.625Z"
        fill={color}
      />
      <path
        d="M10.5419 13.4167C10.3835 13.4167 10.2252 13.3583 10.1002 13.2333C9.85853 12.9917 9.85853 12.5917 10.1002 12.35L12.4502 10L10.1002 7.65C9.85853 7.40834 9.85853 7.00834 10.1002 6.76667C10.3419 6.525 10.7419 6.525 10.9835 6.76667L13.7752 9.55834C14.0169 9.8 14.0169 10.2 13.7752 10.4417L10.9835 13.2333C10.8585 13.3583 10.7002 13.4167 10.5419 13.4167Z"
        fill={color}
      />
    </svg>
  );
};
