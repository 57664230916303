import {useCallback, useContext, useEffect, useState} from 'react';
import {
  RedactedPost,
  RedactPostError,
  RedactPostResponse,
  useRedactPostMutation,
} from './useRedactPostMutation';
import {
  PostContentSource,
  PostStyleSource,
  PostToRedact,
} from './redactPost.types';
import {SavedPostLanguage, SavedPostTone} from '../savedPost/savedPost.types';
import {useTracking} from '../useTracking';
import {UserContext} from '../../common/UserContext/user.context';
import {useSnackbar} from 'notistack';

export const useRedactPost = (
  postSubject: string,
  postIdeas: string,
  contentUrl: string,
  generateFromContentUrl: boolean,
  generateFromProfileUrl: boolean,
  profileUrl: string,
  postTone: SavedPostTone,
  postLang: SavedPostLanguage,
) => {
  const [redactedPosts, setRedactedPosts] = useState<RedactedPost[]>([]);
  const [showPricingPopup, setShowPricingPopup] = useState(false);
  const redactPost = useRedactPostMutation(() => {
    setShowPricingPopup(true);
  });
  const {me} = useContext(UserContext);
  const {trackEvent} = useTracking();
  const {enqueueSnackbar} = useSnackbar();

  const onGeneratePostSubmit = useCallback(async () => {
    const postToRedact: PostToRedact = {
      subject: postSubject,
      contentSource: generateFromContentUrl
        ? PostContentSource.URL
        : PostContentSource.IDEAS,
      content: generateFromContentUrl ? {url: contentUrl} : {ideas: postIdeas},
      styleSource: generateFromProfileUrl
        ? PostStyleSource.PROFILE
        : PostStyleSource.TONE,
      style: generateFromProfileUrl ? {url: profileUrl} : {tone: postTone},
      language: postLang,
    };

    localStorage.setItem(
      'redactPostFormParameters',
      JSON.stringify(postToRedact),
    );
    if (me && !me.hasFinishedOnboarding) {
      trackEvent('Onboarding - Click - Generate post');
    }
    redactPost.mutate(postToRedact);
  }, [
    contentUrl,
    generateFromContentUrl,
    generateFromProfileUrl,
    postIdeas,
    postLang,
    postSubject,
    postTone,
    profileUrl,
    redactPost,
    me,
    trackEvent,
  ]);

  useEffect(() => {
    if (!redactPost.isError) {
      return;
    }
    const error = redactPost.error as RedactPostError;
    enqueueSnackbar(error.message, {
      variant: error.level,
      autoHideDuration: 15000,
    });
  }, [enqueueSnackbar, redactPost.error, redactPost.isError]);

  useEffect(() => {
    const fetchRedactedPosts = async () => {
      if (redactPost.isError || !redactPost.isSuccess || !redactPost.data) {
        return;
      }
      const response = redactPost.data as Response;
      if (!response.ok) {
        return;
      }
      const data = (await response.json()) as RedactPostResponse;
      setRedactedPosts(data.posts);
    };

    fetchRedactedPosts();
  }, [redactPost.data, redactPost.isError, redactPost.isSuccess]);

  return {
    onGeneratePostSubmit,
    redactedPosts,
    isLoading: redactPost.isPending,
    showPricingPopup,
    setShowPricingPopup,
    error: redactPost.error || redactPost.isError,
  };
};
