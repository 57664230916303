import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {TextInput} from '../../../components/atoms/TextInput';
import {isValidLinkedInProfileOrCompanyUrl} from '../../../utils/isValidLinkedInProfileUrl';
import {ProfileUrlStyleToolTip} from './ProfileUrlStyleToolTip';
import HelpRoundedIcon from '../../../components/atoms/Icons/HelpRoundedIcon';
import {Button} from '../../../components/atoms/Button';
import {isTablet} from '../../../utils/responsive';

interface Props {
  onCreateProfile: (profileUrl: string) => Promise<void>;
  isLoading: boolean;
  isRedactPost?: boolean;
}

export const ChooseUserProfile: FunctionComponent<Props> = ({
  onCreateProfile,
  isLoading,
  isRedactPost = false,
}) => {
  const [profileUrl, setProfileUrl] = useState<string>('');
  const [profileUrlError, setProfileUrlError] = useState<string>('');
  const [isToolTipVisible, setIsToolTipVisible] = useState<boolean>(false);

  const onConfirm = async () => {
    if (!isValidLinkedInProfileOrCompanyUrl(profileUrl)) {
      setProfileUrlError(t`Please enter a valid LinkedIn profile URL`);
      return;
    }

    await onCreateProfile(profileUrl);
  };

  return (
    <ContentContainer $isRedactPost={isRedactPost}>
      <InputContainer>
        <StyledTextInput
          label={t`LinkedIn Profile URL`}
          placeholderText={'https://www.linkedin.com/in/yourprofile'}
          value={profileUrl}
          onChange={(event) => {
            setProfileUrl(event.target.value);
            setProfileUrlError('');
          }}
          error={!!profileUrlError}
          helperText={profileUrlError}
        />
        <StyledHelpIconContainer
          onClick={() => setIsToolTipVisible(!isToolTipVisible)}>
          <StyledHelpRoundedIcon />
        </StyledHelpIconContainer>
        {isToolTipVisible && (
          <ProfileUrlStyleToolTip setIsToolTip={setIsToolTipVisible} />
        )}
      </InputContainer>
      <ButtonContainer>
        <Button
          label={
            isLoading
              ? t`Getting your profile...`
              : isRedactPost
                ? t`Use the post generator`
                : t`Let's go!`
          }
          size="big"
          variant="main"
          onClick={onConfirm}
          fullWidth
          disabled={isLoading}
        />
      </ButtonContainer>
      <FooterTitle>
        {t`*RedactAI will retrieve your public profile information to know you better.`}
      </FooterTitle>
    </ContentContainer>
  );
};

const ContentContainer = styled.div<{$isRedactPost: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin-top: 2em;
  width: ${({$isRedactPost}) => ($isRedactPost ? '70%' : '100%')};
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;

const FooterTitle = styled.h2`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1.1em;
  font-weight: 400;
  margin-bottom: 1em;
  text-align: center;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  padding: 0 15em;
  @media (max-width: ${isTablet}) {
    padding: 0 5em;
  }
`;

const StyledHelpIconContainer = styled.div`
  position: absolute;
  right: 1em;
  cursor: pointer;
`;

const StyledHelpRoundedIcon = styled(HelpRoundedIcon)`
  color: ${({theme}) => theme.colors.neutral.shade7};
`;
