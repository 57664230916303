import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const ArrowRight: FunctionComponent<Props> = ({
  width = 8,
  height = 8,
  color = '#1C1B1A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0248 15.0581L17.0832 9.99974L12.0248 4.94141M2.9165 9.99973L16.9415 9.99973"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRight;
