import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import HistoryIcon from '../../../components/atoms/Icons/HistoryIcon';
import {LightTab} from '../../../components/atoms/LightTab';
import {IdeaIcon} from '../../../components/atoms/Icons/IdeaIcon';
import {t} from '@lingui/macro';
import styled from 'styled-components';
import {FilterSelectUsedNotUsed} from '../../../components/molecules/FilterSelectUsedNotUsed';
import {SavedIdea} from '../../../components/molecules/SavedIdea';
import {isTablet} from '../../../utils/responsive';
import SearchBar from '../../../components/atoms/SearchBar';
import {SavedPostLanguage} from '../../../hooks/savedPost/savedPost.types';

export interface SavedIdeaInterface {
  id: string;
  subject: string;
  ideas: string[];
  isUsed: boolean;
  createdAt: Date;
  language?: SavedPostLanguage;
}

interface Props {
  isHistoryTabActive: boolean;
  setIsHistoryTabActive: Dispatch<SetStateAction<boolean>>;
  ideas: SavedIdeaInterface[];
}

export const MyIdeasTab: FunctionComponent<Props> = ({
  isHistoryTabActive,
  setIsHistoryTabActive,
  ideas,
}) => {
  const [searchFilter, setSearchFilter] = useState('');
  const [usedFilter, setUsedFilter] = useState<string>(t`All`);

  const sortedFilteredIdeas = useMemo(() => {
    return [
      ...ideas.filter((idea) => {
        if (usedFilter === t`Not Used` && idea.isUsed) {
          return false;
        }
        if (usedFilter === t`Used` && !idea.isUsed) {
          return false;
        }
        if (
          searchFilter &&
          !idea.subject.toLowerCase().includes(searchFilter.toLowerCase()) &&
          !idea.ideas
            .join(' ')
            .toLowerCase()
            .includes(searchFilter.toLowerCase())
        ) {
          return false;
        }
        return true;
      }),
    ]
      .sort((idea1, idea2) => idea1.subject.localeCompare(idea2.subject))
      .sort(
        (idea1, idea2) => idea2.createdAt.getTime() - idea1.createdAt.getTime(),
      );
  }, [searchFilter, usedFilter, ideas]);
  return (
    <ContentContainer>
      <Between>
        <Row>
          <LightTab
            isSelected={!isHistoryTabActive}
            label={t`Find an idea`}
            icon={<IdeaIcon />}
            onClick={() => setIsHistoryTabActive(false)}
          />
          <LightTab
            isSelected={isHistoryTabActive}
            label={t`History`}
            icon={<HistoryIcon />}
            onClick={() => setIsHistoryTabActive(true)}
          />
        </Row>
        <Row2>
          <StyledSearchBar {...{searchFilter, setSearchFilter}} />
          <FilterSelectUsedNotUsed {...{usedFilter, setUsedFilter}} />
        </Row2>
      </Between>
      <Ideas>
        {sortedFilteredIdeas.map((idea, index) => {
          return <SavedIdea key={index} idea={idea} />;
        })}
      </Ideas>
    </ContentContainer>
  );
};
const ContentContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;
const Row = styled.div`
  display: flex;
  gap: 2em;
`;
const Row2 = styled.div`
  display: flex;
  gap: 1.3em;
  @media (max-width: ${isTablet}) {
    width: 100%;
    gap: 0.5em;
  }
`;
const Between = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    align-items: start;
    gap: 1.5em;
  }
`;
const Ideas = styled.div`
  display: flex;
  gap: 1.5em;
  flex-wrap: wrap;
  justify-content: center;
`;
const StyledSearchBar = styled(SearchBar)`
  width: 30em;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;
