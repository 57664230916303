import {useMutation} from '@tanstack/react-query';

export const useBoostPost = () => {
  const boostPost = useMutation({
    mutationFn: async (postContent: string) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/free-tools/post-booster`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({postContent}),
        },
      );

      if (response.ok) {
        return await response.text();
      }

      throw new Error('Failed to boost the post. Please try again.');
    },
  });

  return boostPost;
};
