import {FunctionComponent, useEffect, useRef, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {Button} from '../../components/atoms/Button';
import {TextInput} from '../../components/atoms/TextInput';
import {useSnackbar} from 'notistack';
import {useGenerateProfileFeedback} from '../../hooks/free-tools/profileFeedback/useGenerateProfileFeedback';
import {SearchIcon} from '../../components/atoms/Icons/SearchIcon';
import GenerateIcon from '../../components/atoms/Icons/GenerateIcon';
import Markdown from 'react-markdown';

interface Suggestion {
  id: string;
  username: string;
  image: string;
}

interface LinkedInAutoCompleteResponse {
  success: number;
  results: Suggestion[];
}

export const ProfileFeedback: FunctionComponent = () => {
  const [feedback, setFeedback] = useState<string>('');
  const [userInput, setUserInput] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const {enqueueSnackbar} = useSnackbar();
  const {mutateAsync: generateProfileFeedback, isPending: loading} =
    useGenerateProfileFeedback();
  const suggestionRef = useRef<HTMLDivElement>(null);

  const extractUsernameFromUrl = (url: string): string | null => {
    const regex = /linkedin\.com\/in\/([^/]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const handleGenerateFeedback = async () => {
    let finalUserName = userInput;

    if (userInput.startsWith('https')) {
      const extractedUsername = extractUsernameFromUrl(userInput);
      if (!extractedUsername) {
        enqueueSnackbar('Invalid LinkedIn profile URL.', {variant: 'error'});
        return;
      }
      finalUserName = extractedUsername;
    }

    try {
      const generatedFeedback = await generateProfileFeedback(finalUserName);
      setFeedback(generatedFeedback);
    } catch (error) {
      enqueueSnackbar('Error generating profile feedback. Please try again.', {
        variant: 'error',
      });
    }
  };

  const fetchSuggestions = async (input: string) => {
    if (input.length === 0) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await fetch(
        `https://us-central1-ez4cast.cloudfunctions.net/linkedinAutoComplete-autoComplete?=&search=${input}`,
      );
      const data: LinkedInAutoCompleteResponse = await response.json();

      if (data.success) {
        const formattedSuggestions = data.results.map((result) => ({
          id: result.id,
          username: result.username,
          image: result.image,
        }));
        setSuggestions(formattedSuggestions);
      }
    } catch (error) {
      console.error('Error fetching LinkedIn username suggestions:', error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setUserInput(input);
    fetchSuggestions(input);
  };

  const handleSuggestionClick = (suggestion: string) => {
    setUserInput(suggestion);
    setSuggestions([]);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionRef.current &&
        !suggestionRef.current.contains(event.target as Node)
      ) {
        setSuggestions([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <MainContainer>
      <ToolContainer>
        <Title>Your profile</Title>
        <InputWrapper>
          <InputContainer>
            <TextInput
              placeholderText="@linkedin_username or LinkedIn profile URL"
              fullWidth
              value={userInput}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
            <Button
              label={'Feedback'}
              variant="main"
              size="medium"
              startIcon={<GenerateIcon height={21} width={21} />}
              onClick={handleGenerateFeedback}
              disabled={loading}
            />
          </InputContainer>
          {suggestions.length > 0 && (
            <SuggestionsContainer ref={suggestionRef}>
              {suggestions.map((suggestion) => (
                <SuggestionItem
                  key={suggestion.id}
                  onClick={() => handleSuggestionClick(suggestion.username)}>
                  <SuggestionImageContainer>
                    <SuggestionImage
                      src={suggestion.image}
                      alt={suggestion.username}
                    />
                  </SuggestionImageContainer>
                  <SuggestionUsername>{suggestion.username}</SuggestionUsername>
                </SuggestionItem>
              ))}
            </SuggestionsContainer>
          )}
        </InputWrapper>
        <FeedbackContainer>
          {loading ? (
            <FeedbackBox>
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
            </FeedbackBox>
          ) : (
            feedback && (
              <>
                <Title>Your Profile Feedback ✨🖊️</Title>
                <FeedbackBox>
                  <Markdown>{feedback}</Markdown>
                </FeedbackBox>
              </>
            )
          )}
        </FeedbackContainer>
      </ToolContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60em;
  margin: 0 auto;
  min-height: calc(100vh - 5.5em);
  padding: 0 1.5em;
`;

const ToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 2em 0 0 0;
  align-items: center;
  border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  border-radius: 2em;
  padding: 1.5em;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 1.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  margin: 0.4em 0;
  align-self: flex-start;
`;

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const SkeletonLoader = styled.div<{
  $width: string;
  $height: string;
  $borderRadius?: string;
  $marginBottom?: string;
}>`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  border-radius: ${(props) => props.$borderRadius || '0.5em'};
  margin-bottom: ${(props) => props.$marginBottom || '0'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
  margin: 1em 0;
`;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
`;

const FeedbackBox = styled.div`
  padding: 1em;
  border-radius: 1em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  font-size: 1.2em;
  font-weight: 400;
  color: ${({theme}) => theme.colors.neutral.shade11};
  line-height: 1.6em;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);

  pre {
    background-color: ${({theme}) => theme.colors.neutral.shade2};
    padding: 1em;
    border-radius: 0.5em;
    overflow-x: auto;
  }

  code {
    font-size: 0.7em;
  }
`;

const SuggestionItem = styled.div`
  padding: 1em;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.colors.neutral.shade5};
  }
  display: flex;
  align-items: center;
  gap: 1em;
`;

const SuggestionUsername = styled.span`
  font-size: 1em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 1em;
  width: 100%;
`;

const SuggestionsContainer = styled.div`
  width: 100%;
  max-height: 30em;
  background-color: white;
  border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
  border-radius: 1em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  margin-top: 1em;
  z-index: 1;
`;

const SuggestionImageContainer = styled.div`
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.neutral.shade5};
  overflow: hidden;
`;

const SuggestionImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  &:not([src]),
  &[src=''],
  &:not([src]):not([alt]) {
    visibility: hidden;
  }
`;
