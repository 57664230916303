import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  className?: string;
}

const RobotIcon: FunctionComponent<Props> = ({
  width = 150,
  height = 150,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2398_62933)">
        <path
          d="M100 199.402C45.0667 199.402 0.533325 154.935 0.533325 100.002C0.533325 45.0685 45.0667 0.535156 100 0.535156C154.933 0.535156 199.467 45.0685 199.467 100.002C199.467 154.935 154.933 199.402 100 199.402Z"
          fill="#FFAC3B"
        />
        <mask
          id="mask0_2398_62933"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="200"
          height="200">
          <path
            d="M100 199.427C45.0801 199.427 0.560059 154.907 0.560059 99.9869C0.560059 45.0669 45.0801 0.546875 100 0.546875C154.92 0.546875 199.44 45.0669 199.44 99.9869C199.44 154.907 154.92 199.427 100 199.427Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2398_62933)">
          <path
            d="M107.333 150.93H92.6667V139.863H107.333V150.93Z"
            fill="#1C1B1A"
          />
          <path
            d="M90.2 143.064H109.8C113.333 143.064 116.133 140.197 116.133 136.73H83.8667C83.8667 140.197 86.6667 143.064 90.2 143.064Z"
            fill="#CFC6C7"
          />
          <path
            d="M109.8 147.73H90.2C86.6667 147.73 83.8667 150.597 83.8667 154.064H116.133C116.133 150.597 113.333 147.73 109.8 147.73Z"
            fill="white"
          />
          <path
            d="M43.2667 83.1328C38.8001 83.1328 35.2001 90.5995 35.2001 99.7328C35.2001 108.933 38.8001 116.399 43.2667 116.399H53.0001V83.1328H43.2667Z"
            fill="#FEFCFD"
          />
          <path
            d="M61.0667 99.7328C61.0667 108.933 57.4667 116.399 53 116.399C48.5334 116.399 44.8667 108.933 44.8667 99.7328C44.8667 90.5995 48.5334 83.1328 53 83.1328C57.4667 83.1328 61.0667 90.5995 61.0667 99.7328Z"
            fill="#F0E6E7"
          />
          <path
            d="M156.733 83.1328C161.2 83.1328 164.8 90.5995 164.8 99.7328C164.8 108.933 161.2 116.399 156.733 116.399H147V83.1328H156.733Z"
            fill="#FEFCFD"
          />
          <path
            d="M138.933 99.7328C138.933 108.933 142.533 116.399 147 116.399C151.467 116.399 155.133 108.933 155.133 99.7328C155.133 90.5995 151.467 83.1328 147 83.1328C142.533 83.1328 138.933 90.5995 138.933 99.7328Z"
            fill="#F0E6E7"
          />
          <path
            d="M79.7334 63.4649L71.2668 66.5316L61.7334 40.3983C60.8668 38.0649 62.0668 35.4649 64.4668 34.5983C66.8001 33.7316 69.3334 34.9316 70.2001 37.2649L79.7334 63.4649Z"
            fill="#FEFCFD"
          />
          <path
            d="M123.733 63.4649L132.133 66.5316L141.667 40.3983C142.533 38.0649 141.333 35.4649 139 34.5983C136.667 33.7316 134.067 34.9316 133.2 37.2649L123.733 63.4649Z"
            fill="#FEFCFD"
          />
          <path
            d="M128.6 136.732H71.4001C61.8668 136.732 53.5335 130.066 51.4668 120.732L46.8001 84.1323C43.9334 71.3323 53.6668 59.2656 66.7335 59.2656H133.267C146.333 59.2656 156.067 71.3323 153.2 84.1323L148.533 120.732C146.467 130.066 138.133 136.732 128.6 136.732Z"
            fill="#FEFCFD"
          />
          <path
            d="M72.5334 59.2656L75.7334 65.3323C77.4001 68.5323 80.6668 70.5323 84.2668 70.5323H115.733C119.333 70.5323 122.6 68.5323 124.267 65.399L127.467 59.2656H72.5334Z"
            fill="#F0E6E7"
          />
          <path
            d="M124.667 78.6641H75.2667C66.2 78.7307 58.8667 86.0641 58.8667 95.1307C58.8667 104.197 66.2 111.531 75.2667 111.597H124.667C133.733 111.597 141.133 104.264 141.133 95.1307C141.133 86.0641 133.733 78.6641 124.667 78.6641Z"
            fill="#1C1B1A"
          />
          <path
            d="M75.3334 86.6641C70.6667 86.6641 66.8667 90.4641 66.8667 95.1307C66.8667 99.7974 70.6667 103.597 75.3334 103.597C80.0667 103.597 83.8667 99.7974 83.8667 95.1307C83.8667 90.4641 80.0667 86.6641 75.3334 86.6641ZM124.667 86.6641C119.933 86.6641 116.133 90.4641 116.133 95.1307C116.133 99.7974 119.933 103.597 124.667 103.597C129.333 103.597 133.133 99.7974 133.133 95.1307C133.133 90.4641 129.333 86.6641 124.667 86.6641Z"
            fill="#FEFCFD"
          />
          <path
            d="M88.6001 121.863C86.8001 121.863 85.2667 123.397 85.2667 125.197C85.2667 127.063 86.8001 128.597 88.6001 128.597C90.4667 128.597 91.9334 127.063 91.9334 125.197C91.9334 123.397 90.4667 121.863 88.6001 121.863ZM100 121.863C98.1334 121.863 96.6667 123.397 96.6667 125.197C96.6667 127.063 98.1334 128.597 100 128.597C101.867 128.597 103.333 127.063 103.333 125.197C103.333 123.397 101.867 121.863 100 121.863ZM111.4 121.863C109.533 121.863 108.067 123.397 108.067 125.197C108.067 127.063 109.533 128.597 111.4 128.597C113.2 128.597 114.733 127.063 114.733 125.197C114.733 123.397 113.2 121.863 111.4 121.863Z"
            fill="#1C1B1A"
          />
          <path
            d="M147 210.4H53.0001V171.866C53.0001 162 60.9335 154.066 70.7335 154.066H129.267C139.067 154.066 147 162 147 171.866V210.4Z"
            fill="white"
          />
          <path
            d="M108.558 186.07C110.511 186.07 112.093 184.488 112.093 182.535C112.093 180.583 110.511 179 108.558 179C106.606 179 105.023 180.583 105.023 182.535C105.023 184.488 106.606 186.07 108.558 186.07Z"
            fill="#FFAC3B"
          />
          <path
            d="M99.5404 188.699C101.493 188.699 103.076 187.116 103.076 185.164C103.076 183.212 101.493 181.629 99.5404 181.629C97.588 181.629 96.0052 183.212 96.0052 185.164C96.0052 187.116 97.588 188.699 99.5404 188.699Z"
            fill="#1C1B1A"
          />
          <path
            d="M90.5352 188.699C92.4876 188.699 94.0703 187.116 94.0703 185.164C94.0703 183.212 92.4876 181.629 90.5352 181.629C88.5827 181.629 87 183.212 87 185.164C87 187.116 88.5827 188.699 90.5352 188.699Z"
            fill="#1C1B1A"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2398_62933">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RobotIcon;
