import {Dispatch, FunctionComponent, SetStateAction, useEffect} from 'react';
import {t} from '@lingui/macro';
import styled from 'styled-components';
import {isTablet} from '../../../utils/responsive';
import {
  SavedPostLanguage,
  SavedPostTone,
} from '../../../hooks/savedPost/savedPost.types';
import {LightTab} from '../../../components/atoms/LightTab';
import LinkIcon from '../../../components/atoms/Icons/LinkIcon';
import LinkedInIcon from '../../../components/atoms/Icons/LinkedInIcon';
import {useSavedPosts} from '../../../hooks/savedPostsAndIdeas/useSavedPosts';
import {ProfileUrlStyle} from '../../onboarding/components/ProfileUrlStyle';
import {PostStyle} from '../../onboarding/components/PostStyle';

export enum RedactStyleSource {
  PROFILE_URL = 'PROFILE_URL',
  POST_TYPE = 'POST_TYPE',
}

interface Props {
  styleSource: RedactStyleSource;
  setStyleSource: (source: RedactStyleSource) => void;
  postTone: SavedPostTone;
  setPostTone: Dispatch<SetStateAction<SavedPostTone>>;
  postLang: SavedPostLanguage;
  setPostLang: React.Dispatch<React.SetStateAction<SavedPostLanguage>>;
  profileUrl: string;
  setProfileUrl: Dispatch<SetStateAction<string>>;
  profileUrlError: string;
  isSubmitted: boolean;
  isNewPost: boolean;
}

export const RedactPostStyle: FunctionComponent<Props> = ({
  styleSource,
  setStyleSource,
  postTone,
  setPostTone,
  postLang,
  setPostLang,
  profileUrl,
  setProfileUrl,
  profileUrlError,
  isSubmitted,
  isNewPost,
}) => {
  const {savedPosts} = useSavedPosts();

  useEffect(() => {
    if (!isNewPost || savedPosts.length === 0) {
      return;
    }

    const lastProfileUrlUsed = savedPosts.find(
      (post) => !!post.profileUrl,
    )?.profileUrl;
    if (lastProfileUrlUsed) {
      setProfileUrl(lastProfileUrlUsed);
    }

    const lastLanguageUsed = savedPosts.find(
      (post) => !!post.language,
    )?.language;
    if (lastLanguageUsed) {
      setPostLang(lastLanguageUsed);
    }
  }, [isNewPost, savedPosts, setProfileUrl, setPostLang]);

  return (
    <ContentContainer>
      <Between>
        <P>{t`writing style`}</P>
        <Div>
          <LightTab
            label={t`From LinkedIn profile URL`}
            icon={<StyledLinkedInIcon size={18} />}
            isSelected={styleSource === RedactStyleSource.PROFILE_URL}
            onClick={() => setStyleSource(RedactStyleSource.PROFILE_URL)}
          />
          <LightTab
            label={t`From post type`}
            icon={<StyledLinkIcon />}
            isSelected={styleSource === RedactStyleSource.POST_TYPE}
            onClick={() => setStyleSource(RedactStyleSource.POST_TYPE)}
          />
        </Div>
      </Between>
      {styleSource === RedactStyleSource.POST_TYPE ? (
        <PostStyle
          onGoBack={() => {}}
          chooseStyleSource={() => {}}
          setPostTone={setPostTone}
          postTone={postTone}
          setPostLang={setPostLang}
          postLang={postLang}
          isOnboarding={false}
        />
      ) : (
        <ProfileUrlStyle
          onGoBack={() => {}}
          setProfileUrl={setProfileUrl}
          profileUrl={profileUrl}
          setPostLang={setPostLang}
          postLang={postLang}
          profileUrlError={profileUrlError}
          isSubmitted={isSubmitted}
          isOnboarding={false}
        />
      )}
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin-top: -0.5em;
  width: 100%;
  box-sizing: border-box;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
`;

const Between = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    align-items: start;
    gap: 1em;
  }
`;

const P = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  @media (max-width: ${isTablet}) {
    width: 100%;
    justify-content: space-between;
  }
`;

const StyledLinkIcon = styled(LinkIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;

const StyledLinkedInIcon = styled(LinkedInIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
