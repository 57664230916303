import {useMutation, useQueryClient} from '@tanstack/react-query';
import {regeneratePostMock} from './regeneratePost.mock';
import {useContext} from 'react';
import {useTracking} from '../useTracking';
import {UserContext} from '../../common/UserContext/user.context';
import {t} from '@lingui/macro';

export interface RegeneratedPost {
  id: string;
  text: string;
  isFavourite: boolean;
}

export interface RegeneratePostResponse {
  posts: RegeneratedPost[];
}

export class RegeneratePostError extends Error {
  constructor(
    message: string,
    public readonly level: 'error' | 'warning' | 'info',
  ) {
    super(message);
    this.name = 'RegeneratePostError';
  }
}

export const useRegeneratePostMutation = () => {
  const {trackEvent} = useTracking();
  const {me} = useContext(UserContext);
  const queryClient = useQueryClient();

  const regeneratePost = useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['savedPosts']});
    },
    mutationFn: async (data: {lastPostsIds: string[]; message: string}) => {
      if (
        import.meta.env.VITE_NODE_ENV == 'development' &&
        import.meta.env.VITE_WITH_AI !== 'true'
      ) {
        if (me && !me.hasFinishedOnboarding) {
          trackEvent('Onboarding - Post has been generated');
        }
        return regeneratePostMock();
      }
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/redactPost/chat`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );

      if (response.ok) {
        return response;
      }

      throw new RegeneratePostError(
        t`Please check if all the fields are filled correctly`,
        'info',
      );
    },
  });

  return regeneratePost;
};
