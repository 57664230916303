import ThirdPartyEmailPassword from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import {t} from '@lingui/macro';
import {OptionsObject} from 'notistack';

export const handleGoogleSignInUp = async (
  enqueueSnackbar: (message: string, options?: OptionsObject) => void,
  setLoading: (isLoading: boolean) => void,
) => {
  try {
    setLoading(true);
    const authUrl =
      await ThirdPartyEmailPassword.getAuthorisationURLWithQueryParamsAndSetState(
        {
          thirdPartyId: 'google',
          frontendRedirectURI: `${window.location.origin}/auth/callback/google`,
        },
      );
    enqueueSnackbar(
      t`You are being redirected to Google for authentication...`,
      {
        variant: 'success',
      },
    );
    window.location.href = authUrl;
  } catch (err) {
    console.error('Error during Google sign-in:', err);
    enqueueSnackbar(t`Oops! Something went wrong with Google sign-in.`, {
      variant: 'error',
    });
    setLoading(false);
  }
};
