import {t} from '@lingui/macro';
import {FunctionComponent, useContext} from 'react';
import styled from 'styled-components';
import {isTablet} from '../../../utils/responsive';
import {RedactaiIcon} from '../../../components/atoms/Icons/RedactaiIcon';
import {OnboardingContentChoiceCards} from '../../../components/molecules/ChooseContentSourceCards/OnboardingContentChoiceCards';
import {UserContext} from '../../../common/UserContext/user.context';

export enum ContentSource {
  MY_OWN_IDEAS = 'MY_OWN_IDEAS',
  FROM_AN_URL = 'FROM_AN_URL',
  I_DONT_HAVE_IDEAS = 'I_DONT_HAVE_IDEAS',
}

interface Props {
  chooseContentSource: (contentSource: ContentSource) => void;
}

export const ChooseContentSource: FunctionComponent<Props> = ({
  chooseContentSource,
}) => {
  const {me} = useContext(UserContext);
  return (
    <Container>
      <Col>
        <div>
          <StyledRedactaiIcon />
        </div>
        <Header>{t`Choose the content of your post`}</Header>
        <SubHeader>
          {t`Let's do this,`} {me?.firstName}!{' '}
          <StrongText>{t`It will only take 2 mins`}</StrongText> 🚀
        </SubHeader>
      </Col>
      <OnboardingContentChoiceCards chooseContentSource={chooseContentSource} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4em;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  text-align: center;
`;
const Header = styled.div`
  font-size: 2.75em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const SubHeader = styled.div`
  font-size: 1.25em;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.neutral.shade8};
`;
const StrongText = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 600;
`;
const StyledRedactaiIcon = styled(RedactaiIcon)`
  @media (max-width: ${isTablet}) {
    scale: 0.75;
  }
  @media (min-width: ${isTablet}) {
    display: none;
  }
`;
