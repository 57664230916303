import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {useSnackbar} from 'notistack';
import {TextInput} from '../../../components/atoms/TextInput';
import {SelectButton} from '../../../components/atoms/SelectButton';
import {isTablet} from '../../../utils/responsive';
import {Button} from '../../../components/atoms/Button';

enum ReferralSource {
  LINKEDIN_MESSAGE = 'LINKEDIN_MESSAGE',
  LINKEDIN_POST = 'LINKEDIN_POST',
  LINKEDIN_TRAINING = 'LINKEDIN_TRAINING',
  WORD_OF_MOUTH = 'WORD_OF_MOUTH',
  NEWSLETTER = 'NEWSLETTER',
  EMAIL = 'EMAIL',
  GOOGLE_SEARCH = 'GOOGLE_SEARCH',
  OTHER = 'OTHER',
}

export interface UserReferralSource {
  source: ReferralSource;
  details?: string;
}

interface Props {
  setUserReferralSource: (userReferralSource: UserReferralSource) => void;
}

export const ReferralSourceForm: FunctionComponent<Props> = ({
  setUserReferralSource,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [error, setError] = useState<string>('');
  const [referralSource, setReferralSource] = useState<ReferralSource>();
  const [referralSourceDetails, setReferralSourceDetails] =
    useState<string>('');
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const referralSourceToText = useCallback((source: ReferralSource): string => {
    switch (source) {
      case ReferralSource.LINKEDIN_MESSAGE:
        return t`LinkedIn message`;
      case ReferralSource.LINKEDIN_POST:
        return t`LinkedIn post`;
      case ReferralSource.LINKEDIN_TRAINING:
        return t`LinkedIn training`;
      case ReferralSource.WORD_OF_MOUTH:
        return t`Word of mouth`;
      case ReferralSource.NEWSLETTER:
        return t`Newsletter`;
      case ReferralSource.EMAIL:
        return t`Email`;
      case ReferralSource.GOOGLE_SEARCH:
        return t`Google search`;
      case ReferralSource.OTHER:
        return t`Other`;
    }
  }, []);

  const referralOptions = useMemo(
    () =>
      Object.values(ReferralSource).map((source) => ({
        name: referralSourceToText(source),
        value: source,
      })),
    [referralSourceToText],
  );

  const onSubmit = useCallback(() => {
    if (!referralSource) {
      enqueueSnackbar(t`Please select a referral source`, {
        variant: 'error',
      });
      return;
    }
    setIsFormSubmitted(true);
    if (referralSource === ReferralSource.OTHER && !referralSourceDetails) {
      setError(t`Please fill in the referral source details`);
      return;
    }
    setUserReferralSource({
      source: referralSource,
      details: referralSourceDetails,
    });
  }, [
    referralSourceDetails,
    referralSource,
    setUserReferralSource,
    enqueueSnackbar,
  ]);

  const handleSelect = useCallback((source: ReferralSource) => {
    setReferralSource(source);
  }, []);

  useEffect(() => {
    if (referralSource && referralSource !== ReferralSource.OTHER) {
      onSubmit();
    }
  }, [referralSource, onSubmit]);

  return (
    <Col>
      <Container>
        <StrongText>{t`How did you know us?`}</StrongText>
        <Suggestions>
          {referralOptions.map((referralOption, index) => (
            <SelectButton
              key={index}
              text={referralOption.name}
              isSelected={referralOption.value === referralSource}
              onClick={() => handleSelect(referralOption.value)}
            />
          ))}
        </Suggestions>
        {referralSource === ReferralSource.OTHER && (
          <TextInput
            label={t`Where did you know us?`}
            placeholder={t`Where did you know us?`}
            onChange={(event) => setReferralSourceDetails(event.target.value)}
            error={isFormSubmitted && !referralSourceDetails}
            helperText={
              isFormSubmitted && referralSourceDetails.length === 0 && error
            }
          />
        )}
      </Container>
      {referralSource === ReferralSource.OTHER && (
        <StyledButton
          label={t`Start exploring`}
          size="big"
          variant="main"
          onClick={onSubmit}
        />
      )}
    </Col>
  );
};

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4em;
  padding: 4em;
  border-radius: 2em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  max-width: 39em;
`;
const StrongText = styled.span`
  font-size: 1.5em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
  text-align: center;
`;
const Suggestions = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.25em;
`;
const StyledButton = styled(Button)`
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;
