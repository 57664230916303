import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import {t} from '@lingui/macro';
import styled, {useTheme} from 'styled-components';
import {RedactPostProfileSelect} from './RedactPostProfileSelect';
import {Profile} from '../../../hooks/profile/profile.ts';
import {Button} from '../../../components/atoms/Button.tsx';
import {isTablet} from '../../../utils/responsive.ts';
import EditIcon from '../../../components/atoms/Icons/EditIcon.tsx';

interface Props {
  postProfile: Profile | undefined;
  setPostProfile: Dispatch<SetStateAction<Profile | undefined>>;
  savedProfiles: Profile[];
  setEditProfile: Dispatch<SetStateAction<boolean>>;
  setCreateProfile: Dispatch<SetStateAction<boolean>>;
  setProfileIdToEdit: Dispatch<SetStateAction<string | undefined>>;
}

export const RedactPostProfile: FunctionComponent<Props> = ({
  postProfile,
  setPostProfile,
  savedProfiles,
  setEditProfile,
  setCreateProfile,
  setProfileIdToEdit,
}) => {
  const theme = useTheme();
  return (
    <ContentContainer>
      <P>{t`Profile`}</P>
      <Row>
        <RedactPostProfileSelect
          selectedProfile={postProfile}
          setSelectedProfile={setPostProfile}
          profileOptions={savedProfiles}
          setEditProfile={setEditProfile}
          setCreateProfile={setCreateProfile}
          setProfileIdToEdit={setProfileIdToEdit}
        />
        <StyledButton
          label={t`Change writing style`}
          size="big"
          variant="light"
          onClick={() => {
            setProfileIdToEdit(postProfile?.id);
            setEditProfile(true);
          }}
          startIcon={
            <StyledArrowRight
              width={23}
              height={23}
              color={theme.colors.neutral.shade11}
            />
          }
        />
      </Row>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin-top: -0.5em;
  width: 100%;
  box-sizing: border-box;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
`;

const P = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const Row = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const StyledArrowRight = styled(EditIcon)`
  stroke: ${({theme}) => theme.colors.neutral.shade11};

  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;

const StyledButton = styled(Button)`
  height: 100%;
  width: fit-content;
`;
