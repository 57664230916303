import {t} from '@lingui/macro';
import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {useCreatorsSuggestionsBasedOnLocale} from '../../../hooks/useCreatorsSuggestionsBasedOnLocale.ts';

interface Props {
  onClick: () => void;
}

export const CreatorStyle: FunctionComponent<Props> = ({onClick}) => {
  const creators = useCreatorsSuggestionsBasedOnLocale();
  return (
    <Container onClick={onClick}>
      <Header>{t`Another creator's style`}</Header>
      <ProfileImageContainer>
        {creators.map((creator, index) => (
          <ProfileImage key={index} src={creator.imageUrl} alt={creator.name} />
        ))}
      </ProfileImageContainer>
      <Div>
        <b>{t`You can choose any creator you want.`}</b>
        <br />
        <br />
        {t`RedactAI will analyze the lasts posts of another creator of your choice.`}
      </Div>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1em;
  padding-top: 2em;
  min-width: 19.875em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2em;
  align-items: center;
  cursor: pointer;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.25em;
  letter-spacing: 0.0125em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Div = styled.div`
  padding: 0.75em;
  border-radius: 0.875em;
  background-color: ${({theme}) => theme.colors.neutral.shade13};
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 0.875em;
  line-height: 1.25em;
  letter-spacing: 0.0125em;
  text-align: center;
`;

const ProfileImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ProfileImage = styled.img`
  width: 5em;
  height: 5em;
  border-radius: 50%;
  margin-right: -10px;
`;
