import {FunctionComponent} from 'react';

interface Props {
  width?: string;
  height?: string;
  onClick?: () => void;
  className?: string;
}

const RedactAILogo: FunctionComponent<Props> = ({
  width = '161',
  height = '23',
  onClick,
  className,
}) => (
  <svg
    className={className}
    onClick={onClick}
    width={width}
    height={height}
    viewBox="0 0 161 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.2755 3.49726H30.2412V0.0117188H40.2389C44.3322 0.0117188 47.1852 2.52974 47.1852 6.74712C47.1852 10.7784 44.4191 12.4406 43.4763 12.6266V12.6887C43.4763 12.6887 44.171 12.9988 44.5803 13.8174L46.751 18.1216C47.0983 18.7791 47.6317 18.8411 48.3263 18.8411H48.7357V22.3266H46.565C44.9028 22.3266 44.171 22.0785 43.4515 20.6645L40.5862 15.033C40.1148 14.1523 39.6435 13.9911 38.4775 13.9911H36.406V22.3266H32.2879V3.49726H32.2755ZM39.6683 10.4807C41.7398 10.4807 42.9678 9.19072 42.9678 6.95799C42.9678 4.76247 41.7398 3.49726 39.7303 3.49726H36.3936V10.4807H39.6683Z"
      fill="#1C1B1A"
      style={{fill: '#1C1B1A', fillOpacity: 1}}
    />
    <path
      d="M57.9766 5.91797C62.5661 5.91797 65.0841 9.27947 65.0841 13.5341C65.0841 14.0054 64.9849 15.0101 64.9849 15.0101H53.9205C54.2306 17.8383 56.3145 19.3144 58.7333 19.3144C61.3133 19.3144 63.2359 17.5158 63.2359 17.5158L64.8981 20.2819C64.8981 20.2819 62.4421 22.7007 58.448 22.7007C53.139 22.7007 49.8271 18.8678 49.8271 14.3031C49.8271 9.3787 53.1638 5.91797 57.9766 5.91797ZM60.9908 12.244C60.9288 10.3214 59.6015 9.00658 57.9766 9.00658C55.9299 9.00658 54.4911 10.2346 54.0445 12.244H60.9908Z"
      fill="#1C1B1A"
      style={{fill: '#1C1B1A', fillOpacity: 1}}
    />
    <path
      d="M74.6354 5.91674C78.1209 5.91674 79.2621 7.86417 79.2621 7.86417H79.3241C79.3241 7.86417 79.2869 7.45484 79.2869 6.92147V4.09334C79.2869 3.62199 79.0388 3.39871 78.5923 3.39871H77.3395V0H81.1475C82.6236 0 83.281 0.657417 83.281 2.1335V18.2712C83.281 18.7053 83.5291 18.9658 83.9756 18.9658H85.2036V22.3273H81.5196C80.0684 22.3273 79.535 21.6327 79.535 20.69C79.535 20.3427 79.535 20.0946 79.535 20.0946H79.4854C79.4854 20.0946 78.1705 22.6994 74.4865 22.6994C70.1451 22.6994 67.3418 19.2759 67.3418 14.3019C67.3418 9.22862 70.3932 5.91674 74.6354 5.91674ZM79.3861 14.2895C79.3861 11.8087 78.0961 9.35266 75.4292 9.35266C73.2337 9.35266 71.3979 11.1513 71.3979 14.3267C71.3979 17.3781 72.9981 19.3627 75.3548 19.3627C77.4387 19.3503 79.3861 17.837 79.3861 14.2895Z"
      fill="#1C1B1A"
      style={{fill: '#1C1B1A', fillOpacity: 1}}
    />
    <path
      d="M96.4664 12.2812H96.975V12.1572C96.975 9.86246 96.0571 9.00658 94.0228 9.00658C93.3034 9.00658 91.8893 9.19264 91.8893 10.0733V10.9168H88.1805V9.21745C88.1805 6.22807 92.3979 5.91797 94.06 5.91797C99.4062 5.91797 100.981 8.72129 100.981 12.3061V18.2848C100.981 18.719 101.23 18.9794 101.676 18.9794H102.929V22.3409H99.3442C97.8681 22.3409 97.2975 21.5223 97.2975 20.5796C97.2975 20.1702 97.3347 19.8849 97.3347 19.8849H97.2727C97.2727 19.8849 96.0447 22.7131 92.4351 22.7131C89.5698 22.7131 86.9649 20.9145 86.9649 17.7142C86.9401 12.6782 93.8492 12.2812 96.4664 12.2812ZM93.3778 19.5996C95.5113 19.5996 97.0246 17.3297 97.0246 15.3823V15.0101H96.33C94.2585 15.0101 90.9466 15.2954 90.9466 17.5282C90.959 18.5949 91.7405 19.5996 93.3778 19.5996Z"
      fill="#1C1B1A"
      style={{fill: '#1C1B1A', fillOpacity: 1}}
    />
    <path
      d="M113.286 5.91797C115.358 5.91797 119.104 6.76144 119.104 9.53995V11.4254H115.581V10.5447C115.581 9.66399 114.266 9.29187 113.286 9.29187C110.52 9.29187 108.536 11.4006 108.536 14.2907C108.536 17.5654 110.955 19.2275 113.535 19.2275C116.276 19.2275 118.285 17.0568 118.285 17.0568L119.947 19.8229C119.947 19.8229 117.553 22.7131 113.187 22.7131C107.903 22.7131 104.504 18.967 104.504 14.3155C104.517 9.78803 107.779 5.91797 113.286 5.91797Z"
      fill="#1C1B1A"
      style={{fill: '#1C1B1A', fillOpacity: 1}}
    />
    <path
      d="M123.098 9.47526H121.026V6.29982H123.197V1.93359H127.092V6.29982H130.739V9.47526H127.092V15.9502C127.092 18.5922 129.163 18.9644 130.267 18.9644C130.702 18.9644 130.987 18.9023 130.987 18.9023V22.4003C130.987 22.4003 130.478 22.4995 129.759 22.4995C127.526 22.4995 123.098 21.8421 123.098 16.4587V9.47526Z"
      fill="#1C1B1A"
      style={{fill: '#1C1B1A', fillOpacity: 1}}
    />
    <path
      d="M132.438 20.391H132.848C133.418 20.391 133.765 20.2917 133.976 19.6963L141.208 0.234375H143.565L150.796 19.6963C151.019 20.2917 151.33 20.391 151.925 20.391H152.297V22.3384H151.292C149.717 22.3384 149.246 22.0283 148.688 20.515L146.765 15.2929H137.995L136.048 20.515C135.477 22.0283 135.043 22.3384 133.468 22.3384H132.426V20.391H132.438ZM146.182 13.3703L143.255 5.48129C142.882 4.43935 142.411 2.61596 142.411 2.61596H142.349C142.349 2.61596 141.878 4.43935 141.506 5.48129L138.615 13.3703H146.182Z"
      fill="#FFAC3B"
      style={{fill: '#FFAC3B', fillOpacity: 1}}
    />
    <path
      d="M154.17 20.391H156.241V2.18181H154.17V0.234375H160.496V2.18181H158.387V20.391H160.496V22.3384H154.17V20.391Z"
      fill="#FFAC3B"
      style={{fill: '#FFAC3B', fillOpacity: 1}}
    />
    <path
      d="M21.5581 19.7109C23.5105 19.7109 25.0933 18.1282 25.0933 16.1758C25.0933 14.2234 23.5105 12.6406 21.5581 12.6406C19.6057 12.6406 18.0229 14.2234 18.0229 16.1758C18.0229 18.1282 19.6057 19.7109 21.5581 19.7109Z"
      fill="#FFAC3B"
      style={{fill: '#FFAC3B', fillOpacity: 1}}
    />
    <path
      d="M12.5405 22.3398C14.4929 22.3398 16.0757 20.7571 16.0757 18.8047C16.0757 16.8523 14.4929 15.2695 12.5405 15.2695C10.5881 15.2695 9.00537 16.8523 9.00537 18.8047C9.00537 20.7571 10.5881 22.3398 12.5405 22.3398Z"
      fill="#1C1B1A"
      style={{fill: '#1C1B1A', fillOpacity: 1}}
    />
    <path
      d="M3.53516 22.3398C5.48757 22.3398 7.07031 20.7571 7.07031 18.8047C7.07031 16.8523 5.48757 15.2695 3.53516 15.2695C1.58274 15.2695 0 16.8523 0 18.8047C0 20.7571 1.58274 22.3398 3.53516 22.3398Z"
      fill="#1C1B1A"
      style={{fill: '#1C1B1A', fillOpacity: 1}}
    />
  </svg>
);

export default RedactAILogo;
