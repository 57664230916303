import {FunctionComponent} from 'react';
import styled from 'styled-components';

interface Props {
  label: string;
  variant: 'warning' | 'info' | 'completed' | 'alert' | 'warningOutlined';
  fullWidth?: boolean;
  className?: string;
}

export const Status: FunctionComponent<Props> = ({
  label,
  variant,
  fullWidth,
  className,
}) => {
  return (
    <StyledStatus
      $variant={variant}
      $fullWidth={fullWidth}
      className={className}>
      <span>{label}</span>
    </StyledStatus>
  );
};

const StyledStatus = styled.div<{
  $variant: Props['variant'];
  $fullWidth: Props['fullWidth'];
}>`
  font-weight: 600;
  font-size: 0.7em;
  padding: 0.5em 0.7em;
  border-radius: 0.5em;
  width: ${({$fullWidth}) => !$fullWidth && 'fit-content'};
  ${({theme, $variant}) => {
    switch ($variant) {
      case 'warning':
        return `
          background-color: ${theme.colors.primary2.shade1};
          color: ${theme.colors.primary2.shade2};
        `;
      case 'info':
        return `
          background-color: ${theme.colors.neutral.shade4};
          color: ${theme.colors.neutral.shade11};
        `;
      case 'completed':
        return `
          background-color: ${theme.colors.primary3.shade1};
          color: ${theme.colors.primary3.shade2};
        `;
      case 'alert':
        return `
          background-color: ${theme.colors.primary1.shade1};
          color: ${theme.colors.primary1.shade3};
        `;
      case 'warningOutlined':
        return `
          background-color: ${theme.colors.primary2.shade1};
          border: 1px solid ${theme.colors.primary2.shade2};
          color: ${theme.colors.primary2.shade2};
        `;
    }
  }}
`;
