import {FunctionComponent} from 'react';

/* global SVGSVGElement */

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: string;
  color?: string;
  className?: string;
}

export const IdeaIcon: FunctionComponent<Props> = ({
  size = '16',
  color = '#FFAC3B',
  className,
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M12.8278 4.23779C12.1343 2.83745 10.794 1.80386 9.24028 1.46378C7.61322 1.10369 5.94615 1.49045 4.6725 2.51737C3.39219 3.53762 2.66534 5.06466 2.66534 6.69839C2.66534 8.42548 3.69893 10.2326 5.25931 11.2795V11.833C5.25264 12.0197 5.24597 12.3064 5.4727 12.5398C5.70609 12.7799 6.05284 12.8065 6.32624 12.8065H9.74707C10.1072 12.8065 10.3806 12.7065 10.5673 12.5198C10.8207 12.2597 10.814 11.9263 10.8073 11.7463V11.2795C12.8745 9.88583 14.1748 6.94511 12.8278 4.23779Z"
        fill={color}
      />
      <path
        d="M10.1943 14.6672C10.1543 14.6672 10.1077 14.6606 10.0676 14.6472C8.72732 14.2671 7.32031 14.2671 5.97998 14.6472C5.73325 14.7139 5.47319 14.5739 5.40651 14.3271C5.33316 14.0804 5.47986 13.8204 5.72659 13.7537C7.23362 13.3269 8.82067 13.3269 10.3277 13.7537C10.5744 13.827 10.7211 14.0804 10.6478 14.3271C10.5811 14.5339 10.3944 14.6672 10.1943 14.6672Z"
        fill={color}
      />
      <path
        d="M8.02017 9.23176C7.93348 9.23176 7.84679 9.21175 7.77344 9.16507C7.53339 9.02504 7.45337 8.7183 7.58673 8.48491L8.15354 7.498H7.5934C7.25998 7.498 6.98658 7.3513 6.83988 7.10457C6.69318 6.85117 6.70652 6.54443 6.87322 6.25103L7.58673 5.01072C7.72677 4.77067 8.03351 4.69065 8.2669 4.82401C8.50696 4.96405 8.58698 5.27079 8.45361 5.50418L7.8868 6.49775H8.44694C8.78036 6.49775 9.05376 6.64446 9.20046 6.89118C9.34716 7.14458 9.33382 7.45132 9.16712 7.74473L8.45361 8.98503C8.36025 9.14507 8.19355 9.23176 8.02017 9.23176Z"
        fill={color}
      />
    </svg>
  );
};
