import {NavigateFunction} from 'react-router-dom';
import EmailVerification, {
  isEmailVerified,
} from 'supertokens-web-js/recipe/emailverification';

export const emailVerificationCheck = async (navigate: NavigateFunction) => {
  const emailVerification = await isEmailVerified();
  if (!emailVerification.isVerified) {
    await EmailVerification.sendVerificationEmail();
    navigate('/auth/send-email-verification');
  }
};
