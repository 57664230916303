import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {isTablet} from '../../utils/responsive';

interface Props {
  src: string;
  name: string;
  description: string;
  selected: boolean;
  onClick?: () => void;
  className?: string;
}

export const Gif: FunctionComponent<Props> = ({
  src,
  name,
  description,
  onClick,
  selected,
  className,
}) => {
  return (
    <Container className={className} $selected={selected} onClick={onClick}>
      <Image src={src} alt={name} />
      <TextContainer>
        <Name>{name}</Name>
        <Description>{description}</Description>
      </TextContainer>
    </Container>
  );
};
const Container = styled.div<{$selected: boolean}>`
  width: 12.8em;
  height: 16.15em;
  border-radius: 1.5em;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-shadow: ${({$selected, theme}) =>
    $selected ? `0 0 0 4px ${theme.colors.primary1.shade2}` : 'none'};

  @media (max-width: ${isTablet}) {
    width: 12.5em;
  }
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const TextContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  color: ${({theme}) => theme.colors.neutral.shade1};
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  display: flex;
  flex-direction: column;
  gap: 0.15em;
  padding: 1.2em;
  justify-content: end;
`;
const Name = styled.span`
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
`;
const Description = styled.span`
  font-size: 0.875em;
  line-height: 1.25em;
`;
