import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {Button} from '../../components/atoms/Button';
import {TextInput} from '../../components/atoms/TextInput';
import GenerateIcon from '../../components/atoms/Icons/GenerateIcon';
import {useSnackbar} from 'notistack';
import CopyIcon from '../../components/atoms/Icons/CopyIcon';
import {useBoostPost} from '../../hooks/free-tools/postBooster/useBoostPost';

export const PostBooster: FunctionComponent = () => {
  const [post, setPost] = useState<string>('');
  const [userInput, setUserInput] = useState<string>('');
  const {enqueueSnackbar} = useSnackbar();
  const postBoxRef = useRef<HTMLDivElement>(null);

  const {mutateAsync: boostPost, isPending: loading} = useBoostPost();

  const handleGeneratePosts = async () => {
    try {
      const boostedPost = await boostPost(userInput);
      setPost(boostedPost);
    } catch (error) {
      enqueueSnackbar('Error boosting post. Please try again.', {
        variant: 'error',
      });
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(post).then(() => {
      enqueueSnackbar('Copied to clipboard', {
        variant: 'success',
      });
    });
  };

  const formatPostContent = (content: string) => {
    return content.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  useEffect(() => {
    if (post && postBoxRef.current) {
      postBoxRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [post]);

  return (
    <MainContainer>
      <ToolContainer>
        <Title>Your post content</Title>
        <InputContainer>
          <TextInput
            placeholderText="Paste your post content here"
            label="Post Content"
            fullWidth
            value={userInput}
            onChange={(event) => setUserInput(event.target.value)}
            multiline
            rows={5}
          />
        </InputContainer>
        <Button
          label={'Boost This Post'}
          variant="main"
          size="medium"
          startIcon={<GenerateIcon height={21} width={21} />}
          onClick={handleGeneratePosts}
          disabled={loading}
        />
        <PostsContainer>
          {loading ? (
            <PostBox>
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
            </PostBox>
          ) : (
            post && (
              <div ref={postBoxRef}>
                <Title>Your boosted post</Title>
                <PostBox>
                  <CopyIconContainer>
                    <CopyIcon width={18} height={18} />
                  </CopyIconContainer>
                  <div>{formatPostContent(post)}</div>
                </PostBox>
              </div>
            )
          )}
        </PostsContainer>
        {post && (
          <ButtonsContainer>
            <Button
              label={'Copy'}
              variant="light"
              size="medium"
              startIcon={<CopyIcon />}
              onClick={() => copyToClipboard()}
              disabled={loading}
            />
            <Button
              label={'Try Again'}
              variant="main"
              size="medium"
              onClick={handleGeneratePosts}
              disabled={loading}
            />
          </ButtonsContainer>
        )}
      </ToolContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60em;
  margin: 0 auto;
  min-height: calc(100vh - 5.5em);
  padding: 0 1.5em;
`;

const ToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 2em 0 0 0;
  align-items: center;
  border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  border-radius: 2em;
  padding: 1.5em;
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 1em;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 1.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  margin: 0.4em 0;
  align-self: flex-start;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const SkeletonLoader = styled.div<{
  $width: string;
  $height: string;
  $borderRadius?: string;
  $marginBottom?: string;
}>`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  border-radius: ${(props) => props.$borderRadius || '0.5em'};
  margin-bottom: ${(props) => props.$marginBottom || '0'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
  margin: 1em 0;
`;

const PostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
`;

const CopyIconContainer = styled.div`
  position: absolute;
  left: 1em;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  display: none;
`;

const PostBox = styled.div`
  padding: 1em;
  border: 2px solid ${({theme}) => theme.colors.primary1.shade1};
  border-radius: 1em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  animation: ${fadeIn} 0.5s ease;
  font-size: 1em;
  font-weight: 500;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: collumn;
  gap: 2em;
`;
