import {FunctionComponent} from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
  isSelected: boolean;
  onClick?: () => void;
}

export const SelectButton: FunctionComponent<Props> = ({
  text,
  isSelected,
  onClick,
}) => {
  return (
    <StyledButton $isSelected={isSelected} onClick={onClick}>
      {text}
    </StyledButton>
  );
};

const StyledButton = styled.button<{$isSelected: boolean}>`
  border-radius: 1.25em;
  padding: 0.875em 1.75em;
  line-height: 1.5em;
  letter-spacing: 0.0125em;
  font-weight: 600;
  cursor: pointer;
  color: ${({theme}) => theme.colors.neutral.shade11};
  background-color: ${({theme, $isSelected}) =>
    $isSelected ? theme.colors.primary1.shade5 : 'transparent'};
  box-shadow: 0 0 0 1px ${({theme}) => theme.colors.neutral.shade4};

  ${({theme, $isSelected}) =>
    $isSelected &&
    `
      box-shadow: 0 0 0 2px ${theme.colors.primary1.shade2};
    `}
  border: none;
`;
