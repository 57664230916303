import {useMutation} from '@tanstack/react-query';

export const useGenerateSummary = () => {
  const generateSummary = useMutation({
    mutationFn: async (inputData: {
      description: string;
      targetAudience: string;
      callToAction: string;
      tone: string;
      includeEmojis: boolean;
    }) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/free-tools/generate-summary`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(inputData),
        },
      );

      if (response.ok) {
        return await response.text();
      }

      throw new Error('Failed to generate summary. Please try again.');
    },
  });

  return generateSummary;
};
