import {useMutation} from '@tanstack/react-query';

export const useDownloadVideo = () => {
  return useMutation({
    mutationFn: async (videoUrl: string) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/free-tools/video-downloader`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({url: videoUrl}),
        },
      );

      if (response.ok) {
        const data = await response.json();
        return data.downloadUrl;
      }

      throw new Error(
        'Failed to fetch the video download URL. Please try again.',
      );
    },
  });
};
