import {useMutation} from '@tanstack/react-query';
import {deleteApi} from '../../utils/fetchApi';
import {t} from '@lingui/macro';
import {signOut} from 'supertokens-web-js/recipe/thirdpartyemailpassword';

export const useDeleteAccount = () => {
  const deleteAccount = useMutation({
    onSuccess: async () => {
      await signOut();
    },
    mutationFn: async () =>
      deleteApi('/user/delete', {}, t`Unable to delete the account`),
  });

  return deleteAccount;
};
