import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const PlusIcon: FunctionComponent<Props> = ({
  width = 17,
  height = 17,
  color = '#1C1B1A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 12H18.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 18V6"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusIcon;
