import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const LogoDotIcon: FunctionComponent<Props> = ({
  width = 3,
  height = 4,
  color = '#2A203D',
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 3 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.47036 3.1165C2.25005 3.1165 2.88212 2.48443 2.88212 1.70473C2.88212 0.925037 2.25005 0.292969 1.47036 0.292969C0.690662 0.292969 0.0585938 0.925037 0.0585938 1.70473C0.0585938 2.48443 0.690662 3.1165 1.47036 3.1165Z"
        fill={color}
      />
    </svg>
  );
};
export default LogoDotIcon;
