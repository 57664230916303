import {FunctionComponent, ReactNode} from 'react';
import styled from 'styled-components';
import {CloseButton} from '../../atoms/CloseButton';
import {isTablet} from '../../../utils/responsive';

interface Props {
  children: ReactNode;
  onClose?: () => void;
}

export const Modal: FunctionComponent<Props> = ({children, onClose}) => {
  return (
    <Container>
      <CloseButtonWrapper onClick={onClose}>
        <CloseButton />
      </CloseButtonWrapper>
      {children}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  padding: 2.5em;
  position: relative;
  @media (max-width: ${isTablet}) {
    padding: 1.75em;
  }
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
`;
