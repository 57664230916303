import {FunctionComponent} from 'react';
import styled from 'styled-components';

export const CloseButton: FunctionComponent = () => {
  return (
    <StyledCloseButton>
      <CloseIcon />
    </StyledCloseButton>
  );
};

const CloseIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.9em;
  height: 0.9em;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.2em;
    background-color: ${({theme}) => theme.colors.neutral.shade11};
    border-radius: 2em;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const StyledCloseButton = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.neutral.shade4};
  padding: 0.4em;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({theme}) => theme.colors.neutral.shade10};
  }

  &:hover ${CloseIcon}::before, &:hover ${CloseIcon}::after {
    background-color: ${({theme}) => theme.colors.neutral.shade1};
  }
`;
