import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import CopyIcon from '../../components/atoms/Icons/CopyIcon';
import {LightTab} from '../../components/atoms/LightTab';
import {MobileIcon} from '../../components/atoms/Icons/MobileIcon';
import {DesktopIcon} from '../../components/atoms/Icons/DesktopIcon';
import {Button} from '../../components/atoms/Button';
import {useSnackbar} from 'notistack';
import {isTablet} from '../../utils/responsive';
import {Editor} from './components/Editor';

export const TextFormatter: FunctionComponent = () => {
  const {enqueueSnackbar} = useSnackbar();
  const [postText, setPostText] = useState<string>('');
  const [showFullText, setShowFullText] = useState(false);
  const [isLaptopPreview, setIsLaptopPreview] = useState<boolean>(true);
  const toggleShowFullText = () => setShowFullText(!showFullText);
  const handleCopy = async () => {
    await navigator.clipboard.writeText(postText);
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
    });
  };
  return (
    <MainContainer>
      <TextFormatterContainer>
        <LeftContainer>
          <Editor setPostText={setPostText} postText={postText} />
        </LeftContainer>
        <RightContainer>
          <Row>
            <LightTab
              isSelected={isLaptopPreview}
              label="Web"
              icon={<DesktopIcon />}
              onClick={() => setIsLaptopPreview(true)}
            />
            <LightTab
              isSelected={!isLaptopPreview}
              label="Mobile"
              icon={<MobileIcon />}
              onClick={() => setIsLaptopPreview(false)}
            />
          </Row>
          <PreviewContainer $isLaptopPreview={isLaptopPreview}>
            <AvatarSection>
              <AvatarWrapper>
                <Avatar>AD</Avatar>
              </AvatarWrapper>
              <UserInfo>
                <UserName>Abderrahim Defaoui</UserName>
                <UserSubtitle>I use RedactAI because I'm smart</UserSubtitle>
                <TimeAgo>
                  an hour
                  <Dot />
                </TimeAgo>
              </UserInfo>
            </AvatarSection>
            <Text>
              {!showFullText ? (
                <PostText $isLaptopPreview={isLaptopPreview}>
                  {postText}
                  <SeeMoreText onClick={toggleShowFullText}>
                    ...see more
                  </SeeMoreText>
                </PostText>
              ) : (
                <div>
                  {postText}
                  <SeeLessText onClick={toggleShowFullText}>
                    See less
                  </SeeLessText>
                </div>
              )}
            </Text>
          </PreviewContainer>
          <ButtonWrapper>
            <div />
            <StyledButton
              onClick={handleCopy}
              variant="light"
              size="small"
              startIcon={<CopyIcon />}
              label="Copy"
            />
          </ButtonWrapper>
        </RightContainer>
      </TextFormatterContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: calc(100vh - 5.5em);
  padding: 0 1.5em;
  @media (min-width: ${isTablet}) {
    width: 60em;
  }
`;

const Text = styled.span`
  line-height: 1.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  letter-spacing: 0.03125em;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const TextFormatterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 2em auto 0 auto;
  overflow: hidden;
  border: 1px solid ${({theme}) => theme.colors.neutral.shade6};
  border-radius: 1.5em;
  width: 100%;
  @media (min-width: ${isTablet}) {
    height: 35em;
  }
  @media (max-width: ${isTablet}) {
    flex-direction: column;
  }
`;

const RightContainer = styled.div`
  padding: 0.75em;
  background-color: ${({theme}) => theme.colors.neutral.shade13};
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  width: 50%;
  @media (max-width: ${isTablet}) {
    width: 100%;
    box-sizing: border-box;
    height: 24em;
    border-top: 1px solid ${({theme}) => theme.colors.neutral.shade6};
  }
  @media (min-width: ${isTablet}) {
    border-left: 1px solid ${({theme}) => theme.colors.neutral.shade6};
  }
`;

const PreviewContainer = styled.div<{$isLaptopPreview: boolean}>`
  flex: 1;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  padding: 1.5em;
  border-radius: 1em;
  overflow-y: scroll;
  box-sizing: border-box;
  width: ${({$isLaptopPreview}) => ($isLaptopPreview ? '100%' : '80%')};
  margin: 0 auto;
  &::-webkit-scrollbar {
    display: none;
  }
  white-space: pre-wrap;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  transition: all 0.3s;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  width: fit-content;
`;

const AvatarWrapper = styled.div`
  height: 3.6em;
  width: 3.6em;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.neutral.shade5};
  margin-top: 0.15em;
`;

const Avatar = styled.p`
  font-size: 1.25em;
  font-weight: 700;
`;

const UserInfo = styled.div`
  color: #000000;
  font-size: 1em;
`;

const UserName = styled.p`
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.0125em;
  margin: 0;
`;

const UserSubtitle = styled.p`
  font-size: 0.8125em;
  letter-spacing: 0.00625em;
  opacity: 60%;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
`;

const TimeAgo = styled.span`
  font-size: 0.8em;
  opacity: 60%;
  display: flex;
  align-items: center;
`;

const Dot = styled.span`
  width: 0.3em;
  height: 0.3em;
  background-color: ${({theme}) => theme.colors.neutral.shade11};
  border-radius: 50%;
  margin-left: 0.5em;
`;

const AvatarSection = styled.div`
  display: flex;
  gap: 0.9375em;
`;

const PostText = styled.div<{$isLaptopPreview: boolean}>`
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: ${({$isLaptopPreview}) => ($isLaptopPreview ? 4 : 2)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const SeeLessText = styled.span`
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: block;
  margin-top: 0.5em;
`;

const SeeMoreText = styled.span`
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  padding-left: 10px;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
`;

const LeftContainer = styled.div`
  width: 50%;
  @media (max-width: ${isTablet}) {
    width: 100%;
    box-sizing: border-box;
    height: 18em;
  }
`;
