import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const ChevronBottom: FunctionComponent<Props> = ({
  width = 15,
  height = 16,
  color = '#1C1B1A',
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4498 6.09375L8.3748 10.1688C7.89355 10.65 7.10605 10.65 6.6248 10.1688L2.5498 6.09375"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronBottom;
