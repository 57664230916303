import {FunctionComponent} from 'react';
import {MyIdeas} from './MyIdeas';
import {FromUrl} from './FromUrl';
import {NoIdeas} from './NoIdeas';
import {ContentSource} from '../../../pages/onboarding/components/ChooseContentSource';
import styled from 'styled-components';
import {isTablet} from '../../../utils/responsive';

interface Props {
  chooseContentSource: (contentSource: ContentSource) => void;
}

export const OnboardingContentChoiceCards: FunctionComponent<Props> = ({
  chooseContentSource,
}) => {
  return (
    <Cards>
      <MyIdeas
        onClick={() => chooseContentSource(ContentSource.MY_OWN_IDEAS)}
      />
      <FromUrl onClick={() => chooseContentSource(ContentSource.FROM_AN_URL)} />
      <NoIdeas
        onClick={() => chooseContentSource(ContentSource.I_DONT_HAVE_IDEAS)}
      />
    </Cards>
  );
};

const Cards = styled.span`
  display: flex;
  gap: 1em;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    width: 100%;
  }
`;
