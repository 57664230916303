import {FunctionComponent, useCallback, useState} from 'react';
import styled from 'styled-components';
import {Modal} from './Modal';
import Yohan from '../images/Yohan.gif';
import Nicolas from '../images/Nicolas.gif';
import Saad from '../images/Saad.gif';
import {t} from '@lingui/macro';
import {Button} from '../../atoms/Button';
import {isTablet} from '../../../utils/responsive';
import {Gif} from '../Gif';
import {useSnackbar} from 'notistack';

interface Props {
  onClose: () => void;
  showReasonAsk: (person: string) => void;
}

export const CancelSubscriptionPopupConfirmation: FunctionComponent<Props> = ({
  onClose,
  showReasonAsk,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [selectedGif, setSelectedGif] = useState<string>('');

  const onConfirmation = useCallback(() => {
    if (selectedGif === '') {
      enqueueSnackbar(t`Please select who will take a pay cut`, {
        variant: 'error',
      });
      return;
    }
    showReasonAsk(selectedGif);
  }, [enqueueSnackbar, selectedGif, showReasonAsk]);

  return (
    <Modal onClose={onClose}>
      <Container>
        <FelxCol>
          <StrongText>{t`Are you sure you want to cancel your subscription?`}</StrongText>
          <GrayText>{t`If you are doing it, you are messing one of us and that's....unacceptable!`}</GrayText>
          <SmallStrongText>{t`Pick who will take a pay cut.`}</SmallStrongText>
        </FelxCol>
        <Gifs>
          <Gif
            src={Yohan}
            name="Yohan"
            description={t`The most paid at RedactAI.`}
            selected={selectedGif === 'Yohan'}
            onClick={() => setSelectedGif('Yohan')}
          />
          <Gif
            src={Nicolas}
            name="Nico"
            description={t`Responsible for all the bugs.`}
            selected={selectedGif === 'Nicolas'}
            onClick={() => setSelectedGif('Nicolas')}
          />
          <StyledGif
            src={Saad}
            name="The Interns"
            description={t`Doing all the work but don’t tell anyone.`}
            selected={selectedGif === 'The Interns'}
            onClick={() => setSelectedGif('The Interns')}
          />
        </Gifs>
        <Row>
          <Button
            onClick={onConfirmation}
            label={t`I have to cancel it`}
            size="big"
            variant="light"
            fullWidth
          />
          <Button
            onClick={onClose}
            label={t`Alright, I've changed my mind :)`}
            size="big"
            variant="main"
            fullWidth
          />
        </Row>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  margin-top: 1em;
  @media (max-width: ${isTablet}) {
    width: 26em;
  }
`;
const StrongText = styled.span`
  font-weight: 700;
  font-size: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  text-align: center;
  max-width: 14em;
`;
const FelxCol = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`;
const GrayText = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  line-height: 1.5em;
`;
const SmallStrongText = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 1em;
  font-weight: 700;
  text-align: center;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 2em;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;
const Gifs = styled.div`
  display: flex;
  gap: 0.75em;
`;
const StyledGif = styled(Gif)`
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
