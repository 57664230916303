import {FunctionComponent} from 'react';
import styled from 'styled-components';

interface Props {
  progress: number;
  height: string;
  background: string;
  fullWidth?: boolean;
  label?: boolean;
  className?: string;
}

export const ProgressBar: FunctionComponent<Props> = ({
  progress,
  height,
  background,
  fullWidth,
  label,
  className,
}) => {
  return (
    <StyledProgressBar $fullWidth={fullWidth} className={className}>
      <ProgressBarWrapper>
        <ProgressBarContainer $background={background} $height={height}>
          <ProgressBarFill $progress={progress} />
        </ProgressBarContainer>
        {label && <ProgressLabel>{progress}%</ProgressLabel>}
      </ProgressBarWrapper>
    </StyledProgressBar>
  );
};

const StyledProgressBar = styled.div<{$fullWidth: Props['fullWidth']}>`
  display: flex;
  flex-direction: column;
  width: ${({$fullWidth}) => ($fullWidth ? '100%' : '41em')};
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

const ProgressBarContainer = styled.div<{
  $background: Props['background'];
  $height: Props['height'];
}>`
  flex-grow: 1;
  height: ${({$height}) => $height};
  background-color: ${({$background}) => $background};
  border-radius: 2em;
  overflow: hidden;
`;

const ProgressBarFill = styled.div<{$progress: number}>`
  height: 100%;
  width: ${({$progress}) => `${$progress}%`};
  background-color: ${({theme}) => theme.colors.primary3.shade2};
  transition: width 0.3s ease;
  border-radius: 2em;
`;

const ProgressLabel = styled.span`
  font-size: 1.5em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
