import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {Button} from '../atoms/Button';
import {isTablet} from '../../utils/responsive';
import {useSnackbar} from 'notistack';

interface Props {
  link: string;
}

export const AffiliateLinkCopy: FunctionComponent<Props> = ({link}) => {
  const [isCopied, setIsCopied] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    enqueueSnackbar(t`Copied to clipboard`, {
      variant: 'success',
    });
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <Container>
      <Instruction>{t`Share this link with your friends and win free posts every month:`}</Instruction>
      <LinkContainer>
        <StyledInput value={link} readOnly />
        <Button
          label={isCopied ? t`Copied!` : t`Copy`}
          variant="main"
          size="medium"
          onClick={handleCopy}
        />
      </LinkContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledInput = styled.input`
  font-size: 1em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade7};
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
  border-radius: 1em;
  padding: 1em;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: ${isTablet}) {
    width: 40%;
  }

  &:hover {
    border-color: ${({theme}) => theme.colors.neutral.shade7};
  }

  &:focus {
    outline: none;
    border-color: ${({theme}) => theme.colors.primary1.shade2};
    box-shadow: 0px 0px 0px 4px ${({theme}) => theme.colors.primary1.shade1};
  }
`;

const Instruction = styled.p`
  font-size: 1.4em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 600;
`;

const LinkContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1em;
  justify-content: center;
  align-items: center;
`;
