import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const ArrowIndicator: FunctionComponent<Props> = ({
  width = 43,
  height = 49,
  color = '#1C1B1A',
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 48 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4533_21671)">
        <path
          d="M22.3243 2.14179C21.5854 3.11036 20.8465 4.07892 19.9732 5.2312C20.4944 5.28355 20.8474 5.33522 21.2341 5.32002C29.3032 5.26867 35.6443 9.74978 38.3399 17.3821C40.0416 22.2969 39.7428 27.1701 38.4354 32.123C36.9603 37.6615 33.6856 42.0035 29.3512 45.6042C27.1168 47.4545 24.4783 48.4322 21.6713 48.9905C20.9318 49.1215 20.209 49.2191 19.3347 49.1151C19.7044 48.8821 20.074 48.5653 20.4774 48.5167C30.3105 46.9649 38.1858 35.5558 37.8914 24.315C37.7871 19.9092 36.3719 15.9337 33.5283 12.5386C30.1799 8.5553 24.1769 6.50437 19.2018 7.69044C20.4132 9.0521 22.6326 9.49653 22.9874 11.8095C20.8684 10.662 18.9344 9.64918 17.0508 8.61983C16.4285 8.26557 16.0918 7.67795 16.5621 7.16057C18.1409 5.35785 19.7702 3.62234 21.4164 1.93714C21.5172 1.82029 21.8534 1.90539 22.072 1.88952C22.156 1.88986 22.257 2.02427 22.3243 2.14179Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_4533_21671">
          <rect
            width="43.4435"
            height="31.2793"
            fill="white"
            transform="translate(47.7109 14.75) rotate(116.658)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowIndicator;
