import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  onClick?: () => void;
}

const EditIcon: FunctionComponent<Props> = ({
  width = 16,
  height = 16,
  color = '#2048D7',
  className,
  onClick,
}) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.16663 1.66602H7.49996C3.33329 1.66602 1.66663 3.33268 1.66663 7.49935V12.4993C1.66663 16.666 3.33329 18.3327 7.49996 18.3327H12.5C16.6666 18.3327 18.3333 16.666 18.3333 12.4993V10.8327"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3667 2.51639L6.80002 9.08306C6.55002 9.33306 6.30002 9.82472 6.25002 10.1831L5.89169 12.6914C5.75835 13.5997 6.40002 14.2331 7.30835 14.1081L9.81669 13.7497C10.1667 13.6997 10.6584 13.4497 10.9167 13.1997L17.4834 6.63306C18.6167 5.49972 19.15 4.18306 17.4834 2.51639C15.8167 0.849722 14.5 1.38306 13.3667 2.51639Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.425 3.45898C12.9834 5.45065 14.5417 7.00898 16.5417 7.57565"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
