import {FunctionComponent} from 'react';
import styled, {css, keyframes} from 'styled-components';

interface Props {
  isMobile?: boolean;
}

export const LoadingSubscriptionInformationCard: FunctionComponent<Props> = ({
  isMobile,
}) => {
  if (isMobile) {
    return (
      <SkeletonContainerMobile>
        <SkeletonTextMobile />
      </SkeletonContainerMobile>
    );
  }
  return (
    <SkeletonContainer>
      <SkeletonHeader>
        <SkeletonImage />
        <SkeletonText />
      </SkeletonHeader>
      <SkeletonText />
      <SkeletonText />
      <SkeletonButton />
    </SkeletonContainer>
  );
};

const loadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

const loadingStyle = css`
  background: linear-gradient(
    90deg,
    ${({theme}) => theme.colors.neutral.shade6} 25%,
    ${({theme}) => theme.colors.neutral.shade5} 37%,
    ${({theme}) => theme.colors.neutral.shade6} 63%
  );
  background-size: 400% 100%;
  animation: ${loadingAnimation} 1.4s ease infinite;
`;

const SkeletonContainer = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  padding: 1em;
  border-radius: 1.25em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 2em;
`;
const SkeletonContainerMobile = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  padding: 0.5em;
  border-radius: 0.5em;
  width: 6em;
`;

const SkeletonHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const SkeletonImage = styled.div`
  padding: 1.5em;
  border-radius: 50%;
  ${loadingStyle}
`;

const SkeletonText = styled.div`
  height: 1em;
  width: 100%;
  border-radius: 0.25em;
  ${loadingStyle}
`;
const SkeletonTextMobile = styled.div`
  height: 0.75em;
  border-radius: 0.25em;
  ${loadingStyle}
`;

const SkeletonButton = styled.div`
  height: 2.5em;
  width: 100%;
  border-radius: 0.5em;
  ${loadingStyle}
`;
