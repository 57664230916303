import {t} from '@lingui/macro';
import {FunctionComponent} from 'react';
import RobotIcon from '../../atoms/Icons/RobotIcon';
import styled from 'styled-components';
import {isTablet} from '../../../utils/responsive';

interface Props {
  onClick: () => void;
}

export const NoIdeas: FunctionComponent<Props> = ({onClick}) => {
  return (
    <Container onClick={onClick}>
      <Col>
        <Header>{t`I don't have ideas`}</Header>
        <SubHeader>{t`Let's use our idea generator`}</SubHeader>
      </Col>
      <StyledRobotIcon />
    </Container>
  );
};
const Container = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1em;
  padding-top: 2em;
  width: 19.875em;
  display: flex;
  flex-direction: column;
  gap: 3.125em;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${isTablet}) {
    flex-direction: row-reverse;
    gap: 0.9em;
    justify-content: start;
    padding: 1.5em;
    width: 100%;
    box-sizing: border-box;
  }
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2em;
`;
const Header = styled.span`
  font-size: 1.25em;
  letter-spacing: 0.0125em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const SubHeader = styled.span`
  letter-spacing: 0.0125em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade8};
  line-height: 1.5em;
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
const StyledRobotIcon = styled(RobotIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
