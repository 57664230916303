import {FunctionComponent} from 'react';
import {useTheme} from '../../theme/theme';

interface Props {
  width?: number;
  height?: number;
}

const MyPostsIcon: FunctionComponent<Props> = ({width = 20, height = 21}) => {
  const theme = useTheme();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 10.7109V15.7109C22 20.7109 20 22.7109 15 22.7109H9C4 22.7109 2 20.7109 2 15.7109V9.71094C2 4.71094 4 2.71094 9 2.71094H14"
        stroke={theme.colors.neutral.shade3}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 10.7109H18C15 10.7109 14 9.71094 14 6.71094V2.71094L22 10.7109Z"
        stroke={theme.colors.neutral.shade3}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13.7109H13"
        stroke={theme.colors.primary1.shade2}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 17.7109H11"
        stroke={theme.colors.primary1.shade2}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MyPostsIcon;
