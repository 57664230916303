import {FunctionComponent} from 'react';
import styled, {keyframes, css} from 'styled-components';
import {Button} from '../atoms/Button';
import {t} from '@lingui/macro';

interface Idea {
  subject: string;
  ideas: string[];
}

interface Props {
  idea?: Idea;
  isLoading?: boolean;
  onClick?: () => void;
  fullWidth?: boolean;
  width?: string;
}

export const GeneratedIdea: FunctionComponent<Props> = ({
  idea,
  isLoading = false,
  onClick,
  fullWidth,
  width,
}) => {
  return (
    <Card $fullWidth={fullWidth} $width={width}>
      <Title $isLoading={isLoading}>{isLoading ? '' : idea?.subject}</Title>
      <KeyIdeasSection>
        <KeyIdeasTitle $isLoading={isLoading}>
          {isLoading ? '' : t`Key ideas`}
        </KeyIdeasTitle>
        <KeyIdeasList $isLoading={isLoading}>
          {isLoading
            ? Array(7)
                .fill(0)
                .map((_, index) => (
                  <KeyIdea key={index} $isLoading={true} as="li" />
                ))
            : idea?.ideas.map((ideaText, index) => (
                <KeyIdea key={index} $isLoading={false} as="li">
                  {ideaText}
                </KeyIdea>
              ))}
        </KeyIdeasList>
      </KeyIdeasSection>
      {isLoading ? (
        <Div>
          <StyledKeyIdea $isLoading={true} />
        </Div>
      ) : (
        <Button
          onClick={onClick}
          label={t`Generate Post`}
          fullWidth={false}
          variant={isLoading ? 'inverted' : 'main'}
          size="medium"
        />
      )}
    </Card>
  );
};

const loadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

const loadingStyle = css`
  background: linear-gradient(
    90deg,
    ${({theme}) => theme.colors.neutral.shade4} 25%,
    ${({theme}) => theme.colors.neutral.shade5} 37%,
    ${({theme}) => theme.colors.neutral.shade4} 63%
  );
  background-size: 400% 100%;
  animation: ${loadingAnimation} 1.4s ease infinite;
`;

const LoadingBackground = styled.div<{$isLoading: boolean}>`
  ${({$isLoading}) => $isLoading && loadingStyle}
`;

const Card = styled.div<{
  $fullWidth: Props['fullWidth'];
  $width: Props['width'];
}>`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  width: ${({$fullWidth}) => ($fullWidth ? '100%' : '22em')};
  width: ${({$width}) => $width};
  border-radius: 2em;
  padding: 1em;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
  min-width: 22em;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const Title = styled(LoadingBackground)<{$isLoading: boolean}>`
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 1.5em;
  margin-top: 0;
  color: ${({theme}) => theme.colors.neutral.shade11};
  height: ${({$isLoading}) => ($isLoading ? '1.5em' : 'auto')};
  border-radius: 1em;
`;

const KeyIdeasSection = styled.div`
  margin-bottom: 0.5em;
  flex: 1;
`;

const KeyIdeasTitle = styled(LoadingBackground)<{$isLoading: boolean}>`
  font-size: 1.2em;
  font-weight: 500;
  color: ${({theme}) => theme.colors.neutral.shade9};
  margin-bottom: 1.2em;
  height: ${({$isLoading}) => ($isLoading ? '1.5em' : 'auto')};
  width: ${({$isLoading}) => ($isLoading ? '5em' : 'auto')};
  border-radius: 1em;
`;

const KeyIdeasList = styled.ul<{$isLoading: boolean}>`
  list-style-type: ${({$isLoading}) => ($isLoading ? 'none' : 'disc')};
  padding-left: ${({$isLoading}) => ($isLoading ? `0` : `1em`)};
  margin: 0;
`;

const KeyIdea = styled(LoadingBackground)<{$isLoading: boolean}>`
  font-size: 1.2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  margin-bottom: 1.2em;
  font-weight: 500;
  height: ${({$isLoading}) => ($isLoading ? '.8em' : 'auto')};
  width: ${({$isLoading}) => ($isLoading ? '100%' : 'auto')};
  border-radius: 1em;

  &::marker {
    color: ${({theme}) => theme.colors.neutral.shade11};
  }
`;
const StyledKeyIdea = styled(KeyIdea)`
  margin-bottom: 0;
`;
const Div = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade4};
  padding: 1em 4em;
  border-radius: 0.875em;
  display: flex;
  justify-content: center;
`;
