import {useSavedPosts} from '../savedPostsAndIdeas/useSavedPosts';
import useDefaultLanguage from '../postLang/useDefaultLanguage';
import {SavedPostLanguage} from '../savedPost/savedPost.types';

export const useLastPostLanguage = (): SavedPostLanguage => {
  const {savedPosts} = useSavedPosts();
  const [defaultLanguage] = useDefaultLanguage();

  if (savedPosts.length === 0) {
    return defaultLanguage;
  }

  const lastLanguage = savedPosts[0]?.language;

  return lastLanguage || defaultLanguage;
};
