import {FunctionComponent} from 'react';
import {CurrentSubscription} from '../../../components/molecules/CurrentSubscription';
import {
  ProductPeriod,
  useSubscription,
} from '../../../hooks/subscription/useSubscription';
import {creditsToPost} from '../../../utils/creditsToPost';
import {CurrentSubscriptionFree} from '../../../components/molecules/CurrentSubscriptionFree';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {InvoiceTable} from '../../../components/molecules/InvoiceTable';
import {useInvoices} from '../../../hooks/subscription/useInvoices';
import {useUserContext} from '../../../common/UserContext/useUserContext';

export const SettingsSubscription: FunctionComponent = () => {
  const {subscription} = useSubscription();
  const {invoices, loading} = useInvoices();
  const {me} = useUserContext();

  if (!me?.stripeCustomerId) {
    return <CurrentSubscriptionFree />;
  }

  if (!subscription?.isActive) {
    return (
      <>
        <CurrentSubscriptionFree />
        <Col>
          <StrongText>{t`Recent transactions`}</StrongText>
          <InvoiceTable invoices={invoices} loading={loading} />
        </Col>
      </>
    );
  }

  return (
    <>
      <CurrentSubscription
        currentPlan={
          subscription.product.period === ProductPeriod.MONTHLY
            ? 'Monthly'
            : 'Annual'
        }
        renewalDate={subscription.creditsRenewalDate}
        posts={creditsToPost(subscription.product.credits)}
        cancelsAtPeriodEnd={subscription.cancelsAtPeriodEnd}
        validUntil={subscription.validUntil}
      />
      <Col>
        <StrongText>{t`Recent transactions`}</StrongText>
        <InvoiceTable invoices={invoices} loading={loading} />
      </Col>
    </>
  );
};

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const StrongText = styled.span`
  font-size: 1.25em;
  font-weight: 700;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
