import {useMutation, useQueryClient} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {postApi} from '../../utils/fetchApi';
import {useTracking} from '../useTracking';
import {Reason} from '../../components/molecules/popups/CancelSubscriptionPopupReasonAsk';

export const useCancelSubscription = (reason?: string, details?: string) => {
  const queryClient = useQueryClient();
  const {trackEvent} = useTracking();

  const cancelSubscription = useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['subscription']});
      await queryClient.invalidateQueries({queryKey: ['me']});
      trackEvent('Cancel Subscription - Click - Confirm reason', {
        reason: reason,
        otherText: reason === Reason.OTHER ? details : undefined,
      });
    },
    mutationFn: async () =>
      postApi(
        '/subscription/cancel',
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            reason,
            ...(reason === Reason.OTHER && details ? {otherText: details} : {}),
          }),
        },
        t`Unable to cancel the subscription`,
      ),
  });

  return cancelSubscription;
};
