import {t} from '@lingui/macro';
import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Profile} from '../../../hooks/profile/profile.ts';

interface Props {
  onClick: () => void;
  profile: Profile;
  hasEnoughPosts: boolean;
}

export const MyStyle: FunctionComponent<Props> = ({
  onClick,
  profile,
  hasEnoughPosts,
}) => {
  return (
    <Container onClick={onClick} $disabled={!hasEnoughPosts}>
      <InnerContainer $disabled={!hasEnoughPosts}>
        <Header>{t`${profile.name}'s style`}</Header>
        <Avatar src={profile.profilePhotoUrl} alt={profile.name} />
        <Div>
          <b>{t`WARNING: You must already have a good style to use it.`}</b>
          <br />
          <br />
          {t`RedactAI will analyze your last posts to imitate your writing style`}
        </Div>
      </InnerContainer>
      {!hasEnoughPosts && (
        <Information>{t`You need to have at least 15 published posts to use your own style.`}</Information>
      )}
    </Container>
  );
};

const Container = styled.div<{$disabled: boolean}>`
  width: 19.875em;
  min-width: 19.875em;
  cursor: ${({$disabled}) => ($disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1em;
`;

const InnerContainer = styled.div<{$disabled: boolean}>`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  height: 100%;
  padding: 1em;
  padding-top: 2em;
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2em;
  align-items: center;
  opacity: ${({$disabled}) => ($disabled ? 0.5 : 1)};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.25em;
  letter-spacing: 0.0125em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Avatar = styled.img`
  height: 7.25em;
  width: 7.25em;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid ${({theme}) => theme.colors.neutral.shade13};
`;
const Div = styled.div`
  padding: 0.75em;
  border-radius: 0.875em;
  background-color: ${({theme}) => theme.colors.neutral.shade13};
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 0.875em;
  line-height: 1.25em;
  letter-spacing: 0.0125em;
  text-align: center;
`;

const Information = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade2}F0;
  position: absolute;
  text-align: center;
  width: 80%;
  bottom: 30%;
  border-radius: 0.5em;
  display: flex;
  gap: 0.5em;
  font-size: 1.1em;
  padding: 0.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 700;
`;
