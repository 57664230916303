import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import ThirdPartyEmailPassword from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import {t} from '@lingui/macro';
import {ErrorIcon} from '../icons/ErrorIcon';
import {TextInput} from '../../atoms/TextInput';
import {Button} from '../../atoms/Button';
import {VerificationCheck} from '../icons/VerificationCheck';
import {useSnackbar} from 'notistack';
import {BackgroundStripe} from '../../atoms/Images/BackgroundStripe.tsx';
import {useNavigate} from 'react-router-dom';

const STATUS_MESSAGES = {
  initial: t`Enter your new password`,
  success: t`Your password has been successfully reset!`,
  error: t`An error occurred during password reset.`,
  invalidLink: t`Invalid password reset link.`,
};

export const PasswordResetHandler: React.FunctionComponent = () => {
  const [status, setStatus] = useState<string>(STATUS_MESSAGES.initial);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = searchParams.get('token');

    if (!tokenParam) {
      setStatus(STATUS_MESSAGES.invalidLink);
      enqueueSnackbar(STATUS_MESSAGES.invalidLink, {
        variant: 'error',
      });
      return;
    }
    setToken(tokenParam);
  }, [location, enqueueSnackbar]);

  const handlePasswordReset = async (
    event?: React.FormEvent<HTMLFormElement>,
  ) => {
    if (event) {
      event.preventDefault();
    }
    if (!token) {
      setStatus(STATUS_MESSAGES.invalidLink);
      enqueueSnackbar(STATUS_MESSAGES.invalidLink, {
        variant: 'error',
      });
      return;
    }

    if (!newPassword || !confirmPassword) {
      const errorMessage = t`Please fill in both password fields.`;
      setError(errorMessage);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      const errorMessage = t`Passwords do not match.`;
      setError(errorMessage);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
      return;
    }

    try {
      const response = await ThirdPartyEmailPassword.submitNewPassword({
        formFields: [
          {
            id: 'password',
            value: newPassword,
          },
        ],
        userContext: {token},
      });

      if (response.status !== 'OK') {
        const errorMessage = t`Failed to reset password. Please try again.`;
        setError(errorMessage);
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
        return;
      }
      setStatus(STATUS_MESSAGES.success);
      enqueueSnackbar(STATUS_MESSAGES.success, {
        variant: 'success',
      });
      setTimeout(() => {
        navigate('/auth');
      }, 3000);
    } catch (err) {
      const errorMessage = t`An error occurred. Please try again later.`;
      setError(errorMessage);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    }
  };

  if (status === STATUS_MESSAGES.invalidLink) {
    return (
      <PageContainer>
        <BackgroundStripesStyled />
        <VerificationContainer>
          <StyledErrorIcon />
          <Title>{t`Password Reset Failed`}</Title>
          <ErrorMessage>{STATUS_MESSAGES.invalidLink}</ErrorMessage>
          <Message>{t`Please contact support for assistance.`}</Message>
        </VerificationContainer>
      </PageContainer>
    );
  }

  if (status === STATUS_MESSAGES.success) {
    return (
      <PageContainer>
        <BackgroundStripesStyled />
        <VerificationContainer>
          <StyledVerificationCheck />
          <Title>{t`Password Reset Successful`}</Title>
          <SuccessMessage>{STATUS_MESSAGES.success}</SuccessMessage>
          <Message>{t`You will be redirected to the login page shortly.`}</Message>
        </VerificationContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <BackgroundStripesStyled />
      <VerificationContainer>
        <Title>{t`Reset Your Password`}</Title>
        <Message>{STATUS_MESSAGES.initial}</Message>
        <StyledTextInput
          type="password"
          label={t`New Password`}
          placeholderText={t`Enter new password`}
          value={newPassword}
          onChange={(event) => {
            setNewPassword(event.target.value);
            setError(null);
          }}
          error={!!error}
        />
        <StyledTextInput
          type="password"
          label={t`Confirm New Password`}
          placeholderText={t`Confirm new password`}
          value={confirmPassword}
          onChange={(event) => {
            setConfirmPassword(event.target.value);
            setError(null);
          }}
          error={!!error}
          helperText={error}
        />
        <StyledButton
          label={t`Reset Password`}
          variant="main"
          size="medium"
          onClick={handlePasswordReset}
        />
      </VerificationContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const BackgroundStripesStyled = styled(BackgroundStripe)`
  position: fixed;
  bottom: 10em;
  right: 0em;
`;

const VerificationContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em 6em;
  background: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  box-shadow: 0 4px 12px ${({theme}) => theme.colors.neutral.shade5};
  width: 27em;
  z-index: 1;
`;

const Title = styled.h2`
  font-size: 2.3em;
  font-weight: 700;
  text-align: center;
`;

const SuccessMessage = styled.p`
  font-size: 1em;
  color: ${({theme}) => theme.colors.primary3.shade2};
  text-align: center;
  margin: 0em;
`;

const ErrorMessage = styled.p`
  font-size: 1em;
  color: ${({theme}) => theme.colors.primary2.shade3};
  text-align: center;
  margin: 0em;
`;

const Message = styled.p`
  font-size: 1.3em;
  font-weight: 500;
  color: ${({theme}) => theme.colors.neutral.shade8};
  text-align: center;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  align-self: center;
`;

const StyledVerificationCheck = styled(VerificationCheck)`
  align-self: center;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  margin-bottom: 2em;
`;

const StyledButton = styled(Button)`
  margin-top: 1em;
`;
