import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Profile} from '../../../hooks/profile/profile.ts';

interface Props {
  createdProfile: Profile;
}

export const ProfileBadge: FunctionComponent<Props> = ({createdProfile}) => {
  return (
    <Container>
      <AvatarNameContainer>
        <Avatar
          src={createdProfile.profilePhotoUrl}
          alt={createdProfile.name}
        />
        <ProfileName>{createdProfile.name}</ProfileName>
      </AvatarNameContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: space-between;
  padding: 0.05em 1.15em;
  border-radius: 0.75em;
  flex: 1;
  text-wrap: nowrap;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 2px solid ${({theme}) => theme.colors.neutral.shade6};
`;

const AvatarNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const Avatar = styled.img`
  width: 1.7em;
  height: 1.7em;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid ${({theme}) => theme.colors.neutral.shade13};
`;

const ProfileName = styled.span`
  font-weight: 600;
  font-size: 1em;
`;
