import {FunctionComponent} from 'react';

interface Props {
  size?: number;
  color?: string;
  className?: string;
  onClick?: () => void;
}

export const CloseIcon: FunctionComponent<Props> = ({
  size = 24,
  color = 'white',
  className,
  onClick,
}) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 7L17 17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00004 17L17 7"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
