import {FunctionComponent, useState} from 'react';
import HelpRoundedIcon from '../../../components/atoms/Icons/HelpRoundedIcon';
import {t} from '@lingui/macro';
import styled from 'styled-components';
import EditIcon from '../../../components/atoms/Icons/EditIcon';
import LinkIcon from '../../../components/atoms/Icons/LinkIcon';
import {TextInput} from '../../../components/atoms/TextInput';
import {isTablet} from '../../../utils/responsive';
import {isValidHttpUrl} from '../../../utils/isValidHttpUrl';
import {ContentURLToolTipFirstStep} from './ContentURLToolTipFirstStep';
import {ContentURLToolTipFinalStep} from './ContentURLToolTipFinalStep';

interface Props {
  onGoBack: () => void;
  contentUrl: string;
  setContentUrl: (url: string) => void;
  postSubject: string;
  setPostSubject: (subject: string) => void;
  isSubmitted: boolean;
  contentUrlError: string;
  postSubjectUrlError: string;
  isOnboarding: boolean;
}

export const GenerateFromContentURL: FunctionComponent<Props> = ({
  onGoBack,
  contentUrl,
  setContentUrl,
  postSubject,
  setPostSubject,
  contentUrlError,
  isSubmitted,
  postSubjectUrlError,
  isOnboarding = true,
}) => {
  const [firstStep, setfirstStep] = useState<boolean>(false);
  const [finalStep, setfinalStep] = useState<boolean>(false);
  return (
    <ContentContainer $isOnboarding={isOnboarding}>
      {isOnboarding && (
        <Between>
          <P>{t`content`}</P>
          <Div>
            <Type>
              <StyledLinkIcon />
              {t`A content URL`}
            </Type>
            <Change onClick={onGoBack}>
              <StyledEditIcon width={20} height={20} />
              {t`Change`}
            </Change>
          </Div>
        </Between>
      )}
      <TextInputContainer
        $contentUrl={contentUrl}
        $contentUrlError={contentUrlError}>
        <StyledTextInput
          label={t`URL of the content`}
          placeholderText={t`URL of the content. Example: https://www.forbes.com/profile/elon-musk/?sh=33e5b9517999`}
          value={contentUrl}
          onChange={(e) => setContentUrl(e.target.value)}
          error={
            !isValidHttpUrl(contentUrl) && isSubmitted && contentUrlError !== ''
          }
          helperText={!isValidHttpUrl(contentUrl) && contentUrlError}
        />
        <StyledHelpRoundedIcon
          onClick={() => {
            setfirstStep(!firstStep);
            setfinalStep(false);
          }}>
          <StyleHelpRoundedIcon />
        </StyledHelpRoundedIcon>
        {firstStep && (
          <ContentURLToolTipFirstStep {...{setfirstStep, setfinalStep}} />
        )}
      </TextInputContainer>
      <TextInputContainer
        $postSubject={postSubject}
        $postSubjectUrlError={postSubjectUrlError}>
        <StyledTextInput
          placeholderText={t`Subject of your post. Example: The story of elon musk`}
          label={t`Subject`}
          value={postSubject}
          onChange={(e) => setPostSubject(e.target.value)}
          error={!postSubject && isSubmitted && postSubjectUrlError !== ''}
          helperText={!postSubject && postSubjectUrlError}
        />
        <StyledHelpRoundedIcon
          onClick={() => {
            setfinalStep(!finalStep);
            setfirstStep(false);
          }}>
          <StyleHelpRoundedIcon />
        </StyledHelpRoundedIcon>
        {finalStep && (
          <ContentURLToolTipFinalStep
            {...{setfirstStep, setfinalStep, isOnboarding}}
          />
        )}
      </TextInputContainer>
    </ContentContainer>
  );
};

const ContentContainer = styled.div<{$isOnboarding: boolean}>`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  ${({$isOnboarding}) => $isOnboarding && 'padding: 1.5em;'}
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  width: 100%;
  box-sizing: border-box;
`;
const Between = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    align-items: start;
    gap: 1em;
  }
`;
const P = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  @media (max-width: ${isTablet}) {
    width: 100%;
    justify-content: space-between;
  }
`;
const Type = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 1.125em;
  font-weight: 600;
`;
const Change = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.primary4.shade1};
  font-size: 0.875em;
  font-weight: 600;
  cursor: pointer;
`;
const TextInputContainer = styled.div<{
  $postSubject?: string;
  $postSubjectUrlError?: string;
  $contentUrl?: string;
  $contentUrlError?: string;
}>`
  flex: 1;
  position: relative;
  margin-bottom: ${({
    $postSubjectUrlError,
    $postSubject,
    $contentUrl,
    $contentUrlError,
  }) =>
    ((!$postSubject && $postSubjectUrlError) ||
      (!isValidHttpUrl($contentUrl!) && $contentUrlError)) &&
    `0.5em`};
`;
const StyledTextInput = styled(TextInput)`
  width: 100%;
`;
const StyledHelpRoundedIcon = styled.div`
  position: absolute;
  right: 0.8em;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  background-color: white;
  height: calc(100% - 4px);
  padding-left: 6px;
`;
const StyledLinkIcon = styled(LinkIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
const StyledEditIcon = styled(EditIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
const StyleHelpRoundedIcon = styled(HelpRoundedIcon)`
  cursor: pointer;
  @media (max-width: ${isTablet}) {
    height: 1.45em;
    width: 1.45em;
  }
`;
