import {useQuery} from '@tanstack/react-query';
import {useContext, useMemo} from 'react';
import {UserContext} from '../../common/UserContext/user.context';
import {Profile} from '../profile/profile.ts';
import {fetchApi} from '../../utils/fetchApi.ts';

export const useSavedProfiles = () => {
  const {me} = useContext(UserContext);
  const {data, isLoading, isSuccess, refetch} = useQuery({
    queryKey: ['profiles'],
    queryFn: () => fetchApi(`/profile`),
    enabled: !!me,
  });

  const savedProfiles: Profile[] = useMemo(
    () =>
      !data
        ? []
        : data.map((element: Profile) => ({
            ...element,
          })),
    [data],
  );

  return {
    savedProfiles,
    loading: isLoading,
    isSuccess: isSuccess,
    refetch,
  };
};
