export const PostSubjectSuggestionsMock = () => {
  return new Promise((resolve) => {
    const suggestions: string[] = [
      `SEO is not Dead: here’s why`,
      `The best automation tools that I use`,
      `My life as a startup CEO: from Bordeaux to Dubaï`,
    ];

    setTimeout(() => resolve(suggestions), 1000);
  });
};
