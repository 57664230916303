import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import {t} from '@lingui/macro';
import styled from 'styled-components';
import EditIcon from '../../../components/atoms/Icons/EditIcon';
import {LanguageSelect} from '../../../components/atoms/LanguageSelect';
import {PostStyleSelect} from './PostStyleSelect';
import LogoDotIcon from '../../../components/atoms/Icons/LogoDotIcon';
import {useTheme} from '../../../components/theme/theme';
import {isTablet} from '../../../utils/responsive';
import {
  SavedPostLanguage,
  SavedPostTone,
} from '../../../hooks/savedPost/savedPost.types';
import {StyleSource} from './ChooseStyleSource';

interface Props {
  onGoBack: () => void;
  chooseStyleSource: (styleSource: StyleSource) => void;
  setPostTone: Dispatch<SetStateAction<SavedPostTone>>;
  postTone: SavedPostTone;
  postLang: SavedPostLanguage;
  setPostLang: React.Dispatch<React.SetStateAction<SavedPostLanguage>>;
  isOnboarding: boolean;
}

export const PostStyle: FunctionComponent<Props> = ({
  onGoBack,
  chooseStyleSource,
  postTone,
  setPostTone,
  postLang,
  setPostLang,
  isOnboarding,
}) => {
  const theme = useTheme();
  return (
    <ContentContainer $isOnboarding={isOnboarding}>
      {isOnboarding && (
        <Between>
          <P>{t`writing style`}</P>
          <Div>
            <Type>
              <RedactaiPoints>
                <LogoDotIcon />
                <LogoDotIcon />
                <StyledLogoDotIcon color={theme.colors.primary1.shade2} />
              </RedactaiPoints>
              {t`From post type`}
            </Type>
            <Change onClick={onGoBack}>
              <StyledEditIcon width={20} height={20} />
              {t`Change`}
            </Change>
          </Div>
        </Between>
      )}
      <Flex>
        <PostStyleSelect {...{postTone, setPostTone, chooseStyleSource}} />
        <LanguageSelect {...{postLang, setPostLang}} />
      </Flex>
    </ContentContainer>
  );
};

const ContentContainer = styled.div<{$isOnboarding: boolean}>`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  ${({$isOnboarding}) => $isOnboarding && 'padding: 1.5em;'}
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  ${({$isOnboarding}) => $isOnboarding && 'margin-top: -0.5em;'}
  width: 100%;
  box-sizing: border-box;
`;
const Between = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    align-items: start;
    gap: 1em;
  }
`;
const P = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  @media (max-width: ${isTablet}) {
    width: 100%;
    justify-content: space-between;
  }
`;
const Type = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 1.125em;
  font-weight: 600;
`;
const Change = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.primary4.shade1};
  font-size: 0.875em;
  font-weight: 600;
  cursor: pointer;
`;
const Flex = styled.div`
  display: flex;
  gap: 1em;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    gap: 1.5em;
  }
`;
const RedactaiPoints = styled.div`
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  border: 1px solid ${({theme}) => theme.colors.primary1.shade8};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8px;
`;
const StyledLogoDotIcon = styled(LogoDotIcon)`
  margin-bottom: 1px;
`;
const StyledEditIcon = styled(EditIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
