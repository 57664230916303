import {useMutation, useQueryClient} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {IdeaToFind} from './findIdeas.types';
import {findIdeasMock} from './findIdeas.mock';

export const useFindIdeas = (onInsufficientCreditBalance: () => void) => {
  const queryClient = useQueryClient();

  const findIdeas = useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['subscription'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['savedIdeas'],
      });
    },
    mutationFn: async (idea: IdeaToFind) => {
      if (
        import.meta.env.VITE_NODE_ENV == 'development' &&
        import.meta.env.VITE_WITH_AI !== 'true'
      ) {
        return findIdeasMock();
      }
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/findPostIdeas`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(idea),
        },
      );

      if (response.ok) {
        return response;
      }

      if (response.status === 403) {
        onInsufficientCreditBalance();
        return response;
      }

      throw new Error(t`Please check if all the fields are filled correctly`);
    },
  });

  return findIdeas;
};
