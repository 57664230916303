import {FunctionComponent} from 'react';
import {CircularProgress, Chip as MUIChip} from '@mui/material';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  label: string;
  variant: 'success' | 'loading';
}

export const LoaderChip: FunctionComponent<Props> = ({label, variant}) => {
  const icon =
    variant === 'success' ? (
      <StyledCheckIcon />
    ) : (
      <StyledCircularProgress size={8} thickness={6} />
    );
  return (
    <StyledChip
      label={label}
      variant="outlined"
      $chipVariant={variant}
      icon={icon}
    />
  );
};

const StyledCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    color: ${({theme}) => theme.colors.neutral.shade10};
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  &.MuiSvgIcon-root {
    color: ${({theme}) => theme.colors.primary3.shade2};
  }
`;

export const StyledChip = styled(MUIChip)<{$chipVariant: Props['variant']}>`
  &.MuiChip-root {
    display: flex;
    gap: 0.3em;
    border-radius: 2em;
    font-weight: 600;
    font-size: 1em;
    padding: 0.3em 0.6em;

    ${({theme, $chipVariant}) => {
      switch ($chipVariant) {
        case 'success':
          return `
            border-color: ${theme.colors.primary3.shade2};
            color: ${theme.colors.primary3.shade2};
            background-color: ${theme.colors.neutral.shade1};
          `;
        case 'loading':
          return `
            border-color: ${theme.colors.neutral.shade4};
            color: ${theme.colors.neutral.shade11};
            background-color: ${theme.colors.neutral.shade1};
          `;
      }
    }}
  }
`;
