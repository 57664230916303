import React from 'react';
import {User} from './user.types';

export interface UserContext {
  me: User | undefined;
  loading: boolean;
  refetch: () => Promise<void>;
}

const userContextDefault = {
  me: undefined,
  loading: false,
  refetch: () => Promise.resolve(),
};

export const UserContext = React.createContext<UserContext>(userContextDefault);
