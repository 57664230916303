import {FunctionComponent} from 'react';
import {useTheme} from '../../theme/theme';

interface Props {
  width?: number;
  height?: number;
}

const FindIdeaIcon: FunctionComponent<Props> = ({width = 20, height = 21}) => {
  const theme = useTheme();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0001 8.60156L10.9301 10.4616C10.6901 10.8716 10.8901 11.2116 11.3601 11.2116H12.6301C13.1101 11.2116 13.3001 11.5516 13.0601 11.9616L12.0001 13.8216"
        stroke={theme.colors.primary1.shade2}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.29986 18.7511V17.5911C5.99986 16.2011 4.10986 13.4911 4.10986 10.6111C4.10986 5.66111 8.65986 1.78111 13.7999 2.90111C16.0599 3.40111 18.0399 4.90111 19.0699 6.97111C21.1599 11.1711 18.9599 15.6311 15.7299 17.5811V18.7411C15.7299 19.0311 15.8399 19.7011 14.7699 19.7011H9.25986C8.15986 19.7111 8.29986 19.2811 8.29986 18.7511Z"
        stroke={theme.colors.neutral.shade3}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 22.7102C10.79 22.0602 13.21 22.0602 15.5 22.7102"
        stroke={theme.colors.primary1.shade2}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FindIdeaIcon;
