import {FunctionComponent, ReactNode} from 'react';
import styled from 'styled-components';

interface Props {
  label: string;
  isSelected: boolean;
  icon?: ReactNode;
  onClick?: () => void;
}

export const LightTab: FunctionComponent<Props> = ({
  label,
  isSelected,
  icon,
  onClick,
}) => {
  return (
    <StyledLightTab onClick={onClick}>
      <TabLabel $isSelected={isSelected}>
        {icon && icon}
        {label}
      </TabLabel>
      <UnderLine $isSelected={isSelected} />
    </StyledLightTab>
  );
};

const StyledLightTab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  cursor: pointer;
`;

const TabLabel = styled.span<{$isSelected: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.3em;
  gap: 0.3em;
  font-weight: ${({$isSelected}) => ($isSelected ? '600' : '400')};
  color: ${({theme, $isSelected}) =>
    $isSelected ? theme.colors.neutral.shade11 : theme.colors.neutral.shade7};
  font-size: 1.3em;
`;

const UnderLine = styled.div<{$isSelected: boolean}>`
  width: 100%;
  height: 0.2em;
  background-color: ${({theme}) => theme.colors.primary1.shade2};
  opacity: ${({$isSelected}) => ($isSelected ? 1 : 0)};
  transition: opacity 0.5s ease;
  border-radius: 1em 1em 0 0;
`;
