import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import ThirdPartyEmailPassword from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import EmailVerification from 'supertokens-web-js/recipe/emailverification';

export const initSuperTokens = () => {
  SuperTokens.init({
    appInfo: {
      appName: 'RedactAI',
      apiDomain: import.meta.env.VITE_API_URL || '',
      apiBasePath: '/auth',
    },
    recipeList: [
      Session.init(),
      ThirdPartyEmailPassword.init(),
      EmailVerification.init(),
    ],
  });
};
