import {useMutation} from '@tanstack/react-query';

export const useGenerateHeadlines = () => {
  const generateHeadlines = useMutation({
    mutationFn: async (userInput: string) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/free-tools/headline-generator`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({userInput}),
        },
      );

      if (response.ok) {
        const data = await response.json();
        if (data && data.headlines) {
          return data.headlines.slice(0, 3);
        }
      }

      throw new Error('Failed to generate headlines. Please try again.');
    },
  });

  return generateHeadlines;
};
