import {FunctionComponent} from 'react';

interface Props {
  color?: string;
  size?: string;
  onClick?: () => void;
}

export const HeartIconFilled: FunctionComponent<Props> = ({
  color = '#D92020',
  size = '22',
  onClick,
}) => {
  return (
    <svg
      onClick={onClick}
      width={size}
      height={size}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.62 17.8116C10.28 17.9316 9.72 17.9316 9.38 17.8116C6.48 16.8216 0 12.6916 0 5.69156C0 2.60156 2.49 0.101562 5.56 0.101562C7.38 0.101562 8.99 0.981563 10 2.34156C11.01 0.981563 12.63 0.101562 14.44 0.101562C17.51 0.101563 20 2.60156 20 5.69156C20 12.6916 13.52 16.8216 10.62 17.8116Z"
        fill={color}
      />
    </svg>
  );
};
