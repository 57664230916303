import {FunctionComponent} from 'react';

export const TwoDoneIcon: FunctionComponent = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5503_1806)">
        <path
          d="M2.66699 12L7.91699 17L10.542 14M8.66699 12L13.917 17L22.667 7M16.667 7L13.167 11"
          stroke="#2048D7"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5503_1806">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.666992)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
