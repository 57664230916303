import {FunctionComponent} from 'react';
import {t} from '@lingui/macro';
import styled from 'styled-components';
import {isTablet} from '../../../utils/responsive';
import {LightTab} from '../../../components/atoms/LightTab';
import LinkIcon from '../../../components/atoms/Icons/LinkIcon';
import {IdeaIcon} from '../../../components/atoms/Icons/IdeaIcon';
import {GenerateFromMyOwnIdeas} from '../../onboarding/components/GenerateFromMyOwnIdeas';
import {GenerateFromContentURL} from '../../onboarding/components/GenerateFromContentURL';

export enum RedactContentSource {
  MY_OWN_IDEAS = 'MY_OWN_IDEAS',
  FROM_AN_URL = 'FROM_AN_URL',
  RECYCLE_POST = 'RECYCLE_POST',
}

interface Props {
  contentSource: RedactContentSource;
  setContentSource: (source: RedactContentSource) => void;
  postSubject: string;
  setPostSubject: (subject: string) => void;
  postIdeas: string;
  setPostIdeas: (ideas: string) => void;
  contentUrl: string;
  setContentUrl: (url: string) => void;
  errorMessage?: string | null;
  onInputChange?: () => void;
  contentUrlError: string;
  postSubjectError: string;
  postSubjectUrlError: string;
  isSubmitted: boolean;
}

export const RedactPostContent: FunctionComponent<Props> = ({
  contentSource,
  setContentSource,
  postSubject,
  setPostSubject,
  postIdeas,
  setPostIdeas,
  contentUrl,
  setContentUrl,
  contentUrlError,
  postSubjectError,
  postSubjectUrlError,
  isSubmitted,
}) => {
  return (
    <ContentContainer>
      <Between>
        <P>{t`content`}</P>
        <Div>
          <LightTab
            label={t`My own ideas`}
            isSelected={contentSource === RedactContentSource.MY_OWN_IDEAS}
            icon={<IdeaIcon size="24" />}
            onClick={() => setContentSource(RedactContentSource.MY_OWN_IDEAS)}
          />
          <LightTab
            label={t`From an URL`}
            isSelected={contentSource === RedactContentSource.FROM_AN_URL}
            icon={<StyledLinkIcon />}
            onClick={() => setContentSource(RedactContentSource.FROM_AN_URL)}
          />
        </Div>
      </Between>
      {contentSource === RedactContentSource.MY_OWN_IDEAS ? (
        <GenerateFromMyOwnIdeas
          onGoBack={() => {}}
          setPostIdeas={setPostIdeas}
          setPostSubject={setPostSubject}
          postIdeas={postIdeas}
          postSubject={postSubject}
          postSubjectError={postSubjectError}
          isSubmitted={isSubmitted}
          isOnboarding={false}
        />
      ) : (
        <GenerateFromContentURL
          onGoBack={() => {}}
          contentUrl={contentUrl}
          setContentUrl={setContentUrl}
          setPostSubject={setPostSubject}
          postSubject={postSubject}
          contentUrlError={contentUrlError}
          postSubjectUrlError={postSubjectUrlError}
          isSubmitted={isSubmitted}
          isOnboarding={false}
        />
      )}
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  box-sizing: border-box;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
`;

const Between = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    align-items: start;
    gap: 1em;
  }
`;

const P = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  @media (max-width: ${isTablet}) {
    width: 100%;
    justify-content: space-between;
  }
`;

const StyledLinkIcon = styled(LinkIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
