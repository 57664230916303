import {t} from '@lingui/macro';
import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import styled from 'styled-components';
import {isTablet} from '../../../utils/responsive';

interface Props {
  setIsToolTip: Dispatch<SetStateAction<boolean>>;
}

export const ProfileUrlStyleToolTip: FunctionComponent<Props> = ({
  setIsToolTip,
}) => {
  return (
    <Relative>
      <HelpContainer>
        <Head>{t`URL of the LinkedIn profile you want to imitate.`}</Head>
        <Flex>
          <ExampleText>{t`RedactAI will retrieve the latests posts of the given profile and generate a post with the same writing style.`}</ExampleText>
          <ExampleText>{t`It can be your profile URL or your favourite content creator on LinkedIn.`}</ExampleText>
          <ExampleText>{t`Please note that it is best to use a profile with a lot of posts, so if you're a beginner on LinkedIn we advise you to use other's profile URL.`}</ExampleText>
          <ExampleText>{t`Don’t worry, nobody will know that you used their profile url to generate posts on RedactAI.`}</ExampleText>
          <ExampleContainer>
            <Example>{t`Examples:`}</Example>
            <div>
              <ExampleText>- https://www.linkedin.com/in/pamartn/</ExampleText>
              <ExampleText>
                - https://www.linkedin.com/in/alexhormozi/
              </ExampleText>
              <ExampleText>
                - https://www.linkedin.com/in/justinwelsh/
              </ExampleText>
            </div>
          </ExampleContainer>
        </Flex>
        <NextContainer>
          <div />
          <NextText
            onClick={() => {
              setIsToolTip(false);
            }}>{t`Okay, I got it`}</NextText>
        </NextContainer>
      </HelpContainer>
      <Triangle />
    </Relative>
  );
};
const Relative = styled.div`
  position: absolute;
  right: 3em;
  bottom: 0em;
  background-color: ${({theme}) => theme.colors.neutral.shade11};
  border-radius: 1em;
  z-index: 100;
  max-width: 25em;
  @media (max-width: ${isTablet}) {
    right: 0.3em;
    bottom: 3em;
  }
`;
const HelpContainer = styled.div`
  padding: 1.25em;
  display: flex;
  flex-direction: column;
  gap: 1.25em;
  font-size: 0.9em;
  max-height: 30em;
`;
const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25em;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Head = styled.span`
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.primary1.shade2};
`;
const ExampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  @media (max-width: ${isTablet}) {
    gap: 0.5em;
  }
`;
const Example = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade6};
  letter-spacing: 0.03125em;
`;
const ExampleText = styled.p`
  margin: 0;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.neutral.shade1};
  font-weight: 600;
  line-height: 1.6em;
`;
const NextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const NextText = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade1};
  letter-spacing: 0.03125em;
  cursor: pointer;
`;
const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 7px solid ${({theme}) => theme.colors.neutral.shade11};
  transform: rotate(90deg);
  position: absolute;
  right: -10px;
  bottom: 20px;
  @media (max-width: ${isTablet}) {
    bottom: -5px;
    right: 11px;
    transform: rotate(180deg);
  }
`;
