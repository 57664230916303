import {FunctionComponent, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import CopyIcon from '../../components/atoms/Icons/CopyIcon';
import {Button} from '../../components/atoms/Button';
import {useSnackbar} from 'notistack';
import {TextInput} from '../../components/atoms/TextInput';
import {
  FilterSelect,
  FilterSelectOption,
} from '../recyclepost/components/FilterSelect';
import {Checkbox} from '../../components/atoms/Checkbox';
import GenerateIcon from '../../components/atoms/Icons/GenerateIcon';
import {useGenerateSummary} from '../../hooks/free-tools/summaryGenerator/useGenerateSummary';

const tones = ['Formal', 'Friendly', 'Humorous'];

export const SummaryGenerator: FunctionComponent = () => {
  const {enqueueSnackbar} = useSnackbar();
  const [summary, setSummary] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [targetAudience, setTargetAudience] = useState<string>('');
  const [callToAction, setCallToAction] = useState<string>('');
  const [includeEmojis, setIncludeEmojis] = useState<boolean>(false);
  const [selectedTone, setSelectedTone] = useState<FilterSelectOption<string>>({
    label: tones[0],
    value: tones[0],
  });

  const {mutateAsync: generateSummary, isPending: loading} =
    useGenerateSummary();

  const handleGenerateSummary = async () => {
    try {
      const generatedSummary = await generateSummary({
        description,
        targetAudience,
        callToAction,
        tone: selectedTone.value,
        includeEmojis,
      });
      setSummary(generatedSummary);
    } catch (error) {
      enqueueSnackbar('Error generating summary. Please try again.', {
        variant: 'error',
      });
    }
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(summary);
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
    });
  };
  const toneOptions: FilterSelectOption<string>[] = tones.map((tone) => ({
    label: tone,
    value: tone,
  }));
  return (
    <MainContainer>
      <ToolContainer>
        <Title>Describe yourself..</Title>
        <StyledTextArea
          label="What do you do?"
          placeholderText="ex: co-founder of RedactAI, a tool that helps you grow on LinkedIn"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={2}
        />
        <StyledTextArea
          label="Who are you targeting?"
          placeholderText="ex: founders, solopreneurs, freelancers, agencies"
          value={targetAudience}
          onChange={(e) => setTargetAudience(e.target.value)}
          multiline
          rows={2}
        />
        <StyledTextArea
          label="What do you want people to do after reading your summary?"
          placeholderText="ex: follow, visit website, schedule a call"
          value={callToAction}
          onChange={(e) => setCallToAction(e.target.value)}
          multiline
          rows={2}
        />
        <div style={{width: '100%'}}>
          <FilterSelect<string>
            selectedOption={selectedTone}
            setSelectedOption={setSelectedTone}
            options={toneOptions}
          />
        </div>
        <ShowEmojis>
          Include emojis in your summary{' '}
          <Checkbox
            size="small"
            isChecked={includeEmojis}
            onChange={() => setIncludeEmojis(!includeEmojis)}
          />
        </ShowEmojis>
        <SummaryContainer>
          {loading ? (
            <HeadlineBox>
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
              <SkeletonLoader
                $width={`${Math.floor(Math.random() * 41) + 30}%`}
                $height="1em"
              />
            </HeadlineBox>
          ) : (
            summary && <HeadlineBox>{summary}</HeadlineBox>
          )}
        </SummaryContainer>

        {!loading &&
          (!summary ? (
            <Button
              label="Generate Summary"
              variant="main"
              size="medium"
              onClick={handleGenerateSummary}
              endIcon={<GenerateIcon />}
            />
          ) : (
            <Row>
              <Button
                onClick={handleCopy}
                variant="light"
                size="medium"
                startIcon={<CopyIcon />}
                label="Copy"
              />
              <Button
                label="Try Again"
                variant="main"
                size="medium"
                onClick={handleGenerateSummary}
              />
            </Row>
          ))}
      </ToolContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 60em;
  min-height: calc(100vh - 5.5em);
  padding: 0 1.5em;
`;

const ToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 2em;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  border-radius: 1em;
  padding: 1.5em;
`;

const StyledTextArea = styled(TextInput)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 0.5em;
    padding-left: 0.4em;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 1.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  margin: 0;
  align-self: flex-start;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const SkeletonLoader = styled.div<{
  $width: string;
  $height: string;
  $borderRadius?: string;
  $marginBottom?: string;
}>`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  border-radius: ${(props) => props.$borderRadius || '0.5em'};
  margin-bottom: ${(props) => props.$marginBottom || '0'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
  margin: 1em 0;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
`;

const HeadlineBox = styled.div`
  padding: 1em;
  border: 2px solid ${({theme}) => theme.colors.primary1.shade1};
  border-radius: 1em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  animation: ${fadeIn} 0.5s ease;
  font-size: 1em;
  font-weight: 500;
  color: ${({theme}) => theme.colors.neutral.shade11};
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const Row = styled.div`
  display: flex;
  gap: 1.5em;
`;

const ShowEmojis = styled.div`
  font-size: 1em;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.3em;
  width: 100%;
`;
