import {isWithinInterval} from 'date-fns';
import {useSearchParams} from 'react-router-dom';

export const useIsBlackFriday = () => {
  const blackFridayStartDate = new Date('2024-11-28T00:00:00Z');
  const blackFridayEndDate = new Date('2024-12-04T00:00:00Z');
  const currentDate = new Date();
  const [searchParams] = useSearchParams();

  return (
    isWithinInterval(currentDate, {
      start: blackFridayStartDate,
      end: blackFridayEndDate,
    }) || searchParams.get('isBlackFriday') === 'true'
  );
};
