import {useMutation, useQueryClient} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {postApi} from '../../utils/fetchApi';
import {SavedPostType} from '../savedPost/savedPost.types';

export const useMarkPostAsFavourite = () => {
  const queryClient = useQueryClient();
  const markPostAsFavourite = useMutation({
    mutationFn: async (id: string) => {
      await postApi(
        `/savedPosts/favourite/${id}`,
        {
          method: 'POST',
        },
        t`Unable to mark the post as favourite`,
      );
    },
    onMutate: async (id: string) => {
      await queryClient.cancelQueries({queryKey: ['savedPosts']});

      const previousPosts = queryClient.getQueryData(['savedPosts']);

      queryClient.setQueryData<SavedPostType[]>(['savedPosts'], (posts) => {
        return posts?.map((post) =>
          post.id === id ? {...post, isFavourite: !post.isFavourite} : post,
        );
      });

      return {previousPosts};
    },
    onError: (_err, _id, context) => {
      queryClient.setQueryData(['savedPosts'], context?.previousPosts);
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['savedPosts']});
    },
  });

  return markPostAsFavourite;
};
