import {FunctionComponent} from 'react';
import {Modal} from './Modal';
import styled from 'styled-components';
import {Button} from '../../atoms/Button';
import {t} from '@lingui/macro';
import {isTablet} from '../../../utils/responsive';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
  setOtherReason: (reason: string) => void;
  otherReason: string;
}

export const DescribeReason: FunctionComponent<Props> = ({
  onClose,
  onSubmit,
  setOtherReason,
  otherReason,
}) => {
  return (
    <Modal onClose={onClose}>
      <Container>
        <Col>
          <Text>{t`Describe the reason`}</Text>
          <Textarea
            placeholder={t`Type here..`}
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
          />
        </Col>
        <Row>
          <Button
            onClick={onClose}
            label={t`Cancel`}
            size="medium"
            variant="light"
            fullWidth
          />
          <StyledButton
            onClick={onSubmit}
            label={t`Submit`}
            size="medium"
            variant="main"
            fullWidth
          />
        </Row>
      </Container>
    </Modal>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  @media (max-width: ${isTablet}) {
    margin-top: 1em;
  }
`;
const Row = styled.div`
  display: flex;
  gap: 2em;
  width: 100%;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
`;
const Text = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 0.0125em;
`;
const Textarea = styled.textarea`
  resize: none;
  width: 29.75em;
  height: 9.875em;
  border-radius: 0.75em;
  padding: 1em;
  border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
  outline: none;
  box-sizing: border-box;
  font-family: ${({theme}) => theme.fontFamily};
  line-height: 1.5em;
  letter-spacing: 0.03125em;
  font-size: 0.9em;
`;
const StyledButton = styled(Button)`
  width: 100%;
`;
