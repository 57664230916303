import {useCallback} from 'react';
import {fetchApi} from '../../utils/fetchApi';

export const useRedirectToCustomerPortal = () =>
  useCallback(async () => {
    const url = (await fetchApi('/stripe/createPortalSession')).url;
    if (!url) {
      return;
    }
    window.location.href = url;
  }, []);
