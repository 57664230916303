import {FunctionComponent} from 'react';

interface Props {
  size?: number;
}

export const MobileIcon: FunctionComponent<Props> = ({size = 16}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M10.828 1.33203H5.17464C3.33464 1.33203 2.66797 1.9987 2.66797 3.87203V12.1254C2.66797 13.9987 3.33464 14.6654 5.17464 14.6654H10.8213C12.668 14.6654 13.3346 13.9987 13.3346 12.1254V3.87203C13.3346 1.9987 12.668 1.33203 10.828 1.33203Z"
        fill="#FFAC3B"
      />
      <path
        d="M9.33464 4.16797H6.66797C6.39464 4.16797 6.16797 3.9413 6.16797 3.66797C6.16797 3.39464 6.39464 3.16797 6.66797 3.16797H9.33464C9.60797 3.16797 9.83464 3.39464 9.83464 3.66797C9.83464 3.9413 9.60797 4.16797 9.33464 4.16797Z"
        fill="#FFAC3B"
      />
      <path
        d="M7.9987 12.8685C8.64303 12.8685 9.16536 12.3462 9.16536 11.7018C9.16536 11.0575 8.64303 10.5352 7.9987 10.5352C7.35437 10.5352 6.83203 11.0575 6.83203 11.7018C6.83203 12.3462 7.35437 12.8685 7.9987 12.8685Z"
        fill="#FFAC3B"
      />
    </svg>
  );
};
