import {FunctionComponent} from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {}

export const VerificationCheck: FunctionComponent<Props> = (props) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 70 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="35" cy="34.5" r="34.5" fill="#21C07D" />
      <path
        d="M24.5 34.75L30.9815 41L45.5 27"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};
