import {FunctionComponent, useCallback, useState} from 'react';
import styled from 'styled-components';
import {Status} from '../atoms/Status';
import EditIcon from '../atoms/Icons/EditIcon';
import {format} from 'date-fns';
import {t} from '@lingui/macro';
import {isTablet} from '../../utils/responsive';
import {useNavigate} from 'react-router-dom';
import {CancelSubscription} from '../../pages/settings/components/CancelSubscription';
import {useRedirectToCustomerPortal} from '../../hooks/subscription/useRedirectToCustomerPortal.ts';
import {useUserContext} from '../../common/UserContext/useUserContext.ts';
import {UNLIMITED_SUBSCRIPTION_NUMBER_OF_POSTS} from './SubscriptionInformationCard.tsx';
import {useTracking} from '../../hooks/useTracking.tsx';

interface Props {
  currentPlan: 'Annual' | 'Monthly';
  renewalDate: Date;
  posts: number;
  cancelsAtPeriodEnd: boolean;
  validUntil: Date | null;
}

export const CurrentSubscription: FunctionComponent<Props> = ({
  currentPlan,
  renewalDate,
  posts,
  cancelsAtPeriodEnd,
  validUntil,
}) => {
  const navigate = useNavigate();
  const [isCancelSubscription, setIsCancelSubscription] =
    useState<boolean>(false);
  const {me} = useUserContext();
  const {trackEvent} = useTracking();
  const [loading, setLoading] = useState<boolean>(false);

  const redirectToCustomerPortal = useRedirectToCustomerPortal();

  const onEditPlan = useCallback(async () => {
    if (loading) {
      return;
    }
    if (me?.stripeCustomerId) {
      setLoading(true);
      await redirectToCustomerPortal();
      setLoading(false);
      return;
    }
    navigate('/pricing');
  }, [me?.stripeCustomerId, navigate, redirectToCustomerPortal, loading]);

  const onCancel = useCallback(() => {
    trackEvent('Cancel Subscription - Click - Cancel');
    setIsCancelSubscription(true);
  }, [trackEvent]);
  return (
    <Container>
      <FlexRow>
        <CurrentPlan>
          <StrongText>{t`Current plan`}</StrongText>
          <Status
            label={currentPlan === 'Annual' ? t`Annual` : t`Monthly`}
            variant="info"
          />
        </CurrentPlan>
        <ActionsWrapperDesktop>
          <Action $loading={loading} onClick={onEditPlan}>
            <EditIcon /> {t`Edit plan`}
          </Action>
          {!cancelsAtPeriodEnd && (
            <>
              <Line />
              <Action onClick={onCancel}>{t`Cancel subscription`}</Action>
            </>
          )}
        </ActionsWrapperDesktop>
      </FlexRow>
      <div>
        <RenewalDate>
          {posts === UNLIMITED_SUBSCRIPTION_NUMBER_OF_POSTS ? (
            t`Unlimited posts`
          ) : (
            <>
              <StrongText>
                {posts} {posts > 1 ? t`posts` : t`post`}{' '}
              </StrongText>
              {t`per month.`}
            </>
          )}
        </RenewalDate>
        {!cancelsAtPeriodEnd ? (
          <RenewalDate>
            {t`Your plan will be renewed on`}
            <StrongText> {format(renewalDate, 'do MMMM yyyy')}</StrongText>
          </RenewalDate>
        ) : (
          <>
            {validUntil && (
              <RenewalDate>
                {t`Your subscription will be valid until`}{' '}
                <StrongText>{format(validUntil, 'do MMMM yyyy')}</StrongText>
              </RenewalDate>
            )}
            <StyledStatus
              label={t`Subscription canceled`}
              variant={'warningOutlined'}
            />
          </>
        )}
      </div>
      <ActionsWrapperMobile>
        <Action $loading={loading} onClick={onEditPlan}>
          <EditIcon /> {t`Edit plan`}
        </Action>
        {!cancelsAtPeriodEnd && (
          <>
            <Line />
            <Action onClick={onCancel}>{t`Cancel subscription`}</Action>
          </>
        )}
      </ActionsWrapperMobile>
      {isCancelSubscription && (
        <CancelSubscription setIsCancelSubscription={setIsCancelSubscription} />
      )}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 1.5em;
  padding: 1.5em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
  display: flex;
  flex-direction: column;
  gap: 1em;
  box-sizing: border-box;
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CurrentPlan = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const ActionsWrapperDesktop = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
const ActionsWrapperMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  @media (min-width: ${isTablet}) {
    display: none;
  }
`;
const Action = styled.span<{$loading?: boolean}>`
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.25em;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.primary4.shade1};
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: ${({$loading}) => ($loading ? 'default' : 'pointer')};
`;

const RenewalDate = styled.div`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1em;
  letter-spacing: 0.03125em;
  line-height: 1.5em;
  @media (max-width: ${isTablet}) {
  }
`;
const StrongText = styled.span`
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Line = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade5};
  width: 1px;
  height: 11px;
`;
const StyledStatus = styled(Status)`
  margin-top: 0.75em;
`;
