import {FunctionComponent} from 'react';
import {useTheme} from '../../theme/theme';

interface Props {
  width?: number;
  height?: number;
}

const CreatePostIcon: FunctionComponent<Props> = ({
  width = 20,
  height = 21,
}) => {
  const theme = useTheme();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 2.71094H9C4 2.71094 2 4.71094 2 9.71094V15.7109C2 20.7109 4 22.7109 9 22.7109H15C20 22.7109 22 20.7109 22 15.7109V13.7109"
        stroke={theme.colors.neutral.shade3}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0399 3.73021L8.15988 11.6102C7.85988 11.9102 7.55988 12.5002 7.49988 12.9302L7.06988 15.9402C6.90988 17.0302 7.67988 17.7902 8.76988 17.6402L11.7799 17.2102C12.1999 17.1502 12.7899 16.8502 13.0999 16.5502L20.9799 8.67021C22.3399 7.31021 22.9799 5.73021 20.9799 3.73021C18.9799 1.73021 17.3999 2.37021 16.0399 3.73021Z"
        stroke={theme.colors.primary1.shade2}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9102 4.86133C15.5802 7.25133 17.4502 9.12133 19.8502 9.80133"
        stroke={theme.colors.primary1.shade2}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreatePostIcon;
