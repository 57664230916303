import {FunctionComponent, Dispatch, SetStateAction, useState} from 'react';
import styled from 'styled-components';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import HdrAutoOutlinedIcon from '@mui/icons-material/HdrAutoOutlined';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import {string_to_unicode_variant} from 'string-to-unicode-variant';

interface Props {
  postText: string;
  setPostText: Dispatch<SetStateAction<string>>;
}

export const Editor: FunctionComponent<Props> = ({postText, setPostText}) => {
  const [history, setHistory] = useState<string[]>([postText]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);

  const updatePostText = (newText: string) => {
    const newHistory = history.slice(0, currentHistoryIndex + 1);
    setHistory([...newHistory, newText]);
    setCurrentHistoryIndex(newHistory.length);
    setPostText(newText);
  };

  const applyFormatting = (variant: string, combinings: string) => {
    const textArea = document.getElementById('editor') as HTMLTextAreaElement;
    const start = textArea.selectionStart;
    const end = textArea.selectionEnd;

    if (start === end) return;

    const selectedText = postText.slice(start, end);
    const formattedText = string_to_unicode_variant(
      selectedText,
      variant,
      combinings,
    );
    const newText =
      postText.slice(0, start) + formattedText + postText.slice(end);

    updatePostText(newText);
    setTimeout(() => {
      textArea.focus();
      textArea.setSelectionRange(end, end);
    }, 0);
  };

  const handleUndo = () => {
    if (currentHistoryIndex > 0) {
      const previousText = history[currentHistoryIndex - 1];
      setCurrentHistoryIndex(currentHistoryIndex - 1);
      setPostText(previousText);
    }
  };

  const handleRedo = () => {
    if (currentHistoryIndex < history.length - 1) {
      const nextText = history[currentHistoryIndex + 1];
      setCurrentHistoryIndex(currentHistoryIndex + 1);
      setPostText(nextText);
    }
  };

  return (
    <Container>
      <Toolbar>
        <ToolbarButton onClick={() => applyFormatting('bs', '')}>
          <FormatBoldIcon />
        </ToolbarButton>
        <ToolbarButton onClick={() => applyFormatting('is', '')}>
          <FormatItalicIcon />
        </ToolbarButton>
        <ToolbarButton onClick={() => applyFormatting('bis', '')}>
          <FormatBoldIcon />
          <StyledFormatItalicIcon />
        </ToolbarButton>
        <ToolbarButton onClick={() => applyFormatting('s', 'u')}>
          <FormatUnderlinedIcon />
        </ToolbarButton>
        <ToolbarButton onClick={() => applyFormatting('s', 's')}>
          <StrikethroughSIcon />
        </ToolbarButton>
        <ToolbarButton onClick={() => applyFormatting('o', '')}>
          <HdrAutoOutlinedIcon />
        </ToolbarButton>
        <ToolbarButton onClick={() => applyFormatting('on', '')}>
          <HdrAutoIcon />
        </ToolbarButton>
        <ToolbarButton
          onClick={handleUndo}
          disabled={currentHistoryIndex === 0}>
          <UndoIcon />
        </ToolbarButton>
        <ToolbarButton
          onClick={handleRedo}
          disabled={currentHistoryIndex === history.length - 1}>
          <RedoIcon />
        </ToolbarButton>
      </Toolbar>

      <EditorContainer
        id="editor"
        placeholder="Write here.."
        value={postText}
        onChange={(e) => updatePostText(e.target.value)}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Toolbar = styled.div`
  padding: 0.5em 1em;
  position: sticky;
  top: 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.neutral.shade6};
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5em;
`;

const EditorContainer = styled.textarea`
  outline: none;
  word-wrap: break-word;
  white-space: pre-wrap;
  padding: 1em;
  flex: 1;
  line-height: 1.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  letter-spacing: 0.03125em;
  font-size: 1em;
  font-family: ${({theme}) => theme.fontFamily};
  resize: none;
  border: none;
`;

const ToolbarButton = styled.button<{disabled?: boolean}>`
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em;
  border-radius: 0.5em;
  background-color: transparent;
  transition: all 0.3s;
  outline: none;
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
  cursor: pointer;

  &:hover {
    background-color: ${({theme}) => theme.colors.primary1.shade2};
  }
`;
const StyledFormatItalicIcon = styled(FormatItalicIcon)`
  margin-left: -10px;
`;
