import {SavedPostLanguage, SavedPostTone} from '../savedPost/savedPost.types';

export interface PostToRedact {
  subject: string;
  contentSource: PostContentSource;
  content: ContentFromUrl | ContentFromIdeas | ContentFromRecyclePost;
  styleSource: PostStyleSource;
  style: StyleFromProfile | StyleFromTone;
  language: SavedPostLanguage;
}

export enum PostContentSource {
  URL = 'URL',
  IDEAS = 'IDEAS',
  RECYCLE_POST = 'RECYCLE_POST',
}

export enum PostStyleSource {
  PROFILE = 'PROFILE',
  TONE = 'TONE',
}

export interface ContentFromUrl {
  url: string;
}

export interface ContentFromIdeas {
  ideas: string;
}

export interface ContentFromRecyclePost {
  post: string;
}

export interface StyleFromProfile {
  url: string;
}

export interface StyleFromTone {
  tone: SavedPostTone;
}
