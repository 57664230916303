import {FunctionComponent} from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
  isSelected?: boolean;
  onClick?: () => void;
}

export const SelectButtonListOfStyle: FunctionComponent<Props> = ({
  text,
  isSelected,
  onClick,
}) => {
  return (
    <StyledButton $isSelected={isSelected} onClick={onClick}>
      {text}
    </StyledButton>
  );
};

const StyledButton = styled.button<{$isSelected?: boolean}>`
  border-radius: 0.875em;
  padding: 0 1.25em;
  letter-spacing: 0.0125em;
  height: 2.9em;
  line-height: 1.25em;
  font-weight: ${({$isSelected}) => $isSelected && `600`};
  font-size: 0.875em;
  cursor: pointer;
  color: ${({theme}) => theme.colors.neutral.shade11};
  background-color: ${({theme, $isSelected}) =>
    $isSelected ? theme.colors.primary1.shade5 : theme.colors.neutral.shade13};
  border: none;
  ${({theme, $isSelected}) =>
    $isSelected &&
    `
      border: 2px solid ${theme.colors.primary1.shade2};
    `}
`;
