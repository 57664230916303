import {t} from '@lingui/macro';
import {formatRelativeTime} from './formatRelativeTime';

export const formatCreatedAt = (
  createdAt: string | Date | undefined,
): string => {
  if (!createdAt) {
    return t`Now`;
  }

  if (typeof createdAt === 'string') {
    return createdAt;
  }

  return formatRelativeTime(createdAt);
};
