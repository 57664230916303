import {FunctionComponent} from 'react';
import {useTheme} from '../../theme/theme';

interface Props {
  width?: number;
  height?: number;
}

const RecyclePostIcon: FunctionComponent<Props> = ({
  width = 20,
  height = 21,
}) => {
  const theme = useTheme();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.11008 5.79133C9.98008 5.53133 10.9401 5.36133 12.0001 5.36133C16.7901 5.36133 20.6701 9.24133 20.6701 14.0313C20.6701 18.8213 16.7901 22.7013 12.0001 22.7013C7.21008 22.7013 3.33008 18.8213 3.33008 14.0313C3.33008 12.2513 3.87008 10.5913 4.79008 9.21133"
        stroke={theme.colors.neutral.shade3}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.87012 6.03094L10.7601 2.71094"
        stroke={theme.colors.primary1.shade2}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.87012 6.03125L11.2401 8.49125"
        stroke={theme.colors.primary1.shade2}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RecyclePostIcon;
