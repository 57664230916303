import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import styled from 'styled-components';
import {DeleteAccountPopupConfirmation} from '../../../components/molecules/popups/DeleteAccountPopupConfirmation';
import {DeleteAccountPopupConfirmed} from '../../../components/molecules/popups/DeleteAccountPopupConfirmed';
import {UserContext} from '../../../common/UserContext/user.context';
import {useSnackbar} from 'notistack';
import {useDeleteAccount} from '../../../hooks/deleteAccount/useDeleteAccount';
import {t} from '@lingui/macro';
import {CircularLoader} from '../../../components/atoms/CircularLoader';
import {useSubscription} from '../../../hooks/subscription/useSubscription';

interface Props {
  setShowDeleteAccountPopup: Dispatch<SetStateAction<boolean>>;
  onSignout: () => void;
}

export const DeleteAccount: FunctionComponent<Props> = ({
  setShowDeleteAccountPopup,
  onSignout,
}) => {
  const {me, refetch} = useContext(UserContext);
  const {enqueueSnackbar} = useSnackbar();
  const deleteAccount = useDeleteAccount();
  const {subscription} = useSubscription();
  const onDelete = () => {
    if (me?.stripeCustomerId && subscription?.isActive) {
      enqueueSnackbar(
        t`You have an active subscription. Please cancel your subscription in the subscription tab before deleting your account.
    `,
        {
          variant: 'error',
          autoHideDuration: 6000,
        },
      );
      onClose();
      return;
    }
    deleteAccount.mutate();
  };
  const onClose = useCallback(() => {
    setShowDeleteAccountPopup(false);
  }, [setShowDeleteAccountPopup]);

  useEffect(() => {
    if (deleteAccount.error) {
      enqueueSnackbar(deleteAccount.error.message, {
        variant: 'error',
      });
      onClose();
      return;
    }
  }, [
    deleteAccount.error,
    enqueueSnackbar,
    onClose,
    deleteAccount.isSuccess,
    onSignout,
  ]);

  return (
    <PreviewPopupContainer>
      {deleteAccount.isPending ? (
        <CircularLoader />
      ) : deleteAccount.isSuccess ? (
        <DeleteAccountPopupConfirmed onClose={() => refetch()} />
      ) : (
        <DeleteAccountPopupConfirmation onClose={onClose} onDelete={onDelete} />
      )}
    </PreviewPopupContainer>
  );
};

const PreviewPopupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000099;
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 60;
`;
