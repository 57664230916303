import {
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import {NavigationBar, SIDEBAR_WIDTH} from '../organisms/NavigationBar';
import {isSmallScreen} from '../../utils/responsive';
import {MenuIcon} from '../atoms/Icons/MenuIcon';
import {RedactaiIcon} from '../atoms/Icons/RedactaiIcon';
import {useLocation, useNavigate} from 'react-router-dom';
import {NavigationBarMobile} from '../organisms/NavigationBarMobile';
import {PricingPopup} from '../organisms/PricingPopup';
import {UserContext} from '../../common/UserContext/user.context';
import {CircularLoader} from '../atoms/CircularLoader';
import {addDays, differenceInSeconds} from 'date-fns';
import {useSubscription} from '../../hooks/subscription/useSubscription';
import SettingsIcon from '../atoms/Icons/SettingsIcon';
import MyPostsIcon from '../atoms/Icons/MyPostsIcon';
import RecyclePostIcon from '../atoms/Icons/RecyclePostIcon';
import FindIdeaIcon from '../atoms/Icons/FindIdeaIcon';
import CreatePostIcon from '../atoms/Icons/CreatePostIcon';
import {t} from '@lingui/macro';
import {SubscriptionInformationCard} from '../molecules/SubscriptionInformationCard';
import {creditsToPost} from '../../utils/creditsToPost';
import ConfettiExplosion from 'react-confetti-explosion';
import {emailVerificationCheck} from '../../utils/emailVerificationCheck';
import {LoadingSubscriptionInformationCard} from '../molecules/LoadingSubscriptionInformationCard';
import {endOfDay} from 'date-fns';
import {useIsBlackFriday} from '../../hooks/useIsBlackFriday.ts';

export interface Route {
  name: string;
  path: string;
  icon: ReactNode;
  isNew?: boolean;
}

interface Props {
  children?: ReactNode;
  className?: string;
}

const getBlackFridayTimeLeft = () => {
  const today = new Date();
  const end = endOfDay(today);
  let timeDifference = differenceInSeconds(end, today);

  const hoursLeft = Math.floor(timeDifference / 3600);
  timeDifference -= hoursLeft * 3600;
  const minutesLeft = Math.floor((timeDifference % 3600) / 60);
  timeDifference -= minutesLeft * 60;
  const secondsLeft = timeDifference;

  const pad = (n: number) => String(n).padStart(2, '0');

  return `${pad(hoursLeft)}:${pad(minutesLeft)}:${pad(secondsLeft)}`;
};

export const Page: FunctionComponent<Props> = ({children, className}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const location = useLocation();
  const parseQueryParams = (query: string) => new URLSearchParams(query);
  const queryParams = parseQueryParams(location.search);
  const [showPricingPopup, setShowPricingPopup] = useState<boolean>(false);
  const {subscription} = useSubscription();
  const navigate = useNavigate();
  const {me, loading, refetch} = useContext(UserContext);

  const [blackFridayTimeLeft, setBlackFridayTimeLeft] = useState<string>(
    getBlackFridayTimeLeft(),
  );
  const isBlackFriday = useIsBlackFriday();

  useEffect(() => {
    const interval = setInterval(() => {
      setBlackFridayTimeLeft(getBlackFridayTimeLeft());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const renewalDate = useMemo(() => {
    if (!subscription) return null;

    if (subscription.isInTrial) {
      const trialEndDate = addDays(new Date(subscription.createdAt), 7);
      return trialEndDate;
    }

    return subscription.creditsRenewalDate;
  }, [subscription]);

  useEffect(() => {
    if (
      location.pathname !== '/onboarding' &&
      me?.hasFinishedOnboarding === false
    ) {
      navigate('/onboarding');
    }
  }, [me?.hasFinishedOnboarding, navigate, location.pathname]);

  useEffect(() => {
    if (!me) {
      refetch();
    }
  }, [me, refetch, loading]);

  useEffect(() => {
    const verifyEmail = async () => {
      await emailVerificationCheck(navigate);
    };

    verifyEmail();
  }, [navigate]);

  if (!me || loading) {
    return <CircularLoader />;
  }

  const routes: Route[] = [
    {name: t`Find an idea`, path: '/findIdea', icon: <FindIdeaIcon />},
    {
      name: t`Create a post`,
      path: '/redactPost',
      icon: <CreatePostIcon />,
      isNew: true,
    },
    ...(new Date(me.createdAt) < new Date('2024-10-21T00:00:00')
      ? [
          {
            name: t`Create a post`,
            path: '/redactPostOld',
            icon: <CreatePostIcon />,
          },
        ]
      : []),
    {
      name: t`Recycle a post`,
      path: '/recyclePost',
      icon: <RecyclePostIcon />,
    },
    {name: t`My posts`, path: '/myposts', icon: <MyPostsIcon />},
    {name: t`Settings`, path: '/settings', icon: <SettingsIcon />},
    // {name: t`Help & support`, path: '/help', icon: <HelpIcon />},
  ];

  return (
    <Container className={className}>
      {queryParams.get('isNewSubscription') === 'true' && (
        <StyledConfettiExplosion
          force={1.0}
          particleCount={200}
          width={window.innerWidth}
        />
      )}
      <NavigationBarMobileContainer $isActive={isActive}>
        {isActive && (
          <NavigationBarMobile
            {...{isActive, setIsActive, routes, subscription, renewalDate}}
          />
        )}
      </NavigationBarMobileContainer>
      <NavigationBarContainer>
        <NavigationBar {...{routes, subscription, renewalDate}} />
      </NavigationBarContainer>
      <Header>
        <Between>
          <Row>
            <StyledMenuIcon onClick={() => setIsActive(true)} />
            <StyledRedactaiIcon
              onClick={() => navigate('/redactPost')}
              color1="white"
              width={124}
              height={17}
            />
          </Row>
          {subscription && !loading ? (
            <SubscriptionInformationCard
              isMobile
              name={`${me?.firstName} ${me?.lastName}`}
              numberOfPostsLeft={creditsToPost(
                subscription?.remainingCredits || 0,
              )}
              numberOfPosts={creditsToPost(subscription?.product.credits || 0)}
              renewalDate={renewalDate!}
              canceled={!subscription?.isActive}
            />
          ) : (
            <LoadingSubscriptionInformationCard isMobile />
          )}
        </Between>
      </Header>
      <PageContentContainer>
        {isBlackFriday && (
          <BlackFridayContainer
            onClick={() => {
              navigate('/pricing');
            }}>
            <BlackFridayTitle>
              {t`BLACKFRIDAY SALE`}
              <BlackFridayTitleSpan>
                <DiscountSpan>-50%</DiscountSpan>
                {t`off on annual subscriptions`}
              </BlackFridayTitleSpan>
            </BlackFridayTitle>
            <span>
              {t`Only`}
              <TimeLeft>{blackFridayTimeLeft}</TimeLeft> {t`hours left`}
            </span>
          </BlackFridayContainer>
        )}
        <PageContentInnerContainer>{children}</PageContentInnerContainer>
      </PageContentContainer>
      {showPricingPopup && (
        <PricingPopup onClose={() => setShowPricingPopup(false)} />
      )}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  background-color: ${({theme}) => theme.colors.neutral.shade13};
  height: 100vh;
`;

const PageContentContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${isSmallScreen}) {
    padding-top: 6em;
  }
  @media (min-width: ${isSmallScreen}) {
    margin-left: calc(${SIDEBAR_WIDTH}em + 3em);
  }
`;

const PageContentInnerContainer = styled.div`
  padding-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  height: 100%;
`;

const Header = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade14};
  padding: 3em 2.5em;
  padding-bottom: 2em;
  position: fixed;
  top: -1em;
  right: -1em;
  left: -1em;
  z-index: 10;
  @media (min-width: ${isSmallScreen}) {
    display: none;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75em;
`;
const Between = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StyledMenuIcon = styled(MenuIcon)`
  cursor: pointer;
`;
const NavigationBarMobileContainer = styled.div<{$isActive: boolean}>`
  background-color: #00000099;
  backdrop-filter: blur(3px);
  position: fixed;
  z-index: 20;
  width: 100vw;
  opacity: ${({$isActive}) => ($isActive ? '1' : '0')};
  transition: opacity 0.2s ease-out;
`;
const NavigationBarContainer = styled.div`
  z-index: 20;
  position: fixed;
`;
const StyledRedactaiIcon = styled(RedactaiIcon)`
  cursor: pointer;
`;
const StyledConfettiExplosion = styled(ConfettiExplosion)`
  position: absolute;
  left: 50%;
`;

const BlackFridayContainer = styled.div`
  padding-top: 1em;
  padding-bottom: 0.5em;
  width: 100%;
  font-size: 1.2em;
  color: ${({theme}) => theme.colors.neutral.shade1};
  background-color: ${({theme}) => theme.colors.neutral.shade14};
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  cursor: pointer;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
  }
`;

const BlackFridayTitle = styled.div`
  font-size: 4em;
  color: ${({theme}) => theme.colors.neutral.shade1};
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.5em;
  margin-left: 0.5em;
  text-align: center;
  animation-duration: 3s;
  /* animation-iteration-count: 3; */
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: light;

  @keyframes light {
    0% {
      text-shadow:
        0 0 2px #fff,
        0 0 8px #ffac3b,
        0 0 16px #ffac3b;
    }

    20% {
      text-shadow: none;
    }

    30% {
      text-shadow:
        0 0 2px #fff,
        0 0 8px #ffac3b,
        0 0 16px #ffac3b;
    }

    50% {
      text-shadow: none;
    }

    70% {
      text-shadow:
        0 0 2px #fff,
        0 0 8px #ffac3b,
        0 0 16px #ffac3b;
    }

    100% {
      text-shadow:
        0 0 2px #fff,
        0 0 8px #ffac3b,
        0 0 16px #ffac3b;
    }
  }
`;

const BlackFridayTitleSpan = styled.span`
  font-size: 0.5em;
  font-weight: 700;
  background-color: ${({theme}) => theme.colors.primary1.shade2};
  padding-left: 0.5em;
  padding-right: 0.5em;
  transform: skew(-15deg);
  gap: 0;
  text-shadow: none;
`;

const TimeLeft = styled.div`
  font-size: 2.5em;
  color: ${({theme}) => theme.colors.neutral.shade1};
  font-weight: 800;
`;

const DiscountSpan = styled.span`
  font-size: 1.3em;
  gap: 0;
`;
