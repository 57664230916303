import {FunctionComponent} from 'react';
import {Checkbox as MUICheckbox} from '@mui/material';
import styled from 'styled-components';

interface Props {
  isChecked?: boolean;
  size: 'small' | 'medium';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
}

export const Checkbox: FunctionComponent<Props> = ({
  isChecked = false,
  size,
  onChange,
  error = false,
}) => {
  return (
    <StyledCheckbox
      checked={isChecked}
      onChange={onChange}
      $size={size}
      $error={error}
    />
  );
};

export const StyledCheckbox = styled(MUICheckbox)<{
  $size: 'small' | 'medium';
  $error: boolean;
}>`
  .MuiSvgIcon-root {
    width: ${(props) => (props.$size === 'small' ? '1.2em' : '1.7em')};
    height: ${(props) => (props.$size === 'small' ? '1.2em' : '1.7em')};
  }

  &.MuiCheckbox-root {
    color: ${({theme, $error}) =>
      $error ? theme.colors.primary2.shade3 : theme.colors.neutral.shade8};
  }

  &.Mui-checked {
    color: ${({theme}) => theme.colors.primary1.shade2};
  }
`;
